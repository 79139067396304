import axios from "axios";
import React from "react";
export const requestResult = async (props) => {
  let requestData = await axios.get(
    `https://agrodigital.io/api/logs/?field=${props.fieldId}&type=${props.type}`,
    props.tokenUser
  );
  let searchPolygons = await axios.get(
    `https://agrodigital.io/api/polygons/?field=${props.fieldId}`,
    props.tokenUser
  );

  let filterByPolygonAndDate = requestData.data.filter(
    (el) =>
      el.date === props.date && el.polygons.includes(props.polygonSelected[0])
  )[0]?.polygons;
  if (filterByPolygonAndDate?.length > 1) {
    let namePolygons = searchPolygons.data.filter((el) => {
      if (filterByPolygonAndDate.includes(el.id)) {
        return el.name;
      }
    });

    return namePolygons;
  }
};

export const AlertMultiPolygons = (props) => {
  return (
    <div style={{ textAlign: "center", color: "red", fontWeight: "600" }}>
      <p>{`Esta acción afectará a los polígonos: ${props.nameOfAllPolygons
        .map((el) => {
          if (el.name?.length > 13) {
            return " " + el.name.substring(0, 14) + "... ";
          } else {
            return " " + el.name;
          }
        })
        .toLocaleString()}`}</p>
    </div>
  );
};

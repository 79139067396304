import React, { useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";

import { Button, CardBody, CardTitle, CardSubtitle, Modal } from "reactstrap";
const SaveChangesModal = (props) => {
  const [t, i18n] = useTranslation("global")

  return (
    <Modal isOpen={props.status} toggle={() => props.toggle()} 
    
    style={{ display: "table", marginTop: "20vh", minWidth: "700px" }}
    >

      <CardBody>
        <div
          className="card-avatar"
          style={{ position: "absolute", padding: "10px", left: "0", top: "0" }}
        >
          <img
            style={{ height: "50px", display: "block" }}
            alt="..."
            className="img"
            src={require("assets/img/oryzativa_logo.png")}
          />
        </div>
        <br/>
        <br/>
        <br/>
        <CardSubtitle className="mb-2" style={{color:"black", fontWeight:"500", textAlign:"center"}}>
         {props.msg}
        </CardSubtitle>
        <div style={{textAlign:"center"}}>
        <Button
          onClick={() => {
            props.toggle()
          }}
        >
          {t("buttons.understoodButton")}
        </Button>
        </div>
      </CardBody>
    </Modal>
  );
};

export default SaveChangesModal;

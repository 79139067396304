/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import PropTypes from "prop-types";
import routes from "routes.js";
// var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "success",
      sidebarMini: false,
      sidebarMapOpen: false,
    };
    this.onMapSidebarOpen = this.onMapSidebarOpen.bind(this);
  }
  getLogin(history) {
    let token = localStorage.getItem("token");
    if (token) {
    } else {
      history.push("/auth/login");
    }
  }
  onMapSidebarOpen(open) {
    this.setState({ sidebarMapOpen: open });
  }
  // componentDidMount() {
  //   this.getLogin(this.props.history);
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     document.documentElement.className += " perfect-scrollbar-on";
  //     document.documentElement.classList.remove("perfect-scrollbar-off");
  //     // ps = new PerfectScrollbar(this.refs.mainPanel);
  //   }
  // }
  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //     document.documentElement.className += " perfect-scrollbar-off";
  //     document.documentElement.classList.remove("perfect-scrollbar-on");
  //   }
  // }
  // componentDidUpdate(e) {
  //   if (e.history.action === "PUSH") {
  //     document.documentElement.scrollTop = 0;
  //     document.scrollingElement.scrollTop = 0;
  //     this.refs.mainPanel.scrollTop = 0;
  //   }
  // }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />

        <div className="main-panel" ref="mainPanel">
          {/* {this.props.location.pathname.indexOf("indexmap") !== -1 ||
          this.props.location.pathname.indexOf("potreros") !== -1 ||
          this.props.location.pathname.indexOf("cropmap/map") !== -1 ||
          this.props.location.pathname.indexOf("cropmap/add-field") !==
            -1 ? null : this.props.location.pathname.indexOf("dashboard") !==
            -1 ? (
            <AdminNavbar
              {...this.props}
              handleMiniClick={this.handleMiniClick}
              showAddRanchButton={true}
            />
          ) : (
            <AdminNavbar
              {...this.props}
              handleMiniClick={this.handleMiniClick}
              showAddRanchButton={false}
            />
          )} */}
          <Switch>{this.getRoutes(routes)}</Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("indexmap") !== -1 ||
            this.props.location.pathname.indexOf("cropmap/map") !== -1 ||
            this.props.location.pathname.indexOf("cropmap/add-field") !== -1 ||
            this.props.location.pathname.indexOf("potreros") !== -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
      </div>
    );
  }
}

export default Admin;

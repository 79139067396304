import React, { useCallback, useReducer, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import produce from "immer";
import iconDone from "../../../../assets/img/done.png";
import backArrowIcon from "../../../../assets/img/backArrowIcon.png";
import "../../../../assets/css/licenses.css";
import arrowRight from "../../../../assets/img/arrowRight.png";
import axios from "axios";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import SaveChangesModal from "../fields/SaveChangesModal";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

//TODO: COLUMN IZQ LAS CHACRAS SIN LICENCIA
//TODO: COLUMN DER CUADRO VACIO PARA SER LLENADO
//TODO: MANDAR UN EMAIL A INFO@ORYZATIVA.COM
//TODO: Se cierra el modal y mandarlo a listLicenses
const dragReducer = produce((draft, action) => {
  if (action.type === "MOVE") {
    if (action.from.split(" ")[0] === "farmers" ) {
      if (action.to.split(" ")[0] === "farmers" ) {
        return;
      }
      let farmId = action.from.split(" ")[1];
      let positionFarm = draft["fieldsNotLicenses"].findIndex(
        (el) => el.id === Number(farmId)
      );
      const removed = draft["fieldsNotLicenses"][
        positionFarm
      ].fieldsNotPlan;
      if(removed.length > 0){
        let removeData = JSON.parse(JSON.stringify(removed))
        let areaFarm = 0
        for (let i = 0; i < removeData.length; i++) {
          const el = removeData[i];
          el.isFarm = true
          areaFarm += el.area
        }
        draft["fieldsNotLicenses"][positionFarm].fieldsNotPlan = []
        draft["newLicense"].splice(action.toIndex, 0, ...removeData);
        draft["area"] += areaFarm
      }
    }

    if (action.from.split(" ")[0] === "items-farmer" ) {
      if (action.to.split(" ")[0] === "items-farmer" ) {
        return;
      }
      let itemFarm = action.from.split(" ")[1];
      let positionItemFarm = draft["fieldsNotLicenses"].findIndex(
        (el) => el.id === Number(itemFarm)
      );
      const [removed] = draft["fieldsNotLicenses"][
        positionItemFarm
      ].fieldsNotPlan.splice(action.fromIndex, 1);
      if(removed){
        removed.isFarm = true;
        draft["newLicense"].splice(action.toIndex, 0, removed);
        draft["area"] += removed.area;
      }
    }


    if (action.from.split(" ")[0] === "fields") {
      if (action.to.split(" ")[0] === "fields") {
        return;
      }
      const [removed] = draft["fieldsNotLicenses"].splice(action.fromIndex, 1);
      removed.isFarm = false;
      draft["newLicense"].splice(action.toIndex, 0, removed);
      draft["area"] += removed.area;
      let checkFieldsNotFarm = draft["fieldsNotLicenses"].filter(
        (el) => !el.fields
      );
      if (checkFieldsNotFarm.length === 0) {
        draft["addAll"] = true;
      } else {
        draft["addAll"] = false;
      }
    }
    if (action.from === "newLicense") {
      if (action.to.split(" ")[0] === "farmers" || action.to.split(" ")[0] === "items-farmer") {
        let farmId = action.to.split(" ")[1];
        let farmData = draft["fieldsNotLicenses"].filter(
          (el) => el.id === Number(farmId)
        )[0];
        let farmPosition = draft["fieldsNotLicenses"].findIndex(
          (el) => el.id === Number(farmId)
        );
        let validField = farmData.fields
          .map((el) => el.id)
          .includes(Number(action.elementId));
        if (validField) {
          const [removed] = draft["newLicense"].splice(action.fromIndex, 1);
          draft["fieldsNotLicenses"][farmPosition].fieldsNotPlan.splice(
            action.toIndex,
            0,
            removed
          );
          draft["area"] -= removed.area;
        } else {
          return;
        }
      }
      if (action.to.split(" ")[0] === "fields") {
        let findField = draft["aux"]
          .filter((el) => !el.fields)
          .filter((el) => el.id === Number(action.elementId));
        if (findField.length) {
          const [removed] = draft["newLicense"].splice(action.fromIndex, 1);
          if (removed.isFarm === false) {
            let searchFields = draft["fieldsNotLicenses"].filter(
              (el) => !el.fields
            );
            if (searchFields.length > 0) {
              draft["fieldsNotLicenses"].splice(action.toIndex, 0, removed);
            } else {
              draft["fieldsNotLicenses"].push(removed);
            }
            draft["area"] -= removed.area;
          } else {
            draft["newLicense"].splice(action.fromIndex, 0, removed);
          }
          draft["addAll"] = false;
        } else {
          return;
        }
      }
    }
  }
  if (action.type === "removeAll") {
    draft["area"] = 0;
    draft["fieldsNotLicenses"] = action.value;
    draft["newLicense"] = [];
    draft["addAll"] = false;
  };
  if (action.type === "addAll") {
    let findFields = draft["fieldsNotLicenses"].filter((el) => !el.fields);
    let farmList = draft["fieldsNotLicenses"]?.filter((el) => el.fields);
    for (const element of action.value) {
      if (element.fields) {
        element.isFarm = true;
      } else {
        element.isFarm = false;
      }
    }
    if(farmList.length > 0){
      let fieldsInFarm = farmList?.map((el) => [...el.fieldsNotPlan]);
      let fieldsResult = [];
      fieldsInFarm.forEach((element) => {fieldsResult.push(...element);})
      let fields = fieldsResult.concat(findFields)
      draft["newLicense"] = [...draft["newLicense"], ...fields];
    } else{
      draft["newLicense"] = [...draft["newLicense"], ...action.value];
    }
    if (draft["newLicense"].length > 1) {
      draft["area"] = draft["newLicense"]
        .map((el) => el.area)
        .reduce((a, b) => Number(a) + Number(b));
    } else {
      draft["area"] = draft["newLicense"][0].area;
    }
    for (const element of farmList) {
      if (element.fields) {
        element.fieldsNotPlan = [];
      }
    }
    draft["fieldsNotLicenses"] = farmList;
    if (findFields.length > 0) {
      draft["addAll"] = true;
    }
  }

});

const CreateLicenses = (props) => {
  const [t, i18n] = useTranslation("global");
  const [state, dispatch] = useReducer(dragReducer, {
    fieldsNotLicenses: props.fieldsNotLicenses,
    aux: props.fieldsNotLicenses,
    newLicense: [],
    addAll: false,
    area: 0,
  });
  const [nameLicense, setNameLicense] = useState("");
  const [saveDataComplete, setSaveDataComplete] = useState(false);
  const tokenUser = {
    headers: {
      // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
  const onDragEnd = useCallback((result) => {
    if (result.reason === "DROP") {
      let destinationArea =
        result.destination.droppableId === "fieldsNotLicenses"
          ? "areaRemove"
          : "";
      if (!result.destination) {
        return;
      }
      dispatch({
        type: "MOVE",
        from: result.source.droppableId,
        to: result.destination.droppableId,
        fromIndex: result.source.index,
        toIndex: result.destination.index,
        area: destinationArea,
        elementId: result.draggableId,
      });
    }
  }, []);

  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the fieldsNotLicenses look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#f6f6f6" : "white",
    borderRadius: "10px",
    marginBottom: "10px",
    border: "1px solid #e2e2e2",
    listStyle: "none",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getContainerStyle = (isDragging, draggableStyle) => ({
    background:"#5db283",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: `20px`,
    borderTopRightRadius: "20px",
    backgroundColor: isDragging ? "#f6f6f6" : "white",
    ...draggableStyle,
  });

  const saveData = () => {
    if (state.newLicense.length > 0) {
      let fieldsId = state.newLicense.map((el) => el.id);
      let nameLicenses = nameLicense
        ? nameLicense
        : `${props.userInfo.first_name} ${props.userInfo.last_name} #${
            props.licensesData.length + 1
          }`;
      //BODY: name, owner, fields
      let bodyLicensesBAS = {
        name: nameLicenses,
        max_area: 0,
        product: "BAS",
        fields: fieldsId,
      };
      let bodyLicensesPLA = {
        name: nameLicenses,
        max_area: 0,
        product: "PLA",
        fields: fieldsId,
      };
      let licensesBAS = axios.post(
        "https://agrodigital.io/api/licenses/",
        bodyLicensesBAS,
        tokenUser
      );
      let licensesPLA = axios.post(
        "https://agrodigital.io/api/licenses/",
        bodyLicensesPLA,
        tokenUser
      );
      Promise.all([licensesBAS, licensesPLA]).then((res) => {
        let basResponse = res[0].data;
        let plaResponse = res[1].data;

        if (basResponse.id && plaResponse.id) {
          dispatch({
            type: "RESET",
          });
          setSaveDataComplete(true);
        }
      });
    }
  };

  const onSaveChangesToggle = () => {
    setSaveDataComplete(!saveDataComplete);
    props.fetchLicenses();
    if (saveDataComplete) {
      props.setScreens((prev) => ({
        ...prev,
        createLicenses: false,
        listLicenses: true,
      }));
    }
  };
  const msgModal = t("globals.saveCreateLicenses");
  const saveChangesModal = (
    <SaveChangesModal
      status={saveDataComplete}
      msg={msgModal}
      toggle={onSaveChangesToggle}
    />
  );
  return (
    <div
      style={{
        background: "#f4f3ef",
        width: "100%",
        position: "relative",
      }}
    >
      {saveChangesModal}
      <div
        style={{
          marginLeft: "50px",
          marginRight: "50px",
          marginTop: "3vh",
          fontSize: "revert",
        }}
      >
        {t("globals.createLicensesInfo")}{" "}
      </div>
      <form style={{ marginTop: "1vh" }} onSubmit={(e) => e.preventDefault()}>
        <input
          value={nameLicense}
          onChange={(e) => setNameLicense(e.target.value)}
          className="inputCreateLicense"
          placeholder={t("globals.nameLicense")}
          type="text"
        ></input>
      </form>
      <div className="rowDragCreateLicense">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="createLicenseColumnDragLeft">
            {state.fieldsNotLicenses?.map((farm, farmIndex) => {
              let findFirstElement = state.fieldsNotLicenses?.filter(
                (el) => !el.fieldsNotPlan
              )[0];
              return (
                // width: 100%
                <div style={{ width: "100%" }}>
                  {farm.fieldsNotPlan && (
                    <Droppable
                      droppableId={`farmers ${farm.id}`}
                      type="LICENSES"
                      direction="vertical"
                      ignoreContainerClipping={true}
                      isCombineEnabled={true}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {farm.fieldsNotPlan && (
                            <>
                              <Draggable
                                key={farm.id}
                                draggableId={`${farm.name}`}
                                index={farmIndex}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="createLicense-farmerContainer"
                                    style={getContainerStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <h5 className="createLicense-titleFarmsList">
                                      {farm.name}
                                    </h5>
                                    <Droppable
                                      droppableId={`items-farmer ${farm.id}`}
                                      type="LICENSES"
                                      direction="vertical"
                                      ignoreContainerClipping={true}
                                      isCombineEnabled={true}
                                    >
                                    {(provided, snapshot) => (
                                <div className="createLicense-item-container"
                                  ref={provided.innerRef}
                                
                                >
                                {farm.fieldsNotPlan?.map(
                                  (farmFields, farmFieldsIndex) => (
                                    <Draggable
                                      key={farmFields.id}
                                      draggableId={`${farmFields.id}`}
                                      index={farmFieldsIndex}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="createLicense-item-container"
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                          ref={provided.innerRef}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <p style={{ margin: "0" }}>
                                            {farmFields.name}
                                          </p>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                {provided.placeholder}
                              </div> 
                                    )}
                                    </Droppable>
                                  </div>
                                )}
                              </Draggable>
                            </>
                          )}
                        </div>
                      )}
                    </Droppable>
                  )}
                  {!farm.fieldsNotPlan && (
                    <Droppable
                      droppableId={`fields ${farm.id}`}
                      type="LICENSES"
                      direction="vertical"
                      ignoreContainerClipping={true}
                      isCombineEnabled={true}
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{minHeight: props.farms.length === 0 ? "50%" : ""}}
                        >
                          {!farm.fieldsNotPlan && (
                            <>
                              {farm.id === findFirstElement.id &&
                                props.farms.length > 0 && (
                                  <div className="createLicense-store-container">
                                    <h5 className="createLicense-titleFarmsList">
                                      {t("globals.otherFields")}
                                    </h5>
                                  </div>
                                )}
                              {/* <div className="editFarm-store-container">
                                      <h5 className="editFarm-titleFarmsList">
                                        OTRAS
                                      </h5>
                                    </div> */}
                              <div className="createLicense-item-container">
                                <Draggable
                                  key={farm.id}
                                  draggableId={`${farm.id} `}
                                  index={farmIndex}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="createLicense-item-container"
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <p style={{ margin: "0" }}>{farm.name}</p>
                                    </div>
                                  )}
                                </Draggable>
                                {provided.placeholder}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </Droppable>
                  )}
                </div>
              );
            })}
            {state.addAll &&  (
              <Droppable
                droppableId={`fields addAll`}
                type="LICENSES"
                direction="vertical"
                ignoreContainerClipping={true}
                isCombineEnabled={true}
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ height: "50%" }}
                  >
                    <>
                    {props.farms.length > 0 && 
                      <div className="createLicense-store-container">
                        <h5 className="createLicense-titleFarmsList">
                          {t("globals.otherFields")}
                        </h5>
                      </div>
                    }
                      <div style={{ height: "2vh" }}>
                        {[]?.map((fakeData, fakeIndex) => (
                          <Draggable
                            key={fakeData.id}
                            draggableId={`${fakeData.id}`}
                            index={fakeIndex}
                          >
                            {(provided, snapshot) => (
                              <div
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                style={{ height: "10vh" }}
                              ></div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                      {provided.placeholder}
                    </>
                  </div>
                )}
              </Droppable>
            )}
            <div className="stickyPosition">
              {state.newLicense?.length > 0 && (
                <div className="containerButton2">
                  <button
                    onClick={() =>
                      dispatch({ type: "removeAll", value: state.aux })
                    }
                    style={{ background: "none", padding: "0" }}
                  >
                    <div className="arrowRight"></div>
                  </button>
                </div>
              )}
              {state.fieldsNotLicenses.length > 0 &&
                !state.newLicense.length && (
                  <div className="containerButton">
                    <button
                      onClick={() =>
                        dispatch({
                          type: "addAll",
                          value: state.fieldsNotLicenses,
                        })
                      }
                      style={{ background: "none", padding: "0" }}
                    >
                      <div className="arrowLeft"></div>
                    </button>
                  </div>
                )}
            </div>
          </div>
          <Droppable
            droppableId="newLicense"
            type="LICENSES"
            direction="vertical"
            ignoreContainerClipping={true}
            isCombineEnabled={true}
          >
            {(provided, snapshot) => (
              <>
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  class="createLicenseColumnDragRight"
                >
                  <div className="createLicense-item-container">
                    {state.newLicense?.map((field, fieldIndex) => (
                      <Draggable
                        key={field.id}
                        draggableId={`${field.id}`}
                        index={fieldIndex}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="createLicense-item-container"
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <p style={{ margin: "0" }}>{field.name}</p>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              </>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {state.area > 0 && (
        <div className="areaTotal">
          <span>{state.area.toFixed(1)} has</span>
        </div>
      )}
      {state.newLicense.length > 0 && (
        <div style={{ width: "100%", textAlign: "center", marginTop: "2vh" }}>
          <Button
            className="btn-btn btn-success btn-sm  btn btn-success"
            style={{
              border: "none",
              borderRadius: "10px",
              background: "#5db283",
            }}
            onClick={() => {
              saveData();
            }}
          >
            <p style={{ margin: "0", padding: "5px" }}>
              {t("globals.saveChanges")}
            </p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateLicenses;

import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import {
  Application,
  Bugs,
  Diseases,
  Presence,
  Weeds,
} from "../../../Models/Logs.js";
import Chip from "../../components/Chip.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import moment from "moment";
import { AlertMultiPolygons, requestResult } from "./AlertLogsManyPolygons.js";
class ApplicationForm extends Component {
  constructor(props) {
    super(props);
    this.applicationTypes = Application.getApplicationTypes(props);
    let appTypeProps = props.selectedLog?.herb
      ? this.applicationTypes.filter(
          (item) => item.type === props.selectedLog?.typeApp
        )[0]
      : null;
    this.state = {
      tokenUser: {
        headers: {
          // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
      appType: appTypeProps,
      triggerLog: null,
      date: props.selectedLog ? props.selectedLog.date : "",
      observation: props.selectedLog ? props.selectedLog.observation : null,
      agrochemicalsArray: [],

      selectedAgrochemicals: [],

      //Control values

      dateValidState: true,
      appTypeState: true,
      loading: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
    };
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onDeleteTapped = this.onDeleteTapped.bind(this);
    if (props.selectedLog && appTypeProps) {
      this.getAgrochemicals();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedLog) {
      let appTypeProps = this.applicationTypes.filter(
        (item) => item.value === nextProps.selectedLog.type
      )[0];
      this.setState(
        {
          appType: appTypeProps,
          date: nextProps.selectedLog.date,
          observation: nextProps.selectedLog
            ? nextProps.selectedLog.observation
            : null,

          selectedAgrochemicals: [],
        },
        () => {
          if (appTypeProps) {
            this.getAgrochemicals();
          }
        }
      );
    }
  }

  componentDidMount() {
    if (this.props.selectedLog?.id) {
      this.checklogs();
    }
  }
  checklogs = async () => {
    let info = {
      fieldId: this.props.fieldId,
      polygonSelected: Object.keys(this.props.selectedPolygons).map((el) =>
        Number(el)
      ),
      date: this.state.date,
      tokenUser: this.state.tokenUser,
      type: this.props.selectedLog.type,
    };

    let result = await requestResult(info);

    this.setState((prev) => ({
      ...prev,
      alertMoreThanOnePolygon: result?.length > 1 && true,
      nameOfAllPolygons: result,
    }));
  };

  clearStatus() {
    this.setState({
      appType: null,
      triggerLog: null,
      date: null,
      agrochemicalsArray: null,
      triggerArr: [],
      selectedAgrochemicals: [],
      loading: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
      observation: null,
    });
  }

  isValidated = () => {
    var isValidated = true;

    if (this.state.appType === null) {
      this.setState({
        appTypeState: false,
      });
      isValidated = false;
    }

    if (this.state.date == null) {
      this.setState({
        dateValidState: false,
      });

      if (isValidated) {
        isValidated = false;
      }
    }

    return isValidated;
  };

  closeButtonTapped(success = false) {
    this.clearStatus();
    this.props.onClose(success);
  }

  changeAppType = (value) => {
    this.setState(
      {
        appType: value,
        agrochemicalsArray: null,
        triggerArr: [],
        selectedAgrochemicals: [],
        triggerLog: null,
        appTypeState: true,
      },
      () => {
        this.getAgrochemicals();
      }
    );
  };


  filterSelectLogsAgrochemicals = (agrochemicalsInfo) => {
    let selectAgrochemical = this.props.selectedLog.herb.filter((el) =>
      agrochemicalsInfo.some((agroq) => agroq.label === el.name)
    );

    if (selectAgrochemical.length > 0) {
      let resultSelect = selectAgrochemical.map((item) => {
        return {
          value: item.value,
          label: item.name,
          inputValue: item.dose,
        };
      });

      this.setState({
        selectedAgrochemicals: resultSelect,
      });
    }
  };
  async getAgrochemicals() {
    if (this.state.appType) {
      let type = this.state.appType.value;
      let vm = this;
      let agrochemicalsInfo = [];
      axios
        .get(`https://agrodigital.io/api/agroq/`, this.state.tokenUser)
        .then(async function (agrochemicalReference) {
          agrochemicalReference.data.map((item, _) => {
            if (type === "DiseaseReport" && item.type === "FUN") {
              let active = item.active.join(" + ");
              agrochemicalsInfo.push({
                value: item.id,
                label: active,
                inputValue: 0,
              });
            }
            if (type === "WeedReport" && item.type === "HER") {
              let active = item.active.join(" + ");
              agrochemicalsInfo.push({
                value: item.id,
                label: active,
                inputValue: 0,
              });
            }
            if (type === "BugReport" && item.type === "INS") {
              let active = item.active.join(" + ");
              agrochemicalsInfo.push({
                value: item.id,
                label: active,
                inputValue: 0,
              });
            }
          });
          if (vm.props.selectedLog) {
            vm.filterSelectLogsAgrochemicals(agrochemicalsInfo);
          }

          vm.setState({
            agrochemicalsArray: agrochemicalsInfo,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }


  selectAgrochemichal(value) {
    if (this.state.selectedAgrochemicals.indexOf(value) <= -1) {
      this.state.selectedAgrochemicals.push(value);
      this.setState({});
    }
  }

  saveButtonTapped() {
    let polygonsIds = Object.keys(this.props.selectedPolygons).map((el) =>
      Number(el)
    );
    if (this.isValidated() && polygonsIds.length > 0 && this.props.fieldId) {
      let insertData = {};
      let value = this.state.selectedAgrochemicals.map((item, _) => {
        return { value: parseFloat(item["inputValue"]), item: item["value"] };
      });

      let dateString = this.state.date.toString();

      if (dateString.length === 10) {
        insertData.date = moment(new Date(dateString + "T00:00:00")).format(
          "YYYY-MM-DD"
        );
      }
      if (dateString.length > 10) {
        insertData.date = moment(new Date(this.state.date)).format(
          "YYYY-MM-DD"
        );
      }
      insertData.products = value;
      insertData.obs = this.state.observation;
      insertData.type = "Application";

      let vm = this;
      this.setState({
        loading: true,
      });
      if (this.props.selectedLog?.id) {
        axios
          .get(
            `https://agrodigital.io/api/logs/?field=${this.props.fieldId}`,
            this.state.tokenUser
          )
          .then((res) => {
            let data = {};
            for (let i = 0; i < res.data.length; i++) {
              const logReference = res.data[i];
              if (logReference.id === this.props.selectedLog.id) {
                data = logReference;
              }
            }
            axios
              .patch(
                `https://agrodigital.io/api/logs/${data.id}/`,
                insertData,
                this.state.tokenUser
              )
              .then((res) => {
                vm.setState({
                  loading: false,
                });
                vm.closeButtonTapped(true);
              });
          });
      } else {
        insertData.polygons = polygonsIds;
        axios
          .post(
            "https://agrodigital.io/api/logs/",
            insertData,
            this.state.tokenUser
          )
          .then((res) => {
            vm.setState({
              loading: false,
            });
            vm.closeButtonTapped(true);
          });
      }
    }
  }

  onChangeInput = (input) => {
    let agrochemicalIndex = input.target.id;
    if (
      this.state.selectedAgrochemicals &&
      this.state.selectedAgrochemicals.length > 0 &&
      this.state.selectedAgrochemicals[agrochemicalIndex]
    ) {
      let agroChemichal = this.state.selectedAgrochemicals[agrochemicalIndex];
      agroChemichal["inputValue"] = input.target.value;
      this.setState({});
    }
  };

  onDeleteTapped = (input) => {
    let agrochemicalIndex = input.target.id;

    if (
      this.state.selectedAgrochemicals &&
      this.state.selectedAgrochemicals.length > 0 &&
      this.state.selectedAgrochemicals[agrochemicalIndex]
    ) {
      let agroChemichal = this.state.selectedAgrochemicals[agrochemicalIndex];
      let filteredArray = this.state.selectedAgrochemicals.filter(
        (item) => item.value !== agroChemichal.value
      );
      this.setState({ selectedAgrochemicals: filteredArray });
    }
  };

  render() {
    const {t}= this.props
    return (
      <Modal isOpen={this.props.isOpen}>
        <div
          className="modal-header justify-content-center"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <h4 className="title title-up">{t("applicationLogForm.applicationTitle")}</h4>
        </div>
        <div className="modal-body">
          {this.state.alertMoreThanOnePolygon &&
            this.props.selectedLog?.id &&
            this.state.nameOfAllPolygons.length > 1 && (
              <AlertMultiPolygons
                nameOfAllPolygons={this.state.nameOfAllPolygons}
              />
            )}
          <br />
          <Row className="justify-content-center">
            <Col sm={12} md={10} lg={8}>
              <FormGroup>
                <label>{t("globals.date")}</label>
                <ReactDatetime
                  dateFormat="DD/MM/YYYY"
                  value={this.state.date}
                  onChange={(value) => {
                    this.setState({
                      dateValidState: true,
                      date: value,
                    });
                  }}
                  inputProps={{
                    className: "form-control",
                    placeholder: t("globals.insertDateLog"),
                  }}
                  timeFormat={false}
                />
                {this.state.dateValidState === false ? (
                  <label style={{ color: "#ff0000" }}>{t("globals.requiredField")}</label>
                ) : null}
              </FormGroup>
              <div>
                <FormGroup>
                  <label>{t("applicationLogForm.applicationType")}</label>
                  <Select
                    name="logType"
                    className="react-select"
                    placeholder={t("applicationLogForm.selectApplicationType")}
                    classNamePrefix="react-select"
                    value={this.state.appType}
                    onChange={(value) => this.changeAppType(value)}
                    options={this.applicationTypes}
                  />
                  {this.state.appTypeState === false ? (
                    <label style={{ color: "#ff0000" }}>{t("globals.requiredField")}</label>
                  ) : null}
                </FormGroup>
              </div>
              {this.state.agrochemicalsArray &&
                this.state.agrochemicalsArray.length > 0 && (
                  <div>
                    <FormGroup>
                      <label>{t("applicationLogForm.addPA")}</label>
                      <Select
                        name="logType"
                        className="react-select"
                        placeholder={t("applicationLogForm.addPA")}
                        classNamePrefix="react-select"
                        value={null}
                        onChange={(value) => this.selectAgrochemichal(value)}
                        options={this.state.agrochemicalsArray}
                      />
                    </FormGroup>
                  </div>
                )}
              <div>
                {this.state.selectedAgrochemicals &&
                  this.state.selectedAgrochemicals.map(
                    (agrochemical, index) => {
                      return (
                        <Chip
                          key={index}
                          id={index}
                          value={agrochemical["inputValue"]}
                          onChange={this.onChangeInput}
                          label={agrochemical.label}
                          onDeleteTapped={this.onDeleteTapped}
                        ></Chip>
                      );
                    }
                  )}
              </div>
              <div>
                <FormGroup>
                  <label>{t("globals.observation")}</label>
                  <Input
                    type="text"
                    value={this.state.observation || null}
                    onChange={(e) =>
                      this.setState({
                        observation: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </div>
        {!this.state.loading ? (
          <div className="modal-footer">
            <div className="left-side">
              <Button
                className="btn-link"
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={() => this.closeButtonTapped(false)}
              >
                {t("buttons.cancelButton")}
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                className="btn-link"
                color="primary"
                type="button"
                onClick={() => this.saveButtonTapped()}
              >
                {t("buttons.saveButton")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center py-4">
            <CircularProgress color="secondary" />
          </div>
        )}
      </Modal>
    );
  }
}
export default ApplicationForm;

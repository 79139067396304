import React from "react";
import { TileLayer } from "react-leaflet";

const MapLink = () => {
  return (
    <TileLayer
      url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
      maxZoom={20}
      subdomains={["mt1", "mt2", "mt3"]}
    />
  );
};

export default MapLink;

//FILTRAR POR POLIGONO (JOACO)
import React, { useEffect, useState } from "react";
import { FaLeaf } from "react-icons/fa";
import { firestore } from "firebase";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import { CardBody, CardHeader, CardSubtitle, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import * as moment from "moment";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import ReactDatetime from "react-datetime";
import { Chart, Line } from "react-chartjs-2";
import Select from "react-select";
import axios from "axios";
import annotationPlugin from "chartjs-plugin-annotation";
import { withTranslation } from "react-i18next";
Chart.pluginService.register(annotationPlugin);

class TermicWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stations:[],
      stationInfo:null,
      currentpolygon: null,
      polygondata: null,
      chartdata: null,
      phenologyDates: [],
      phenologyAnnotations: [],
      date1: moment().subtract(30, "days"),
      date2: moment(),
      noMeteoData:false,
    };
  }

  update_poly = async (stationsData, selectStation = null) => {
    let phenoAnotattions =[]
    const headers = {
      headers: {
        // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    this.setState({ chartdata: {} });
    await axios
      .get(
        `https://agrodigital.io/api/logs/?field=${this.props.fieldId}&type=Phenology`,
        headers
      )
      .then((phenologyData) => {
        var phenologyDatesAux = [];
        
        let phenologyFilter = phenologyData.data.filter(
          (el) => el.polygons.includes(this.state.currentpolygon.value)
          );
        if (phenologyFilter.length > 0) {
          phenologyFilter.map((data, index) => {
            switch (data.value) {
              case 1:
                phenologyDatesAux.push({ date: data.date, type: this.props.t("globals.emergency") });
                break;
              case 2:
                phenologyDatesAux.push({ date: data.date, type: this.props.t("globals.primordium") });
                break;
              case 3:
                phenologyDatesAux.push({
                  date: data.date,
                  type: this.props.t("globals.floration"),
                });
                break;
              case 4:
                phenologyDatesAux.push({
                  date: data.date,
                  type: this.props.t("globals.harvest_start"),
                });
                break;
              case 5:
                phenologyDatesAux.push({
                  date: data.date,
                  type: this.props.t("globals.harvest_end"),
                });
                break;
              default:
                break;
            }
          });
        }
        if (phenologyDatesAux.length > 0) {
          let auxAnnotations = phenologyDatesAux.map(function (event, index) {
            return {
              id: "a-line-" + index,
              type: "line",
              mode: "vertical",
              scaleID: "x-axis-1",
              value: event.date,
              borderColor: "green",
              borderWidth: 2,
              label: {
                // Background color of label, default below
                backgroundColor: "rgba(0,0,0,0.8)",
                fontFamily: "sans-serif",
                fontSize: 12,
                fontStyle: "bold",
                fontColor: "#fff",
                xPadding: 6,
                yPadding: 6,
                cornerRadius: 6,
                position: "bottom",
                xAdjust: 0,
                yAdjust: 1,
                enabled: true,
                content: event.type,
              },
            };
          });
          phenoAnotattions = auxAnnotations
          this.setState({
            phenologyDates: phenologyDatesAux,
            phenologyAnnotations: auxAnnotations,
          });
        } else{
          phenoAnotattions = []
          this.setState({
            phenologyDates: [],
            phenologyAnnotations: [],
          });
        }
      });
    let metadata = await axios.get(
      `https://agrodigital.io/api/logs/?field=${this.props.fieldId}&type=Sowing`,
      headers
    );

    let metadataJson = metadata.data.length > 0 ? metadata.data : {};

    //por poligono

    this.setState({
      polysowing: moment(metadataJson["date"]),
    });

    //estos van a nivel de field ya que estan cerca y va a dar la misma temperatura
    await axios
      .get(
        `https://agrodigital.io/api/meteo/data/?field=${this.props.fieldId}`,
        headers
      )
      .then((res) => {
        // Filtra los datos por el poligono seleccionado 
        // let filterByPolygon = res.data.filter((el)=> el.polygon === this.state.currentpolygon.value)
        let filterByStation = []
        if(res.data.length){
          // filtrar los datos del poligono por la estacion "stationsData"
          if(stationsData.length > 0 ){
            let stationId = selectStation?.value ? selectStation.value : res.data[0]?.station 
            let stationsByPolygon = res.data.filter((el)=> el.station === stationId)
            let stationInfo = stationsData.filter((el)=> el.id === stationId)[0]
            filterByStation = stationsByPolygon
            if(stationInfo){
              this.setState({
                noMeteoData: false,
                stations: stationsData.map((el)=>({label: el.name, value: el.id})),
                stationInfo: {label: stationInfo?.name, value: stationInfo?.id},
              })
            }
          }
        } else {
          this.setState({
            noMeteoData:true
          })
        } 
        phenoAnotattions = filterByStation.length > 0 ? phenoAnotattions : []
        this.update(filterByStation, phenoAnotattions);
      });
  };

  update(polydata, annotation) {
    var labels = new Set();
    var max = [];
    var mean = [];
    var min = [];
    var rad = [];
    let sortedData = polydata.sort(
      (a, b) =>
        new Date(a.date + "T00:00:00").getTime() -
        new Date(b.date + "T00:00:00").getTime()
    );
    sortedData.map((item, index) => {
      if (moment(item.date).isBetween(this.state.date1, this.state.date2)) {
        let datos = item;
        labels.add(datos.date);
        if (datos.variable === 2) {
          max.push(datos["value"].toFixed(2));
        }
        if (datos.variable === 3) {
          mean.push(datos["value"].toFixed(2));
        }
        if (datos.variable === 4) {
          min.push(datos["value"].toFixed(2));
        }
        if (datos.variable === 5) {
          rad.push(datos["value"].toFixed(2));
        }
      }
    });
    var data = {
      labels: Array.from(labels),
      datasets: [
        {
          label: "T Min C",
          data: min,
          fill: false,
          backgroundColor: "rgb(54, 162, 235)",
          borderColor: "rgba(54, 162, 235, 0.2)",

          yAxisID: "y-axis-1",
        },
        {
          label: "T Med C",
          data: mean,
          fill: false,
          backgroundColor: "rgb(90, 150, 132)",
          borderColor: "rgba(180, 200, 132, 0.2)",
          yAxisID: "y-axis-1",
        },
        {
          label: "T Max C",
          data: max,
          fill: false,
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgba(255, 99, 132, 0.2)",
          yAxisID: "y-axis-1",
        },
        {
          label: "Rad",
          data: rad,
          fill: false,
          backgroundColor: "rgb(200, 200,0)",
          borderColor: "rgba(255, 200, 0, 0.2)",
          yAxisID: "y-axis-2",
        },
      ],
    };

    const options = {
      scales: {
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            scaleLabel: { display: true, labelString: this.props.t("globals.temperature") },
          },
          {
            labelString: this.props.t("globals.radiation"),
            type: "linear",
            display: true,
            position: "right",
            id: "y-axis-2",
            scaleLabel: { display: true, labelString: `${this.props.t("globals.radiation")} (MJ/m2)` },
            gridLines: {
              drawOnArea: false,
            },
          },
        ],
        xAxes: [
          {
            id: "x-axis-1",
            scaleLabel: { display: true, labelString: this.props.t("globals.date") },
          },
        ],
      },
      annotation: {
        drawTime: "afterDatasetsDraw", // (default)
        events: ["click"],
        dblClickSpeed: 350, // ms (default)
        annotations: annotation,
      },
    };
    this.setState({ chartdata: data, options: options });
  }
  componentDidMount() {
    let vm = this;
    if (this.props.polygons.length > 0) {
      let polygons = this.props.polygons;

      const fetchIndexData = async (stationsData) => {
        var db = [];
        for (let i = 0; i < polygons.length; i++) {
          let polygon = polygons[i];
          db.push({ label: polygon.name, value: polygon.id });
        }
        this.setState({
          polygons: db,
          currentpolygon: { label: db[0].label, value: db[0].value },
        });
        await this.update_poly(stationsData);
      };
      const fetchStations = async ()=>{
        const headers = {
          headers: {
            // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        };
        await axios.get("https://agrodigital.io/api/meteo/stations/", headers)
        .then((res)=> {
          fetchIndexData(res.data)
        })
      }
      fetchStations()
    }
  }

  render() {
    const {t}= this.props
    return (
      <>
      {this.state.stations?.length === 0 || this.state.noMeteoData ? (
  <div className="content" style={{padding:"0"}}>
            <Row
              style={{
                marginLeft: "2px",
                marginRight: "2px",
              }}
            >
              <Col style={{padding:"0"}}>
                <Card className="card-testimonial">
                  <CardBody>
                    <div className="icon icon-primary">
                      <i className="fa fa-laptop-code" />
                    </div>
                    <CardTitle tag="h6">
                      {t("globals.meteorology")}
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted">
                      {this.state.noMeteoData ? 
                      t("globals.noMeteoData")
                      :
                      (t("globals.noStationsData"))
                      }
                    </CardSubtitle>
                    <div
                      className="card-avatar"
                      style={{ paddingTop: "1rem" }}
                    >
                      <a>
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/oryzativa_logo.png")}
                        />
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
              
      ) : (
          this.state.chartdata !== null && this.state.currentpolygon ? (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <br></br>
              <Card>
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>{t("globals.meteorology")}</h6>
                    <h6>{t("globals.selectPhenologyData")}</h6>
                  </div>
                  <Row>
                    <Col lg={4} md={4} sm={4}>
                      <ReactDatetime
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        value={this.state.date1}
                        onChange={(val) => {
                          if (val) {
                            this.setState({
                              date1: val,
                            });
                            this.update_poly(this.state.stations);
                          }
                        }}
                        inputProps={{
                          className: "form-control",
                          placeholder: t("globals.from"),
                        }}
                      />
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                      <ReactDatetime
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        value={this.state.date2}
                        onChange={(val) => {
                          if (val) {
                            this.setState({
                              date2: val,
                            });
                            this.update_poly(this.state.stations);
                          }
                        }}
                        inputProps={{
                          className: "form-control",
                          placeholder: t("globals.until"),
                        }}
                      />{" "}
                    </Col>
                   
                    <Col lg={4} md={4} sm={4}>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name=""
                        value={this.state.currentpolygon}
                        onChange={(value) => {
                          this.setState({ currentpolygon: value }, () => {
                            this.update_poly(this.state.stations);
                          });
                        }}
                        options={this.state.polygons}
                        placeholder={t("globals.field")}
                      />
                    </Col>
                  </Row>
                  <br></br>
                  <div>
                    <h6
                      style={{
                        textAlign: "end",
                      }}
                    >
                      {t("globals.selectStation")}
                    </h6>
                    <Row>
                      <Col lg={4} md={4} sm={4}></Col>
                      <Col lg={4} md={4} sm={4}></Col>
                      <Col lg={4} md={4} sm={4}>
                        <Select
                          style={{ textAlign: "start" }}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name=""
                          value={this.state.stationInfo}
                          onChange={(value) => {
                            this.setState({ stationInfo: value }, () => {
                              this.update_poly(this.state.stations, value);
                            });
                          }}
                          options={this.state.stations}
                          placeholder={t("globals.field")}
                        />
                      </Col>
                    </Row>
                  </div>
                  <br></br>
                  <Row>
                    <Line
                      data={this.state.chartdata}
                      options={this.state.options}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null
      )}
      </>
    );
  }
}

export default withTranslation("global")(TermicWidget);

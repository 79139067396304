import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { firestore } from "firebase";
import { Bugs, DataField, Diseases, Log, Weeds } from "../../../Models/Logs.js";
import Chip from "../../components/Chip.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as firebase from "firebase/app";
import moment from "moment";
import axios from "axios";
import { isTemplateHead } from "typescript";
import { AlertMultiPolygons, requestResult } from "./AlertLogsManyPolygons.js";

class FieldDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenUser: {
        headers: {
          // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
      logType: null,
      logTypes: [
        { typeLog: "DiseaseReport", label: props.t("globals.diseases") },
        { typeLog: "BugReport", label: props.t("globals.bugs") },
        { typeLog: "WeedReport", label: props.t("globals.weeds") },
      ],
      date: props.selectedLog ? props.selectedLog.date : null,
      inputValue: null,
      observation: props.selectedLog ? props.selectedLog.observation : null,
      fieldReportVar: null,
      presenceArray: [],
      selectedPrecenses: [],

      //Control values

      dateValidState: true,
      logTypeState: true,
      loading: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
    };

    this.onDeleteTapped = this.onDeleteTapped.bind(this);
  }

  componentDidMount() {
    this.getFieldReport();
    if (this.props.selectedLog?.id) {
      this.checkLogs();
      if (this.props.selectedLog?.type != "FieldReport") {
        this.getPrecenses(this.props.selectedLog?.type);
      }
    }
  }

  checkLogs = async () => {
    let info = {
      fieldId: this.props.fieldId,
      polygonSelected: Object.keys(this.props.selectedPolygons).map((el) =>
        Number(el)
      ),
      date: this.state.date,
      tokenUser: this.state.tokenUser,
      type: this.props.selectedLog.type,
    };
    let result = await requestResult(info);
    this.setState((prev) => ({
      ...prev,
      alertMoreThanOnePolygon: result?.length > 1 && true,
      nameOfAllPolygons: result,
    }));
  };
  clearStatus() {
    this.setState({
      logType: null,
      date: null,
      loading: false,
      dateValidState: true,
      logTypeState: true,
      presenceArray: [],
      selectedPrecenses: [],
      inputValue: null,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
    });
  }

  isValidated = () => {
    var isValidated = true;

    if (this.state.logType === null) {
      this.setState({
        logTypeState: false,
      });
      isValidated = false;
    }

    if (this.state.date == null) {
      this.setState({
        dateValidState: false,
      });

      if (isValidated) {
        isValidated = false;
      }
    }

    return isValidated;
  };

  closeButtonTapped(success = false) {
    this.clearStatus();
    this.props.onClose(success);
  }

  changeLogType = (value) => {
    if (
      value.typeLog === "BugReport" ||
      value.typeLog === "WeedReport" ||
      value.typeLog === "DiseaseReport"
    ) {
      this.setState(
        {
          logType: value,
          presenceArray: [],
          selectedPrecenses: [],
          logTypeState: true,
        },
        () => {
          this.getPrecenses(value.typeLog);
        }
      );
    } else {
      this.setState({
        logType: value,
        selectedPrecenses: [],
        logTypeState: true,
        presenceArray: [value],
      });
    }
  };

  getFieldReport = async () => {
    let presencesInfo = [];
    let vm = this;
    axios
      .get(`https://agrodigital.io/api/field_var/`, this.state.tokenUser)
      .then((res) => {
        res.data.map((item, _) => {
          if (isTemplateHead) {
            presencesInfo.push({
              typeLog: "FieldReport",
              value: item.id,
              label: item.name,
              unit: item.unit,
            });
          }
        });
        if (vm.props.selectedLog?.value && vm.props.selectedLog?.type === "FieldReport") {
          let filterBugReport = presencesInfo.filter(
            (item) => vm.props.selectedLog.variable === item.label
          );
          vm.setState({
            selectedPrecenses: filterBugReport,
            logType: filterBugReport[0],
            inputValue: vm.props.selectedLog.value
          });
        }
        vm.setState({
          presenceArray: presencesInfo.sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
          logTypes: [...this.state.logTypes, ...presencesInfo].sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
        });
      });
  };
  async getPrecenses(value) {
    let vm = this;
    let presencesInfo = [];
    switch (value) {
      case "DiseaseReport":
        axios
          .get(`https://agrodigital.io/api/diseases/`, this.state.tokenUser)
          .then((res) => {
            res.data.map((item, _) => {
              if (isTemplateHead) {
                presencesInfo.push({
                  typeLog: value,
                  value: item.id,
                  label: item.specie,
                });
              }
            });
            if (vm.props.selectedLog?.values?.length) {
              let filterDiseaseReport = presencesInfo.filter((item) =>
                vm.props.selectedLog.values.includes(item.label)
              );
              vm.setState({
                selectedPrecenses: filterDiseaseReport,
                logType: filterDiseaseReport[0],
              });
            }
            vm.setState({
              presenceArray: presencesInfo.sort((a, b) =>
                a.label.localeCompare(b.label)
              ),
            });
          });

        break;
      case "WeedReport":
        axios
          .get(`https://agrodigital.io/api/weeds/`, this.state.tokenUser)
          .then((res) => {
            res.data.map((item, _) => {
              if (isTemplateHead) {
                presencesInfo.push({
                  typeLog: value,
                  value: item.id,
                  label: item.specie,
                });
              }
            });
            if (vm.props.selectedLog?.values) {
              let filterWeedReport = presencesInfo.filter((item) =>
                vm.props.selectedLog.values.includes(item.label)
              );

              let logTypeSelected = vm.state.logTypes.filter(
                (el) => el.typeLog === vm.props.selectedLog?.type
              );
              vm.setState({
                selectedPrecenses: filterWeedReport,
                logType: logTypeSelected,
              });
            }
            vm.setState({
              presenceArray: presencesInfo.sort((a, b) =>
                a.label.localeCompare(b.label)
              ),
            });
          });

        break;
      case "BugReport":
        axios
          .get(`https://agrodigital.io/api/bugs/`, this.state.tokenUser)
          .then((res) => {
            res.data.map((item, _) => {
              if (isTemplateHead) {
                presencesInfo.push({
                  typeLog: value,
                  value: item.id,
                  label: item.specie,
                });
              }
            });
            if (vm.props.selectedLog?.values?.length) {
              let filterBugReport = presencesInfo.filter((item) =>
                vm.props.selectedLog.values.includes(item.label)
              );
              vm.setState({
                selectedPrecenses: filterBugReport,
                logType: filterBugReport[0],
              });
            }
            vm.setState({
              presenceArray: presencesInfo.sort((a, b) =>
                a.label.localeCompare(b.label)
              ),
            });
          });
        break;
      default:
        break;
    }
  }

  selectPresence(value) {
    if (this.state.selectedPrecenses.indexOf(value) <= -1) {
      this.state.selectedPrecenses.push(value);
      this.setState({});
    }
  }

  onDeleteTapped = (input) => {
    let presenceIndex = input.target.id;

    if (
      this.state.selectedPrecenses &&
      this.state.selectedPrecenses.length > 0 &&
      this.state.selectedPrecenses[presenceIndex]
    ) {
      let presence = this.state.selectedPrecenses[presenceIndex];
      let filteredArray = this.state.selectedPrecenses.filter(
        (item) => item.value !== presence.value
      );
      this.setState({ selectedPrecenses: filteredArray });
    }
  };

  saveButtonTapped() {
    let polygonsIds = Object.keys(this.props.selectedPolygons).map((el) =>
      Number(el)
    );
    if (this.isValidated() && polygonsIds.length > 0 && this.props.fieldId) {
      let insertData = {};
      insertData.type = this.state.logType.typeLog;
      insertData.obs = this.state.observation;
      let dateString = this.state.date.toString();

      if (dateString.length === 10) {
        insertData.date = moment(new Date(dateString + "T00:00:00")).format(
          "YYYY-MM-DD"
        );
      }
      if (dateString.length > 10) {
        insertData.date = moment(new Date(this.state.date)).format(
          "YYYY-MM-DD"
        );
      }

      //si es fieldReport va a hacer esto
      if (this.shouldShowInputField()) {
        if (this.state.inputValue) {
          insertData.value = parseFloat(this.state.inputValue);
          // insertData.unit = this.state.logType.unit;
          insertData.variable = this.state.presenceArray
            .filter((el) => this.state.logType.label === el.label)
            .map((el) => el.value)[0];
        }
      } else {
        //si son herb, fung y insect esto
        if (this.state.selectedPrecenses) {
          insertData.species = this.state.selectedPrecenses.map(
            (item, _) => item.value
          );
        }
      }

      let vm = this;
      this.setState({
        loading: true,
      });

      //si esta editando
      if (this.props.selectedLog?.id) {
        axios
          .get(
            `https://agrodigital.io/api/logs/?field=${this.props.fieldId}`,
            this.state.tokenUser
          )
          .then((res) => {
            let logReference = res.data.filter(
              (el) => el.id === this.props.selectedLog.id
            )[0];
            axios
              .patch(
                `https://agrodigital.io/api/logs/${logReference.id}/`,
                insertData,
                this.state.tokenUser
              )
              .then((res) => {
                vm.setState({
                  loading: false,
                });
                vm.closeButtonTapped(true);
              })
              .catch((error) => {
                console.log(error);
              });
          });
      } else {
        insertData.loc = null;
        insertData.image = null;
        insertData.polygons = polygonsIds;

        axios
          .post(
            "https://agrodigital.io/api/logs/",
            insertData,
            this.state.tokenUser
          )
          .then((res) => {
            vm.setState({
              loading: false,
            });
            vm.closeButtonTapped(true);
          });
      }
    }
  }

  shouldShowInputField() {
    if (this.state.logType) {
      if (this.state.logType.typeLog === "FieldReport") {
        return true;
      }
      return false;
    }
    return false;
  }

  shouldShowPresenceField() {
    if (this.state.logType) {
      if (this.state.logType.typeLog !== "FieldReport") {
        return true;
      }
      return false;
    }
    return false;
  }

  render() {
    const { t } = this.props;
    return (
      <Modal isOpen={this.props.isOpen}>
        <div
          className="modal-header justify-content-center"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <h4 className="title title-up">{t("fieldLogForm.fieldLogTitle")}</h4>
        </div>
        <div className="modal-body">
          {this.state.alertMoreThanOnePolygon &&
            this.props.selectedLog?.id &&
            this.state.nameOfAllPolygons.length > 1 && (
              <AlertMultiPolygons
                nameOfAllPolygons={this.state.nameOfAllPolygons}
              />
            )}
          <br />
          <Row className="justify-content-center">
            <Col sm={12} md={10} lg={8}>
              <FormGroup>
                <label>{t("globals.date")}</label>
                <ReactDatetime
                  dateFormat="DD/MM/YYYY"
                  value={this.state.date}
                  onChange={(value) => {
                    this.setState({
                      dateValidState: true,
                      date: value,
                    });
                  }}
                  inputProps={{
                    className: "form-control",
                    placeholder: t("globals.insertDateLog"),
                  }}
                  timeFormat={false}
                />
                {this.state.dateValidState === false ? (
                  <label style={{ color: "#ff0000" }}>
                    {t("globals.requiredField")}
                  </label>
                ) : null}
              </FormGroup>
              <div>
                <FormGroup>
                  <label>{t("fieldLogForm.fieldLogType")}</label>
                  <Select
                    name="logType"
                    className="react-select"
                    placeholder={t("globals.selectLogType")}
                    classNamePrefix="react-select"
                    value={this.state.logType}
                    onChange={(value) => this.changeLogType(value)}
                    options={this.state.logTypes}
                  />
                  {this.state.logTypeState === false ? (
                    <label style={{ color: "#ff0000" }}>
                      {t("globals.requiredField")}
                    </label>
                  ) : null}
                </FormGroup>
              </div>
              {this.shouldShowPresenceField() &&
                this.state.presenceArray &&
                this.state.presenceArray.length > 0 && (
                  <div>
                    <FormGroup>
                      <label>{t("fieldLogForm.addSpecies")}</label>
                      <Select
                        name="logType"
                        className="react-select"
                        placeholder={t("fieldLogForm.addSpecies")}
                        classNamePrefix="react-select"
                        value={null}
                        onChange={(value) => this.selectPresence(value)}
                        options={this.state.presenceArray}
                      />
                    </FormGroup>
                  </div>
                )}
              <div>
                {this.shouldShowPresenceField() &&
                  this.state.selectedPrecenses &&
                  this.state.selectedPrecenses.map((presence, index) => {
                    return (
                      <Chip
                        key={index}
                        id={index}
                        label={presence.label}
                        onDeleteTapped={this.onDeleteTapped}
                      ></Chip>
                    );
                  })}
              </div>
              {this.shouldShowInputField() &&
                this.state.presenceArray.length > 0 && (
                  <div>
                    <FormGroup>
                      {this.state.presenceArray.map((el) => {
                        if (this.state.logType.label === el.label) {
                          return (
                            <label>
                              {`${this.state.logType.label} (${el.unit})`}
                            </label>
                          );
                        }
                      })}
                      <Input
                        type="number"
                        min={0}
                        value={this.state.inputValue}
                        onChange={(e) =>
                          this.setState({
                            inputValue: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </div>
                )}
              <div>
                <FormGroup>
                  <label>{t("globals.observation")}</label>
                  <Input
                    type="text"
                    value={this.state.observation || ""}
                    onChange={(e) =>
                      this.setState({
                        observation: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </div>
        {!this.state.loading ? (
          <div className="modal-footer">
            <div className="left-side">
              <Button
                className="btn-link"
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={() => this.closeButtonTapped(false)}
              >
                {t("buttons.cancelButton")}
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                className="btn-link"
                color="primary"
                type="button"
                onClick={() => this.saveButtonTapped()}
              >
                {t("buttons.saveButton")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center py-4">
            <CircularProgress color="secondary" />
          </div>
        )}
      </Modal>
    );
  }
}
export default FieldDataForm;

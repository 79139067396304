/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import logoOryzaWhite from "assets/img/logo.png";
import logoOryzaColor from "assets/img/oryzativa_logo_color.png";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

import { useState } from "react";

const AuthNavbar = (props) => {
  const [t, i18n] = useTranslation("global");
  const [state, setState] = useState({
    collapseOpen: false,
    color: "navbar-transparent",
  });
  const [size, setSize] = useState(undefined)

  useEffect(() => {
    window.addEventListener("resize", this?.updateColor);
  }, []);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      let size= window.innerWidth
      // Set window width/height to state
      setSize(window.innerWidth);
      let updateColors={
        collapseOpen: false,
        color:"navbar-transparent"
      }
      if(size > 989){
        setState(updateColors)
      } 
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    let newState = {
      collapseOpen: !state.collapseOpen,
    };
    if (!state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    setState(newState);
  };
  return (
    <Navbar
      className={classnames("navbar-absolute fixed-top", state.color)}
      expand="lg"
    >
      <Container>
        <div className="navbar-wrapper">
          <NavbarBrand onClick={(e) => e.preventDefault()}>
            <img width="150" src={size > 990 ?  logoOryzaWhite :logoOryzaColor} alt="oryzativa logo" />
          </NavbarBrand>
        </div>
        <button
          aria-controls="navigation-index"
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-toggle="collapse"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse
          isOpen={state.collapseOpen}
          className="justify-content-end"
          navbar
        >
          <Nav navbar >
            <NavItem>
              <NavLink
                to="/auth/register"
                className="nav-link"
              >
                <i className="nc-icon nc-book-bookmark" />
                {t("loginAndRegisterScreen.createAccount")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/auth/login"
                className="nav-link"
              >
                <i className="nc-icon nc-tap-01" />
                {t("loginAndRegisterScreen.loginIn")}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default AuthNavbar;

/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useContext, useEffect} from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import "../../assets/css/emptyView.css";

import avatar1 from "assets/img/avatar/1.jpg";
import avatar2 from "assets/img/avatar/2.jpg";
import avatar3 from "assets/img/avatar/3.jpg";

import logo from "assets/img/oryzativa_logo.png";
import logo2 from "assets/img/logo.png";

import axios from "axios";
import SourceProvider from "providers/SourceProvider";
import { useTranslation } from "react-i18next";
import { ApiContext } from "context/ApiContext";

// var ps;

const Sidebar = (props) => {
  const [farmers, setFarmers, token, setToken] = useContext(ApiContext)
    const context = useContext(SourceProvider);
    const [t, in18]= useTranslation("global")
    const getCollapseStates = (routes) => {
      let initialState = {};
      routes.map((prop, key) => {
        if (prop.collapse) {
          initialState = {
            [prop.state]: getCollapseInitialState(prop.views),
            ...getCollapseStates(prop.views),
            ...initialState,
          };
        }
        return null;
      });
      return initialState;
    };
  const [state, setState]= useState({
    stateRoutes: getCollapseStates(props.routes),
    userName: "",
    close: false,
  })
  const [selected, setSelected]= useState(0)
  const [optionsAdm, setOptionsAdm]= useState(true)
  const active={
    color:"#44c47d",
    outline:"none"
  }
  const inactive={
    outline:"none"
  }
  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

    // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes


 const hashCode = function (s) {
    var h = 0,
      l = s.length,
      i = 0;
    if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
    return Math.abs(h % 3);
  };
 const getImage= (name) =>{
    if (hashCode(name) == 0) {
      return avatar1;
    }
    if (hashCode(name) == 1) {
      return avatar2;
    }
    if (hashCode(name) == 2) {
      return avatar3;
    }
  }

    // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState= (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;

      }
    }
    return false;
  }

    // this function creates the links and collapses that appear in the sidebar (left menu)
   const createLinks = (routes) => {
      return routes.map((prop, key) => {
        if (prop.redirect || prop.hide) {
          return null;
        }
        if (prop.collapse) {
          var st = {};
          st[prop["state"]] = !state.stateRoutes[prop.state];
          return (
            <li
              className={getCollapseInitialState(prop.views) ? "active" : ""}
              key={key}
            >
              <a
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={state.stateRoutes[prop.state]}
                onClick={(e) => {
                  e.preventDefault();
                  setState(st);
                }}
              >
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <p>
                      {prop.name}
                      <b className="caret" />
                    </p>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">
                      {prop.name}
                      <b className="caret" />
                    </span>
                  </>
                )}
              </a>
              <Collapse isOpen={state.stateRoutes[prop.state]}>
                <ul className="nav">{createLinks(prop.views)}</ul>
              </Collapse>
            </li>
          );
        }
        const administrationOptionsToggle =()=>{
          setOptionsAdm(!optionsAdm)
        }
        return (
          <li className={activeRoute(prop.layout + prop.path)} key={key}>
            {prop.name === t("sidebarDashboard.itemAdministration") ? (
              <div
                style={{
                  color: "white",
                  margin: "10px 15px 0",
                  color: "#FFFFFF",
                  display: "block",
                  textDecoration: "none",
                  position: "relative",
                  textTransform: "uppercase",
                  cursor: "pointer",
                  fontSize: "12px",
                  padding: "10px 8px",
                  lineHeight: "30px",
                  opacity: ".7",
                }}
              >
                <div onClick={()=>{administrationOptionsToggle()}} style={{display:"flex"}}>
                  <i className={prop.icon} />
                  <p>{prop.name} </p>
                  <b className={optionsAdm ? "caret caret-rotation" : "caret" } style={{position:"absolute", top:"20px", right:"18px"}}/>
                </div>
                {optionsAdm && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ul
                    style={{
                      listStyle: "none",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px",
                      justifyContent: "center",
                    }}
                  >
                    <NavLink to="/admin/administration/farm">
                      {" "}
                      <button
                        className="buttonSections"
                        style={selected == 1 ? active : inactive}
                        onClick={handleClick(1)}
                      >
                        {t("globals.admItemFarm")}
                      </button>
                    </NavLink>
                    <NavLink to="/admin/administration/field">
                      <button
                        className="buttonSections"
                        style={selected == 2 ? active : inactive}
                        onClick={handleClick(2)}
                      >
                        {t("globals.admItemField")}
                      </button>
                    </NavLink>
                    <NavLink to="/admin/administration/licenses">
                      {" "}
                      <button
                        className="buttonSections"
                        style={selected == 3 ? active : inactive}
                        onClick={handleClick(3)}
                      >
                        {t("globals.admItemLicense")}
                      </button>
                    </NavLink>
                  </ul>
                </div>
                )}
              </div>
            ) : (
              <NavLink to={prop.layout + prop.path} activeClassName="">
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
                )}
              </NavLink>
            )}
          </li>
        );
      });
    };
      // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    if(props.location.pathname.split("/").indexOf("administration") === -1 && selected >= 1){
      setSelected(0)
    }
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };


  useEffect(() => {
    if (localStorage.getItem("token")) {
      const headers = {
        headers: {
          // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      };

      axios
        .get("https://agrodigital.io/api/auth/user/", headers)
        .then((res) => {
          let user = res.data;
          setState((prev)=>({
            ...prev,userName: user.first_name,
          }));
        });
    }

      // if you are using a Windows Machine, the scrollbars will have a Mac look
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.refs.sidebar, {
    //     suppressScrollX: true,
    //     suppressScrollY: false,
    //   });
    // }
  }, [])
  // componentWillUnmount() {
  //   // we need to destroy the false scrollbar when we navigate
  //   // to a page that doesn't have this component rendered
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  // }
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a
          href="https://app.oryzativa.com/"
          className="simple-text logo-mini"
        >
          <div className="logo-img">
            <img src={logo} alt="oryzativa icon" />
          </div>
        </a>
        <a
          href="https://app.oryzativa.com/"
          className="simple-text logo-normal"
        >
          <img width="130" src={logo2} alt="oryzativa logo" />
        </a>
      </div>

      <div className="sidebar-wrapper" >
        <div className="user">
          <div className="photo">
            <img src={getImage(state.userName)} alt="Avatar" />;
          </div>
          <div className="info">
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state.openAvatar}
              onClick={() =>
                setState((prev)=>({...prev, openAvatar: !state.openAvatar }))
              }
            >
              <span>
                {state.userName}
                <b className="caret" />
              </span>
            </a>
            <Collapse isOpen={state.openAvatar}>
              <ul className="nav">
                <li>
                  <NavLink
                    to={"/auth/login"}
                    activeClassName=""
                    onClick={() => {
                      setToken("")
                      localStorage.removeItem("token")
                      context.changePlan("restart", false);
                      context.changeDisabledButton("basic", false);
                      context.changeDisabledButton("planet", false);
                    }}
                  >
                    <span className="sidebar-mini-icon">CS</span>
                    <span className="sidebar-normal">{t("sidebarDashboard.logOut")}</span>
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </div>
        </div>
        <Nav>{createLinks(props.routes)}</Nav>
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            bottom: "0px",
            left: "0px",
            right: "0px",
          }}
        >
          <button
            style={{ padding: "9px", marginRight: "20px", width:"80px" }}
            className={
              context.planPlanet && !context.planBasic
                ? "filterEnabled"
                : "filterDisabled"
            }
            onClick={() => {
              if (!context.planet) {
                context.changePlan("planet", true);
              }
            }}
            disabled={context.disabledPlanPlanet}
          >
            HD
          </button>
          <button
            style={{ padding: "9px" , width:"80px"}}
            className={
              context.planBasic && !context.planPlanet
                ? "filterEnabled"
                : "filterDisabled"
            }
            onClick={() => {
              if (!context.planBasic) {
                context.changePlan("basic", true);
              }
            }}
            disabled={context.disabledPlanBasic}
          >
            SD
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar
import React, { useState } from "react";
import { useEffect } from "react";
import { Spinner, Table } from "reactstrap";
import Farmer from "./sections/farmer/Farmer";
import axios from "axios";
import "../../assets/css/administration.css"
import "../../assets/css/licenses.css"
import ReorganizeFields from "./sections/fields/ReorganizeFields";
import Licenses from "./sections/licenses/Licenses";
import NoDataAdm from "./NoDataAdm";
//TODO: Hacer un loading 
//TODO: Aviso en caso de que no haya fields


const Administration = (props) => {
  const [userInfo, setUserInfo]= useState(null)
  const [selectedSection, setSelectedSection] = useState({
    farm: false,
    licenses: false,
    field: false,
  });
  const [fieldList, setFieldList] = useState([]);
  const [polygonsList, setPolygonsList] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [noDataLoading, setNoDataLoading] = useState(false);
  const [sectionSelect, setSelectionSelect] = useState("");
  const [farms, setFarms] = useState([]);
  const [fieldsAfected, setFieldsAfected] = useState([]);
  const tokenUser = {
    headers: {
      // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
  const {
    match: { params },
  } = props;
  useEffect(() => {
    if(userInfo){
      fetchFieldList();
    }
  }, [userInfo]);

  useEffect(() => {
    if(fieldList.length){
      fetchFarms()
    }
  }, [fieldList.length]);

  useEffect(() => {
    setIsloading(true)
    let section = params.type;
    setSelectionSelect(section)
    selectedSection[section] = true
    fetchUser()
  }, []);
  
  useEffect(()=>{
    let section = params.type;
    if(selectedSection[section] === true){
      return;
    } else {
    selectedSection[sectionSelect]= false
    setSelectionSelect(section)
    selectedSection[section]= true
    }
  },[props])

  const fetchUser = ()=>{
    axios.get("https://agrodigital.io/api/auth/user/", tokenUser).then((res)=>{
    setUserInfo(res.data)
    })
  }
  const fetchFieldList = async () => {
    let fieldsReference = await axios.get(
      "https://agrodigital.io/api/fields/",
      tokenUser
    );
    let filterFieldsByOwner= fieldsReference?.data?.filter((el)=> el.owner === userInfo.id)
    fetchPolygons(filterFieldsByOwner);
  };

  const fetchPolygons = async (fieldsReference) => {
    let fieldInfo = fieldsReference;
    if (fieldInfo.length > 0) {
      let polygonData = await axios.get(
        `https://agrodigital.io/api/polygons/`,
        tokenUser
      );
      for (let i = 0; i < polygonData.data.length; i++) {
        const polygons = polygonData.data[i];
        for (let j = 0; j < fieldInfo.length; j++) {
          const field = fieldInfo[j];
          if (field.polygons.includes(polygons.id)) {
            setPolygonsList((prev)=>[...prev, polygons].filter((polygon,index,list)=>list.findIndex(listElement=>(listElement.id===polygon.id))===index))
            let findPolInx = field.polygons.findIndex(
              (el) => el === polygons.id
            );
            field.polygons.splice(findPolInx, 1);
            field.polygons.push(polygons);
          }
        }
      }
      setIsloading(false);
      setFieldList(fieldInfo);
    } else {
      setIsloading(false);
      setNoDataLoading(true);
    }
  };

  const fetchFarms = async (type = null) => {
    let allFieldsToFarmer = [];
    let farmsData = await axios.get(
      "https://agrodigital.io/api/farms/",
      tokenUser
    );
    //TODO: HACER ARRAY CON TODOS LOS ID DE LOS FIELDS EN CADA FARMER
    farmsData.data.forEach((el) => allFieldsToFarmer.push(...el.fields));

    //NAME de cada field hacer un filtro
    for (let j = 0; j < fieldList.length; j++) {
      const field = fieldList[j];
      for (let i = 0; i < farmsData.data.length; i++) {
        const farm = farmsData.data[i];
        if (farm.fields.includes(field.id)) {
          field.farmName = farm.name
          farm.fields = [...farm.fields, field];
        }
      }
    }
    for (let fa of farmsData.data) {
      fa.fields = fa.fields.filter((el) => el.id);
    }

    setFieldsAfected(allFieldsToFarmer);
    setFarms(farmsData.data);
    setIsloading(false)
  };
  return (
    <div className="tableNotScroll">
    <Table
      responsive
      hover={false}
      className="detail-table"
      style={{
        overflow: "auto!important",
        //   maxHeight: "570px !important",
          height: "700px !important",
      }}
    >
      {isLoading ? (
      <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
      <Spinner
        style={{ marginTop: "100px" }}
        animation="border"
        variant={"primary"}
      ></Spinner>
    </div>
      ) : ( 
        <>
      {fieldList.length > 0 && selectedSection.farm && (
        <tbody>
          <tr>
            <td colSpan="3" className="farmScreen" style={{padding:"0px", border:"none", height:"91vh", background:"#f4f3ef"}}>
              <Farmer fieldList={fieldList} userInfo={userInfo} setFieldList={setFieldList} farms={farms} fieldsAfected={fieldsAfected} fetchFarms={fetchFarms}/>
            </td>
          </tr>
        </tbody>
      )}
      {fieldList.length > 0 && polygonsList.length > 0 && selectedSection.field && (
        <tbody>
          <tr>
            <td colSpan="3" style={{padding:"0px", border:"none", height:"91vh", verticalAlign:"middle", background:"#f4f3ef"}}>
              <ReorganizeFields fieldList={fieldList} polygonsList={polygonsList} userInfo={userInfo} setFieldList={setFieldList}/>
            </td>
          </tr>
        </tbody>
      )}
      {selectedSection.licenses && fieldList.length > 0 &&(
        <tbody>
          <tr>
            <td colSpan="3"className="licScreen" style={{padding:"0px", border:"none", height:"91vh", background:"#f4f3ef"}}>
              <Licenses fieldList={fieldList} userInfo={userInfo} farms={farms}/>
            </td>
          </tr>
        </tbody>
      )}
      {
        noDataLoading && (
          // <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <NoDataAdm />
        // </div>
        )
      }
      </>
      
      )}
    </Table>
    </div>
  );
};
export default Administration;

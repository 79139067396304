import React, { useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Button,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
const ErrorCreateFieldModal = (props) => {
  const [t, i18n]= useTranslation("global")
  const [modal, setModal]=useState(null)
  const history= useHistory()

  const hideAlert=()=>{
    setModal(null)
  }
  return(
    <ReactBSAlert
          style={{ display: "table", marginTop: "-100px", minWidth:"700px"}}
          onConfirm={() => hideAlert()}
          showConfirm={false}
        >
          <CardBody>
      <div className="card-avatar" style={{position:"absolute", padding:"10px", left:"0", top:"0" }}>
          <img
            style={{height:"50px", display:"block"}}
            alt="..."
            className="img"
            src={require("assets/img/oryzativa_logo.png")}
          />
      </div>
      <CardTitle tag="h6">{t("globals.createFieldError")}</CardTitle>
        <br/>
      <CardSubtitle className="mb-2 text-muted">
      {props.msg}
        <br/>
      </CardSubtitle>
      <Button
      onClick={()=>{
        props.closeModal()
        hideAlert()
      }}
      >
        {t("buttons.understoodButton")}
      </Button>
    </CardBody>
        </ReactBSAlert>
  )
};

export default ErrorCreateFieldModal;

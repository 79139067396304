import "../../assets/css/Chip.css";
import React, { Component } from "react";

class Chip extends Component {
  render() {
    return (
      <div className="chip">
        {this.props.onChange ? (
          <input
            type="number"
            id={this.props.id}
            value={this.props.value}
            onChange={this.props.onChange}
            className="input"
          />
        ) : null}
        <p className="inputLabel" for={this.props.id}>
          {" "}
          {this.props.label}{" "}
        </p>
        <span
          className="closebtn"
          id={this.props.id}
          onClick={this.props.onDeleteTapped}
        >
          &times;
        </span>
      </div>
    );
  }
}
export default Chip;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
import "../../../../assets/css/emptyView.css"
import { useTranslation } from "react-i18next";

const ListLicences = (props) => {
  const [t, i18n] = useTranslation("global")
  const [fieldsWithOutPlan, setFieldsWithOutPlan] = useState([])
  const [selectedFarmOptions, setSelectedFarmOptions] = useState([])
  const [areaFieldsPlan, setAreaFieldsPlan] = useState(null)
  const [areaFieldsNotPlan, setAreaFieldsNotPlan] = useState(null)
  const [optionsOtherFields, setOptionsOtherFields] = useState(true)
  
  useEffect(()=>{
    if(props.farms.length || props.fieldsNotFarm.length){
      let fieldIdData = props.farms.map((el) => [...el.fields, ...el.fields]);
      let fieldsIdResult = [];
      fieldIdData.forEach((element) => {
        fieldsIdResult.push(...element);
      });
      let deleteRepeat = fieldsIdResult.filter((item, index) => {
        return fieldsIdResult.indexOf(item) === index;
      }).map((el)=> el.id);

      let fieldsNotPlan=  props.fieldsNotFarm.filter((field)=> !deleteRepeat.includes(field.id))
      let areaFieldsLicense= fieldsNotPlan.filter((el)=> el.license).map((el)=> el.area) 
      let licensedArea = areaFieldsLicense.length > 1
      ? areaFieldsLicense.reduce((a, b) => a + b)?.toFixed(1)
      : areaFieldsLicense[0]?.toFixed(1);
      let areaFieldsNotLicense= fieldsNotPlan.filter((el)=> !el.license).map((el)=> el.area) 
      let areaNotLicenseTotal = areaFieldsNotLicense.length > 1
      ? areaFieldsNotLicense.reduce((a, b) => a + b)?.toFixed(1)
      : areaFieldsNotLicense[0]?.toFixed(1);
      setFieldsWithOutPlan(fieldsNotPlan)
      setAreaFieldsPlan(licensedArea)
      setAreaFieldsNotPlan(areaNotLicenseTotal)
    }
  },[props.farms.length, props.fieldsNotFarm.length])
  return (
    <>
      <Card className="card-stats cardTableLicences" st>
        <CardBody style={{ maxHeight: "600px", zIndex: "1" }}>
          <Table
            responsive
            hover={true}
            className="tableLicences detail-table "
          >
            <thead style={{ color: "#5db283" }}>
              <tr>
                <th></th>
                <th>{t("globals.name")}</th>
                <th>{t("globals.licensedArea")}</th>
                <th>{t("globals.areaWithoutLicense")}</th>
                <th>{t("globals.licenseStatus")}</th>
              </tr>
            </thead>
            <tbody>
              {props.farms.length > 0 &&
                props.farms?.map((farm) => {
                  let areaActiveTotal = farm.fields
                  .filter((el) => el.license)
                  .map((el) => el.area);
                  let areaInactive = farm.fields
                    .filter((el) => !el.license)
                    .map((el) => el.area);

                  let areaInactiveTotal =
                    areaInactive.length > 1
                      ? areaInactive.reduce((a, b) => a + b)?.toFixed(1)
                      : areaInactive[0]?.toFixed(1);
                  let areaActive =
                  areaActiveTotal.length > 1
                      ? areaActiveTotal.reduce((a, b) => a + b)?.toFixed(1)
                      : areaActiveTotal[0]?.toFixed(1);
                  let allOptions = selectedFarmOptions.includes(farm.id);
                  return (
                    <>
                      <tr
                        className="field-row"
                        style={{
                          // background: dateActive ? "#5db283" : "#cb5858",
                          background: "#5db283",
                          cursor: "auto",
                          color: "white",
                        }}
                        onClick={() => {
                          let repeat = selectedFarmOptions.includes(farm.id);
                          if (repeat) {
                            let remove = selectedFarmOptions.filter(
                              (el) => el !== farm.id
                            );
                            setSelectedFarmOptions(remove);
                          } else {
                            setSelectedFarmOptions([
                              ...selectedFarmOptions,
                              farm.id,
                            ]);
                          }
                        }}
                      >
                        <td>
                        <b
                          className="fa fa-caret-down"
                          style={{transform: allOptions && "rotate(180deg)", fontSize:"x-large"}}
                        />
                        </td>
                        <td className="text-center">{farm.name}</td>
                        <td>{areaActive && areaActive} </td>
                        {/* <td>{dateActive ? dateFormat : "Solicitud enviada..."}</td> */}
                        <td>{areaInactiveTotal && areaInactiveTotal}</td>
                        {/* hacer validacion real de date */}
                        {/* <td>{dateActive ? "Activo" : "Inactiva"}</td> */}
                        <td></td>
                      </tr>
                      {allOptions &&
                        farm.fields.map((field, index) => {
                          //mayor a la fecha actual
                          let dateActive =
                            new Date(
                              field.licenseDate?.replaceAll("Z", "")
                            ).getTime() > new Date().getTime();
                          //sea menor a la fecha actual y no sea 2000
                          let licenseExpired = new Date(
                            field.licenseDate?.replaceAll("Z", "")
                          ).getTime() < new Date().getTime() && new Date(
                            field.licenseDate?.replaceAll("Z", "")
                          ).getFullYear() !== 2000;
                          let fieldLicense = field.license;
                          return (
                            <tr>
                              <td></td>
                              <td
                                className="text-center"
                                style={{ color: fieldLicense ? "" : "red" }}
                              >
                                {field.name}
                              </td>
                              {fieldLicense ? (
                                <>
                                  <td
                                    style={{ color: fieldLicense ? "" : "red" }}
                                  >
                                    {field.area}
                                  </td>
                                  <td></td>
                                </>
                              ) : (
                                <>
                                  <td></td>
                                  <td
                                    style={{ color: fieldLicense ? "" : "red" }}
                                  >
                                    {field.area}
                                  </td>
                                </>
                              )}
                              {/* hacer validacion real de date */}
                              {!field.licenseDate ? (
                                <td
                                  style={{ color: fieldLicense ? "" : "red" }}
                                >
                                 {t("globals.licenseNotRequested")}
                                </td>
                              ) : (
                                <td style={{ color: fieldLicense ? "" : "red", fontWeight:fieldLicense && dateActive && "bold" }}>
                                {fieldLicense && dateActive
                                  ? `${t("globals.activeLicense")} (HD + SD)`
                                  : fieldLicense && licenseExpired ?
                                  t("globals.expiredLicense") : fieldLicense &&
                                  !dateActive &&
                                  t("globals.requestSend")
                                }
                              </td>
                              )}
                            
                            </tr>
                          );
                        })}
                    </>
                  );
                })}
              {fieldsWithOutPlan.length > 0 && (
                <>
                  {props.farms.length > 0 && (
                    <tr
                      className="field-row"
                      style={{
                        background: "#5db283",
                        cursor: "auto",
                        color: "white",
                      }}
                      onClick={()=>{
                        setOptionsOtherFields(!optionsOtherFields)
                      }}
                    >
                      <td>
                        <b
                          className="fa fa-caret-down"
                          style={{transform: optionsOtherFields && "rotate(180deg)", fontSize:"x-large"}}
                        />
                      </td>
                      <td className="text-center">{t("globals.otherFields")}</td>
                      <td>{areaFieldsPlan > 0 && areaFieldsPlan}</td>
                      <td>{areaFieldsNotPlan > 0 && areaFieldsNotPlan}</td>
                      <td></td>
                    </tr>
                  )}
                  {fieldsWithOutPlan.map((fields) => {
                    let fieldsLicenses = fields.license;
                          //sea menor a la fecha actual y no sea 2000
                    let licenseExpired = new Date(fields.licenseDate?.replaceAll("Z", "")).getTime() < new Date().getTime() && 
                    new Date(fields.licenseDate?.replaceAll("Z", "")).getFullYear() !== 2000;
                    let dateActive =
                      new Date(
                        fields.licenseDate?.replaceAll("Z", "")
                      ).getTime() > new Date().getTime();
                    return (
                      <>
                        {optionsOtherFields && (
                          <tr>
                            <td></td>
                            <td
                              className="text-center"
                              style={{ color: fieldsLicenses ? "" : "red" }}
                            >
                              {fields.name}
                            </td>
                            <td style={{ color: fieldsLicenses ? "" : "red" }}>
                              {fieldsLicenses ? fields.area : ""}
                            </td>
                            <td style={{ color: fieldsLicenses ? "" : "red" }}>
                              {!fieldsLicenses && fields.area}
                            </td>
                            {/* hacer validacion real de date */}
                            {!fields.licenseDate ? (
                              <td
                                style={{ color: fieldsLicenses ? "" : "red" }}
                              >
                                 {t("globals.licenseNotRequested")}
                              </td>
                            ) : (
                              <td style={{ color: fieldsLicenses ? "" : "red" }}>
                              {fieldsLicenses && dateActive
                                  ? `${t("globals.activeLicense")} (HD + SD)`
                                  : fieldsLicenses && licenseExpired ?
                                  t("globals.expiredLicense") : fieldsLicenses &&
                                  !dateActive &&
                                  t("globals.requestSend")
                                }
                            </td>
                            )}
                            
                          </tr>
                        )}
                      </>
                    );
                  })}
                </>
              )}
              {/* <tr>
          <td
            colSpan="6"
            style={{ textAlign: "center", fontSize: "1.8em" }}
          >
            NOSE
          </td>
        </tr> */}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default ListLicences;

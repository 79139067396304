import axios from "axios";
import { FieldLocationIcon } from "components/Maps/locationIcon";
import MapLink from "components/Maps/MapLink";
//le puse mayusculas por si se rompe en algun lado
import React, { useEffect, useState } from "react";
import { Map, Marker, Popup, FeatureGroup } from "react-leaflet";
import LoadingOverlay from "react-loading-overlay";
import {
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Button,
  Form,
} from "reactstrap";
import ReutBackMapDetail from "./ReutBackMapDetail";
import { useTranslation } from "react-i18next";

const ReutMapDetail = (props) => {
  const [t, i18n] = useTranslation("global")
  const headers = {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
  const [mapShow, setMapShow] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [fieldsAcess, setFieldsAcces] = useState([]);
  const [asignPolygons, setAsignPolygons] = useState([]);
  const [searchPolygonsAndField, setSearchPolygonsAndField] = useState([]);
  const [colorDefault, setColorDefault] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bounds, setBounds] = useState([
    [-90, -180],
    [90, 180],
  ]);

  const handleSearchChange = (e) => {
    setInputSearch(e.target.value);
  };

  //APENAS INICIA
  useEffect(() => {
    fetch();
  }, []);

  //TODOS LOS POLIGONOS
  useEffect(() => {
    if (asignPolygons.length > 0) {
      getBounds(false);
    }
  }, [asignPolygons.length]);

  //TODOS LOS POLIGONOS BUSCADOS POR NOMBRE
  useEffect(() => {
    if (searchPolygonsAndField.length > 0) {
      getBounds(true);
      setLoading(true);
    }
  }, [searchPolygonsAndField.length]);

  const fetch = async () => {
    const reAsignPolygons = await axios.get(
      `https://agrodigital.io/api/polygons/`,
      headers
    );
    setAsignPolygons(reAsignPolygons.data);
    let fieldData = await axios.get(
      "https://agrodigital.io/api/fields/",
      headers
    );
    let fieldInfo = fieldData.data;
    for (let i = 0; i < fieldInfo.length; i++) {
      let id = fieldInfo[i]["id"];
      const polygonData = await axios.get(
        `https://agrodigital.io/api/polygons/?field=${id}`,
        headers
      );
      let polygons = polygonData.data.map((item) => {
        let tempData = item;
        if (tempData.deleted === null) {
          tempData["id"] = item.id;
          return tempData;
        }
      });
      fieldInfo[i]["polygons"] = polygons;
    }

    setFieldsAcces(fieldInfo);
  };

  const randomColor = (length) => {
    let arrColor = [];
    for (let i = 0; i < length; i++) {
      const random = "#" + Math.floor(Math.random() * 16777215).toString(16);
      arrColor.push(random);
    }

    setColorDefault(arrColor);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var emailRex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (emailRex.test(inputSearch)) {
      await axios
        .get(`https://agrodigital.io/api/auth/users/`, headers)
        .then(async (res) => {
          let valid = false;
          let userAccess = res.data;
          for (let i = 0; i < userAccess.length; i++) {
            let user = userAccess[i];
            if (user.email === inputSearch) {
              valid = true;
            }
          }

          if (valid) {
            await axios
              .get(
                `https://agrodigital.io/api/auth/users/?email=${inputSearch}`,
                headers
              )
              .then((res) => {
                let userEmail = res.data[0];
                let searchForEmail = fieldsAcess.filter((el) =>
                  el.access.includes(userEmail.id)
                );
                if (searchForEmail.length > 0) {
                  randomColor(searchForEmail.length);
                  setSearchPolygonsAndField(searchForEmail);
                } else {
                  setLoading(false);
                }
              });
          } else {
            setLoading(false);
          }
        });
    } else {
      let inputLength = inputSearch.length;

      let searchForName = asignPolygons.filter(
        (el) =>
          el.name.toUpperCase() === inputSearch.toUpperCase() ||
          el.name.toLowerCase().slice(0, inputLength) === inputSearch
      );
      if (searchForName.length > 0) {
        setSearchPolygonsAndField(searchForName);
      } else {
        setLoading(false);
      }
    }
  };
  const getBounds = (statuSelect) => {
    var left = -57;
    var top = -32;
    var right = 57;
    var bottom = 32;
    if (statuSelect) {
      if (
        searchPolygonsAndField.length > 0 &&
        !searchPolygonsAndField[0]?.hasOwnProperty("polygons")
      ) {
        for (let i = 0; i < searchPolygonsAndField.length; i++) {
          let coordinates = searchPolygonsAndField[i]?.boundary.coordinates[0];
          if (coordinates) {
            for (let k = 0; k < coordinates.length; k++) {
              let coords = coordinates[k];
              let latitude = coords[1];
              let longitude = coords[0];
              if (latitude > top) {
                top = coords[1];
              }

              if (latitude < bottom) {
                bottom = coords[1];
              }

              if (longitude > left) {
                left = longitude;
              }

              if (longitude < right) {
                right = longitude;
              }
            }
          }
        }
      }
      if (
        searchPolygonsAndField.length > 0 &&
        searchPolygonsAndField[0]?.hasOwnProperty("polygons")
      ) {
        for (let i = 0; i < searchPolygonsAndField.length; i++) {
          let polygons = searchPolygonsAndField[i].polygons;
          if (polygons.length > 0) {
            for (let j = 0; j < polygons.length; j++) {
              let coordinates = polygons[i]?.boundary.coordinates[0];
              if (coordinates) {
                for (let k = 0; k < coordinates.length; k++) {
                  let coords = coordinates[k];
                  let latitude = coords[1];
                  let longitude = coords[0];
                  if (latitude > top) {
                    top = coords[1];
                  }

                  if (latitude < bottom) {
                    bottom = coords[1];
                  }

                  if (longitude > left) {
                    left = longitude;
                  }

                  if (longitude < right) {
                    right = longitude;
                  }
                }
              }
            }
          }
        }
      }
    } else {
      if (asignPolygons.length > 0) {
        for (let i = 0; i < asignPolygons.length; i++) {
          let coordinates = asignPolygons[i]?.boundary.coordinates[0];
          if (coordinates) {
            for (let k = 0; k < coordinates.length; k++) {
              let coords = coordinates[k];
              let latitude = coords[1];
              let longitude = coords[0];
              if (latitude > top) {
                top = coords[1];
              }

              if (latitude < bottom) {
                bottom = coords[1];
              }

              if (longitude > left) {
                left = longitude;
              }

              if (longitude < right) {
                right = longitude;
              }
            }
          }
        }
      }
    }

    let result = [
      [top, left],
      [bottom, right],
    ];
    setMapShow(true);
    setBounds(result);
  };
  const searchResult = searchPolygonsAndField.map((field, index) => {
    if (field["polygons"]) {
      return (
        <FeatureGroup key={index} color={colorDefault[index]}>
          <ReutBackMapDetail
            field={field}
            selectedPolygons={props.selectedPolygons}
          />
        </FeatureGroup>
      );
    } else {
      return (
        <FeatureGroup key={index} color="lime">
          <ReutBackMapDetail
            field={field}
            selectedPolygons={props.selectedPolygons}
          />
        </FeatureGroup>
      );
    }
  });
  return (
    <>
      <LoadingOverlay active={!mapShow} spinner text={"Cargando..."}>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText></InputGroupText>
            </InputGroupAddon>
            <Input
              name="reutilizarPol"
              placeholder={t("globals.searchPolygonInput")}
              type="text"
              onChange={handleSearchChange}
            />
            <Button style={{ margin: "0" }}>{t("buttons.searchButton")}</Button>
          </InputGroup>
        </Form>

        <Map
          center={props.center}
          bounds={bounds}
          style={{ width: "100%", height: "100%", minHeight: "500px" }}
          zoom={props.zoom}
          zoomControl={false}
        >
          <MapLink />
          <Marker position={props.center} icon={FieldLocationIcon}>
            <Popup>Center of Map!</Popup>
          </Marker>
          {!loading && asignPolygons.length > 0
            ? asignPolygons.map((field, index) => (
                <FeatureGroup key={index} color="lime">
                  <ReutBackMapDetail
                    field={field}
                    selectedPolygons={props.selectedPolygons}
                  />
                </FeatureGroup>
              ))
            : searchResult}
        </Map>
      </LoadingOverlay>
    </>
  );
};

export default ReutMapDetail;

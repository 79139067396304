import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "../../components/Chip.js";
import moment from "moment";
import axios from "axios";
import { AlertMultiPolygons, requestResult } from "./AlertLogsManyPolygons.js";
import AlertsPhenoEvents from "../AlertsForms/AlertsPhenoEvents.js";

class GenericDateAndSelectForm extends Component {
  constructor(props) {
    super(props);

    var eventTypes = [];
    var eventTypeOptions = [];
    var logType = null;
    var selectedEventType = null;
    var selectedChemichals = [];

    if (props.type) {
      logType = props.type;
    } else if (props.selectedLog) {
      logType = props.selectedLog.type;
    }

    if (props.selectedLog) {
      if (logType === "LabReport") {
        if (props.selectedLog.data) {
          selectedChemichals = props.selectedLog.values;
        }
      }
    }

    this.state = {
      logType: logType,
      date: props.selectedLog ? props.selectedLog.date : null,

      eventTypes: eventTypes,
      eventTypeOptions: eventTypeOptions,

      selectedEventType: selectedEventType,
      selectedChemichals: selectedChemichals,

      observation: props.selectedLog ? props.selectedLog.obs : null,

      //Control values
      dateValidState: true,
      eventTypeState: true,
      chemichalSelectState: true,
      loading: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
      selectedLog: props.selectedLog ? props.selectedLog : null,
      tokenUser: {
        headers: {
          // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
      crop: null,
      variety: null,
      predecesor: null,
      cropArr: [],
      varietyArr: [],

      cropState: true,
      predecesorState: true,
      sowingDateState: true,

      predecessorArray: [],
      loading: false,
      predecesorVariety: null,
      predecesorVarietyArr: [],
      recentDate: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
      alertDifferentSowing: false,
      validateHaveSowing: false,
      alertNotSowingLog: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    var eventTypes = [];
    var eventTypeOptions = [];
    var logType = null;
    var selectedEventType = null;
    var selectedChemichals = [];

    if (nextProps.type) {
      logType = nextProps.type;
    } else if (nextProps.selectedLog) {
      logType = nextProps.selectedLog.type;
    }

    if (logType === "Phenology") {
      this.fetchsetOptions(logType);
    } else if (logType === "LabReport") {
      this.fetchsetOptions(logType);
    } else if (logType === "Water") {
      this.fetchsetOptions(logType);
    }

    if (nextProps.selectedLog) {
      if (logType === "LabReport") {
        if (nextProps.selectedLog.data) {
          selectedChemichals = props.selectedLog.values;
        }
      } else {
        if (nextProps.selectedLog.value) {
          selectedEventType = eventTypes.filter(
            (item) => item.label === props.selectedLog.variable
          );
        }
      }
    }

    this.state = {
      logType: logType,
      date: nextProps.selectedLog ? nextProps.selectedLog.date : null,

      eventTypes: eventTypes,
      eventTypeOptions: eventTypeOptions,

      selectedEventType: selectedEventType,
      selectedChemichals: selectedChemichals,

      observation: nextProps.selectedLog ? nextProps.selectedLog.obs : null,

      //Control values
      dateValidState: true,
      eventTypeState: true,
      chemichalSelectState: true,
      loading: false,
      tokenUser: {
        headers: {
          // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
      crop: null,
      variety: null,
      predecesor: null,
      cropArr: [],
      varietyArr: [],

      cropState: true,
      predecesorState: true,
      sowingDateState: true,

      predecessorArray: [],
      loading: false,
      predecesorVariety: null,
      predecesorVarietyArr: [],
      recentDate: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
      lastSowingCrop: null,
      alertDifferentSowing: false,
      validateHaveSowing: false,
      alertNotSowingLog: false,
    };
  }

  componentDidMount() {
    if (this.state.logType === "Phenology") {
      this.fetchsetOptions(this.state.logType);
    } else if (this.state.logType === "LabReport") {
      this.fetchsetOptions(this.state.logType);
    } else if (this.state.logType === "Water") {
      this.fetchsetOptions(this.state.logType);
    }
    if (this.props.selectedLog?.id) {
      this.checkLogs();
    }
  }

  fetchCrops = async (phenoData, lastSowing) => {
    let vm = this;
    let phenoCrops = phenoData.map((el)=> el.crop)
    const cropsData = await axios.get(
      "https://agrodigital.io/api/crops/",
      this.state.tokenUser
    );
    let dataInfo = [];
    let orderCrops = cropsData.data.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    orderCrops.map((item, index) => {
      if (phenoCrops.includes(item.id)) {
        let tempData = item;
        let tempArr = {};
        tempArr["value"] = item.id;
        tempArr["label"] = tempData["name"];
        dataInfo.push(tempArr);
      }
    });
   
    this.setState(
      {
        predecessorArray: dataInfo,
        cropArr: dataInfo,
      },
      () => {
        if (vm.state.selectedLog && vm.state.selectedLog.crop) {
          let crop = dataInfo.filter(
            (crop) => crop.value === vm.state.selectedLog.crop
          )[0];
          if (crop) {
            this.changeCrop(crop);
          }
        } else{
          let crop = dataInfo.filter(
            (crop) => crop.value === lastSowing.crop
          )[0];
          if (crop) {
            this.changeCrop(crop);
          } else{
            this.changeCrop(dataInfo[0]);
          }
        }
      }
    );
  };
  changeCrop = (value, changeCrop = false) => {
      let filterEvents = this.state.eventTypeOptions.filter((el)=> el.crop === value.value)
      let presence = [];
      filterEvents.map((el) => {
          presence.push({
            value: el.id,
            label: el.name,
          });
      });
      let orderPresence = presence.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      if(changeCrop){
        this.setState({
        eventTypes: orderPresence,
        crop: value,
        cropState: true,
        selectedEventType:null
      }); 
      }else{
        this.setState({
          eventTypes: orderPresence,
          crop: value,
          cropState: true,
        }); 
      }
  };


  checkLogs = async () => {
    let info = {
      fieldId: this.props.fieldId,
      polygonSelected: Object.keys(this.props.selectedPolygons).map((el) =>
        Number(el)
      ),
      date: this.state.date,
      tokenUser: this.state.tokenUser,
      type: this.props.selectedLog.type,
    };
    let result = await requestResult(info);
    this.setState((prev) => ({
      ...prev,
      alertMoreThanOnePolygon: result?.length > 1 && true,
      nameOfAllPolygons: result,
    }));
  };
  selectedEventTypeFilter = (presence) => {
    let selectedEvent = presence.map(
      (item) => {
      if(item.id === this.props.selectedLog.value){
        return {
          value: item.id,
          label: item.name
      }
      }}
    ).filter((el)=> el)[0];

    this.setState({ selectedEventType: selectedEvent });
  };
  fetchLastSowing = async ()=>{
    let polygonSelected =Object.keys(this.props.selectedPolygons).map((el) =>
    Number(el)
  );
    await axios
    .get(`https://agrodigital.io/api/logs/?field=${this.props.fieldId}&type=Sowing`, this.state.tokenUser)
    .then((res)=>{
      let sowingData= res.data
      if(sowingData.length > 0){
        if(polygonSelected.length === 1){
          this.onePolygonSelect(sowingData, polygonSelected[0]);
        }
        if(polygonSelected.length > 1){
          this.multiPolygonsSelect(sowingData, polygonSelected);
        }
      } else {
        this.setState({
          validatePerformance: true,
          alertNotSowingLog: true,
        });
      }
    })
  }

  onePolygonSelect = (sowingData, polygonSelected)=>{
      let sowingByPolygon = sowingData.filter((el)=> el.polygons.includes(polygonSelected))
      if(sowingByPolygon.length > 0){
        let orderAsc= sowingByPolygon
        .filter((el) => el.date !== "-")
        .sort(
          (a, b) =>
          new Date(b.date + "T00:00:00").getTime() -
            new Date(a.date + "T00:00:00").getTime() 
        );
        let lastSowing = orderAsc[0]
        this.setState({ lastSowingCrop: lastSowing });
        this.fetchPhenoEvent(lastSowing)
      } else{
        this.setState({
          alertNotSowingLog: true,
        });
      }
  }
  // Tomar la ultima fecha de sowing por poligono y verificar que tengan el mismo crop
  multiPolygonsSelect=(sowingData, polygonSelected)=>{
    let filterSowingForPolygon = sowingData.filter((logSow) =>
    polygonSelected.some((select) => logSow.polygons.includes(select))
    );
    let sowingForPolygon = {};
    for (let k = 0; k < polygonSelected.length; k++) {
      const polSelect = polygonSelected[k];
      sowingForPolygon[polSelect] = [];
      for (let i = 0; i < filterSowingForPolygon.length; i++) {
        const filterSowing = filterSowingForPolygon[i];
        if (filterSowing.polygons.includes(polSelect)) {
          let arrSort = [...sowingForPolygon[polSelect], filterSowing].sort(
            (a, b) =>
              new Date(b.date + "T00:00:00") - new Date(a.date + "T00:00:00")
          );
          sowingForPolygon[polSelect] = arrSort;
        }
      }
    }
    let validSowingData = Object.values(sowingForPolygon).filter(
      (el) => el.length > 0
    );
    if (filterSowingForPolygon.length > 0) {
      //si tienen la misma cantidad significa que en los poligonos seleccionados todos tienen registro de siembra
      if (polygonSelected.length === validSowingData.length) {
        let valuesSowing = validSowingData.map((el) => el[0]);
      //todos los elementos del array tienen que tener el mismo crop
        let polygonSowingCrop = valuesSowing[0].crop;
        let checkCrop = valuesSowing.every(
          (list) => list.crop === polygonSowingCrop
        );
        if(checkCrop){
          this.fetchPhenoEvent(valuesSowing[0])
          this.setState({ lastSowingCrop: valuesSowing[0], alertDifferentSowing: false });
        } else{
          // si alguno de los poligonos seleccionados no tienen el mismo sowing
          this.setState({
            alertDifferentSowing: true,
          });
        }
      } else {
        //si algun poligono no tiene registro de siembra
        this.setState({
          validateHaveSowing: true,
        });
      }
    } else {
      // si no tienen registro de siembra
      this.setState({
        alertNotSowingLog: true,
      });
    }
  }
  //EJECUTAR AL ULTIMO TENIENDO EL DATO DE LA ULTIMA SIEMBRA 
  fetchPhenoEvent = async (lastSowing)=>{
    await axios
    .get("https://agrodigital.io/api/pheno_event/", this.state.tokenUser)
    .then((res) => {
      let presence = [];
      res.data.map((el) => {
        if(el.crop === lastSowing.crop){
          presence.push({
            value: el.id,
            label: el.name,
          });
        } 
      });
      let orderPresence = presence.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      if (this.props.selectedLog?.value) {
        this.selectedEventTypeFilter(res.data);
      }
      this.setState({ eventTypes: orderPresence });
      this.setState({ eventTypeOptions: res.data });

      this.fetchCrops(res.data, lastSowing)
    });
  }


  fetchsetOptions = async (logType) => {
    switch (logType) {
      case "Phenology":
        this.fetchLastSowing()
        break;
      case "Water":
        await axios
          .get("https://agrodigital.io/api/water_event/", this.state.tokenUser)
          .then((res) => {
            let presence = [];
            res.data.map((el) => {
              presence.push({
                value: el.id,
                label: el.name,
              });
            });
            let orderPresence = presence.sort((a, b) =>
              a.label.localeCompare(b.label)
            );
            if (this.props.selectedLog?.id) {
              this.selectedEventTypeFilter(res.data);
            }

            this.setState({ eventTypes: orderPresence });
          });
        break;
      case "LabReport":
        await axios
          .get("https://agrodigital.io/api/lab_var/", this.state.tokenUser)
          .then((res) => {
            let presence = [];
            res.data.map((el) => {
              presence.push({
                value: el.id,
                label: el.name,
              });
            });
            let orderPresence = presence.sort((a, b) =>
              a.label.localeCompare(b.label)
            );
            if (this.props.selectedLog?.value) {
              this.selectedEventTypeFilter(res.data);
            }
            this.setState({ eventTypes: orderPresence });
          });
        break;
      default:
        break;
    }
  };

  clearStatus() {
    this.setState({
      crop: null,
      variety: null,
      predecesor: null,
      sowing_date: null,
      varietyArr: [],
      predecesorVariety: null,
      predecesorVarietyArr: [],
      recentDate: false,
      date: null,
      eventTypes: [],
      selectedEventType: null,
      waterObsInput: null,
      logType: null,
      dateValidState: true,
      eventTypeState: true,
      chemichalSelectState: true,
      loading: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
      selectedLog: null,
      alertDifferentSowing: false,
      validateHaveSowing: false,
      alertNotSowingLog: false
    });
  }

  isValidated = () => {
    var isValidated = true;
    if (this.state.crop === null && this.state.logType === "Phenology") {
      this.setState({
        cropState: false,
      });
      isValidated = false;
    }
    if (this.state.date == null) {
      this.setState({
        dateValidState: false,
      });

      if (isValidated) {
        isValidated = false;
      }
    }

    if (this.shouldShowSelect()) {
      if (this.state.selectedEventType == null) {
        this.setState({
          eventTypeState: false,
        });

        if (isValidated) {
          isValidated = false;
        }
      }
    } else {
      if (this.state.selectedChemichals.length === 0) {
        this.setState({
          chemichalSelectState: false,
        });

        if (isValidated) {
          isValidated = false;
        }
      }
    }

    return isValidated;
  };

  closeButtonTapped(success = false) {
    this.clearStatus();
    this.props.onClose(success);
  }

  changeSelectedEventType(value) {
    this.setState({
      selectedEventType: value,
    });
  }

  selectChemical(value) {
    if (this.state.selectedChemichals.indexOf(value) <= -1) {
      this.state.selectedChemichals.push(value);
      this.setState({});
    }
  }

  onChangeInput = (input) => {
    let chemicalIndex = input.target.id;
    if (
      this.state.selectedChemichals &&
      this.state.selectedChemichals.length > 0 &&
      this.state.selectedChemichals[chemicalIndex]
    ) {
      let agroChemichal = this.state.selectedChemichals[chemicalIndex];
      agroChemichal["inputValue"] = input.target.value;
      this.setState({});
    }
  };

  onDeleteTapped = (input) => {
    let chemicalIndex = input.target.id;

    if (
      this.state.selectedChemichals &&
      this.state.selectedChemichals.length > 0 &&
      this.state.selectedChemichals[chemicalIndex]
    ) {
      let agroChemichal = this.state.selectedChemichals[chemicalIndex];
      let filteredArray = this.state.selectedChemichals.filter(
        (item) => item.value !== agroChemichal.value
      );

      this.setState({ selectedChemichals: filteredArray });
    }
  };

  saveButtonTapped() {
    let polygonsIds = Object.keys(this.props.selectedPolygons).map((el) =>
      Number(el)
    );
    if (this.isValidated() && polygonsIds.length > 0 && this.props.fieldId) {
      let insertData = {};
      insertData.type = this.state.logType;

      if (this.shouldShowSelect()) {
        if(this.state.logType === "Phenology"){
          insertData.crop = this.state.crop.value;
        }
        if (this.state.selectedEventType) {
          insertData.value = this.state.selectedEventType.value;
        } else {
          insertData.value = null;
        }
      } else {
        //Esto es para el dato analítico
        if (
          this.state.selectedChemichals &&
          this.state.selectedChemichals.length > 0
        ) {
          let result = [];
          this.state.selectedChemichals.map((el) => {
            result.push({
              value: parseFloat(el["inputValue"]),
              item: el.value,
            });
          });

          insertData.results = result;
        } else {
          insertData.data = null;
        }
      }
      insertData.obs = this.state.observation;
      let dateString = this.state.date.toString();
      if (dateString.length === 10) {
        insertData.date = moment(new Date(dateString + "T00:00:00")).format(
          "YYYY-MM-DD"
        );
      }
      if (dateString.length > 10) {
        insertData.date = moment(new Date(this.state.date)).format(
          "YYYY-MM-DD"
        );
      }
      let vm = this;
      this.setState({
        loading: true,
      });
      if (this.props.selectedLog?.id) {
        axios
          .get(
            `https://agrodigital.io/api/logs/?field=${this.props.fieldId}`,
            this.state.tokenUser
          )
          .then((res) => {
            let data = {};
            for (let i = 0; i < res.data.length; i++) {
              const logReference = res.data[i];
              if (logReference.id === this.props.selectedLog.id) {
                data = logReference;
              }
            }
            axios
              .patch(
                `https://agrodigital.io/api/logs/${data.id}/`,
                insertData,
                this.state.tokenUser
              )
              .then((res) => {
                vm.setState({
                  loading: false,
                });
                vm.closeButtonTapped(true);
              });
          });
      } else {
        insertData.polygons = polygonsIds;
        axios
          .post(
            "https://agrodigital.io/api/logs/",
            insertData,
            this.state.tokenUser
          )
          .then((res) => {
            vm.setState({
              loading: false,
            });
            vm.closeButtonTapped(true);
          });
      }
    }
  }

  getFormTitle() {
    if (this.state.logType === "Phenology") {
      return this.props.t("genericAndSelectForm.phenologyTitle")
    } else if (this.state.logType === "LabReport") {
      return this.props.t("genericAndSelectForm.labReportTitle")
    } else if (this.state.logType === "Water") {
      return this.props.t("genericAndSelectForm.irrigationTitle")
    }
  }

  getSelectPlaceholder() {
    if (this.state.logType === "Phenology") {
      return this.props.t("genericAndSelectForm.selectEventPhenology")
    } else if (this.state.logType === "Water") {
      return this.props.t("genericAndSelectForm.selectEventIrrigation")
    }
    return "";
  }

  shouldShowSelect() {
    if (this.state.logType === "Phenology") {
      return true;
    } else if (this.state.logType === "Water") {
      return true;
    }
    return false;
  }

  render() {
    const {t}= this.props;
    return (
      <>
      {
        (this.state.alertDifferentSowing || 
          this.state.alertNotSowingLog || 
          this.state.validateHaveSowing) && 
        <AlertsPhenoEvents  
        differentSowing={this.state.alertDifferentSowing} 
        noSowingLog={this.state.alertNotSowingLog} 
        haveSowing={this.state.validateHaveSowing} 
        closeButtonTapped={this.closeButtonTapped.bind(this)}
        />
      }
      <Modal isOpen={this.props.isOpen}>
        <div
          className="modal-header justify-content-center"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <h4 className="title title-up">{this.getFormTitle()}</h4>
        </div>
        <div className="modal-body">
          {this.state.alertMoreThanOnePolygon &&
            this.state.selectedLog?.id &&
            this.state.nameOfAllPolygons.length > 1 && (
              <AlertMultiPolygons
                nameOfAllPolygons={this.state.nameOfAllPolygons}
              />
            )}
          <br />
          <Row className="justify-content-center">
          <Col sm={12} md={10} lg={8}>
                {this.state.cropArr?.length > 0 && this.state.logType === "Phenology" && (
                  <FormGroup>
                    <label>{t("globals.crop")}</label>
                    <Select
                      name="crop"
                      className="react-select"
                      placeholder={t("globals.selectCrop")}
                      classNamePrefix="react-select"
                      value={this.state.crop}
                      onChange={(value) => this.changeCrop(value, true)}
                      options={this.state.cropArr}
                    />
                    {this.state.cropState === false ? (
                      <label style={{ color: "#ff0000" }}>
                        {t("globals.requiredField")}
                      </label>
                    ) : null}
                  </FormGroup>
                )}
              </Col>
            <Col sm={12} md={10} lg={8}>
              <FormGroup>
                <label>{t("globals.date")}</label>
                <ReactDatetime
                  dateFormat="DD/MM/YYYY"
                  value={this.state.date}
                  onChange={(value) => {
                    this.setState({
                      dateValidState: true,
                      date: value,
                    });
                  }}
                  inputProps={{
                    className: "form-control",
                    placeholder: t("globals.insertDateLog"),
                  }}
                  timeFormat={false}
                />
                {this.state.dateValidState === false ? (
                  <label style={{ color: "#ff0000" }}>{t("globals.requiredField")}</label>
                ) : null}
              </FormGroup>
              {this.shouldShowSelect() && (
                <div>
                  <FormGroup>
                    <label>{t("globals.typeEvent")}</label>
                    <Select
                      name="logType"
                      className="react-select"
                      placeholder={this.getSelectPlaceholder()}
                      classNamePrefix="react-select"
                      value={this.state.selectedEventType}
                      onChange={(value) => {
                        this.changeSelectedEventType(value);
                      }}
                      options={this.state.eventTypes}
                    />
                    {this.state.eventTypeState === false ? (
                      <label style={{ color: "#ff0000" }}>
                       {t("globals.requiredField")}
                      </label>
                    ) : null}
                  </FormGroup>
                </div>
              )}
              {!this.shouldShowSelect() && (
                <div>
                  <FormGroup>
                    <label>{t("globals.addVariable")}</label>
                    <Select
                      name="logType"
                      className="react-select"
                      placeholder={t("globals.addVariable")}
                      classNamePrefix="react-select"
                      value={null}
                      onChange={(value) => {
                        this.selectChemical(value);
                      }}
                      options={this.state.eventTypes}
                    />
                    {this.state.chemichalSelectState === false ? (
                      <label style={{ color: "#ff0000" }}>
                        {t("globals.requiredField")}
                      </label>
                    ) : null}
                  </FormGroup>
                </div>
              )}
              <div>
                <FormGroup>
                  <label>{t("globals.observation")}</label>
                  <Input
                    type="text"
                    value={this.state.observation}
                    onChange={(e) =>
                      this.setState({
                        observation: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </div>
              <div>
                {this.state.selectedChemichals &&
                  this.state.selectedChemichals.map((agrochemical, index) => {
                    return (
                      <Chip
                        id={index}
                        value={agrochemical["inputValue"]}
                        onChange={this.onChangeInput}
                        label={agrochemical.label}
                        onDeleteTapped={this.onDeleteTapped}
                      ></Chip>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </div>
        {!this.state.loading ? (
          <div className="modal-footer">
            <div className="left-side">
              <Button
                className="btn-link"
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={() => this.closeButtonTapped(false)}
              >
                {t("buttons.cancelButton")}
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                className="btn-link"
                color="primary"
                type="button"
                onClick={() => this.saveButtonTapped()}
              >
                {t("buttons.saveButton")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center py-4">
            <CircularProgress color="secondary" />
          </div>
        )}
      </Modal>
      </>
    );
  }
}

export default GenericDateAndSelectForm;

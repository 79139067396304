/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// react plugin used to create switch buttons

import { Button } from "reactstrap";
import Select from "react-select";
import MultiSlider from "multi-slider";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { slide as RightSidebar } from "react-burger-menu";
import "../../assets/css/map.css";
import ModalInputs from "../../views/components/ModalInputs";
import DeleteRasterInfo from "views/maps/raster/DeleteRasterInfo";
import CheckDataRasters from "views/maps/raster/CheckDataRasters";
import { NavLink } from "react-router-dom";
var objetos = {
  values_NDVI: [0, 10, 30, 40, 20],
  values_NDWI: [0, 10, 30, 40, 20],
  values_CCCI: [0, 10, 30, 40, 20],
  values_RGB: [0, 10, 30, 40, 20],
};
class SidebarLayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [0, 10, 30, 40, 20],
      min: 0 / 100,
      min2: 10 / 100,
      max2: 40 / 100,
      max: 80 / 100,
      index: { value: "RGB", label: props.t("globals.visualRasterOption") },
      showModal: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.cbDataAux = this.cbDataAux.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (value) => {
    var cbdata = {
      _0: value[0] / 100,
      _20: (value[0] + value[1]) / 100,
      _40: (value[0] + value[1] + value[2]) / 100,
      _60: (value[0] + value[1] + value[2] + value[3]) / 100,
    };
    this.props.handleColors(cbdata);
    var key = "values_" + this.state.index.value;
    objetos[key] = value;
    if (this.state.index.value == "NDWI") {
      this.setState({
        values: value,
        min: 2 * cbdata._0 - 1,
        min2: 2 * cbdata._20 - 1,
        max2: 2 * cbdata._40 - 1,
        max: 2 * cbdata._60 - 1,
      });
    } else {
      this.setState({
        values: value,
        min: cbdata._0,
        min2: cbdata._20,
        max2: cbdata._40,
        max: cbdata._60,
      });
    }
  };

  closeModal() {
    this.setState({ showModal: false });
  }

  cbDataAux(value) {
    var cbdata = [
      value[0] / 100,
      (value[0] + value[1]) / 100,
      (value[0] + value[1] + value[2]) / 100,
      (value[0] + value[1] + value[2] + value[3]) / 100,
    ];
    return cbdata;
  }

  render() {
    const {t}= this.props;
    return (
      <div style={{ position: "relative" }}>
        <RightSidebar
          right
          disableCloseOnEsc
          noOverlay
          isOpen={true}
          customCrossIcon={false}
          width={this.props.width}
          styles={{
            bmMenu: { background: "linear-gradient(#66615b,#000 80%)" },
          }}
        >
          <div className="py-2 px-2" style={{ width: "100%" }}>
            {this.props.fieldInfo?.name && (
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "24px",
                }}
              >
                <span>
                  {this.props.fieldInfo?.name.length > 15
                    ? this.props.fieldInfo?.name.substring(0, 15) + "..."
                    : this.props.fieldInfo?.name}
                </span>
                <div className="linea-gris"></div>
              </div>
            )}
            <span
              style={{
                fontSize: "24px",
                color: "white",
                display: "block",
                textAlign: "center",
              }}
            >
              {t("globals.layerControl")}
            </span>
          </div>
          <div className="row mx-0 my-0 px-1" style={{ color: "white" }}>
            <Row>
              <Col className="mt-3 ml-3 mr-3">
                <Card>
                  <CardHeader className="text-center"></CardHeader>
                  <CardBody className="pl-1 pr-1 pt-1">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={this.state.index}
                      onChange={(value2) => {
                        this.props.changeIndex(value2);
                        var key = "values_" + value2.value;
                        this.setState({ index: value2, values: objetos[key] });
                        var value = objetos[key];
                        var cbdata = {
                          _0: value[0] / 100,
                          _20: (value[0] + value[1]) / 100,
                          _40: (value[0] + value[1] + value[2]) / 100,
                          _60:
                            (value[0] + value[1] + value[2] + value[3]) / 100,
                        };
                        if (value2.value == "NDWI") {
                          this.setState({
                            values: value,
                            min: 2 * cbdata._0 - 1,
                            min2: 2 * cbdata._20 - 1,
                            max2: 2 * cbdata._40 - 1,
                            max: 2 * cbdata._60 - 1,
                          });
                        } else {
                          this.setState({
                            values: value,
                            min: cbdata._0,
                            min2: cbdata._20,
                            max2: cbdata._40,
                            max: cbdata._60,
                          });
                        }
                      }}
                      options={
                        !this.props.planPlanet
                          ? [
                              { value: "RGB", label:  t("globals.visualRasterOption") },
                              { value: "NDVI", label: t("globals.greenIndexRasterOption") },
                              { value: "NDWI", label: t("globals.waterIndexRasterOption") },
                              {
                                value: "CCCI",
                                label:t("globals.ccciRasterOption"),
                              },
                            ]
                          : [
                              { value: "RGB", label:  t("globals.visualRasterOption") },
                              { value: "NDVI", label: t("globals.greenIndexRasterOption") },
                              { value: "NDWI", label: t("globals.waterIndexRasterOption") },
                            ]
                      }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 ml-3 mr-3">
                <Card>
                  <CardHeader
                    className="text-center"
                    onClick={() =>
                      this.setState({
                        showModal: true,
                      })
                    }
                  >
                    <i
                      className="fa fa-square"
                      style={{ color: this.props.colors[0] }}
                    ></i>
                    {this.state.min.toFixed(2) + " "}
                    <i
                      className="fa fa-square"
                      style={{ color: this.props.colors[1], marginLeft: "4px" }}
                    ></i>
                    {this.state.min2.toFixed(2) + " "}
                    <i
                      className="fa fa-square"
                      style={{ color: this.props.colors[2], marginLeft: "4px" }}
                    ></i>
                    {this.state.max2.toFixed(2) + " "}
                    <i
                      className="fa fa-square"
                      style={{ color: this.props.colors[3], marginLeft: "4px" }}
                    ></i>
                    {this.state.max.toFixed(2) + " "}
                  </CardHeader>
                  <CardBody className="pl-1 pr-1 pt-1">
                    <MultiSlider
                      colors={this.props.colors}
                      values={this.state.values}
                      onChange={this.onChange}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 ml-3 mr-3">
                <Card>
                  <CardBody className="pl-1 pr-1 pt-1">
                    <div className="col-12">
                      <NavLink
                        className="btn btn-success container"
                        to={"/admin/cropmap/detail/" + this.props.fieldId}
                      >
                        <i className="fi flaticon-bar-chart-2"></i>
                        {t("globals.graphs")}
                      </NavLink>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 ml-3 mr-3">
                <Card>
                  <CardBody className="pl-1 pr-1 pt-1">
                    <div className="col-12">
                      <Button
                        className="btn btn-success container"
                        onClick={() => this.props.toggleCursor()}
                      >
                        <i className="fi flaticon-leaf-5" />
                        {t("globals.markTour")}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.props.rgbSelect && <DeleteRasterInfo />}
            {(this.props.noDataRasterCCCI || this.props.noDataRasterNDWI) && (
              <CheckDataRasters
                noDataRasterCCCI={this.props.noDataRasterCCCI}
                noDataRasterNDWI={this.props.noDataRasterNDWI}
              />
            )}
          </div>
          <ModalInputs
            values={this.cbDataAux(this.state.values)}
            isOpen={this.state.showModal}
            colors={this.props.colors}
            onClose={this.closeModal}
            onSaveTapped={this.onChange}
            index={this.state.index.value}
            placeHolders={
              this.state.index.value == "NDWI"
                ? ["-1 - -0.8", "-1.0 - -0.20", "-0.8 - 0.6", "-0.2 - 1.0"]
                : ["0 - 0.10", "0 - 0.40", "0.1 - 0.80", "0.4 - 1.0"]
            }
          ></ModalInputs>
        </RightSidebar>
      </div>
    );
  }
}

export default SidebarLayer;

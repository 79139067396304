import React, { useState, useEffect } from 'react'
import { ApiContext } from '../context/ApiContext'
import axios from 'axios'
function FarmerData({ children }) {
  const [farmers, setFarmers] = useState([])
  const [token, setToken]= useState("")
  useEffect(() => {
    if(token){
      const tokenUser = {
        headers: {
          // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
          Authorization: `Token ${token}`,
        },
      };
      axios
        .get("https://agrodigital.io/api/farms/", tokenUser)
        .then((res) => {
          setFarmers(res.data)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      if(localStorage.getItem("token")){
        setToken(localStorage.getItem("token"))
      }
    }
  }, [token])


  
  return (
    <ApiContext.Provider value={[farmers, setFarmers, token, setToken]}>
      {children}
    </ApiContext.Provider>
  )
}
export default FarmerData
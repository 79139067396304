//TESTEAR CLOROFILA

import React, { useEffect, useState } from "react";
import { FaLeaf } from "react-icons/fa";
import { GiLeafSkeleton } from "react-icons/gi";
import ReactEcharts from "echarts-for-react";
import { firestore } from "firebase";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import {
  CardBody,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardFooter,
} from "reactstrap";
import * as moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../../../assets/css/emptyView.css";
import excelIcon from "../../../../assets/img/excelIcon.png";
import restartIcon from "../../../../assets/img/restartIcon.png";
import { ExportToCSV } from "../../../components/ExportCSV.js";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
const IndexChart = (props) => {
  const [t, i18n]= useTranslation("global")
  const [loadIndexes, setLoadIndexes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [indexPlanBasic, setIndexPlanBasic] = useState([]);
  const [indexPlanPlanet, setIndexPlanPlanet] = useState([]);
  const [stateData, setStateData] = useState({
    tokenUser:{
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    },
    data: null,
    dataZero: null,
    polygons: [],
    resetGraphStatus: false,
  });
  useEffect(() => {
    if (props.polygons.length > 0) {
      // console.log("1.primer useEffect que valida si hay poligonos");
      loadIndexData();
    }
  }, [props.polygons.length, props.zeroDate]);
  useEffect(() => {
    if (loadIndexes) {
      if (props.planBasic || props.planPlanet) {
        // console.log("4. useEffect de userPLanSource, valida la idexData");
        userPlanSource();
      }
    }
  }, [
    props.zeroDate,
    props.planBasic,
    props.planPlanet,
    loadIndexes
  ]);

  const loadIndexData = async () => {
    setIsLoading(true);
    //si solo se cambia la fecha no hace falta volver a cargar los axios, ya que ya estan cargados

    if (props.zeroDate != undefined) {
      // console.log("1.5 Si hay fecha seleccionada que filtre");
      userPlanSource();
    } else {
      // console.log("2. si no hay fecha");
      // console.log("2. si no hay fecha hace los request");
      let fieldId = props.fieldId;
      let indexesPlanetPlan = axios.get(
        `https://agrodigital.io/api/indexes/?field=${fieldId}&sources[]=PLA&sources[]=PLM`,
        stateData.tokenUser
      );
      let indexesBasicPlan = axios.get(
        `https://agrodigital.io/api/indexes/?field=${fieldId}&sources[]=LA9&sources[]=LA8&sources[]=SE2`,
        stateData.tokenUser
      );

      Promise.all([indexesPlanetPlan, indexesBasicPlan]).then((res) => {
        let indexPlanet = res[0].data;
        let indexBasic = res[1].data;
        setIndexPlanBasic(indexBasic);
        setIndexPlanPlanet(indexPlanet);
        if (!indexBasic.length && !indexPlanet.length) {
          // console.log("2.5 no tiene index data");
          fetchIndexData([]);
          props.changePlan("restart", false);
        }

        if (indexBasic.length > 0 && indexPlanet.length > 0) {
          // console.log("3. tiene ambas index data");
          props.changeDisabledButton("basic", false);
          props.changeDisabledButton("planet", false);
          if (!props.planBasic) props.changePlan("planet", true);
          setLoadIndexes(true)
        } else {
          if (indexBasic.length > 0) {
            props.changePlan("basic", true);
            props.changeDisabledButton("basic", false);
          setLoadIndexes(true)

          } else {
            props.changeDisabledButton("basic", true);
          }
          if (indexPlanet.length > 0) {
            props.changePlan("planet", true);
            props.changeDisabledButton("planet", false);
          setLoadIndexes(true)

          } else {
            props.changeDisabledButton("planet", true);
          }
        }
      });
    }
  };

  const userPlanSource = async () => {
    setIsLoading(true);
    if (props.planBasic) {
      fetchIndexData(indexPlanBasic);
    }

    if (props.planPlanet) {
      fetchIndexData(indexPlanPlanet);
    }
    if (!props.planPlanet && !props.planBasic) {
      setIsLoading(false);
    }
  };
  const fetchIndexData = async (indexData) => {
    let polygons = props.polygons;
    let fieldId = props.fieldId;
    const hasta = new Date(props.endDate + "T00:00:00").getTime();
    // console.log("5. trae fenologia y empieza a graficar", polygons, fieldId);

    let indexJson = {};
    // setLoading(false);
    //CAMBIAR FUNCIONALIDAD
    let phenologyEmergence = await axios.get(
      `https://agrodigital.io/api/logs/?field=${fieldId}&type=Phenology`,
      stateData.tokenUser
    );
    //por ahora "indexes", hasta ver como se soluciona lo de switch predeterminados

    Promise.all([phenologyEmergence]).then((res) => {
      let phenologyData = res[0].data;
      for (let i = 0; i < polygons.length; i++) {
        const pol = polygons[i];
        indexJson[pol.name] = {};

        let filterPhenology = phenologyData.filter(
          (el) => el.polygons.includes(pol.id) && el.value === 1
        );
        if (filterPhenology.length > 0) {
          let filterDate = filterPhenology.sort(
            (a, b) =>
              new Date(b.date + "T00:00:00") - new Date(a.date + "T00:00:00")
          )[0];
          indexJson[pol.name]["emergenceDate"] = new Date(
            filterDate.date + "T00:00:00"
          ).getTime();
        } else {
          indexJson[pol.name]["emergenceDate"] = null;
        }

        //PARTE 2
        let sortIndexesRef = indexData.sort(
          (a, b) =>
            new Date(a.date + "T00:00:00") - new Date(b.date + "T00:00:00")
        );

        if (sortIndexesRef.length > 0) {
          let filterByPolygon = sortIndexesRef?.filter(
            (el) => el.polygon === pol.id
          );
          let filterByDate = [];

          if (props.zeroDate !== undefined) {
            const desde = new Date(props.zeroDate + "T00:00:00").getTime();
            filterByDate = filterByPolygon.filter(
              (el) =>
                new Date(el.date + "T00:00:00").getTime() <= hasta &&
                new Date(el.date + "T00:00:00").getTime() >= desde
            );

            //enable button
            setStateData((prev) => ({
              ...prev,
              resetGraphStatus: true,
            }));
          } else {
            filterByDate = filterByPolygon?.filter(
              (el) => new Date(el.date + "T00:00:00").getTime() <= hasta
            );
            //disable button
            setStateData((prev) => ({
              ...prev,
              resetGraphStatus: false,
            }));
          }

          if (filterByDate.length > 0) {
            filterByDate.map((inxData) => {
              if (inxData.type === "NDVI" || inxData.type === "CCCI") {
                let type = inxData.type;
                if (!indexJson[pol.name].hasOwnProperty(type)) {
                  indexJson[pol.name][type] = {};
                }
                if (!indexJson[pol.name][type].hasOwnProperty("graphPoints")) {
                  indexJson[pol.name][type]["graphPoints"] = [];
                }
                if (type === "NDVI") {
                  indexJson[pol.name][type]["graphPoints"][
                    new Date(inxData.date + "T00:00:00").getTime()
                  ] = inxData.value;
                } else {
                  indexJson[pol.name][type]["graphPoints"][
                    new Date(inxData.date + "T00:00:00").getTime()
                  ] = inxData.value;
                }
              }
            });
          }
        }
      }

      // console.log("5.5 termina la funcion y termina de cargar el grafico");
      setStateData((prev) => ({
        ...prev,
        data: indexJson,
        polygons: polygons,
      }));

      setIsLoading(false);
    });
  };

  const getOption = (index) => {
    function sortFunction(a, b) {
      return a - b;
    }
    var polygonsJson = stateData.data;
    var dates = [];
    var values = [];
    var labels = [];
    let polygons = props.polygons;
    if (polygonsJson) {
      Object.keys(polygonsJson).map((polygonName, _) => {
        let polygonJson = polygonsJson[polygonName];
        if (polygonJson) {
          let graphPointsJson = polygonJson[index];
          if (graphPointsJson !== undefined) {
            let graphPointsAux = graphPointsJson["graphPoints"];
            if (graphPointsAux) {
              dates.push(Object.keys(graphPointsAux));
            }
          }
        }
      });
      dates = Array.from(new Set([].concat.apply([], dates)));
      dates = dates.sort(sortFunction);
      for (var i = 0; i < polygons.length; i++) {
        let polygonName = polygons[i].name;
        let polygonJson = polygonsJson[polygonName];
        if (polygonJson) {
          let graphPointsJson = polygonJson[index];
          if (graphPointsJson) {
            let graphsPointsAux = graphPointsJson["graphPoints"];
            if (graphsPointsAux) {
              labels.push(index + "_" + polygonName);
              let valuesAux = dates.map((date, _) => graphsPointsAux[date]);
              values[i] = valuesAux;
            }
          }
        }
      }
      if (dates.length > 0 && values.length > 0) {
        dates = dates.map((date) =>
          moment(new Date(Number(date))).format("DD/MM/YYYY")
        );
        let series = [];
        for (var i = 0; i < polygons.length; i++) {
          var nombre = labels[i];
          var datos = values[i];
          series.push({
            name: nombre,
            type: "line",
            connectNulls: true,
            smooth: true,
            data: datos,
          });
        }
        return {
          tooltip: {
            position: function (point) {
              return ["40%", "-23%"];
            },
            trigger: "axis",
            formatter: function (params, ticket, callback) {
              let res = "";
              let resCompleted = "";
              for (var i = 0, l = params.length; i < l; i++) {
                if (params[i].value) {
                  let graphPointData = params[i].seriesName.split("_");
                  let polygonName = graphPointData[1];
                  let polygonEmergenceDate =
                    stateData.data[polygonName]?.emergenceDate;
                  if (
                    polygonEmergenceDate !== null &&
                    polygonEmergenceDate !== undefined
                  ) {
                    let date = moment(params[i].name, "DD/MM/YYYY");
                    let emergenceDate = moment(new Date(polygonEmergenceDate));
                    let duration = date.diff(emergenceDate, "days");
                    let dpe = Math.round(duration);
                    if (dpe >= -20 && dpe <= 180) {
                      i == 0
                        ? (res +=
                            "<br/>" +
                            "DPE " +
                            dpe +
                            " " +
                            params[i].seriesName +
                            ":" +
                            params[i].value.toFixed(2))
                        : (res +=
                            "<br/>" +
                            "DPE " +
                            dpe +
                            " " +
                            params[i].seriesName +
                            ":" +
                            params[i].value.toFixed(2));
                    } else {
                      i == 0
                        ? (res +=
                            "<br/>" +
                            params[i].seriesName +
                            ": " +
                            params[i].value.toFixed(2))
                        : (res +=
                            "<br/>" +
                            params[i].seriesName +
                            ": " +
                            params[i].value.toFixed(2));
                    }
                  } else {
                    i == 0
                      ? (res +=
                          "<br/>" +
                          params[i].seriesName +
                          ": " +
                          params[i].value.toFixed(2))
                      : (res +=
                          "<br/>" +
                          params[i].seriesName +
                          ": " +
                          params[i].value.toFixed(2));
                  }
                }

                resCompleted = `${params[i].axisValue}` + res;
              }

              setTimeout(function () {
                callback(ticket, resCompleted);
              }, 100);
              return t("globals.calculated");
            },
          },
          legend: {
            data: labels,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "20%",
            containLabel: true,
          },
          toolbox: {
            feature: {
              saveAsImage: { show: true, title: t("globals.saveAsImage") },
              dataZoom: {
                yAxisIndex: "none",
              },
              restore: { show: true, title: "Reset" },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 1000,
            },
            {
              start: 0,
              end: 100,
              handleIcon:
                "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
              handleSize: "80%",
              handleStyle: {
                color: "#fff",
                shadowBlur: 3,
                shadowColor: "rgba(0, 0, 0, 0.6)",
                shadowOffsetX: 2,
                shadowOffsetY: 2,
              },
            },
          ],
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: dates,
            name: t("globals.date"),
            nameLocation: "center",
            nameTextStyle: {
              padding: [8, 8, 8, 8],
            },
          },
          toolbox: {},
          yAxis: {
            type: "value",
          },
          series: series,
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  const exportNDVIToCSV = () => {
    var ndviJSON = [];
    let optionNDVI = getOption("NDVI");
    if (optionNDVI.series.length > 0) {
      optionNDVI.series.forEach((serie) => {
        let serieData = serie.data;
        var serieValues = [];
        for (var i = 0; i < serieData?.length; i++) {
          if (serieData[i]) {
            serieValues.push({
              date: optionNDVI.xAxis.data[i],
              value: serieData[i],
            });
          }
        }
        ndviJSON[`${serie.name}`] = serieValues;
      });
    }

    ExportToCSV.export(
      ndviJSON,
      [
        {
          date: t("globals.date").toUpperCase(),
          value: "NDVI",
        },
      ],
      ["date", "value"],
      "NDVI",
      90
    );
  };

  let optionNDVI = getOption("NDVI");
  let optionCCCI = getOption("CCCI");
  let ndviEnabled = optionNDVI !== null && Object.keys(optionNDVI).length > 0;
  let ccciEnabled = optionCCCI !== null && Object.keys(optionCCCI).length > 0;
  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        {ndviEnabled || ndviEnabled ? (
          <Card className="card-chart h-100">
            <CardHeader>
              <CardTitle></CardTitle>
            </CardHeader>
            <CardBody>
              <Tabs>
                <TabList>
                  {ndviEnabled && (
                    <Tab>
                      <h6>
                        <FaLeaf /> {t("globals.greenIndex")}
                      </h6>
                    </Tab>
                  )}
                  {ccciEnabled && (
                    <Tab>
                      <h6>
                        <GiLeafSkeleton /> {t("globals.chlorophyll")}
                      </h6>
                    </Tab>
                  )}
                </TabList>
                {ndviEnabled && (
                  <TabPanel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        position: "absolute",
                        right: "17px",
                        top: "16px",
                      }}
                    >
                      <button
                        style={{
                          padding: "0px",
                          border: "none",
                          marginRight: "0.5rem",
                          outline: "none",
                          float: "right",
                          zIndex: "99",
                          position: "relative",
                        }}
                        onClick={() => exportNDVIToCSV()}
                      >
                        <img src={excelIcon} />
                      </button>

                      {stateData.resetGraphStatus && (
                        <button
                          style={{
                            background: "none",
                            padding: "0px",
                            border: "none",
                            marginRight: "0.5rem",
                            outline: "none",
                            float: "right",
                            zIndex: "99",
                            position: "relative",
                          }}
                          onClick={() => {
                            props.setStateMapDetail((prev) => ({
                              ...prev,
                              zeroDate: undefined,
                            }));
                          }}
                        >
                          <img src={restartIcon} style={{ height: 20 }} />
                        </button>
                      )}
                    </div>
                    <ReactEcharts
                      className="tooltipReactEcharts"
                      option={optionNDVI}
                    />
                  </TabPanel>
                )}
                {ccciEnabled && (
                  <TabPanel>
                    <ReactEcharts option={optionCCCI} />
                  </TabPanel>
                )}
              </Tabs>
            </CardBody>
          </Card>
        ) : (
          <div className="content">
            <Row>
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                <Card className="card-testimonial">
                  <CardBody>
                    <div className="icon icon-primary">
                      <i className="fa fa-laptop-code" />
                    </div>
                    <CardTitle tag="h6">{t("globals.indexes")}</CardTitle>
                    <CardSubtitle className="mb-2 text-muted">
                      {t("globals.noDataGraph")}
                    </CardSubtitle>
                    <div className="card-avatar" style={{ paddingTop: "1rem" }}>
                      <a>
                        <img
                          alt="..."
                          className="img"
                          src={require("../../../../assets/img/oryzativa_logo.png")}
                        />
                      </a>
                    </div>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </LoadingOverlay>
    </>
  );
};

export default IndexChart;

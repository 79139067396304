import axios from "axios";
import moment from "moment";
import React from "react";

class Log {
  constructor(type, date, id) {
    this.type = type;
    this.date = date;
    this.id = id;
  }

  static getLogTypes(props) {
    const {t} =props;
    var list = [];
    list.push({ value: "Application", label: t("globals.application") });
    list.push({ value: "LabReport", label: t("globals.labReport") });
    list.push({ value: "Phenology", label: t("globals.phenology") });
    list.push({ value: "Fertilization", label: t("globals.fertilization") });
    list.push({ value: "FieldReport", label: t("globals.fieldReport") });
    list.push({ value: "Yield", label: t("globals.yield") });
    list.push({ value: "Water", label: t("globals.irrigation") });
    list.push({ value: "Sowing", label: t("globals.sowing") });

    return list;
  }

  static getCSVHeading(t) {
  
    return [
      {
        id: "ID",
        date: t("globals.date"),
        type: t("globals.type"),
        value:t("globals.value"),
        text: t("globals.text"),
      },
    ];
  }

  toString() {
    return "";
  }

  render() {
    return false;
  }

  getCSVData() {
    return {
      id: this.id,
      date: this.date,
    };
  }
}

class Seeeding extends Log {
  constructor(t,type, date, id, cropName, cropVar, predecessor, obs) {
    super(type, date, id);
    this.t = t;
    this.cropName = cropName;
    this.cropVar = cropVar;
    this.predecessor = predecessor;
    this.obs = obs;
  }

  toString() {
    let observation = this.obs != null ? this.obs : "";
    if (this.cropVar) {
      return `${this.cropName} ${this.cropVar}, ${this.t("globals.sowing")} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")} ${observation}`;
    } else {
      return `${this.cropName}, ${this.t("globals.sowing")} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")} ${observation}`;
    }
  }

  render() {
    return (
      <tr key={this.id}>
        <td>- {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    var valueString = this.cropName;
    if (this.cropVar) {
      valueString += " " + this.cropVar;
    }
    baseJSON["type"] = this.t("globals.sowing");
    baseJSON["value"] = valueString;
    baseJSON["text"] = this.toString();
    return baseJSON;
  }
}

class Phenology extends Log {
  constructor(t,type, date, id, typeEvent, value, obs, crop) {
    super(type, date, id);
   this.t= t;
    this.value = value;
    this.typeEvent = typeEvent;
    this.obs = obs;
    this.crop = crop;
  }
  toString() {
    return `${this.typeEvent} ${moment(
      new Date(this.date.toString() + "T00:00:00")
    ).format("DD-MM-YYYY")} ${this.obs != null ? this.obs : ""}`;
  }

  render() {
    return (
      <tr key={this.id}>
        <td>- {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = this.t("globals.phenology");
    baseJSON["value"] = this.value;
    baseJSON["text"] = this.toString();
    return baseJSON;
  }
}

class Irrigation extends Log {
  constructor(t,type, date, id, typeEvent, value, obs) {
    super(type, date, id);
   this.t= t;
    this.typeEvent = typeEvent;
    this.value = value;
    this.obs = obs;
  }

  toString() {
    return `${this.typeEvent} ${moment(
      new Date(this.date.toString() + "T00:00:00")
    ).format("DD-MM-YYYY")}`;
  }

  render() {
    if (this.obs && this.obs !== "") {
      return (
        <tr key={this.id}>
          <td>
            - {this.toString()} <br></br>
            <div style={{ paddingLeft: "2rem" }}>{this.obs} </div>{" "}
          </td>
        </tr>
      );
    }
    return (
      <tr key={this.id}>
        <td>- {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = this.t("globals.irrigation");
    baseJSON["value"] = this.value;
    baseJSON["text"] = this.toString();
    return baseJSON;
  }
}

class Yield extends Log {
  constructor(t,type, date, id, value, observation) {
    super(type, date, id);
   this.t= t;
    this.value = value;
    this.observation = observation;
  }

  toString() {
    if (this.observation) {
      return `${this.t("globals.yield")} ${this.value} ${this.t("globals.yieldMsgUser")} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")} ${this.observation}`;
    } else {
      return `${this.t("globals.yield")} ${this.value} ${this.t("globals.yieldMsgUser")} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")}`;
    }
  }

  render() {
    return (
      <tr key={this.id}>
        <td>- {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = this.t("globals.yield");
    baseJSON["value"] = this.value;
    baseJSON["text"] = this.toString();
    return baseJSON;
  }
}

class Fertilization extends Log {
  //other_name
  constructor(t,type, date, id, principals, others, value, obs) {
    super(type, date, id);
   this.t= t;
    this.principals = principals;
    this.others = others;
    this.value = value;
    this.obs = obs;
    // this.other_name = other_name;
  }

  toString() {
    let the= this.t("globals.the").toLowerCase()
    let to= this.t("globals.to").toLowerCase()
    let fertilizationWithMsg= this.t("globals.fertilizationWithMsg")
    let kg_ha= this.t("globals.kg_ha").toLowerCase()
    let valuePrincipales = this.principals.map((el) => {
      if (el.value === undefined) {
        return " " + 0 + " ";
      } else {
        return " " + el.inputValue + " ";
      }
    });
    let fill = this.others.filter((el) => el.inputValue !== 0 && el !== 0);
    let othersValueAndName = fill.map(
      (el) => " " + el.inputValue + " " + el.label + " "
    );
    if (this.obs && othersValueAndName.length > 0) {
      let concat = valuePrincipales.concat(othersValueAndName);
      return `${fertilizationWithMsg} ${
        this.value
      } ${kg_ha} ${to} (${concat.toLocaleString()}) ${the} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")} ${this.obs}`;
    }

    if (!this.obs && othersValueAndName.length === 0) {
      return `${fertilizationWithMsg} ${
        this.value
      } ${kg_ha} ${to} (${valuePrincipales.toLocaleString()}) ${the} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")}`;
    }
    if (this.obs && othersValueAndName.length === 0) {
      return `${fertilizationWithMsg} ${
        this.value
      } ${kg_ha} ${to} (${valuePrincipales.toLocaleString()}) ${the} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")} ${this.obs}`;
    }
    if (!this.obs && othersValueAndName.length > 0) {
      let concat = valuePrincipales.concat(othersValueAndName);
      return `${fertilizationWithMsg} ${
        this.value
      } ${kg_ha} ${to} (${concat.toLocaleString()}) ${the} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")}`;
    }
  }

  render() {
    return (
      <tr key={this.id}>
        <td>- {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = this.t("globals.fertilization");
    baseJSON["value"] = this.value;
    baseJSON["text"] = this.toString();
    return baseJSON;
  }
}

class Diseases extends Log {
  constructor(t,type, date, id, values, observation) {
    super(type, date, id);
   this.t= t;
    this.values = values;
    this.observation = observation;
  }

  valueToString() {
    switch (this.type) {
      case "DiseaseReport":
        return this.t("globals.diseases");
      default:
        return "";
    }
  }

  toString() {
    var log = `${this.valueToString()} ${moment(
      new Date(this.date.toString() + "T00:00:00")
    ).format("DD-MM-YYYY")}`;
    let observation = this.observation || "";
    var valuesString = "";
    if (this.values && this.values.length > 0) {
      valuesString = this.values.reduce(
        (prevValue, currentValue) => prevValue + `${currentValue.trim()}, `,
        ``
      );
      valuesString = valuesString.substring(0, valuesString.length - 2);
    }
    if (valuesString !== "") {
      return `${log} ${valuesString} ${observation}`;
    } else {
      return `${log} ${observation}`;
    }
  }

  render() {
    return (
      <tr key={this.id}>
        <td>- {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = this.valueToString();
    baseJSON["value"] = "";
    baseJSON["text"] = this.toString();
    return baseJSON;
  }
}

class Weeds extends Log {
  constructor(t,type, date, id, values, observation) {
    super(type, date, id);
    this.t = t;
    this.values = values;
    this.observation = observation;
  }

  valueToString() {
    switch (this.type) {
      case "WeedReport":
        return this.t("globals.weeds");
    }
  }

  toString() {
    var log = `${this.valueToString()} ${moment(
      new Date(this.date.toString() + "T00:00:00")
    ).format("DD-MM-YYYY")}`;
    let observation = this.observation || "";

    var valuesString = "";
    if (this.values.length > 0) {
      valuesString = this.values.reduce(
        (prevValue, currentValue) => prevValue + `${currentValue.trim()}, `,
        ``
      );
      valuesString = valuesString.substring(0, valuesString.length - 2);
    }
    if (valuesString !== "") {
      return `${log} ${valuesString} ${observation}`;
    } else {
      return `${log} ${observation}`;
    }
  }

  render() {
    return (
      <tr key={this.id}>
        <td>- {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = this.valueToString();
    baseJSON["value"] = "";
    baseJSON["text"] = this.toString();
    return baseJSON;
  }
}

class Bugs extends Log {
  constructor(t,type, date, id, values, observation) {
    super(type, date, id);
   this.t= t;
    this.values = values;
    this.observation = observation;
  }

  valueToString() {
    switch (this.type) {
      case "BugReport":
        return this.t("globals.bugs");
    }
  }

  toString() {
    var log = `${this.valueToString()} ${moment(
      new Date(this.date.toString() + "T00:00:00")
    ).format("DD-MM-YYYY")}`;
    let observation = this.observation || "";

    var valuesString = "";
    if (this.values && this.values.length > 0) {
      valuesString = this.values.reduce(
        (prevValue, currentValue) => prevValue + `${currentValue.trim()}, `,
        ``
      );
      valuesString = valuesString.substring(0, valuesString.length - 2);
    }
    if (valuesString !== "") {
      return `${log} ${valuesString} ${observation}`;
    } else {
      return `${log} ${observation}`;
    }
  }

  render() {
    return (
      <tr key={this.id}>
        <td>- {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = this.valueToString();
    baseJSON["value"] = "";
    baseJSON["text"] = this.toString();
    return baseJSON;
  }
}
class Application extends Log {
  constructor(t,type, date, id, herb, typeApp, observation) {
    super(type, date, id);
   this.t= t;
    this.herb = herb;
    this.typeApp = typeApp;
    this.observation = observation;
  }

  toString() {
    let herbString = this.herb.reduce(
      (prevValue, currentValue) =>
        prevValue +
        `${currentValue["name"]} (${currentValue["dose"]} L/ha), `,
      ``
    );
    herbString = `${herbString.substring(0, herbString.length)} ${this.t(
      "globals.the"
    )} ${moment(new Date(this.date.toString() + "T00:00:00")).format(
      "DD-MM-YYYY"
    )} ${this.observation != null ? this.observation : ""}`;
    return herbString;
  }

  render() {
    return (
      <tr key={this.id}>
        <td>- {this.t("globals.application")} {this.t("globals.to").toLowerCase()} {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = this.t("globals.application");
    baseJSON["value"] = "";
    baseJSON["text"] = this.toString();
    return baseJSON;
  }

  static getApplicationTypes(props) {
    return [
      { value: "WeedReport", label: props.t("applicationLogForm.herbicide"), type: "HER" },
      { value: "DiseaseReport", label: props.t("applicationLogForm.fungicide"), type: "FUN" },
      { value: "BugReport", label: props.t("applicationLogForm.insecticide"), type: "INS" },
    ];
  }
}

class LabAnalytics extends Log {
  constructor(t,type, date, id, data, values, obs) {
    super(type, date, id);
   this.t= t;
    this.data = data;
    this.values = values;
    this.obs = obs;
  }

  toString() {
    var labString = "";
    Object.keys(this.data).map((key, index) => {
      labString += `${key} / ${this.data[key]}, `;
    });
    labString = labString.substring(0, labString.length);
    return labString;
  }

  render() {
    var log = `- ${this.t("globals.analysis")} ${this.t("globals.to").toLowerCase()} ${moment(
      new Date(this.date.toString() + "T00:00:00")
    ).format("DD-MM-YYYY")}:\n ${this.obs != null ? this.obs : ""}`;
    return (
      <tr key={this.id}>
        <td>
          {log}
          {Object.keys(this.data).map((key, index) => {
            return (
              <>
                <br></br>
                <div
                  key={index}
                  style={{
                    paddingLeft: "2rem",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "20rem",
                  }}
                >
                  {key}
                  <div style={{ paddingLeft: "2rem" }}>{this.data[key]}</div>
                </div>
              </>
            );
          })}
        </td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = this.t("globals.analysisData");
    baseJSON["value"] = "";
    baseJSON["text"] = this.toString();
    return baseJSON;
  }

  static getLabAnalyticsTypes() {
    return [
      { id: 1, label: "Fosforo Bray", unit: "ppm" },
      { id: 2, label: "N-NO3", unit: "ppm" },
      { id: 3, label: "Potasio", unit: "meq/100g" },
      { id: 4, label: "Acidez titulable", unit: "meq/100g" },
      { id: 5, label: "pH H2O", unit: "" },
      { id: 7, label: "Materia organica", unit: "%" },
      { id: 8, label: "PMN", unit: "mg/kg N-NH4" },
      { id: 9, label: "Fosforo Citrico", unit: "ppm" },
      { id: 10, label: "Magnesio", unit: "meq/100g" },
      { id: 11, label: "Calcio", unit: "meq/100g" },
      { id: 12, label: "Sodio", unit: "meq/100g" },
      { id: 13, label: "Zinc", unit: "meq/100g" },
      {
        id: 14,
        label: "CIC-pH7 (meq/100g)",
        unit: "meq/100g",
      },
    ];
  }
}

class DataField extends Log {
  constructor(t,type, date, id, variable, value, unit, observation) {
    super(type, date, id);
   this.t= t;
    this.variable = variable;
    this.value = value;
    this.unit = unit;
    this.observation = observation;
  }

  toString() {
    if (this.observation) {
      return `${this.variable} ${this.value} ${this.unit} ${this.t("globals.the")} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")} ${this.observation}`;
    } else {
      return `${this.variable} ${this.value} ${this.unit} ${this.t("globals.the")} ${moment(
        new Date(this.date.toString() + "T00:00:00")
      ).format("DD-MM-YYYY")}`;
    }
  }

  render() {
    return (
      <tr key={this.id}>
        <td>- {this.toString()}</td>
      </tr>
    );
  }

  getCSVData() {
    let baseJSON = super.getCSVData();
    baseJSON["type"] = `${this.variable} (${this.unit})`;
    baseJSON["value"] = this.value;
    baseJSON["text"] = this.toString();
    return baseJSON;
  }

}

export {
  Log,
  DataField,
  LabAnalytics,
  Application,
  Diseases,
  Weeds,
  Bugs,
  Fertilization,
  Irrigation,
  Yield,
  Phenology,
  Seeeding,
};

/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import { SourceProvider } from "providers/SourceProvider";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n  from "./i18n/i18n";
import FarmerData from "context/FarmerData";


const hist = createBrowserHistory();

window.OryzaVersion = { version: "1.0.48", build: 48 };

const Console = (prop) => (
  console[Object.keys(prop)[0]](...Object.values(prop)), null // ➜ React components must return something
);

ReactDOM.render(
  <Router history={hist}>
    <FarmerData>
    <SourceProvider>
      <I18nextProvider i18n={i18n}>
        <Console log="AU" />
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect to="/admin/dashboard" />
        </Switch>
      </I18nextProvider>
    </SourceProvider>
    </FarmerData>
  </Router>,
  document.getElementById("root")
);

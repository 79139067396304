import React, { Component } from "react";
import { FeatureGroup, Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import CustomPolygons from "../../components/Maps/customPolygons";
import MapLink from "./MapLink";
// import L from "leaflet";
var map;
class MapPart extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let nextVal = JSON.stringify(nextProps.bounds);
    let currentVal = JSON.stringify(this.props.bounds);
    if (nextVal === currentVal) {
      return false;
    }

    return true;
  }

  render() {
    const PolygonComponents = this.props?.fieldList?.map(
      (field, index) =>
        field?.polygonData?.length === field?.polygons?.length && (
          <FeatureGroup key={index} color={field.color && field.color}>
            <CustomPolygons
              field={field}
              onShowSidebar={(polygon) =>
                this.props.onShowSidebar(polygon, field)
              }
            />
          </FeatureGroup>
        )
    );
    return (
      <Map
        animate={true}
        bounds={this.props.bounds}
        boundsOptions={{ padding: [0, 0] }}
        style={{ width: "100vw", height: "100vh" }}
        center={this.props.center}
        zoom={this.props.zoom}
      >
        <MapLink />
        {PolygonComponents}
      </Map>
    );
  }
}

export default MapPart;

import L from 'leaflet';
import iconVenue from "../../assets/img/venue_location_icon.svg"


export const FieldLocationIcon = new L.icon({
    iconUrl: iconVenue,
    iconRetinaUrl: iconVenue,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(35, 35),
    className: 'leaflet-venue-icon'
});

//ACA SOLO VA A TRAER LOS POLIGONOS NADA MAS

import axios from "axios";
//le puse mayusculas por si se rompe en algun lado
import { FieldLocationIcon } from "components/Maps/locationIcon";
import MapLink from "components/Maps/MapLink";
import React, { useEffect, useState } from "react";
import { Map, Marker, Popup, FeatureGroup } from "react-leaflet";
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import ReutBackPolygon from "./ReutBackPolygon";
import "../../../../../assets/css/inputSearch.css";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const ReutilizarPol = (props) => {
  const [t, i18n]= useTranslation("global")
  const inputRef = useRef();
  const [isFocus, setIsFocus] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [tokenUser, setTokenUser] = useState({
    headers: {
      // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });
  const [polygonsData, setPolygonData] = useState([]);
  // const [polygonsDataAux, setPolygonsDataAux] = useState([]);
  const [searchPolygonsAndField, setSearchPolygonsAndField] = useState([]);
  const [allUsersAccess, setAllUsersAccess] = useState([]);
  const [colorDefault, setColorDefault] = useState([]);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   axios
  //     .get(`https://agrodigital.io/api/auth/users/`, tokenUser)
  //     .then(async (res) => {
  //       if (res.data.length > 0) {
  //         let name = res.data.map((el) => {
  //           return { name: el.email };
  //         });
  //         setPolygonData((prev) => [...prev, ...name]);
  //         setAllUsersAccess(res.data);
  //       }
  //     });
  // }, []);

  useEffect(() => {
    if (props?.listPolygonsReut.length > 0) {
      let noRepeatName = props?.listPolygonsReut?.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.name === obj.name)
      );
      let polygonsInField = props.fieldList.map((el) => [...el.polygons]);
      let polygonsIdResult = [];
      polygonsInField.forEach((element) => {
        polygonsIdResult.push(...element);
      });
      let polygonsDeletedOrNotField = noRepeatName.filter((polygons)=> polygons.deleted || !polygonsIdResult.includes(polygons.id))
      setPolygonData((prev) => [...prev, ...polygonsDeletedOrNotField]);
    }
  }, [props?.listPolygonsReut.length]);

  const randomColor = (length) => {
    let arrColor = [];
    for (let i = 0; i < length; i++) {
      const random = "#" + Math.floor(Math.random() * 16777215).toString(16);
      arrColor.push(random);
    }
    setLoading(true);
    setColorDefault(arrColor);
  };

  const handleSubmit = async (e, value) => {
    e?.preventDefault();
    var emailRex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!value) {
      setLoading(false);
    } else {
      // if (emailRex.test(value)) {
      //   let valid = false;
      //   let userAccess = allUsersAccess;
      //   let infoByEmail = [];
      //   for (let i = 0; i < userAccess.length; i++) {
      //     let user = userAccess[i];
      //     if (user.email === value) {
      //       valid = true;
      //       infoByEmail.push(user);
      //     }
      //   }
      //   if (valid) {
      //     let userEmail = infoByEmail[0];
      //     let searchForEmail = props.fieldList.filter((el) =>
      //       el.access.includes(userEmail.id)
      //     );
      //     console.log(searchForEmail);
      //     console.log(polygonsData);
      //     if (searchForEmail.length > 0) {
      //       randomColor(searchForEmail.length);
      //       setSearchPolygonsAndField(searchForEmail);
      //     } else {
      //       setLoading(false);
      //     }
      //   } else {
      //     setLoading(false);
      //   }
      // } else {
        filterPolygons(value);
      // }
    }
  };

  const filterPolygons = (value) => {
    let resultFilterFields = [];
    if (!value) {
      resultFilterFields = polygonsData;
    } else {
      let valueSearch = value.toLowerCase();
      let filterSearch = polygonsData.filter((fields) =>
        fields.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(valueSearch)
      );

      if (filterSearch.length > 0) {
        resultFilterFields = filterSearch.filter((el) => el.id);
        setLoading(true);
      } else {
        resultFilterFields = polygonsData;
        setLoading(false);
      }
    }

    setSearchPolygonsAndField(resultFilterFields);
  };
  const searchResult = searchPolygonsAndField.map((field, index) => {
    if (field["polygons"]) {
      return (
        <FeatureGroup key={index} color={colorDefault[index]}>
          <ReutBackPolygon
            field={field}
            selectedReutPolygon={props.selectedReutPolygon}
          />
        </FeatureGroup>
      );
    } else {
      return (
        <FeatureGroup key={index} color="lime">
          <ReutBackPolygon
            field={field}
            selectedReutPolygon={props.selectedReutPolygon}
          />
        </FeatureGroup>
      );
    }
  });

  return (
    <>
      <p style={{ textAlign: "center" }}>{t("globals.reUsePolygon")}</p>
      <Row className="justify-content-center">
        <Col sm="10">
          <Form onSubmit={(e) => handleSubmit(e, inputValue)}>
            <InputGroup style={{ alignItems: "center" }}>
              <Input
                style={{
                  borderRight: "solid 1px gainsboro",
                  borderLeft: "solid 1px gainsboro",
                  borderRadius: "10px 10px 0px 0px",
                }}
                name="reutilizarPol"
                placeholder={t("globals.searchPolygonInput")}
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onFocus={() => setIsFocus(true)}
                onBlur={() => {
                  if (!isHover) {
                    setIsFocus(false);
                  }
                }}
                ref={inputRef}
              />
              <svg
                onClick={(e) => handleSubmit(e, inputValue)}
                viewBox="0 0 1024 1024"
                fill="currentColor"
                width="auto"
                color="#66615B"
                style={{
                  position: "absolute",
                  margin: "0",
                  right: "0",
                  height: "20px",
                  background: "none",
                  border: "none",
                  padding: "0",
                  width: "35px",
                  cursor: "pointer",
                  zIndex: "3",
                }}
              >
                <path d="M772 772c20 22.667 22 43.333 6 62l-46 46c-24 21.333-46.667 21.333-68 0L474 690c-49.333 28-101.333 42-156 42-85.333 0-159.667-31.667-223-95S0 499.333 0 414s30-158.333 90-219 132.667-91 218-91 160 31.667 224 95 96 137.667 96 223c0 58.667-15.333 112.667-46 162l190 188M94 414c0 58.667 22.667 110.667 68 156s97.333 68 156 68 109-21 151-63 63-93 63-153c0-58.667-22.667-110.333-68-155s-97.333-67-156-67-109 21-151 63-63 92.333-63 151" />
              </svg>
              {isFocus && polygonsData.length > 0 && (
                <div
                  style={{ zIndex: "2" }}
                  className="suggestions"
                  onMouseEnter={() => {
                    setIsHover(true);
                  }}
                  onMouseLeave={() => {
                    setIsHover(false);
                  }}
                >
                  {polygonsData.map((field, index) => {
                    const isMatch =
                      field.name
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) > -1;
                    return (
                      <div key={index}>
                        {isMatch && (
                          <div
                            className="suggestionSelect"
                            onClick={(e) => {
                              setInputValue(field.name);
                              handleSubmit(e, field.name);
                              inputRef.current.focus();
                              setIsFocus(false);
                            }}
                          >
                            {field.name}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Map
        center={props.center}
        bounds={props.bounds}
        style={{
          width: "100%",
          height: "100%",
          minHeight: "500px",
          zIndex: 1,
        }}
        zoom={props.zoom}
        zoomControl={false}
      >
        <MapLink />
        <Marker position={props.center} icon={FieldLocationIcon}>
          <Popup>Center of Map!</Popup>
        </Marker>
        {!loading
          ? polygonsData?.map((field, index) => (
              // <FeatureGroup key={index} color={colorDefault[index]}>
              <FeatureGroup key={index} color="lime">
                <ReutBackPolygon
                  field={field}
                  selectedReutPolygon={props.selectedReutPolygon}
                />
              </FeatureGroup>
            ))
          : searchResult}
      </Map>
    </>
  );
};

export default ReutilizarPol;

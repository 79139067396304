import React, { Component } from "react";
import {
  Row,
  FormGroup,
  FormFeedback,
  Modal,
  Button,
  Col,
  Input,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { firestore } from "firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import axios from "axios";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
//PARA ACTUALIZAR YIELD ME PIDE QUE TENGA CROP
class PerformanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      harvest_date: null,
      yieldValue: null,
      yielIsValid: false,
      loading: false,
      valid: null,
      dataSowing: {},
      currentUser: {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
    };
  }
  componentDidMount() {
    const fetchSowing = async () => {
      await axios
        .get(
          `https://agrodigital.io/api/logs/?field=${this.props.fieldId}&type=Sowing`,
          this.state.currentUser
        )
        .then((res) => {
          let logsSowing = res.data.filter((el) =>
            el.polygons.includes(this.props.polygonId)
          );
          if (logsSowing.length > 0) {
            let sowing = logsSowing.sort(
              (a, b) =>
                new Date(b.date + "T00:00:00") - new Date(a.date + "T00:00:00")
            )[0];
            if (sowing.variety !== null) {
              let crops = axios.get(
                `https://agrodigital.io/api/crops/${sowing.crop}`,
                this.state.currentUser
              );
              let varieties = axios.get(
                `https://agrodigital.io/api/varieties/${sowing.variety}`,
                this.state.currentUser
              );
              Promise.all([crops, varieties]).then((res) => {
                let cropName = res[0].data;
                let varietyName = res[1].data;
                let dataSow = {};
                dataSow.crop = cropName;
                dataSow.variety = varietyName;
                this.setState({ dataSowing: dataSow, valid: true });
              });
            } else {
              axios
                .get(
                  `https://agrodigital.io/api/crops/${sowing.crop}`,
                  this.state.currentUser
                )
                .then((res) => {
                  let cropName = res.data;
                  let dataSow = {};
                  dataSow.crop = cropName;
                  this.setState({ dataSowing: dataSow, valid: true });
                });
            }
          } else {
            this.setState({
              valid: false,
            });
          }
        });
    };
    fetchSowing();
  }

  handleYieldValue(e) {
    var yieldValue = parseFloat(e.target.value, 10);
    if (!yieldValue || yieldValue <= 0) {
      this.setState({
        yielIsValid: true,
      });
    } else {
      this.setState({
        yieldValue: yieldValue,
        yielIsValid: false,
      });
    }
  }

  saveButtonTapped = async () => {
    let yieldData = {};
    let polId = [this.props.polygonId];
    yieldData.date = moment(new Date(this.state.harvest_date)).format(
      "YYYY-MM-DD"
    );
    yieldData.value = parseFloat(this.state.yieldValue);
    yieldData.type = "Yield";
    yieldData.crop = this.state.dataSowing?.crop?.id;
    yieldData.variety = this.state.dataSowing?.variety?.id;
    yieldData.polygons = polId;

    if (this.state.valid) {
      axios
        .post(
          "https://agrodigital.io/api/logs/",
          yieldData,
          this.state.currentUser
        )
        .then(() => {
          this.clearState();
          this.props.onClose();
          this.props.onSaveTapped(true);
        })
        .catch((error) => {
          this.clearState();
          this.props.onClose();
          this.props.onSaveTapped(false);
        });
    }
  };

  clearState() {
    this.setState({
      harvest_date: null,
      yieldValue: null,
      yielIsValid: null,
      loading: false,
      valid: null,
    });
  }

  closeTapped() {
    this.clearState();
    this.props.onClose();
  }

  buttonDisabled() {
    return !this.state.harvest_date || !this.state.yieldValue;
  }

  render() {
    const { t } = this.props;
    return (
      <Modal isOpen={this.props.isOpen}>
        <div
          className="modal-header justify-content-center"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <h4 className="title title-up">{t("globals.yield")}</h4>
        </div>
        <div className="modal-body">
          <br />
          {this.state.valid === false && (
            <p style={{ textAlign: "center", color: "Red" }}>
              {t("alerts.noPreviusSowing")}
            </p>
          )}
          {this.state.valid === true && (
            <>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  margin: "0",
                }}
              >
                {t("globals.lastSowing")}
              </p>
              <div style={{ display: "block", textAlign: "center" }}>
                <br />
                {this.state.dataSowing?.crop?.name && (
                  <p>
                    {t("globals.crop")}: {this.state.dataSowing.crop?.name}
                  </p>
                )}
                {this.state.dataSowing?.variety?.name && (
                  <p>
                    {t("globals.variety")}:{" "}
                    {this.state.dataSowing?.variety?.name}
                  </p>
                )}
              </div>
              <hr />
            </>
          )}
          <Row className="justify-content-center">
            <Col sm={12} md={10} lg={8}>
              {
                <FormGroup>
                  <label>{t("globals.yieldLabelText")}</label>
                  <Input
                    type="number"
                    name="rendimiento"
                    onChange={(e) => {
                      this.handleYieldValue(e);
                    }}
                    invalid={this.state.yielIsValid}
                  />
                  <FormFeedback invalid>
                    {t("globals.numberCannotNegative")}
                  </FormFeedback>
                </FormGroup>
              }
            </Col>
            <Col sm={12} md={10} lg={8}>
              <FormGroup>
                <label>{t("globals.haverstDate")}</label>
                <ReactDatetime
                  dateFormat="DD/MM/YYYY"
                  value={this.state.harvest_date}
                  onChange={(val) => {
                    if (val.toDate) {
                      this.setState({
                        harvest_date: val.toDate(),
                      });
                    } else {
                      this.setState({
                        harvest_date: "",
                      });
                    }
                  }}
                  inputProps={{
                    className: "form-control",
                    placeholder: `${t("globals.select")} ${t(
                      "globals.haverstDate"
                    ).toLowerCase()}`,
                  }}
                  timeFormat={false}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        {!this.state.loading ? (
          <div className="modal-footer">
            <div className="left-side">
              <Button
                className="btn-link"
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={() => this.closeTapped()}
              >
                {t("buttons.cancelButton")}
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                className="btn-link"
                color="primary"
                type="button"
                disabled={this.buttonDisabled()}
                onClick={() => this.saveButtonTapped()}
              >
                {t("buttons.saveButton")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center py-4">
            <CircularProgress color="secondary" />
          </div>
        )}
      </Modal>
    );
  }
}

export default PerformanceForm;

/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// // react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// // reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Modal,
  Col,
  Row,
} from "reactstrap";
import axios from "axios";
import { ApiContext } from "context/ApiContext";
import { useContext } from "react";

const Login = () => {
  const [farmers, setFarmers, token, setToken] = useContext(ApiContext)
  const [t, i18n] = useTranslation("global");
  const history = useHistory();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [password_modal, setPassword_modal] = useState(false);
  const [alert, setAlert] = useState(null);
  const [userMailReset, setUserMailReset] = useState("");

  const createSuccess = (msg) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title=""
        onConfirm={() => hideAlert}
        showConfirm={false}
      >
        {msg}
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 4000);
  };

  const createError = (error) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title=""
        onConfirm={() => hideAlert}
        showConfirm={false}
      >
        {error}
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 4000);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://agrodigital.io/api/auth/", {
        username: user.username,
        password: user.password,
      })
      .then((res) => {
        let tokenUser = res.data.token;
        if (res.status === 200 && tokenUser) {
          setToken(tokenUser)
          localStorage.setItem("token", tokenUser);
          if (localStorage.getItem("token")) {
            createSuccess(t("alerts.loginSuccess"));
            setTimeout(() => {
              history.push("/admin/dashboard");
            }, 3000);
          }
        }
      })
      .catch((err) => {
        createError(t("alerts.resetPasswordError"));
      });
  };

  const askReset = () => {
    setPassword_modal(!password_modal);
  };
  const sendReset = async () => {
    if (userMailReset) {
      await axios
        .post("https://agrodigital.io/api/password_reset/", {
          email: userMailReset,
        })
        .then((res) => {
          if (res.status === 200) {
            createSuccess(
              t("alerts.resetPasswordSuccess")
            );
          }
        });
    }
  };

  return (
    <div className="login-page">
      <Container>
        {alert}
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form" onSubmit={handleSubmit}>
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center" style={{border:"none"}}>{t("loginAndRegisterScreen.login")}</h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginAndRegisterScreen.emailPlaceholderText")}
                      type="email"
                      name="username"
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginAndRegisterScreen.passwordPlaceholderText")}
                      type="password"
                      name="password"
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <label>
                    <a href="#" onClick={askReset}>
                      {t("loginAndRegisterScreen.forgotPassword")}
                    </a>
                  </label>
                  {/* Classic Modal */}
                  <Modal isOpen={password_modal} toggle={askReset}>
                    <div className="modal-header justify-content-center">
                      <button
                        aria-label="Cancelar"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={askReset}
                      >
                        <i className="nc-icon nc-simple-remove" />
                      </button>
                      <h4 className="title title-up">
                        {t("loginAndRegisterScreen.resetPassword")}
                      </h4>
                    </div>
                    <div className="modal-body">
                      <p>{t("loginAndRegisterScreen.resetPasswordInfo")}</p>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t("loginAndRegisterScreen.emailPlaceholderText")}
                          type="email"
                          onChange={(e) => setUserMailReset(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                    <div className="modal-footer">
                      <div className="left-side">
                        <Button
                          className="btn-link"
                          color="default"
                          data-dismiss="modal"
                          type="button"
                          onClick={askReset}
                        >
                          {t("buttons.cancelButton")}
                        </Button>
                      </div>
                      <div className="divider" />
                      <div className="right-side">
                        <Button
                          className="btn-link"
                          color="info"
                          type="button"
                          onClick={() => {
                            sendReset();
                            askReset();
                          }}
                        >
                          {t("loginAndRegisterScreen.resetPassword")}
                        </Button>
                      </div>
                    </div>
                  </Modal>
                  {/* End Modal */}
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    type="submit"
                    className="btn-round mb-3"
                    color="warning"
                  >
                    {t("loginAndRegisterScreen.login")}
                  </Button>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="info"
                    href="/auth/register"
                  >
                    {t("loginAndRegisterScreen.createAccount")}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/arroz.jpg")})`,
        }}
      />
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import iconLocation from "../../../../../../assets/img/iconLocation.png";
import iconSelected from "../../../../../../assets/img/iconSelected.png";

const ReutIconMapDetail = (props) => {
  const [selectIcon, setSelectIcon] = useState(false);
  const getIconPosition = (positions) => {
    let coordinates = positions.boundary.coordinates[0];
    let latSum = 0;
    let lngSum = 0;
    for (let i = 0; i < coordinates.length; i++) {
      lngSum +=
        coordinates[i][1].length >= 1
          ? coordinates[i][1][1]
          : coordinates[i][0];
      latSum +=
        coordinates[i][0].length >= 1
          ? coordinates[i][0][0]
          : coordinates[i][1];
    }

    let result = {
      lat: latSum / coordinates.length,
      lng: lngSum / coordinates.length,
    };

    return result;
  };

  let iconSelectPolygon = L.icon({
    iconUrl: iconSelected,
    iconRetinaUrl: iconSelected,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [15, 15],
  });

  let iconLocationPolygon = L.icon({
    iconUrl: iconLocation,
    iconRetinaUrl: iconLocation,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [20, 20],
  });

  return (
    <>
      {selectIcon ? (
        <Marker
          position={getIconPosition(props.polygon)}
          onclick={() => {
            props.selectedPolygons(props.polygon.id, "delete");
            setSelectIcon(false);
          }}
          icon={iconSelectPolygon}
        >
          <Tooltip>{props.polygon.name}</Tooltip>
        </Marker>
      ) : (
        <Marker
          position={getIconPosition(props.polygon)}
          onclick={() => {
            if (selectIcon === false) {
              let obj = {
                delete: props.polygon.deleted,
                id: props.polygon.id,
                name: props.polygon.name,
                coordinates: props.polygon.boundary.coordinates,
              };
              props.selectedPolygons(obj, "add");
              setSelectIcon(true);
            }
          }}
          icon={iconLocationPolygon}
        >
          <Tooltip>{props.polygon.name}</Tooltip>
        </Marker>
      )}
    </>
  );
};
export default ReutIconMapDetail;

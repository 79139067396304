import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { firestore } from "firebase";
import { Fertilization } from "../../../Models/Logs.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "../../components/Chip.js";
import * as firebase from "firebase/app";
import moment from "moment";
import axios from "axios";
import { AlertMultiPolygons, requestResult } from "./AlertLogsManyPolygons.js";
class FertilizationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.selectedLog ? props.selectedLog?.date : null,
      dateValidState: true,
      observation: props.selectedLog ? props.selectedLog?.obs : null,
      loading: false,
      inputValueKg: props.selectedLog ? props.selectedLog?.value : null,
      fertilizationArr: [],
      fertilizationOtherArr: [],
      nitrogeno:
        props.selectedLog?.principals?.length > 0
          ? {
              item: props.selectedLog?.principals[0]?.value || 4,
              value: props.selectedLog?.principals[0]?.inputValue || 0,
            }
          : null,
      fosforo:
        props.selectedLog?.principals?.length > 0
          ? {
              item: props.selectedLog?.principals[1]?.value || 5,
              value: props.selectedLog?.principals[1]?.inputValue || 0,
            }
          : null,
      potasio:
        props.selectedLog?.principals?.length > 0
          ? {
              item: props.selectedLog?.principals[2]?.value || 6,
              value: props.selectedLog?.principals[2]?.inputValue || 0,
            }
          : null,
      selectFertilizerOther:
        props.selectedLog?.others?.length > 0 ? props.selectedLog?.others : [],
      tokenUser: {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
    };
  }

  componentDidMount() {
    this.fetchFertilization();
    if (this.props.selectedLog?.id) {
      this.checkLogs();
    }
  }
  checkLogs = async () => {
    let info = {
      fieldId: this.props.fieldId,
      polygonSelected: Object.keys(this.props.selectedPolygons).map((el) =>
        Number(el)
      ),
      date: this.state.date,
      tokenUser: this.state.tokenUser,
      type: this.props.selectedLog.type,
    };
    let result = await requestResult(info);
    this.setState((prev) => ({
      ...prev,
      alertMoreThanOnePolygon: result?.length > 1 && true,
      nameOfAllPolygons: result,
    }));
  };
  fetchFertilization() {
    axios
      .get("https://agrodigital.io/api/fertilizers/", this.state.tokenUser)
      .then((res) => {
        let fertilizationProm = res.data
          .filter((el) => el.id < 7)
          .sort((a, b) => a.id - b.id);
        let fertilizationOtherArr = res.data.filter((el) => el.id >= 7);
        let orderOtherFertilization = fertilizationOtherArr.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        let selectOptionsFert = orderOtherFertilization.map((el) => {
          let tempArr = {};
          tempArr["value"] = el.id;
          tempArr["label"] = el.name;
          tempArr["inputValue"] = 0;
          return tempArr;
        });

        this.setState({
          fertilizationArr: fertilizationProm,
          fertilizationOtherArr: selectOptionsFert,
        });
      });
  }
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.selectedLog) {
  //     console.log(nextProps.fertilizationArr);
  //     this.state = {
  //       fertilizationArr: nextProps.fertilizationArr,
  //       date: nextProps.selectedLog ? nextProps.selectedLog.date : null,
  //       //Control values
  //       dateValidState: true,
  //       loading: false,
  //     };
  //   }
  // }

  clearStatus() {
    this.setState({
      date: null,
      potasio: null,
      fosforo: null,
      nitrogeno: null,
      selectFertilizerOther: null,
      inputValueKg: null,
      observation: null,
      dateValidState: true,
      loading: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
    });
  }

  isValidated = () => {
    var isValidated = true;

    if (this.state.date == null) {
      this.setState({
        dateValidState: false,
      });

      if (isValidated) {
        isValidated = false;
      }
    }

    return isValidated;
  };

  closeButtonTapped(success = false) {
    this.clearStatus();
    this.props.onClose(success);
  }

  saveButtonTapped() {
    let polygonsIds = Object.keys(this.props.selectedPolygons).map((el) =>
      Number(el)
    );
    if (this.isValidated() && polygonsIds.length > 0 && this.props.fieldId) {
      let insertData = {};
      insertData.nutrients = [];
      insertData.value = parseFloat(this.state.inputValueKg);
      if (this.state.nitrogeno?.item) {
        insertData.nutrients.push(this.state.nitrogeno);
      } else {
        insertData.nutrients.push({ item: 4, value: 0 });
      }
      if (this.state.fosforo?.item) {
        insertData.nutrients.push(this.state.fosforo);
      } else {
        insertData.nutrients.push({ item: 5, value: 0 });
      }
      if (this.state.potasio?.item) {
        insertData.nutrients.push(this.state.potasio);
      } else {
        insertData.nutrients.push({ item: 6, value: 0 });
      }
      if (this.state.selectFertilizerOther.length > 0) {
        let itemsValues = this.state.selectFertilizerOther.map((el) => {
          let tempArr = {};
          tempArr["item"] = el.value;
          tempArr["value"] = Number(el.inputValue);
          return tempArr;
        });
        insertData.nutrients.push(...itemsValues);
      }
      let dateString = this.state.date.toString();
      if (dateString.length === 10) {
        insertData.date = moment(new Date(dateString + "T00:00:00")).format(
          "YYYY-MM-DD"
        );
      }
      if (dateString.length > 10) {
        insertData.date = moment(new Date(this.state.date)).format(
          "YYYY-MM-DD"
        );
      }
      insertData.obs = this.state.observation;

      insertData.type = "Fertilization";
      let vm = this;
      this.setState({
        loading: true,
      });
      if (this.props.selectedLog?.id) {
        axios
          .get(
            `https://agrodigital.io/api/logs/?field=${this.props.fieldId}`,
            this.state.tokenUser
          )
          .then((res) => {
            let data = {};
            for (let i = 0; i < res.data.length; i++) {
              const logReference = res.data[i];
              if (logReference.id === this.props.selectedLog.id) {
                data = logReference;
              }
            }
            axios
              .patch(
                `https://agrodigital.io/api/logs/${data.id}/`,
                insertData,
                this.state.tokenUser
              )
              .then((res) => {
                vm.setState({
                  loading: false,
                });
                vm.closeButtonTapped(true);
              });
          });
      } else {
        insertData.polygons = polygonsIds;
        axios
          .post(
            "https://agrodigital.io/api/logs/",
            insertData,
            this.state.tokenUser
          )
          .then(function () {
            vm.setState({
              loading: false,
            });
            vm.closeButtonTapped(true);
          });
      }
    }
  }

  //CHIP.JS SELECT FERTILIZER
  onChangeOtherFertilizer(event) {
    let index = event.target.id;
    let vm = this;
    if (
      vm.state.selectFertilizerOther &&
      vm.state.selectFertilizerOther.length > 0 &&
      vm.state.selectFertilizerOther[index]
    ) {
      let fertilizer = this.state.selectFertilizerOther[index];
      fertilizer["inputValue"] = event.target.value;
      this.setState({});
    }
    // let fertilizanteUbicacion =
    //   this.state.fertilizationOtherArr[event.target.id];
  }

  selectOtherFert(value) {
    if (this.state.selectFertilizerOther.indexOf(value) <= -1) {
      this.state.selectFertilizerOther.push(value);
      this.setState({});
    }
  }

  onDeleteTapped = (input) => {
    let indexFert = input.target.id;

    if (
      this.state.selectFertilizerOther &&
      this.state.selectFertilizerOther.length > 0 &&
      this.state.selectFertilizerOther[indexFert]
    ) {
      let fertilizer = this.state.selectFertilizerOther[indexFert];
      let filteredArray = this.state.selectFertilizerOther.filter(
        (item) => item.value !== fertilizer.value
      );
      this.setState({ selectFertilizerOther: filteredArray });
    }
  };
  buttonDisabled() {
    if (this.state.inputValueKg && this.state.date) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const {t}= this.props;
    return (
      <Modal isOpen={this.props.isOpen}>
        <div
          className="modal-header justify-content-center"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <h4 className="title title-up">{t("fertilizationForm.fertilizationTitle")}</h4>
        </div>
        <div className="modal-body">
          {this.state.alertMoreThanOnePolygon &&
            this.props.selectedLog?.id &&
            this.state.nameOfAllPolygons.length > 1 && (
              <AlertMultiPolygons
                nameOfAllPolygons={this.state.nameOfAllPolygons}
              />
            )}
          <br />
          <Row className="justify-content-center">
            <Col sm={12} md={10} lg={8}>
              <FormGroup>
                <label>{t("globals.date")}</label>
                <ReactDatetime
                  dateFormat="DD/MM/YYYY"
                  value={this.state.date}
                  onChange={(value) => {
                    this.setState({
                      dateValidState: true,
                      date: value,
                    });
                  }}
                  inputProps={{
                    className: "form-control",
                    placeholder: t("globals.insertDateLog"),
                  }}
                  timeFormat={false}
                />
                {this.state.dateValidState === false ? (
                  <label style={{ color: "#ff0000" }}>{t("globals.requiredField")}</label>
                ) : null}
              </FormGroup>
              {
                <div>
                  <FormGroup>
                    <label>Kg/ha</label>
                    <Input
                      type="number"
                      min={0}
                      value={this.state.inputValueKg}
                      onChange={(e) =>
                        this.setState({
                          inputValueKg: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </div>
              }
              {this.state.fertilizationArr &&
                this.state.fertilizationArr.map((fert, index) => {
                  if (fert.id < 7) {
                    let fertName = fert.name.toLowerCase();
                    let check = this.props.selectedLog?.principals?.length > 0;
                    return (
                      <div key={index}>
                        <FormGroup>
                          <label>{`${fert.name} (%)`}</label>
                          <Input
                            type="number"
                            min={0}
                            value={check ? this.state[fertName]?.value : null}
                            onChange={(e) => {
                              switch (fert.id) {
                                case 6:
                                  let objK = {
                                    item: fert.id,
                                  };
                                  objK.value = parseFloat(e.target.value);
                                  this.setState({
                                    potasio: objK,
                                  });
                                  break;
                                case 5:
                                  let objP = {
                                    item: fert.id,
                                  };
                                  objP.value = parseFloat(e.target.value);
                                  this.setState({
                                    fosforo: objP,
                                  });
                                  break;
                                case 4:
                                  let objN = {
                                    item: fert.id,
                                  };
                                  objN.value = parseFloat(e.target.value);
                                  this.setState({
                                    nitrogeno: objN,
                                  });
                                  break;
                                default:
                                  break;
                              }
                            }}
                          />
                        </FormGroup>
                      </div>
                    );
                  }
                })}
              {this.state.fertilizationOtherArr?.length > 0 && (
                <FormGroup>
                  <label>{t("globals.other")}</label>
                  <Select
                    name="other"
                    className="react-select"
                    placeholder={t("globals.selectOther")}
                    classNamePrefix="react-select"
                    value={null}
                    onChange={(value) => this.selectOtherFert(value)}
                    options={this.state.fertilizationOtherArr}
                  />
                </FormGroup>
              )}
              <div>
                {this.state.selectFertilizerOther &&
                  this.state.selectFertilizerOther.map(
                    (agrochemical, index) => {
                      return (
                        <Chip
                          id={index}
                          value={agrochemical["inputValue"]}
                          onChange={(event) =>
                            this.onChangeOtherFertilizer(event)
                          }
                          label={agrochemical.label}
                          onDeleteTapped={this.onDeleteTapped}
                        ></Chip>
                      );
                    }
                  )}
              </div>
              {
                <div>
                  <FormGroup>
                    <label>{t("globals.observation")}</label>
                    <Input
                      type="text"
                      value={this.state.observation}
                      onChange={(e) =>
                        this.setState({
                          observation: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </div>
              }
            </Col>
          </Row>
        </div>
        {!this.state.loading ? (
          <div className="modal-footer">
            <div className="left-side">
              <Button
                className="btn-link"
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={() => this.closeButtonTapped(false)}
              >
                {t("buttons.cancelButton")}
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                className="btn-link"
                color="primary"
                type="button"
                disabled={this.buttonDisabled()}
                onClick={() => this.saveButtonTapped()}
              >
                {t("buttons.saveButton")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center py-4">
            <CircularProgress color="secondary" />
          </div>
        )}
      </Modal>
    );
  }
}
export default FertilizationForm;

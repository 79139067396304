import React, { Component } from "react";
import { Map, FeatureGroup } from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";

import "leaflet-draw/dist/leaflet.draw.css";
import BackPolygons from "../../../../components/Maps/backPolygons";
import CurrentPolygons from "../../../components/currentPolygons";
import "../../../../assets/css/edit-polygon.css";
import MapLink from "components/Maps/MapLink";
// work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255

const backPolygonsColor = "#ffe913";
const createdPolygonsColor = "#d15f0d";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png",
});

//

let polyline;

export default class MapPolygonEdit extends Component {
  // see http://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event for leaflet-draw events doc

  constructor(props) {
    super(props);
  }

  counter = 0;

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      JSON.stringify(nextProps.initGeoJson) ===
      JSON.stringify(this.props.initGeoJson)
    ) {
      return false;
    }

    if (
      JSON.stringify(nextProps.centerLocation) ===
      JSON.stringify(this.props.centerLocation)
    ) {
      return false;
    }

    return true;
  }

  _onEdited = (e) => {
    let numEdited = 0;
    e.layers.eachLayer((layer) => {
      numEdited += 1;
    });

    this._onChange();
  };

  _onCreated = (e) => {
    let type = e.layerType;
    let layer = e.layer;

    // Do whatever else you need to. (save to db; etc)

    this._onChange();
  };

  _onDeleted = (e) => {
    let numDeleted = 0;
    e.layers.eachLayer((layer) => {
      numDeleted += 1;
    });
    this._onChange();
  };

  render() {
    return (
      <Map
        center={this.props.center}
        className={!this.props.addStatus ? "edit-polygon" : ""}
        bounds={this.props.bounds}
        style={{ width: "100%", height: "100%", minHeight: "300px" }}
        zoom={this.props.zoom}
        zoomControl={false}
      >
        <MapLink />
        {this.props.fieldList.map((field, index) => (
          <FeatureGroup key={index} color={backPolygonsColor}>
            <BackPolygons field={field} />
          </FeatureGroup>
        ))}
        {this.props.createdList.map((item, index) => (
          <FeatureGroup key={index} color={createdPolygonsColor}>
            <CurrentPolygons polygonInfo={item} />
          </FeatureGroup>
        ))}
        <FeatureGroup
          ref={(ref) => {
            this._onFeatureGroupReady(ref);
          }}
        >
          {this.props.fromKml === false && (
            <EditControl
              position="topright"
              onEdited={this._onEdited}
              onCreated={this._onCreated}
              onDeleted={this._onDeleted}
              onCanceled={this._onCanceled}
              draw={{
                rectangle: false,
                marker: false,
                polygon: this.props.addStatus,
                circle: false,
                polyline: false,
                circlemarker: false,
              }}
            />
          )}
        </FeatureGroup>
      </Map>
    );
  }

  _editableFG = null;

  _onFeatureGroupReady = (ref) => {
    if (ref === null) {
      return;
    }

    if (this.counter > 0) {
      return;
    }

    this._editableFG = ref;
    // populate the leaflet FeatureGroup with the geoJson layers

    if (this.props.initGeoJson) {
      let leafletGeoJSON = new L.GeoJSON(this.props.initGeoJson);
      let leafletFG = this._editableFG.leafletElement;
      leafletGeoJSON.eachLayer((layer) => leafletFG.addLayer(layer));
      this.counter++;
    } else {
      let leafletGeoJSON = new L.GeoJSON(getGeoJson());
      let leafletFG = this._editableFG.leafletElement;
      leafletGeoJSON.eachLayer((layer) => leafletFG.addLayer(layer));
    }
  };

  _onChange = () => {
    // this._editableFG contains the edited geometry, which can be manipulated through the leaflet API

    const { onChange } = this.props;

    if (!this._editableFG || !onChange) {
      return;
    }

    const geojsonData = this._editableFG.leafletElement.toGeoJSON();
    onChange(geojsonData);
  };
}

// data taken from the example in https://github.com/PaulLeCam/react-leaflet/issues/176

function getGeoJson() {
  return {
    type: "FeatureCollection",
    features: [],
  };
}

class LogTypeFilter {
    constructor(label, types, active) {
        this.label = label;
        this.types = types;
        this.active = active;
    }

    static getFilters(props) {
        const {t}= props;
        var list = [];
        list.push(new LogTypeFilter(t("globals.sowing"), ["Sowing"], true));
        list.push(new LogTypeFilter(t("globals.phenology"), ["Phenology"], true));
        list.push(new LogTypeFilter(t("globals.fertilization"), ["Fertilization"], true));
        list.push(new LogTypeFilter(t("globals.irrigation"), ["Water"], true));
        list.push(new LogTypeFilter(t("globals.fieldReport"), ["DiseaseReport",
            "BugReport",
            "WeedReport",
            "FieldReport"], true));
        list.push(new LogTypeFilter(t("globals.labReport"), ["LabReport"], true));
        list.push(new LogTypeFilter(t("globals.application"), ["Application"], true));
        list.push(new LogTypeFilter(t("globals.yield"), ["Yield"], true));
        return list;
    }
}
export {LogTypeFilter}
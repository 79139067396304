import React, { useEffect } from "react";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import "../../../../assets/css/licenses.css"
import ListLicences from "./ListLicenses";
import { useState } from "react";
import axios from "axios";
import CreateLicenses from "./CreateLicenses";
import { useTranslation } from "react-i18next";
const Licences = (props) => {
  const [t, i18n] = useTranslation("global")
  const [screens, setScreens]= useState({
    createLicenses: false,
    listLicenses: true,
  })
 const [licensesData, setlicensesData] = useState([])
 const [fieldsNotLicenses, setFieldsNotLicenses] = useState([])
 const [licensesDone, setLicensesDone] = useState(false)
 const [fieldsNotFarm, setFieldsNotFarm] = useState([])
 const tokenUser = {
  headers: {
    // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
};
 useEffect(()=>{
  fetchLicenses()
 },[])

 useEffect(()=>{
  //si tiene licencias creadas
    if(licensesDone){
    if(licensesData.length){
    let licensesList= []
    let licensesExpired = licensesData.filter((el)=> new Date(
      el.date?.replaceAll("Z", "")
    ).getTime() < new Date().getTime() && new Date(
      el.date?.replaceAll("Z", "")
    ).getFullYear() !== 2000)
    if(licensesExpired.length){
      let licensesExpiredId = licensesExpired.map((el)=> el.id)
      let removeLicensesExpired = licensesData.filter((el)=> !licensesExpiredId.includes(el.id))
      licensesList = removeLicensesExpired
    } else {
      licensesList = licensesData
    }
    let fieldIdData = licensesList?.map((el) => [...el.fields, ...el.fields]);
    let fieldsIdResult = [];
    fieldIdData.forEach((element) => {
      fieldsIdResult.push(...element);
    });
    let deleteRepeat = fieldsIdResult.filter((item, index) => {
      return fieldsIdResult.indexOf(item) === index;
    }).map((el)=> el.id);
    let searchFieldWithoutLicense= props.fieldList.filter((field)=> !deleteRepeat.includes(field.id))
    let fieldsNotFarm= deleteRepeat.filter((field)=> props.farms.some((el)=> el.fields.some((e)=> e.id === field)))
    let fieldsWithoutFarm= props.fieldList.filter((field)=> !fieldsNotFarm.includes(field.id))
    setFieldsNotFarm(fieldsWithoutFarm)
    if(!props.farms.length){
      setFieldsNotLicenses(searchFieldWithoutLicense)
    } else{
      let fieldsNotFarm = searchFieldWithoutLicense.filter((field)=> !props.farms.some((el)=> el.fields.some((e)=> e.id === field.id)))
      setFieldsAndFarmsData(fieldsNotFarm)
    }
  } 
  else{
    let fieldsNotFarm= props.fieldList.filter((field)=> !props.farms.some((el)=> el.fields.some((e)=> e.id === field.id)))
    if(!props.farms.length){
      setFieldsNotFarm(props.fieldList)
      setFieldsNotLicenses(props.fieldList)
    } else{
      setFieldsNotFarm(fieldsNotFarm)
      setFieldsAndFarmsData(fieldsNotFarm)
    }
  }
}
 },[licensesDone, props.farms.length])

 useEffect(()=>{
  if(props.fieldList.length && fieldsNotLicenses.length){
    setScreens((prev) => ({
      ...prev,
      createLicenses: true,
      listLicenses: false,
    }));
  } 
  if(props.fieldList.length && !fieldsNotLicenses.length){
    setScreens((prev) => ({
      ...prev,
      createLicenses: false,
      listLicenses: true,
    }));
  }
},[fieldsNotLicenses.length])
  const setFieldsAndFarmsData = (fieldsNotFarm)=>{
    for (let i = 0; i < props.farms.length; i++) {
      const farms = props.farms[i];
      //si no tiene licencia  o su licencia esta expirada
      let searchFieldsInactive = farms.fields.filter((el) => 
      !el.license || 
      new Date(el.licenseDate?.replaceAll("Z", "")).getTime() < new Date().getTime() && new Date(el.licenseDate?.replaceAll("Z", "")).getFullYear() !== 2000);
      farms.fieldsNotPlan = searchFieldsInactive;
  }
    let fieldsResult = props.farms.filter((el)=> el.fieldsNotPlan.length > 0).concat(fieldsNotFarm)
    setFieldsNotLicenses(fieldsResult)
  }
 //llamar luego de la creacion de otra licencia
 const fetchLicenses = async()=>{
  setLicensesDone(false)
  let {data} = await axios.get(`https://agrodigital.io/api/licenses/`, tokenUser)

  if (data.length) {
    let licenses = data.filter((el)=> el.product === "BAS");
    let fields = props.fieldList;
    for (let i = 0; i < licenses.length; i++) {
      let fieldsLicence = licenses[i].fields;
      for (let j = 0; j < fields.length; j++) {
        let field = fields[j];
        if (fieldsLicence.includes(field.id)) {
          field.license= true;
          field.licenseDate= licenses[i].date;
          let searchIndex = fieldsLicence.findIndex(
            (find) => find === field.id
          );
          fieldsLicence.splice(searchIndex, 1);
          fieldsLicence.push(field);
        }
      }
    }
    //llamar como [] una vez que create lic se haya creado
    setlicensesData(licenses)
    setLicensesDone(true)
  } else {
    setLicensesDone(true)
  }
 }
  return (
    <div
      style={{
        position: "absolute",
        top: "3%",
        padding: "15px",
        height: "100%",
        width: "100%",
      }}
    >
        <>
          <div>
            <Row
              className="row-5 row-sm-6 row-md-9 row-lg-10"
              style={{ margin: "0", justifyContent: "space-evenly" }}
            >
              <Card
                onClick={() => {
                  if (screens.listLicenses) {
                    return;
                  }
                  if (screens.createLicenses) {
                    setScreens((prev) => ({
                      ...prev,
                      createLicenses: false,
                      listLicenses: true,
                    }));
                  }
                }}
                className="cardLicences card-stats"
                style={{
                  minWidth: "30vh",
                  width: "50vh",
                  background: screens.listLicenses ? "#5db283" : "white",
                  cursor: "pointer",
                }}
              >
                <CardBody style={{ padding: "0px 0px 0px 5px" }}>
                  <label
                    className="titleCreateLicenses"
                    style={{
                      color: screens.listLicenses ? "white" : "#5db283",
                    }}
                  >
                    {t("globals.listLicenses").toUpperCase()}
                  </label>
                </CardBody>
              </Card>
              <Card
                onClick={() => {
                  if (!fieldsNotLicenses.length) {
                    return;
                  }
                  if (screens.createLicenses) {
                    return;
                  }
                  if (screens.listLicenses) {
                    setScreens((prev) => ({
                      ...prev,
                      createLicenses: true,
                      listLicenses: false,
                    }));
                  }
                }}
                className="cardLicences card-stats"
                style={{
                  minWidth: "30vh",
                  width: "50vh",
                  background: screens.createLicenses ? "#5db283" : "white",
                  cursor: "pointer",
                }}
              >
                <CardBody style={{ padding: "0px 0px 0px 5px" }}>
                  <label
                    className="titleCreateLicenses"
                    style={{
                      color: screens.createLicenses ? "white" : "#5db283",
                    }}
                  >
                    {t("globals.createLicenses").toUpperCase()}
                  </label>
                </CardBody>
              </Card>
            </Row>
          </div>
          {screens.listLicenses && (
            <ListLicences
              licensesData={licensesData}
              fieldList={props.fieldList}
              farms={props.farms}
              fieldsNotFarm={fieldsNotFarm}
            />
          )}
          {screens.createLicenses && fieldsNotLicenses.length > 0 && (
            <CreateLicenses
              farms={props.farms}
              licensesData={licensesData}
              fieldsNotLicenses={fieldsNotLicenses}
              setFieldsNotLicenses={setFieldsNotLicenses}
              setScreens={setScreens}
              fetchLicenses={fetchLicenses}
              userInfo={props.userInfo}
            />
          )}
        </>
    </div>
  );
};

export default Licences;

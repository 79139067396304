import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Row } from "reactstrap";

const DeleteRasterModal = (props) => {
  const [t, i18n]= useTranslation("global")
  const closestDate = (
    dateSelectedmilliseconds,
    first_rasterData,
    datesSort
  ) => {
    let datesArray2 = [];
    let searchIndexRasterData = datesSort.map((el) => {
      let dateMiliseconds = new Date(el + "T00:00:00").getTime();
      if (dateMiliseconds <= dateSelectedmilliseconds) {
        return dateMiliseconds;
      }
    });
    let notUndefined = searchIndexRasterData.filter((el) => el !== undefined);
    let selectedPrevDate = notUndefined.pop();
    let indexDates = datesSort.filter((v, i, a) => a.indexOf(v) === i);
    let searchIndexDate = indexDates.findIndex(
      (el) => el === moment(selectedPrevDate).format("YYYY-MM-DD")
    );
    var last;
    for (var i = 0; i < indexDates.length; i++) {
      last = moment(indexDates[i]);
      last.add(4, "hours");
      last.toDate().toISOString().substring(0, 10);
      datesArray2.push(last);
    }
    //DELETERASTERSCOMPLETED
    props.restartDates(
      first_rasterData,
      searchIndexDate,
      datesSort,
      datesArray2,
      indexDates
    );
  };
  const updateRasters = async () => {
    let dateSelected = moment(props.fecha).format("YYYY-MM-DD");
    let dateSelectedmilliseconds = new Date(
      dateSelected + "T00:00:00"
    ).getTime();
    let first_rasterData = []
    if(props.planBasic){
      let rasterDataBasic = await axios.get(
        `https://agrodigital.io/api/rasters/?field=${props.fieldId}&sources[]=LA9&sources[]=LA8&sources[]=SE2`,
        props.tokenUser
      );

      first_rasterData = rasterDataBasic.data
    }

    if(props.planPlanet){
      let rasterDataPlanet = await axios.get(
        `https://agrodigital.io/api/rasters/?field=${props.fieldId}&sources[]=PLA&sources[]=PLM`,
        props.tokenUser
      );

      first_rasterData = rasterDataPlanet.data
    }

    let allRGBDate = first_rasterData
      .filter((raster) => raster.type === "RGB")
      .map((el) => el.date);
    let datesSort = allRGBDate.sort(
      (a, b) =>
        new Date(a + "T00:00:00").getTime() -
        new Date(b + "T00:00:00").getTime()
    );
    closestDate(dateSelectedmilliseconds, first_rasterData, datesSort);
  };

  const deleteAllRastersByDate = async () => {
    //Obtengo el id de los poligonos
    let polygonsInfo = props.polygonsList;
    let polygonSource = props.sourcePolygon.filter((source) =>
      polygonsInfo.some((polygon) => polygon.id === source.polygon)
    );
    //adquiero la fecha en la que estoy actualmente
    let fecha = moment(props.fecha).format("YYYY-MM-DD");
    if (polygonSource.length > 0) {
      //pongo un acumulador para saber cuando llega al ultimo poligono y ejecutar funciones
      let acum = 0;
      for (let i = 0; i < polygonSource.length; i++) {
        let sourceData = polygonSource[i];
        await axios
          .get(
            `https://agrodigital.io/api/rasters/discard/?source=${sourceData.source}&polygon=${sourceData.polygon}&date=${fecha}`,
            props.tokenUser
          )
          .then((res) => {
            acum += 1;
            // this.setState({ done: false });
            if (acum === 1) {
              props.deleteRastersProcess(false);
            }
            //para que por cada request no se ejecuten las mismas funciones
            if (acum === polygonSource.length) {
              updateRasters();
            }
          });
      }
    }
  };

  const deleteOneRasterData = () => {
    let fecha = moment(props.fecha).format("YYYY-MM-DD");
    let polygonSource = props.sourcePolygon.filter(
      (el) => el.polygon === props.rasterDeleteInfo.id
    )[0];

    axios
      .get(
        `https://agrodigital.io/api/rasters/discard/?source=${polygonSource.source}&polygon=${polygonSource.polygon}&date=${fecha}`,
        props.tokenUser
      )
      .then((res) => {
        props.deleteRastersProcess(true);
        updateRasters();
      });
  };
  return (
    <Modal
      isOpen={props.isOpen}
      keyboard={false}
      style={{
        maxWidth: "600px",
        transform: "translate(0, 0)",
        textAlign: "center",
      }}
    >
      {!props.oneRasterDelete ? (
        <>
          <div
            className="modal-header justify-content-center"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <h4 className="title title-up">
              {t("globals.deleteAllImages")}
            </h4>
          </div>
          <div className="modal-body">
            <br />
            <Row className="justify-content-center">
              <h6 style={{ fontWeight: "400" }}>
              {t("globals.deleteAllImagesDescription")}
              </h6>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div
            className="modal-header justify-content-center"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <h4 className="title title-up">
            {t("globals.deleteOneImage")}
            </h4>
          </div>
          <div className="modal-body">
            <br />
            <Row className="justify-content-center">
              <h6 style={{ fontWeight: "400" }}>
              {t("globals.deleteOneImageDescription")}
              </h6>
            </Row>
          </div>
        </>
      )}
      {!props.loading ? (
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => props.toggleDeleteRastersModal()}
            >
              {t("buttons.noButton").toUpperCase()}
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            {props.oneRasterDelete ? (
              <Button
                className="btn-link"
                color="primary"
                type="button"
                onClick={() => deleteOneRasterData()}
              >
                {t("buttons.yesButton").toUpperCase()}
              </Button>
            ) : (
              <Button
                className="btn-link"
                color="primary"
                type="button"
                onClick={() => deleteAllRastersByDate()}
              >
                {t("buttons.yesButton").toUpperCase()}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="text-center py-4">
          <CircularProgress color="secondary" />
        </div>
      )}
    </Modal>
  );
};

export default DeleteRasterModal;

import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
} from "reactstrap";

const ShareModalDashboard = (props) => {
  const [t, i18n]= useTranslation("global")
  const shareField = async () => {
    let userEmail = props.shareEmail.trim();
    if (userEmail !== "" && props.shareFieldId != null) {
      let fieldId = props.shareFieldId;
      let fieldsInfo = await axios.get(
        "https://agrodigital.io/api/fields/",
        props.tokenUser
      );
      let searchEmail = await axios.get(
        `https://agrodigital.io/api/auth/users/?email=${userEmail}`,
        props.tokenUser
      );
      let userSharedId = searchEmail?.data[0]?.id;
      let filterField = fieldsInfo.data.filter((el) => el.id === fieldId)[0];
      let noAccessRepeat = filterField.access.every(
        (el) => el !== userSharedId
      );

      if (noAccessRepeat) {
        let listAcess = [...filterField.access, userSharedId];
        await axios
          .patch(
            `https://agrodigital.io/api/fields/${fieldId}/`,
            { access: listAcess },
            props.tokenUser
          )
          .then(function () {
            toast.success(t("alerts.shareField"));
            props.closeShareModal();
          })
          .catch((error) => {
            toast.error(
              t("alerts.userNotFound"),
              {
                style: {
                  textAlign: "center",
                },
              }
            );
          });
      } else {
        toast.error(
          t("alerts.alreadyShareField"),
          {
            style: {
              textAlign: "center",
            },
          }
        );
        props.closeShareModal();
      }
    }
  };
  return (
    <Modal isOpen={props.showShareModal}>
      <div className="modal-header justify-content-center">
        <h4 className="title title-up">{t("alerts.shareField")}</h4>
      </div>
      <div className="modal-body">
        <Row>
          <Col sm={12}>
            <label>
            {t("globals.shareFieldDetails")}
            </label>
            <Form className="form" onSubmit={(e) => e.preventDefault()}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-air-baloon" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="email"
                  type="email"
                  onChange={(e) => props.setShareEmail(e.target.value)}
                />
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </div>
      {props.loadingShare ? (
        <div className="text-center py-4">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => props.closeShareModal()}
            >
              {t("buttons.noButton")}
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className={
                props.shareEmail !== "" ? "btn-link" : "btn-link disabled"
              }
              color="primary"
              type="button"
              onClick={() => {
                shareField();
              }}
            >
              {t("buttons.yesButton")}
            </Button>
          </div>
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </Modal>
  );
};

export default ShareModalDashboard;

import React, { useCallback, useReducer, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import produce from "immer";
import iconDone from "../../../../assets/img/done.png";
import backArrowIcon from "../../../../assets/img/backArrowIcon.png";
import "../../../../assets/css/farmer.css"
import axios from "axios";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const dragReducer = produce((draft, action) => {
  if(action.type === "MOVE"){
    draft[action.from] = draft[action.from] || [];
    draft[action.to] = draft[action.to] || [];
    const [removed] = draft[action.from].splice(action.fromIndex, 1);
    draft[action.to].splice(action.toIndex, 0, removed);
  }
  if(action.type === "RESET"){
    draft["newFarmer"] = []
  }
});

const CreateFarmer = (props) => {
  const [t, i18n] = useTranslation("global")
  const [state, dispatch] = useReducer(dragReducer, {
    fieldList: props.fieldList.filter((el)=> !props.fieldsAfected?.includes(el.id)),
    newFarmer: [],
  });
  const [nameFarmer, setNameFarmer]= useState("")
  const tokenUser = {
    headers: {
      // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
  const onDragEnd = useCallback((result) => {
    if (result.reason === "DROP") {
      if (!result.destination) {
        return;
      }
      dispatch({
        type: "MOVE",
        from: result.source.droppableId,
        to: result.destination.droppableId,
        fromIndex: result.source.index,
        toIndex: result.destination.index,
      });
    }
  }, []);

  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the fieldList look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#f6f6f6" : "white",
    borderRadius:"10px",
    marginBottom:"10px",
    border:"1px solid #e2e2e2",
    listStyle: "none",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // const getListStyle = (isDraggingOver) => ({background: isDraggingOver ? "white" : "lightgrey",});

  const saveData = () => {
    if (state.newFarmer.length > 0) {
      //BODY: name, owner, fields
      let body = {
        name: nameFarmer ? nameFarmer : "FarmerSinNombre",
        owner: props.userInfo?.id,
        fields: state.newFarmer.map((el) => el.id),
      };
      axios
        .post("https://agrodigital.io/api/farms/", body, tokenUser)
        .then(() => {
          if(!state.fieldList.length){
            //IR A EDIT FARMER
            // props.closeFarmer()
          }
          dispatch({
            type:"RESET",
            data:props.fieldList
          })
          setNameFarmer("")
          props.fetchFarms()
        });
    }
  };
  return (
    <div
      style={{
        background: "#f4f3ef",
        width: "100%",
      }}
    >
      <div style={{marginLeft:"50px", marginRight:"50px",marginTop:"3vh", fontSize:"revert"}}>{t("globals.createFarmerInfo")} </div>
      <form style={{marginTop:"1vh" }} onSubmit={(e) => e.preventDefault()}>
        <input
          value={nameFarmer}
          onChange={(e) => setNameFarmer(e.target.value)}
          className="inputCreateFarmer"
          placeholder={t("globals.nameNewFarmer")}
          type="text"
        ></input>
      </form>
      <div class="rowDragCreateFarmer">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="fieldList" type="PERSON">
            {(provided, snapshot) => (
              <>
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                  class="createFarmerColumnDragLeft"
                >
                  <ul className="listLeft">
                    {state.fieldList?.map((el, index) => (
                      <Draggable
                        key={el.id}
                        draggableId={`${el.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {el.name}
                          </li>
                        )}
                      </Draggable>
                    ))}
                  </ul>
                  {provided.placeholder}
                </div>
              </>
            )}
          </Droppable>
          <Droppable droppableId="newFarmer" type="PERSON">
            {(provided, snapshot) => (
              <>
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                  className="createFarmerColumnDragRight"
                >
                  <ul className="listRight">
                    {state.newFarmer?.map((el, index) => (
                      <Draggable
                        key={el.id}
                        draggableId={`${el.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {el.name}
                          </li>
                        )}
                      </Draggable>
                    ))}
                  </ul>
                  {provided.placeholder}
                </div>
              </>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {state.newFarmer.length > 0 && (
        <div style={{ width: "100%", textAlign: "center", marginTop: "2vh" }}>
          <Button
            className="btn-btn btn-success btn-sm  btn btn-success"
            style={{
              border: "none",
              borderRadius: "10px",
              background: "#5db283",
            }}
            onClick={() => {
              saveData();
            }}
          >
            <p style={{ margin: "0", padding: "5px" }}>{t("globals.saveChanges")}</p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateFarmer;

import ARR_icon_1 from "../../assets/img/cropmap_icons/arroz/Arroz_1.png";
import ARR_icon_2 from "../../assets/img/cropmap_icons/arroz/Arroz_2.png";
import ARR_icon_3 from "../../assets/img/cropmap_icons/arroz/Arroz_3.png";
import ARR_icon_4 from "../../assets/img/cropmap_icons/arroz/Arroz_4.png";

import RIC_icon_1 from "../../assets/img/cropmap_icons/arroz/Arroz_1.png";
import RIC_icon_2 from "../../assets/img/cropmap_icons/arroz/Arroz_2.png";
import RIC_icon_3 from "../../assets/img/cropmap_icons/arroz/Arroz_3.png";
import RIC_icon_4 from "../../assets/img/cropmap_icons/arroz/Arroz_4.png";

import CN_icon_1 from "../../assets/img/cropmap_icons/campo/Campo_1.png";
import CN_icon_2 from "../../assets/img/cropmap_icons/campo/Campo_2.png";
import CN_icon_3 from "../../assets/img/cropmap_icons/campo/Campo_3.png";
import CN_icon_4 from "../../assets/img/cropmap_icons/campo/Campo_4.png";

import MAI_icon_1 from "../../assets/img/cropmap_icons/maiz/Maiz_1.png";
import MAI_icon_2 from "../../assets/img/cropmap_icons/maiz/Maiz_2.png";
import MAI_icon_3 from "../../assets/img/cropmap_icons/maiz/Maiz_3.png";
import MAI_icon_4 from "../../assets/img/cropmap_icons/maiz/Maiz_4.png";

import COR_icon_1 from "../../assets/img/cropmap_icons/maiz/Maiz_1.png";
import COR_icon_2 from "../../assets/img/cropmap_icons/maiz/Maiz_2.png";
import COR_icon_3 from "../../assets/img/cropmap_icons/maiz/Maiz_3.png";
import COR_icon_4 from "../../assets/img/cropmap_icons/maiz/Maiz_4.png";

import PRA_icon_1 from "../../assets/img/cropmap_icons/pradera/Pradera_1.png";
import PRA_icon_2 from "../../assets/img/cropmap_icons/pradera/Pradera_2.png";
import PRA_icon_3 from "../../assets/img/cropmap_icons/pradera/Pradera_3.png";
import PRA_icon_4 from "../../assets/img/cropmap_icons/pradera/Pradera_4.png";

import SOJ_icon_1 from "../../assets/img/cropmap_icons/soja/Soja_1.png";
import SOJ_icon_2 from "../../assets/img/cropmap_icons/soja/Soja_2.png";
import SOJ_icon_3 from "../../assets/img/cropmap_icons/soja/Soja_3.png";
import SOJ_icon_4 from "../../assets/img/cropmap_icons/soja/Soja_4.png";

import SOY_icon_1 from "../../assets/img/cropmap_icons/soja/Soja_1.png";
import SOY_icon_2 from "../../assets/img/cropmap_icons/soja/Soja_2.png";
import SOY_icon_3 from "../../assets/img/cropmap_icons/soja/Soja_3.png";
import SOY_icon_4 from "../../assets/img/cropmap_icons/soja/Soja_4.png";

import TRI_icon_1 from "../../assets/img/cropmap_icons/trigo/Trigo_1.png";
import TRI_icon_2 from "../../assets/img/cropmap_icons/trigo/Trigo_2.png";
import TRI_icon_3 from "../../assets/img/cropmap_icons/trigo/Trigo_3.png";
import TRI_icon_4 from "../../assets/img/cropmap_icons/trigo/Trigo_4.png";

import WHE_icon_1 from "../../assets/img/cropmap_icons/trigo/Trigo_1.png";
import WHE_icon_2 from "../../assets/img/cropmap_icons/trigo/Trigo_2.png";
import WHE_icon_3 from "../../assets/img/cropmap_icons/trigo/Trigo_3.png";
import WHE_icon_4 from "../../assets/img/cropmap_icons/trigo/Trigo_4.png";

import CEB_icon_1 from "../../assets/img/cropmap_icons/cebada/Cebada_1.png";
import CEB_icon_2 from "../../assets/img/cropmap_icons/cebada/Cebada_2.png";
import CEB_icon_3 from "../../assets/img/cropmap_icons/cebada/Cebada_3.png";

//Colza y canola es lo mismo
import COL_icon_1 from "../../assets/img/cropmap_icons/canola/Canola_1.png";
import COL_icon_2 from "../../assets/img/cropmap_icons/canola/Canola_2.png";
import COL_icon_3 from "../../assets/img/cropmap_icons/canola/Canola_3.png";

import GIR_icon_1 from "../../assets/img/cropmap_icons/girasol/Girasol_1.png";
import GIR_icon_2 from "../../assets/img/cropmap_icons/girasol/Girasol_2.png";
import GIR_icon_3 from "../../assets/img/cropmap_icons/girasol/Girasol_3.png";

import SOR_icon_1 from "../../assets/img/cropmap_icons/sorgo/Sorgo_1.png";
import SOR_icon_2 from "../../assets/img/cropmap_icons/sorgo/Sorgo_2.png";
import SOR_icon_3 from "../../assets/img/cropmap_icons/sorgo/Sorgo_3.png";

import BAR_icon_1 from "../../assets/img/cropmap_icons/barbecho/Barbecho_1.png";

import shadow from "../../assets/img/cropmap_icons/shadow/shadow.png";

export function getCropIcon(crop) {
  let iconImageJson = MapIcon[crop];
  if (iconImageJson) {
    return iconImageJson[crop + "_icon_1"];
  }
  return BAR_icon_1;
}

const MapIcon = {
  ARR: {
    ARR_icon_1,
    ARR_icon_2,
    ARR_icon_3,
    ARR_icon_4,
  },
  CN: {
    CN_icon_1,
    CN_icon_2,
    CN_icon_3,
    CN_icon_4,
  },
  MAI: {
    MAI_icon_1,
    MAI_icon_2,
    MAI_icon_3,
    MAI_icon_4,
  },
  PRA: {
    PRA_icon_1,
    PRA_icon_2,
    PRA_icon_3,
    PRA_icon_4,
  },
  SOJ: {
    SOJ_icon_1,
    SOJ_icon_2,
    SOJ_icon_3,
    SOJ_icon_4,
  },
  TRI: {
    TRI_icon_1,
    TRI_icon_2,
    TRI_icon_3,
    TRI_icon_4,
  },
  CEB: {
    CEB_icon_1,
    CEB_icon_2,
    CEB_icon_3,
  },
  COL: {
    COL_icon_1,
    COL_icon_2,
    COL_icon_3,
  },
  GIR: {
    GIR_icon_1,
    GIR_icon_2,
    GIR_icon_3,
  },
  SOR: {
    SOR_icon_1,
    SOR_icon_2,
    SOR_icon_3,
  },
  BAR: {
    BAR_icon_1,
  },
  //USA
  RIC: {
    RIC_icon_1,
    RIC_icon_2,
    RIC_icon_3,
    RIC_icon_4,
  },
  WHE: {
    WHE_icon_1,
    WHE_icon_2,
    WHE_icon_3,
    WHE_icon_4,
  },
  SOY: {
    SOY_icon_1,
    SOY_icon_2,
    SOY_icon_3,
    SOY_icon_4,
  },
  COR: {
    COR_icon_1,
    COR_icon_2,
    COR_icon_3,
    COR_icon_4,
  },
  shadow: shadow,
};

import React from "react";
import { FeatureGroup, Map, Marker } from "react-leaflet";
import SubPolygon from "../../../components/Maps/subPolygon";
import { getCropIcon } from "../../../components/Maps/mapIcons";
import L from "leaflet";
import MapLink from "components/Maps/MapLink";

class DetailMap extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
  }

  getPolygons = (polygon) => {
    let coordinates = polygon?.boundary.coordinates[0];
    let result = [];
    for (let i = 0; i < coordinates?.length; i++) {
      let coords = coordinates[i];
      let temp = [];
      //long
      temp[1] = coords[0];
      //lat
      temp[0] = coords[1];

      result.push(temp);
    }
    return result;
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getIconInfo = (crop = "") => {
    let iconUrl = "";

    if (!crop) {
      return null;
    }

    iconUrl = getCropIcon(crop);
    if (!iconUrl) {
      return null;
    }

    let iconInfo = L.icon({
      iconUrl: iconUrl,
      iconRetinaUrl: iconUrl,
      iconAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,

      iconSize: [20, 20],
    });
    return iconInfo;
  };

  getColor = () => {
    var colors = ["#88ebcd", "#00EE66", "#22AAcd", "#9999cd"];
    return colors[3];
  };

  render() {
    return this._isMounted ? (
      <Map
        center={this.props.center}
        bounds={this.props.bounds}
        style={{ width: "100%", height: "100%", borderRadius: "5px" }}
        /*zoom={this.props.zoom}*/ zoomControl={false}
      >
        <MapLink />
        <FeatureGroup>
          {this.props.fieldList?.map(
            (data, index) =>
            !data.fields ? (
              data?.polygonData?.length === data?.polygons?.length && (
                <FeatureGroup key={index}>
                  {data.polygonData &&
                    data.polygonData.map((polygon, subIndex) => {
                      return(
                      <SubPolygon
                        key={subIndex}
                        iconInfo={this.getIconInfo(polygon.crop)}
                        positions={this.getPolygons(polygon)}
                        hoverStatus={
                          this.props.hoverFieldNumber === index &&
                          (this.props.hoverPolygonNumber === -1 ||
                            this.props.hoverPolygonNumber === subIndex)
                            ? true
                            : false
                        }
                        name={polygon.name}
                      />
                    )})}
                </FeatureGroup>
              )
            ) : (
              data.fields.map((farmField, farmFieldIndex) => {
                return(
                farmField?.polygonData?.length === farmField?.polygons?.length && (
                  <FeatureGroup key={farmFieldIndex} color="#FF8000">
                  {farmField.polygonData &&
                    farmField.polygonData.map((polygon, subIndex) => 
                    {
                      return(
                      <SubPolygon
                        key={subIndex}
                        iconInfo={this.getIconInfo(polygon.crop)}
                        positions={this.getPolygons(polygon)}
                        hoverStatus={
                          farmField.id === this.props.selectedElement?.id &&
                          (this.props.hoverPolygonNumber === -1 ||
                            this.props.hoverPolygonNumber === subIndex)
                            ? true
                            : false
                        }
                        name={polygon.name}
                      />
                    )})}
                </FeatureGroup>
                )
              )})
            )
             
          )}
        </FeatureGroup>
      </Map>
    ) : (
      <div></div>
    );
  }

  //
}

export default DetailMap;

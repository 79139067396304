import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, Input, Modal, Row } from 'reactstrap';

const AlertsPhenoEvents = (props) => {
  const [t, i18n] = useTranslation("global")
  const [alert, setAlert] = useState("")
  useEffect(() => {
    checkAlerts()
  }, [props])
  const checkAlerts = ()=>{
    let alert=""
    if(props.differentSowing){
     alert = t("alerts.differentSowing")
    }
    if(props.noSowingLog){
        alert = t("alerts.noSowingLog")
    }
    if(props.haveSowing){
        alert = t("alerts.noHaveSowing")
    }
    setAlert(alert)
  }
  const hideAlert = ()=>{
    props.closeButtonTapped(false)
  }
  return (
        <SweetAlert
          title=""
          confirmBtnText={t("buttons.understoodButton")}
          confirmBtnBsStyle="primary"
          onConfirm={() => {hideAlert()}}
          btnSize="xs"
        >
          <div>
            <h6 style={{ fontSize: "20px" }}>{alert}</h6>
          </div>
        </SweetAlert>
  )
}

export default AlertsPhenoEvents
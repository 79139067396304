
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { withTranslation } from "react-i18next";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldname: "",
      fieldnameState: "",
      fieldnameFocus : false
    };
  }
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.props.setDataWizard("chacra", event.target.value)
  };

  isValidated = () => {
    if (
      this.state.fieldnameState === "has-success"
    ) {
      return true;
    } else {
      if (this.state.fieldnameState !== "has-success") {
        this.setState({ fieldnameState: "has-danger" });
      }
      return false;
    }
  };
  render() {
   const {t}= this.props

    return (
      <>
        <h5 className="info-text">
           {t("globals.assignFieldName")}
        </h5>
        <Row className="justify-content-center">
          <Col sm="10">
            <InputGroup
              className={classnames(this.state.fieldnameState, {
                "input-group-focus": this.state.fieldnameFocus,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="fieldtname"
                placeholder={`${t("globals.fieldName")} (${t("globals.required")})`}
                type="text"
                onChange={(e) => this.change(e, "fieldname", "length", 1)}
                onFocus={(e) => this.setState({ fieldnameFocus: true })}
                onBlur={(e) => this.setState({ fieldnameFocus: false })}
              />
              {this.state.fieldnameState === "has-danger" ? (
                <label className="error">{t("globals.requiredField")}</label>
              ) : null}
            </InputGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default Wizard;

import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardWelcome = (props) => {
  const [t, i18n] = useTranslation("global")
  return (
    <div className="content">
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <Card className="card-testimonial">
            <CardBody>
              <div className="icon icon-primary">
                <i className="fa fa-laptop-code" />
              </div>
              <CardTitle tag="h6">{t("alerts.loginSuccess")}</CardTitle>
              <CardSubtitle className="mb-2 text-muted">{t("cardWelcome.description")}</CardSubtitle>
              <div className="card-avatar" style={{ paddingTop: "1rem" }}>
                <a>
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/oryzativa_logo.png")}
                  />
                </a>
              </div>
            </CardBody>
            <CardFooter>
              <div className="empty-view-footer">
                <Button
                  className={"btn-link"}
                  color="success"
                  tag={NavLink}
                  to="/admin/cropmap/add-field"
                >
                  {t("buttons.addFieldButton")}
                </Button>
                <Button
                  color="success"
                  className={"btn-link"}
                  onClick={() =>
                    props.setStateDashboard((prev) => ({
                      ...prev,
                      showContactModal: true,
                    }))
                  }
                >
                  {t("buttons.contactButton")}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CardWelcome;

import React, { useState } from "react";
import CreateFarmer from "./CreateFarmer";
import EditFarmer from "./EditFarmer";
import editIcon from "../../../../assets/img/editIcon.png";
import createIcon from "../../../../assets/img/createIcon.png";
import { useEffect } from "react";
import axios from "axios";
import "../../../../assets/css/farmer.css"
import { Card, CardBody, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
//TODO: HACER UN LOADING DESDE CREATE/EDIT FARMERS PARA VOLVER A CARGAR LOS FARMERSLIST
//TODO: HACER RESPONSIVE LA PAGINA COMO LAS OTRAS SECCIONES 
//Si apreta el boton de editar se abre el EditFarmer
//Si apreta el boton de crear se abre el CreateFarmer
const Farmer = (props) => {
  const [createFarmer, setCreateFarmer] = useState(false);
  const [editFarmer, setEditFarmer] = useState(false);
  const [t, i18n] = useTranslation("global")

  useEffect(()=>{
    if(props.fieldList.length && props.farms.length){
      setEditFarmer(true)
      setCreateFarmer(false)
    } 
    if(props.fieldList.length && !props.farms.length){
      setCreateFarmer(true)
      setEditFarmer(false)
    }
  },[props.farms.length])
  return (
    <div style={{position:"absolute", top:"3%", padding:"10px", width:"100%"}}>
      <div className="farmerSections">
        <Row
        className="row-5 row-sm-6 row-md-9 row-lg-10"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "space-evenly",
          alignItems: "center",
         width:"100%",
         margin:"0", 

        }}
        >
          <Card onClick={()=>{if(props.fieldsAfected.length === props.fieldList.length ){return;} setCreateFarmer(true); if(createFarmer){return;} if(editFarmer){setEditFarmer(false)}}} className="cardFarmers card-stats" style={{minWidth:"30vh", width:"50vh", background: createFarmer ? "#5db283" : "white", cursor:"pointer"}} >
            <CardBody style={{ padding: "0px 0px 0px 5px" }}>
              <label  className="titleCreateFarmer" style={{ color: createFarmer ? "white" :  "#5db283"}}>
                {t("globals.createFarmer").toUpperCase()}
              </label>
            </CardBody>
          </Card>
          <Card onClick={()=>{if(!props.farms.length){return;} setEditFarmer(true); if(editFarmer){return;} if(createFarmer){setCreateFarmer(false)} }} className="cardFarmers card-stats" style={{minWidth:"30vh", width:"50vh", background: editFarmer ? "#5db283" : "white", cursor:"pointer"}}>
            <CardBody style={{ padding: "0px 0px 0px 5px" }}>
            <label  className="titleCreateFarmer" style={{ color: editFarmer ? "white" :  "#5db283"}}>
            {t("globals.editFarmer").toUpperCase()}
              </label>
            </CardBody>
          </Card>
        </Row>
      </div>
      {createFarmer && (
        <CreateFarmer
          fieldList={props.fieldList}
          fieldsAfected={props.fieldsAfected}
          setCreateFarmer={setCreateFarmer}
          userInfo={props.userInfo}
          fetchFarms={props.fetchFarms}
        />
      )}
      {editFarmer && (
        <EditFarmer
          editFarmer={editFarmer}
          fieldList={props.fieldList}
          farms={props.farms}
          fieldsAfected={props.fieldsAfected}
          userInfo={props.userInfo}
          fetchFarms={props.fetchFarms}
        />
      )}
    </div>
  );
  // return (
  //   <div >
  //     {/* <CreateFarmer  fieldList={props.fieldList}/> */}
  //     <OptionsFarmer/>
  //   </div>
  // )
};

export default Farmer;

import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, Row } from 'reactstrap';

const DeleteFieldModal = (props) => {
  const [t, i18n] = useTranslation("global")
//PROPS A TENER EN CUENTA 
// showDeleteModal
// onDeleteToggle
// fieldName
// confirmDeleteField
// setConfirmDeleteField
// onDeleteField
  return (
    <Modal isOpen={props.showDeleteModal} toggle={() => props.onDeleteToggle()}>
    <div className="modal-header justify-content-center">
      <h4 className="title title-up">{t("globals.confirmDelete")}</h4>
    </div>
    <div className="modal-body">
      <Row>
        <Col sm={12}>
          <label>
            {t("globals.confirmDeleteInfo")}{" "}
            <span
              style={{
                color: "#ff592a",
                fontWeight: "bold",
                cursor: "auto",
              }}
            >
              {props.fieldName}
            </span>{" "}
            ({t("globals.fieldName")}).
          </label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-air-baloon" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={t("globals.confirmDeleteFieldPlaceholder")}
              type="text"
              value={props.confirmDeleteField}
              onChange={(e) => props.setConfirmDeleteField(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
    </div>
    {!props.loadingModal ? (
    <div className="modal-footer">
      <div className="left-side">
        <Button
          className="btn-link"
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={() => props.onDeleteToggle()}
        >
          {t("buttons.noButton")}
        </Button>
      </div>
      <div className="divider" />
      <div className="right-side">
        <Button
          className={
            props.confirmDeleteField === props.fieldName
              ? "btn-link"
              : "btn-link disabled"
          }
          color="primary"
          type="button"
          onClick={() => {
            props.onDeleteField();
            loadingButton()
          }}
        >
          {t("buttons.yesButton")}
        </Button>
      </div>
    </div>
    ) : (
        <div className="text-center py-4">
          <CircularProgress color="primary" />
        </div>
    )}
  </Modal>
  )
}

export default DeleteFieldModal
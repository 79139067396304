
//ADDFIELD

/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";

// reactstrap components
import { Button, Col } from "reactstrap";

// wizard steps
import Step1 from "./settingSteps/chacra.js";
// import Step2 from "./settingSteps/uso.js";
import Step3 from "./settingSteps/polygons.js";
import LoadingOverlay from "react-loading-overlay";
import firebase, { firestore } from "firebase";
import { ToastContainer } from "react-toastr";
import "../../../assets/css/toastr.min.css";
import axios from "axios";
import ModalAddfield from "./components/ModalAddfield.js";
import { withTranslation } from "react-i18next";
import ErrorCreateFieldModal from "./components/ErrorCreateFieldModal.js";
let container;

class AddField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noChacraName: false,
      addingStatus: false,
      errorMsg: "",
      created: false,
      chacraName: "",
      polygonSelect: [],
      maxVertices: false,
      errorGeometry: false,
    };
  }
  closeModal = ()=>{
    this.setState({
      noChacraName: false,
      maxVertices: false,
      errorGeometry: false,
    });
  }
  setDataWizard = (type, data) => {
    if (type === "chacra") {
      this.setState({
        // ...prev,
        chacraName: data,
      });
    }
    if (type === "polygon") {
      this.setState((prev) => ({
        // ...prev.polygonSelect,
        polygonSelect: [...data],
      }));
    }
  };

  showErrorToastr = (msg) => {
    container.success(msg, "Error", {
      closeButton: true,
    });
  };

  onFinishWizard = async () => {
    const allSates = {
      fields: this.state.chacraName,
      polygons: this.state.polygonSelect,
    };
    const headers = {
      headers: {
        // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    if(allSates.fields !== ""){
    this.setState({
      addingStatus: true,
    });

    let vm = this;
    let fieldData = {};
    axios
      .get(`https://agrodigital.io/api/auth/user/`, headers)
      .then(async (res) => {
        let user = res.data;
        fieldData["name"] = allSates.fields;
        fieldData["owner"] = user.id;

        //1.POST CON EL OBJ DEL POLIGONO

        let tempPolygons = allSates.polygons;
        let checkVertices= tempPolygons.some((el)=> el.coordinates[0].length > 500)
        if(checkVertices){
          this.setState({
            addingStatus: false,
            maxVertices: true
          })
          return;
        } else{
          this.setState({
            maxVertices: false,
            errorGeometry: false,
          })
        }
        if (tempPolygons.length > 0) {
          const polygonId = [];

          const polygon = {};
          for (let i = 0; i < tempPolygons.length; i++) {
            const polygonData = tempPolygons[i];

            polygon["name"] = polygonData.name;
            polygon["boundary"] = {
              type: "Polygon",
              coordinates: polygonData.coordinates,
            };
            polygon["owner"] = user.id;
            if (polygonData.id) {
              polygonId.push(polygonData.id);
            } else {
              const response = await axios
                .post(`https://agrodigital.io/api/polygons/`, polygon, headers)
                .then((res) => {
                  //2.LA DEVOLUCION DEL POST ME DA EL ID DE LOS POLIGONOS
                  let polygonInfo = res.data;
                  //3.LE PASO A FIELDATA LA PROPIEDAD POLYGONS Y LE PASO LOS IDS DE LOS POLIGONOS
                  polygonId.push(polygonInfo.id);
                  vm.setState({
                    addingStatus: true,
                  });
                })
                .catch((error)=>{
                  const request = Object.values(error);
                  const message = request[2];
                  if(message.data?.boundary[0]){
                    let errorGeometry = message.data?.boundary[0]
                    if(errorGeometry === "Invalid format: string or unicode input unrecognized as GeoJSON, WKT EWKT or HEXEWKB."){
                      this.setState({
                        errorGeometry:true,
                        addingStatus: true,
                      })
                      return;
                    }
                  }
                })
            }
          }
          fieldData.polygons = polygonId;
        } else {
          fieldData.polygons = [];
        }
        if(this.state.errorGeometry === false){
          let postField = await axios
            .post(`https://agrodigital.io/api/fields/`, fieldData, headers)
            .then((res) => {
              vm.setState({
                addingStatus: false,
                maxVertices:false,
                errorGeometry: false,
                created: true,
                polygonSelect: [],
                chacraName: "",
              });
            })
            .catch((err) => console.log(err));
        } else{
          this.setState({addingStatus: false})
        }
      });
    } else{
      this.setState({
        noChacraName: true
      })
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="content" style={{ marginTop: "25px !important" }}>
          <ToastContainer
            ref={(ref) => (container = ref)}
            className="toast-top-right"
          />
          <Col className="mr-auto ml-auto" md="12">
            <LoadingOverlay
              active={this.state.addingStatus}
              spinner
              text={t("globals.savingData")}
            >
              <ReactWizard
                steps={[
                  {
                    stepName: t("globals.field"),
                    stepIcon: "fi flaticon-location",
                    component: (
                      <Step1
                        t={t}
                        setDataWizard={this.setDataWizard.bind(this)}
                      />
                    ),
                  },
                  // {
                  //     stepName: "USO",
                  //     stepIcon: "fi flaticon-leaf",
                  //     component: Step2,
                  // },
                  {
                    stepName:  t("globals.polygons"),
                    stepIcon: "fi flaticon-cuadrado",
                    component: (
                      <Step3
                        t={t}
                        setDataWizard={this.setDataWizard.bind(this)}
                      />
                    ),
                  },
                ]}
                navSteps
                validate
                title={t("globals.createNewField")}
                description={t("globals.createNewFieldInfo")}
                headerTextCenter
                finishButtonClasses="btn-wd"
                nextButtonClasses="btn-wd"
                previousButtonClasses="btn-wd"
                finishButtonText={t("buttons.finishButton")}
                previousButtonText={t("buttons.previusButton")}
                nextButtonText={t("buttons.afterButton")}
                finishButtonClick={this.onFinishWizard}
              />
            </LoadingOverlay>
          </Col>
        </div>
        {this.state.created && <ModalAddfield />}
        {this.state.noChacraName && <ErrorCreateFieldModal closeModal={this.closeModal.bind(this)} msg={t("globals.createFieldErrorInfo")}/>}
        {this.state.maxVertices && <ErrorCreateFieldModal closeModal={this.closeModal.bind(this)} msg={t("globals.maxVerticesAlert")}/>}
        {this.state.errorGeometry && <ErrorCreateFieldModal closeModal={this.closeModal.bind(this)} msg={t("globals.errorGeometry")}/>}
      </>
    );
  }
}

export default withTranslation("global")(AddField);

import React from "react";
import Spinner from "reactstrap/es/Spinner";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardSubtitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import Modal from "reactstrap/es/Modal";
import Select from "react-select";
import "../assets/css/emptyView.css";
import "../assets/css/map.css";
import { NavLink } from "react-router-dom";
import {
  DataField,
  LabAnalytics,
  Application,
  Diseases,
  Weeds,
  Bugs,
  Fertilization,
  Irrigation,
  Yield,
  Phenology,
  Seeeding,
} from "../Models/Logs.js";
import { LogTypeFilter } from "../Models/LogTypeFilter.js";
import { Log } from "../Models/Logs.js";
import CreateAndEditSeedForm from "./forms/Forms/CreateAndEditSeedForm.js";
import YieldForm from "./forms/Forms/YieldForm.js";
import CreateLogForm from "./forms/CreateLog.js";
import ApplicationForm from "./forms/Forms/ApplicationForm.js";
//error update
import FieldDataForm from "./forms/Forms/FieldDataForm.js";
import FertilizationForm from "./forms/Forms/FertilizationForm.js";

import GenericDateAndSelectForm from "./forms/Forms/GenericDateAndSelectForm.js";
import { ExportToCSV } from "./components/ExportCSV.js";
import NotificationAlert from "react-notification-alert";
import excelIcon from "assets/img/excelIcon.png";
import axios from "axios";
import { withTranslation } from "react-i18next";
import {
  AlertMultiPolygons,
  requestResult,
} from "./forms/Forms/AlertLogsManyPolygons";
//TODO: hacer un provider que contenga los request para obtener los tipos de eventos, variables en manageLogs (limpiar)
class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
      fieldId: null,
      selectedField: null,
      selectedPolygon: null,
      fields: [],
      isLoading: false,
      isLoadingLogs: false,
      logs: [],
      allLogs: [],
      filters: LogTypeFilter.getFilters(props),
      cropDict: {},
      weedsDict: {},
      varietyDict: {},
      showCreateLog: false,
      showFungicideModal: false,
      showInsecticideModal: false,
      showHerbicideModal: false,
      showApplicationModal: false,
      showFieldDataModal: false,
      showFertilizationModal: false,
      showSeedFormModal: false,
      showGenericModal: false,
      showYieldModal: false,
      showDeleteLogModal: false,
      selectedLog: null,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
    };
    this.closeCreateLog = this.closeCreateLog.bind(this);
    this.showCreateLog = this.showCreateLog.bind(this);
    this.onCreateSuccess = this.onCreateSuccess.bind(this);
    this.closeEditLog = this.closeEditLog.bind(this);
    this.userId = `Token ${localStorage.getItem("token")}`;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    let fieldId = params.id;

    this.fetchFields(fieldId);
    this.fetchCrops();
    this.fetchVarieties();
  }

  fetchCrops = async () => {
    let cropDate = await axios.get(
      "https://agrodigital.io/api/crops/",
      this.state.currentUser
    );

    let dataInfo = {};

    cropDate.data.map((item, index) => {
      if (item) {
        let tempData = item;
        dataInfo[item.id] = tempData["name"];
      }
    });

    this.setState({
      cropDict: dataInfo,
    });
  };

  fetchVarieties = async () => {
    let varietyData = await axios.get(
      "https://agrodigital.io/api/varieties/",
      this.state.currentUser
    );
    let dataInfo = {};
    varietyData.data.map((item, index) => {
      if (item) {
        let tempData = item;
        dataInfo[item.id] = tempData["name"];
      }
    });

    this.setState({
      varietyDict: dataInfo,
    });
  };

  fetchFields = async (fieldId) => {
    let vm = this;
    vm.setState({
      isLoading: true,
    });
    let fieldsReference = await axios.get(
      "https://agrodigital.io/api/fields/",
      this.state.currentUser
    );
    if (fieldsReference.status === 200 && fieldsReference.data.length > 0) {
      for (let i = 0; i < fieldsReference.data.length; i++) {
        const fieldInfo = fieldsReference.data[i];
        let fieldData = {};
        fieldData["value"] = fieldInfo.id;
        fieldData["label"] = fieldInfo.name;
        let polygonByField = axios.get(
          `https://agrodigital.io/api/polygons/?field=${fieldInfo.id}`,
          this.state.currentUser
        );
        Promise.all([polygonByField])
          .then((res) => {
            let polygonResponse = res[0].data;
            let polygonInfo = polygonResponse.map((el) => {
              return {
                value: el.id,
                label: el.name,
              };
            });
            fieldData["polygons"] = polygonInfo;
            let selectedFieldId = [...this.state.fields, fieldData].filter(
              (el) => el.value === Number(fieldId)
            );
            vm.setState((prev) => ({
              ...prev,
              fields: [...prev.fields, fieldData],
              isLoading: false,
              selectedField: selectedFieldId.length
                ? selectedFieldId[0]
                : fieldData,
            }));
          })
          .catch((_) => {
            vm.setState({
              fields: [],
              isLoading: false,
              selectedField: null,
            });
          });
      }
    } else {
      vm.setState({
        fields: [],
        isLoading: false,
        selectedField: null,
        selectedPolygon: null,
      });
    }
  };

  fetchFieldsCSV() {
    var polygonsLogsCSV = [];
    let vm = this;
    if (
      this.state.fields &&
      this.state.selectedField &&
      this.state.selectedField.value != ""
    ) {
      this.state.selectedField.polygons.map((polygon, index) => {
        let lastOne = index == vm.state.selectedField.polygons.length - 1;
        this.fetchLogsToExcel(
          vm.state.selectedField.value,
          polygon.value,
          polygon.label,
          lastOne,
          polygonsLogsCSV
        );
      });
    }
  }

  exportAllFieldsToCSV(logsInfo) {
    const {t}= this.props;
    let emptyLogs = Object.values(logsInfo).every((array) => array.length == 0);
    if (!emptyLogs) {
      ExportToCSV.export(
        logsInfo,
        Log.getCSVHeading(t),
        ["id", "date", "type", "value", "text"],
        "all_fields_logs",
        80
      );
    } else {
      this.notify(t("alerts.noLogsExport"));
    }
  }

  //ESTE VA A TRAER LA INFORMACION EXCEL DE TODO UN FIELD CON SUS POLIGONOS
  async fetchLogsToExcel(
    fieldId,
    polygonId,
    polygonName,
    lastPolygon,
    logsInfo
  ) {
    let vm = this;
    let fieldLogs = await axios.get(
      `https://agrodigital.io/api/logs/?field=${fieldId}`,
      this.state.currentUser
    );
    let activeFilters = vm.state.filters
      .filter((logFilter) => logFilter.active)
      .map((logFilterActive) => logFilterActive.types)
      .flat();

    let filterForType = fieldLogs.data.filter((el) =>
      activeFilters.includes(el.type)
    );
    let searchForPolygon = filterForType.filter((el) =>
      el.polygons.includes(polygonId)
    );
    let exportExcelArray = [];
    logsInfo[polygonName] = exportExcelArray;
    this.manageLogs(searchForPolygon, vm, true, exportExcelArray);

    exportExcelArray = exportExcelArray.sort(
      (a, b) =>
        new Date(a.date + "T00:00:00").getTime() -
        new Date(b.date + "T00:00:00").getTime()
    );

    logsInfo[polygonName] = exportExcelArray.map((log) => {
      return log.getCSVData();
    });
    if (lastPolygon) {
      this.exportAllFieldsToCSV(logsInfo);
    }
  }
  // logsInfo[polygonName] = searchForPolygon;
  //array con los filtros elegidos

  //no se para que se usaria pero es la info de un poligono seleccionado

  //VA A TRAER LA INFO DE UN POLIGONO SELECCIONADO
  //LISTO
  exportFieldToCSV() {
    const {t}= this.props;
    if (this.state.logs.length === 0) {
      this.notify(t("alerts.noLogsExport"));
    } else {
      var logsArray = [];
      this.state.logs.map((log) => {
        logsArray.push(log.getCSVData());
      });
      var logsJSON = [];
      let polygonName = `${this.state.selectedPolygon.label}`;
      logsJSON[polygonName] = logsArray;
      ExportToCSV.export(
        logsJSON,
        Log.getCSVHeading(t),
        ["id", "date", "type", "value", "text"],
        polygonName,
        80
      );
    }
  }

  setInfo = (clase) => {
    let logsInfo = [];
    logsInfo.push(clase);
    let sortedLogs = [...this.state.logs, ...logsInfo].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    this.setState({
      logs: sortedLogs,
      isLoadingLogs: false,
    });
  };
  //la setInfo lo que hace es recibir el filtro y utilizar switch (no lo he usado nunca jajaj)
  //dependiendo que filtro obtengo (por ejemplo el usuario pide de la chacra frente el registro de siembra, entonces se ejecuta sowing y asi sucesivamente)

  manageLogs = async (logsRef, vm, exportExcel = false, exportExcelArray) => {
    const {t}= this.props
    logsRef.map((item, _)  => {
      if (item) {
        let logData = item;
        if (logData.id) {
          let type = logData["type"];
          let id = item.id;
          let date = logData["date"];

          switch  (type) {
            case "Sowing":
              let cropName = vm.state.cropDict[logData["crop"]]
                ? vm.state.cropDict[logData["crop"]]
                : logData["crop"];
              let cropVar = vm.state.varietyDict[logData["variety"]]
                ? vm.state.varietyDict[logData["variety"]]
                : logData["variety"];
              let predecessor = vm.state.cropDict[logData["predecessor"]]
                ? vm.state.cropDict[logData["predecessor"]]
                : null;

              if (exportExcel === true) {
                exportExcelArray.push(
                  new Seeeding(
                    t,
                    type,
                    date,
                    id,
                    cropName,
                    cropVar,
                    predecessor,
                    logData["obs"]
                  )
                );
              } else {
                this.setInfo(
                  new Seeeding(
                    t,
                    type,
                    date,
                    id,
                    cropName,
                    cropVar,
                    predecessor,
                    logData["obs"]
                  )
                );
              }
              break;
            case "Phenology":
              axios.get(
                `https://agrodigital.io/api/pheno_event/${logData["value"]}`,
                this.state.currentUser
              ).then((res)=>{
                let phenoData= res.data
                if (exportExcel === true) {
                  exportExcelArray.push(
                    new Phenology(
                      t,
                      type,
                      date,
                      id,
                      logData[(phenoData.name, logData["obs"])],
                      null
                    )
                  );
                } else {
                  this.setInfo(
                    new Phenology(
                      t,
                      type,
                      date,
                      id,
                      phenoData.name,
                      phenoData.id,
                      logData["obs"],
                      phenoData.crop,
                    )
                  );
                }
              });
            break;
            case "Water":
              axios.get(
                `https://agrodigital.io/api/water_event/${logData["value"]}`,
                this.state.currentUser
                ).then((res)=>{
                let waterEvent= res.data
                if (exportExcel === true) {
                  exportExcelArray.push(
                    new Irrigation(
                      t,
                      type,
                      date,
                      id,
                      waterEvent.name,
                      logData["obs"]
                    )
                  );
                } else {
                  this.setInfo(
                    new Irrigation(
                      t,
                      type,
                      date,
                      id,
                      waterEvent.name,
                      waterEvent.id,
                      logData["obs"]
                    )
                  );
                }
              })
              break;
            case "Yield":
              let yieldValue = logData["value"] ? logData["value"] : 0;
              let observation = logData["obs"] ? logData["obs"] : null;
              if (exportExcel === true) {
                exportExcelArray.push(
                  new Yield(t,type, date, id, yieldValue, observation)
                );
              } else {
                this.setInfo(
                  new Yield(t,type, date, id, yieldValue, observation)
                );
              }
              break;
            case "Fertilization":
              let resFertilizer = axios.get(
                `https://agrodigital.io/api/fertilizers/`,
                this.state.currentUser
              );
              Promise.all([resFertilizer]).then((res) => {
                let value = logData["value"] ? logData["value"] : 0;
                let observation = logData["obs"] ? logData["obs"] : null;
                let resNutrients = res[0].data;
                let getNutrients = logData.nutrients;
                let result = [];
                for (let i = 0; i < resNutrients.length; i++) {
                  let igual = false;
                  const compareNutrients = resNutrients[i];
                  for (let j = 0; j < getNutrients.length; j++) {
                    const valueNutrients = getNutrients[j];
                    if (compareNutrients.id === valueNutrients.item) {
                      igual = true;
                      result.push({
                        value: compareNutrients.id,
                        inputValue: valueNutrients.value,
                        label: compareNutrients.name,
                      });
                    }
                  }
                  if (!igual) {
                    result.push(0);
                  }
                }
                let principals = result
                  .slice(0, 3)
                  .sort((a, b) => a.value - b.value);

                let otherVal = result.slice(3).filter((el) => el !== 0);
                if (exportExcel === true) {
                  exportExcelArray.push(
                    new Fertilization(
                      t,
                      type,
                      date,
                      id,
                      principals,
                      otherVal,
                      value,
                      observation
                      // otherName,
                    )
                  );
                } else {
                  this.setInfo(
                    new Fertilization(
                      t,
                      type,
                      date,
                      id,
                      principals,
                      otherVal,
                      value,
                      observation
                      // otherName,
                    )
                  );
                }
              });

              break;
            case "DiseaseReport":
              Promise.all(
                logData.species.map((el) =>
                  axios.get(
                    `https://agrodigital.io/api/diseases/${el}`,
                    this.state.currentUser
                  )
                )
              ).then((res) => {
                let arr = res.map((el) => el.data.specie);
                let observation = logData["obs"] ? logData["obs"] : null;
                if (exportExcel === true) {
                  exportExcelArray.push(
                    new Diseases(t,type, date, id, arr, observation)
                  );
                } else {
                  this.setInfo(new Diseases(t,type, date, id, arr, observation));
                }
              });

              break;
            case "WeedReport":
              Promise.all(
                logData.species.map((el) =>
                  axios.get(
                    `https://agrodigital.io/api/weeds/${el}`,
                    this.state.currentUser
                  )
                )
              ).then((res) => {
                let arr = res.map((el) => el.data.specie);
                let observation = logData["obs"] ? logData["obs"] : null;

                if (exportExcel === true) {
                  exportExcelArray.push(
                    new Weeds(t,type, date, id, arr, observation)
                  );
                } else {
                  this.setInfo(new Weeds(t,type, date, id, arr, observation));
                }
              });
              break;
            case "BugReport":
              Promise.all(
                logData.species.map((el) =>
                  axios.get(
                    `https://agrodigital.io/api/bugs/${el}`,
                    this.state.currentUser
                  )
                )
              ).then((res) => {
                let arr = res.map((el) => el.data.specie);
                let observation = logData["obs"] ? logData["obs"] : null;

                if (exportExcel === true) {
                  exportExcelArray.push(
                    new Bugs(t,type, date, id, arr, observation)
                  );
                } else {
                  this.setInfo(new Bugs(t,type, date, id, arr, observation));
                }
              });

              break;
            case "FieldReport":
              axios
                .get(
                  `https://agrodigital.io/api/field_var/${logData.variable}`,
                  this.state.currentUser
                )
                .then((res) => {
                  let variable = res.data.name;
                  let valueData = logData["value"] ? logData["value"] : 0;
                  let unit = res.data.unit;
                  let observation = logData["obs"] ? logData["obs"] : null;

                  if (exportExcel === true) {
                    exportExcelArray.push(
                      new DataField(
                        t,
                        type,
                        date,
                        id,
                        variable,
                        valueData,
                        unit,
                        observation
                      )
                    );
                  } else {
                    this.setInfo(
                      new DataField(
                        t,
                        type,
                        date,
                        id,
                        variable,
                        valueData,
                        unit,
                        observation
                      )
                    );
                  }
                });
              break;
            case "Application":
              Promise.all(
                logData.products.map((el) =>
                  axios.get(
                    `https://agrodigital.io/api/agroq/${el.item}`,
                    this.state.currentUser
                  )
                )
              )
                .then((resData) => {
                  let herb = [];
                  let typeApp = resData[0].data.type;
                  let observation = logData["obs"];
                  logData.products.map((prod) => {
                    resData.map((res) => {
                      if (prod.item === res.data.id) {
                        let data = {};
                        data["name"] = res.data.active.join(" + ");
                        data["dose"] = prod.value;
                        data["value"] = res.data.id;
                        herb.push(data);
                      }
                    });
                  });
                  if (exportExcel === true) {
                    exportExcelArray.push(
                      new Application(
                        t,
                        type,
                        date,
                        id,
                        herb,
                        typeApp,
                        observation
                      )
                    );
                  } else {
                    this.setInfo(
                      new Application(
                        t,
                        type,
                        date,
                        id,
                        herb,
                        typeApp,
                        observation
                      )
                    );
                  }
                })
                .catch((err) => err);
              break;
            case "LabReport":
              Promise.all(
                logData.results.map((el) =>
                  axios.get(
                    `https://agrodigital.io/api/lab_var/${el.item}`,
                    this.state.currentUser
                  )
                )
              ).then((res) => {
                let data = {};
                let chemicalsValue = [];
                let obs = logData["obs"];
                logData.results.map((results) => {
                  res.map((resData) => {
                    if (results.item === resData.data.id) {
                      let valueForm = {};
                      data[resData.data.name] = results.value;
                      valueForm.value = resData.data.id;
                      valueForm.label = resData.data.name;
                      valueForm.inputValue = results.value;

                      chemicalsValue.push(valueForm);
                    }
                  });
                });
                if (exportExcel === true) {
                  exportExcelArray.push(
                    new LabAnalytics(t,type, date, id, data, chemicalsValue, obs)
                  );
                } else {
                  this.setInfo(
                    new LabAnalytics(t,type, date, id, data, chemicalsValue, obs)
                  );
                }
              });
              break;
            default:
              break;
          }
        }
      }
    });
  };

  //Acá se van a realizar las peticiones dependiento lo que elija el usuario
  fetchLogs = async () => {
    if (this.state.selectedPolygon && this.state.selectedField) {
      this.setState({
        isLoadingLogs: true,
        logs: [],
      });
      let vm = this;
      //se cargan los filtros en un array y se van ejecutando en un for loop
      let activeFilters = vm.state.filters
        .filter((logFilter) => logFilter.active)
        .map((logFilterActive) => logFilterActive.types)
        .flat();
      if (activeFilters.length > 0) {
        const polId = this.state?.selectedPolygon;
        for (let i = 0; i < activeFilters.length; i++) {
          const filter = activeFilters[i];
          await axios
            .get(
              `https://agrodigital.io/api/logs/?field=${this.state.selectedField.value}&type=${filter}`,
              this.state.currentUser
            )
            .then((logsReference) => {
              let logsRef = [];
              logsReference?.data?.map((el) => {
                el.polygons.map((pol) => {
                  if (polId.value === pol) {
                    logsRef.push(el);
                  }
                });
              });
              if (logsRef.length > 0) {
                vm.setState((prev) => ({
                  allLogs: [...prev.allLogs, ...logsReference.data],
                }));
                vm.manageLogs(logsRef, vm);
              }

              if(activeFilters.length - 1 === i){
                vm.setState({
                  isLoadingLogs: false,
                });
              }
            })
            .catch((_) => {
              vm.setState({
                logs: [],
                isLoadingLogs: false,
              });
            });
        }
      }
    }
  };

  changeRanch = (value) => {
    this.setState(
      {
        selectedField: value,
        selectedPolygon: value.polygons[0],
      },
      () => {
        this.fetchLogs();
      }
    );
  };

  changePolygon = (value) => {
    this.setState(
      {
        selectedPolygon: value,
      },
      () => {
        this.fetchLogs();
      }
    );
  };

  activeFilterClicked(filter) {
    filter.active = !filter.active;
    this.setState({}, () => {
      this.fetchLogs();
    });
  }

  closeCreateLog() {
    this.setState({
      showCreateLog: false,
    });
  }

  showCreateLog() {
    this.setState({
      showCreateLog: true,
    });
  }

  onCreateSuccess() {
    this.setState(
      {
        showCreateLog: false,
      },
      () => {
        this.fetchLogs();
      }
    );
  }

  onDeleteLog = async (log) => {
    // let searchLog = this.state.allLogs.filter((el) => el.id === log.id)[0];
    // if (searchLog.polygons.length > 1) {
    let info = {
      fieldId: this.state.selectedField.value,
      polygonSelected: [this.state.selectedPolygon.value],

      date: log.date,
      tokenUser: this.state.currentUser,
      type: log.type,
    };
    let result = await requestResult(info);
    this.setState({
      selectedLog: log,
      showDeleteLogModal: true,
      alertMoreThanOnePolygon: result?.length > 1 && true,
      nameOfAllPolygons: result,
    });
  };

  onEditLog = (log) => {
    switch (log.type) {
      case "Application":
        this.setState({
          showApplicationModal: true,
          showFieldDataModal: false,
          showFertilizationModal: false,
          showSeedFormModal: false,
          showGenericModal: false,
          showYieldModal: false,
          selectedLog: log,
        });
        return;
      case "WeedReport":
      case "DiseaseReport":
      case "BugReport":
      case "FieldReport":
        this.setState({
          showApplicationModal: false,
          showFieldDataModal: true,
          showFertilizationModal: false,
          showSeedFormModal: false,
          showGenericModal: false,
          showYieldModal: false,
          selectedLog: log,
        });
        return;
      case "Fertilization":
        this.setState({
          showApplicationModal: false,
          showFieldDataModal: false,
          showFertilizationModal: true,
          showSeedFormModal: false,
          showGenericModal: false,
          showYieldModal: false,
          selectedLog: log,
        });
        return;
      case "Water":
      case "Phenology":
      case "LabReport":
        this.setState({
          showApplicationModal: false,
          showFieldDataModal: false,
          showFertilizationModal: false,
          showSeedFormModal: false,
          showGenericModal: true,
          showYieldModal: false,
          selectedLog: log,
        });
        return;
      case "Sowing":
        this.setState({
          showApplicationModal: false,
          showFieldDataModal: false,
          showFertilizationModal: false,
          showSeedFormModal: true,
          showGenericModal: false,
          showYieldModal: false,
          selectedLog: log,
        });
        return;
      case "Yield":
        this.setState({
          showApplicationModal: false,
          showFieldDataModal: false,
          showFertilizationModal: false,
          showSeedFormModal: false,
          showGenericModal: false,
          showYieldModal: true,
          selectedLog: log,
        });
        return;
      default:
        break;
    }
  };

  closeEditLog(editSuccess = false) {
    if (this.state.selectedLog) {
      this.setState(
        {
          showFungicideModal: false,
          showInsecticideModal: false,
          showHerbicideModal: false,
          showApplicationModal: false,
          showFieldDataModal: false,
          showFertilizationModal: false,
          showSeedFormModal: false,
          showGenericModal: false,
          selectedLog: null,
        },
        () => {
          if (editSuccess) this.fetchLogs();
        }
      );
      return;
    }
  }

  deleteSelectedLog() {
    let vm = this;
    if (this.state.selectedLog) {
      this.setState({
        selectedLog: null,
        showDeleteLogModal: false,
      });
      axios
        .delete(
          `https://agrodigital.io/api/logs/${this.state.selectedLog.id}`,
          this.state.currentUser
        )
        .then(() => {
          vm.fetchLogs();
        });
    }
  }

  notify = (msg) => {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div style={{ zIndex: 10001 }}>
          <div>{msg}</div>
        </div>
      ),
      type: "success",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 4,
    };
    this.refs.notify.notificationAlert(options);
  };

  render() {
    const { t } = this.props;
    const logDeleteModal = (
      <Modal isOpen={this.state.showDeleteLogModal}>
        <div
          className="modal-header justify-content-center"
          style={{ border: "none" }}
        >
          <h5 className="title title-up">Desea eliminar el registro?</h5>
        </div>
        {this.state.alertMoreThanOnePolygon &&
          this.state.selectedLog?.id &&
          this.state.nameOfAllPolygons.length > 1 && (
            <AlertMultiPolygons
              nameOfAllPolygons={this.state.nameOfAllPolygons}
            />
          )}
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className={"btn-link"}
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                this.setState({
                  showDeleteLogModal: false,
                  selectedLog: null,
                  alertMoreThanOnePolygon: false,
                  nameOfAllPolygons: [],
                });
              }}
            >
              No
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              color="primary"
              className={"btn-link"}
              type="button"
              onClick={() => {
                this.deleteSelectedLog();
              }}
            >
              Si
            </Button>
          </div>
        </div>
      </Modal>
    );

    return (
      <>
        <div className="content">
          {this.state.isLoading ? (
            <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
              <Spinner
                style={{ marginTop: "100px" }}
                animation="border"
                variant={"primary"}
              ></Spinner>
            </div>
          ) : this.state.fields.length == 0 ? (
            <div className="content">
              <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <Card className="card-testimonial">
                    <CardBody>
                      <div className="icon icon-primary">
                        <i className="fa fa-laptop-code" />
                      </div>
                      <CardTitle tag="h6">Oryzativa</CardTitle>
                      <CardSubtitle className="mb-2 text-muted">
                        {t("cardFielNotFound")}
                      </CardSubtitle>
                      <div
                        className="card-avatar"
                        style={{ paddingTop: "1rem" }}
                      >
                        <a>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/oryzativa_logo.png")}
                          />
                        </a>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="empty-view-footer">
                        <Button
                          className={"btn-link"}
                          color="success"
                          tag={NavLink}
                          to="/admin/cropmap/add-field"
                        >
                          t{"buttons.addFieldButton"}
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Row>
                <Card className="card-stats" style={{ width: "100%" }}>
                  <CardBody>
                    <Col>
                      <Row
                        style={{ alignItems: "center", marginBottom: "8px" }}
                      >
                        <div style={{ width: "85px" }}>
                          <h6>{t("globals.field")}</h6>
                        </div>
                        <div style={{ width: "25%", paddingLeft: "1rem" }}>
                          <Select
                            name="ranch"
                            className="react-select"
                            placeholder={t("globals.selectField")}
                            classNamePrefix="react-select"
                            value={this.state.selectedField}
                            onChange={(value) => this.changeRanch(value)}
                            options={this.state.fields}
                          />
                        </div>
                        <button
                          style={{
                            padding: "0px",
                            border: "none",
                            marginLeft: "0.5rem",
                            outline: "none",
                          }}
                          onClick={() => this.fetchFieldsCSV()}
                        >
                          <img src={excelIcon} />
                        </button>
                      </Row>
                      <Row style={{ alignItems: "center" }}>
                        <div style={{ width: "85px" }}>
                          <h6>{t("globals.polygon")}</h6>
                        </div>
                        <div style={{ width: "25%", paddingLeft: "1rem" }}>
                          {
                            <Select
                              name="poligon"
                              className="react-select"
                              placeholder={t("globals.selectPolygon")}
                              classNamePrefix="react-select"
                              value={this.state.selectedPolygon}
                              onChange={(value) => this.changePolygon(value)}
                              options={this.state.selectedField.polygons}
                            />
                          }
                        </div>
                      </Row>
                    </Col>
                    <div>
                      {this.state.filters.slice(0, 4).map((filter, index) => {
                        return (
                          <button
                            key={index}
                            className={
                              filter.active ? "filterEnabled" : "filterDisabled"
                            }
                            onClick={() => this.activeFilterClicked(filter)}
                          >
                            {filter.active ? "✓" : "✖"} {filter.label}
                          </button>
                        );
                      })}
                    </div>
                    <div>
                      {this.state.filters.slice(4, 8).map((filter, index) => {
                        return (
                          <button
                            key={index}
                            className={
                              filter.active ? "filterEnabled" : "filterDisabled"
                            }
                            onClick={() => this.activeFilterClicked(filter)}
                          >
                            {filter.active ? "✓" : "✖"} {filter.label}
                          </button>
                        );
                      })}
                    </div>
                    {this.state.isLoadingLogs ? (
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner
                          style={{ marginTop: "100px" }}
                          animation="border"
                          variant={"primary"}
                        ></Spinner>
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "600px",
                          overflow: "auto!important",
                        }}
                      >
                        <Table
                          borderless
                          responsive
                          style={{
                            paddingBottom: "10px",
                            overflow: "auto!important",
                            maxHeight: "570px !important",
                            height: "570px !important",
                          }}
                        >
                          <thead>
                            <tr>
                              <th style={{ fontSize: "1.1rem" }}>
                                {t("globals.logs")}
                                <Button
                                  style={{ marginLeft: "0.5rem" }}
                                  className="btn-icon"
                                  color="success"
                                  size="sm"
                                  title={t("globals.createLog")}
                                  type="button"
                                  onClick={() => {
                                    this.showCreateLog();
                                  }}
                                >
                                  <span className="btn-label">
                                    <i className="fa fa-plus" />
                                  </span>
                                </Button>
                                <button
                                  style={{
                                    padding: "0px",
                                    border: "none",
                                    marginLeft: "0.5rem",
                                    outline: "none",
                                  }}
                                  onClick={() => this.exportFieldToCSV()}
                                >
                                  <img src={excelIcon} />
                                </button>
                              </th>
                            </tr>
                          </thead>
                          {this.state.logs.length === 0 ? (
                            <div className="content">
                              <Row
                                style={{
                                  marginLeft: "2px",
                                  marginRight: "2px",
                                }}
                              >
                                <Col sm={12} md={{ size: 6, offset: 3 }} lg={8}>
                                  <Card className="card-testimonial">
                                    <CardBody>
                                      <div className="icon icon-primary">
                                        <i className="fa fa-laptop-code" />
                                      </div>
                                      <CardTitle tag="h6">
                                        {t("globals.logs")}
                                      </CardTitle>
                                      <CardSubtitle className="mb-2 text-muted">
                                        {t("noEventsPolygon")}
                                      </CardSubtitle>
                                      <div
                                        className="card-avatar"
                                        style={{ paddingTop: "1rem" }}
                                      >
                                        <a>
                                          <img
                                            alt="..."
                                            className="img"
                                            src={require("assets/img/oryzativa_logo.png")}
                                          />
                                        </a>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <tbody>
                              {this.state.logs &&
                                this.state.logs.map((log, logIndex) => {
                                  return (
                                    <tr>
                                      <td> {log.render()} </td>
                                      <td className="text-center">
                                        <Button
                                          className="btn-icon"
                                          color="success"
                                          size="sm"
                                          title={"Edit"}
                                          type="button"
                                          onClick={() => {
                                            this.onEditLog(log);
                                          }}
                                        >
                                          <i className="fa fa-edit" />
                                        </Button>
                                        <Button
                                          className="btn-icon"
                                          color="danger"
                                          size="sm"
                                          title={"Delete"}
                                          type="button"
                                          onClick={() => this.onDeleteLog(log)}
                                        >
                                          <i className="fa fa-trash-o" />
                                        </Button>{" "}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          )}
                        </Table>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Row>
            </div>
          )}
        </div>
        {/* ESTO ES PARA EDITAR LOS REGISTROS (BOTON EDITAR SE EJECUTAN) */}
        <NotificationAlert ref="notify" />
        {this.state.selectedField && this.state.selectedField.polygons && (
          <CreateLogForm
            isOpen={this.state.showCreateLog}
            onClose={this.closeCreateLog}
            onCreateSuccess={this.onCreateSuccess}
            polygons={this.state.selectedField.polygons}
            fieldId={this.state.selectedField.value}
          ></CreateLogForm>
        )}

        {this.state.showApplicationModal &&
          this.state.selectedField &&
          this.state.selectedLog &&
          this.state.selectedPolygon && (
            <ApplicationForm
              t={t}
              isOpen={this.state.showApplicationModal}
              selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
              fieldId={this.state.selectedField.value}
              onClose={this.closeEditLog}
              selectedLog={this.state.selectedLog}
            ></ApplicationForm>
          )}
        {this.state.showFieldDataModal &&
          this.state.selectedField &&
          this.state.selectedLog &&
          this.state.selectedPolygon && (
            <FieldDataForm
              t={t}
              isOpen={this.state.showFieldDataModal}
              selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
              fieldId={this.state.selectedField.value}
              onClose={this.closeEditLog}
              selectedLog={this.state.selectedLog}
            ></FieldDataForm>
          )}
        {this.state.showFertilizationModal &&
          this.state.selectedField &&
          this.state.selectedLog &&
          this.state.selectedPolygon && (
            <FertilizationForm
              t={t}
              isOpen={this.state.showFertilizationModal}
              selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
              fieldId={this.state.selectedField.value}
              onClose={this.closeEditLog}
              selectedLog={this.state.selectedLog}
            ></FertilizationForm>
          )}
        {this.state.showGenericModal &&
          this.state.selectedField &&
          this.state.selectedLog &&
          this.state.selectedPolygon && (
            <GenericDateAndSelectForm
              t={t}
              isOpen={this.state.showGenericModal}
              selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
              fieldId={this.state.selectedField.value}
              onClose={this.closeEditLog}
              selectedLog={this.state.selectedLog}
            ></GenericDateAndSelectForm>
          )}
        {this.state.showSeedFormModal &&
          this.state.selectedField &&
          this.state.selectedLog &&
          this.state.selectedPolygon && (
            <CreateAndEditSeedForm
              t={t}
              isOpen={this.state.showSeedFormModal}
              selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
              fieldId={this.state.selectedField.value}
              onClose={this.closeEditLog}
              selectedLog={this.state.selectedLog}
            ></CreateAndEditSeedForm>
          )}
        {this.state.showYieldModal &&
          this.state.selectedField &&
          this.state.selectedLog &&
          this.state.selectedPolygon && (
            <YieldForm
              t={t}
              isOpen={this.state.showYieldModal}
              selectedPolygons={{ [this.state.selectedPolygon.value]: {} }}
              fieldId={this.state.selectedField.value}
              onClose={this.closeEditLog}
              selectedLog={this.state.selectedLog}
            ></YieldForm>
          )}
        {this.state.showDeleteLogModal &&
          this.state.selectedField &&
          this.state.selectedLog &&
          this.state.selectedPolygon &&
          logDeleteModal}
      </>
    );
  }
}
export default withTranslation("global")(Logs);

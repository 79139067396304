import React, { useCallback, useEffect, useReducer, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import iconDone from "../../../../assets/img/done.png";
import backArrowIcon from "../../../../assets/img/backArrowIcon.png";
import deleteIcon from "../../../../assets/img/deleteIcon.png";
import doneEditFarm from "../../../../assets/img/doneEditFarm.png";
import editFarmIcon from "../../../../assets/img/editFarmIcon.png";
import produce from "immer";
import axios from "axios";
import "../../../../assets/css/farmer.css"
import { Card, CircularProgress } from "@material-ui/core";
import { Button, CardBody, Col, Row } from "reactstrap";
import MapFarmers from "./MapFarmers";
import { useTranslation } from "react-i18next";
//TODO: EN CASO DE NOMBRES LARGOS ??
//TODO: NO HAYA CHACRAS IGUALES EN LAS DOS LISTAS (MISMO ID)
//FUNCION SE EJECUTA LUEGO DE ARRASTRAR EL ELEMENTO
const dragReducer = produce((draft, action) => {
  if(action.type !== "RESET"){

    if(action.toIndex > draft["farmers"].length - 1 && action.to === "farmers"){
      action.to = draft["farmers"][draft["farmers"].length - 1]?.id
      action.toIndex = draft["farmers"].length - 1
    }
    if (action.type === "DELETE") {
      let farmIndex = draft["farmers"].findIndex(
        (farm) => farm.id === action.farm.id
      );
      draft["farmers"].splice(farmIndex, 1);
      draft["fieldList"] = [
        ...JSON.parse(JSON.stringify(draft["fieldList"])),
        ...action.farm.fields,
      ];
      draft["fieldsModificate"] = [];
    }

    if (action.type === "EDITNAME") {
    let fieldsId = draft["farmers"].map((farm) => Number(farm.id));
    let searchPosition = fieldsId.indexOf(Number(action.id));
    draft["farmers"][searchPosition].name = action.value;
    let searchInFieldsModificate= draft["fieldsModificate"].findIndex((el)=> el.id === draft["farmers"][searchPosition].id)
    if(searchInFieldsModificate >= 0){
      let farmerNewData= draft["farmers"][searchPosition]
      draft["fieldsModificate"].splice(searchPosition, 1)
      draft["fieldsModificate"].splice(searchPosition, 0, farmerNewData)
    } else {
      let farmerNewData= draft["farmers"][searchPosition]
      draft["fieldsModificate"].push(farmerNewData)
    }
  }
  
  //desplazamiento misma columna
  if(action.type ==="COLUMN"){
    let searchIndexFarmerMoved= draft["farmers"].findIndex((el)=> el.id === Number(action.to))
    let searchIndexFarmerRemoved= draft["farmers"].findIndex((el)=> el.id === Number(action.from))
    let fieldFarmerInfo= draft["farmers"][searchIndexFarmerRemoved].fields.filter((el)=> Number(el.id) === Number(action.draggableId))[0]
  
   if(searchIndexFarmerMoved >= 0){
     let farmerNewData= draft["farmers"][searchIndexFarmerMoved]
     draft["fieldsModificate"].splice(searchIndexFarmerMoved, 1)
     draft["fieldsModificate"].splice(searchIndexFarmerMoved, 0, farmerNewData)
   } else{
     let fieldNewData= draft["farmers"][searchIndexFarmerMoved]
     draft["fieldsModificate"].push(fieldNewData)
   }
   if(searchIndexFarmerRemoved >= 0){
     let fieldNewData= draft["farmers"][searchIndexFarmerRemoved]
     draft["fieldsModificate"].splice(searchIndexFarmerRemoved, 1)
     draft["fieldsModificate"].splice(searchIndexFarmerRemoved, 0, fieldNewData)
   } else {
     let fieldNewData= draft["farmers"][searchIndexFarmerRemoved]
     draft["fieldsModificate"].push(fieldNewData)
   }
  let searchDuplicateIdInField = draft["farmers"][searchIndexFarmerMoved].fields.filter((el)=> Number(el.id) === Number(fieldFarmerInfo.id))
  if(!searchDuplicateIdInField.length){
   let [searchElementRemoved] = draft["farmers"][searchIndexFarmerRemoved].fields.splice(action.fromIndex, 1)
   draft["farmers"][searchIndexFarmerMoved].fields.splice(action.toIndex, 0, searchElementRemoved)
  }
  
  }
  //desplazamiento IZQ a DER
  if (action.type === "MOVE") {
    if (action.to === "farmers") {
      return;
    }
    let searchIndexFarmer= draft["farmers"].findIndex((el)=> el.id === Number(action.to))
    if(searchIndexFarmer >= 0){
      let fieldNewData= draft["farmers"][searchIndexFarmer]
      draft["fieldsModificate"].splice(searchIndexFarmer, 1)
      draft["fieldsModificate"].splice(searchIndexFarmer, 0, fieldNewData)
    } else{
      let fieldNewData= draft["fieldList"][searchIndexFarmer]
      draft["fieldsModificate"].push(fieldNewData)
    }
    let fieldSelected = draft["fieldList"][action.fromIndex]
    if(fieldSelected?.polygons?.length > 0 ){
    let [removeData] = draft["fieldList"].splice(action.fromIndex, 1)
    draft["farmers"][searchIndexFarmer]?.fields.splice(action.toIndex, 0, removeData);
    }
  }
  //desplazamiento DER a IZQ
  if (action.type === "REMOVE") {
    let searchFieldModificate= draft["fieldsModificate"].findIndex((el)=> el.id === Number(action.from))
      let searchIndexFarmerRemove= draft["farmers"].findIndex((el)=> el.id === Number(action.from))
      if(searchFieldModificate >= 0){
        let farmerNewData= draft["farmers"][searchIndexFarmerRemove]
        draft["fieldsModificate"].splice(searchFieldModificate, 1)
        draft["fieldsModificate"].splice(searchFieldModificate, 0, farmerNewData)
      } else{
        let farmerNewData= draft["farmers"][searchIndexFarmerRemove]
        draft["fieldsModificate"].push(farmerNewData)
      }
      let [searchElementRemoved] = draft["farmers"][searchIndexFarmerRemove].fields.splice(action.fromIndex, 1)
      draft["fieldList"].splice(action.toIndex, 0, searchElementRemoved)
  }
  } else {
    draft["fieldsModificate"] = []
    draft["loading"] = action.value
  }
  
});
const EditFarmer = (props) => {
  const [t, i18n] = useTranslation("global")
  const [state, dispatch] = useReducer(dragReducer, {
    //acá se va a copiar la nueva lista modificada
    fieldList: props.fieldList.filter((el)=> !props.fieldsAfected.includes(el.id)),
    //acá se va a copiar la nueva lista modificada
    //GET de los farmer list
    farmers:props.farms,
    fieldsModificate: [],
    loading: false,
  });
  const [fieldEditable, setFieldEditable] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [selectedField, setSelectedField] = useState(null);
  const tokenUser = {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
  //MAP BOUNDS
  const centerLocation = { lat: -32, lng: -57 };
  const zoom = 14;
  const [boundsData, setBoundsData] = useState([]);
  const [mouseOnMapStatus, setMouseOnMapStatus] = useState(false);
  const [isFarm, setIsFarm] = useState(false);
  const [hoverFarmerNumber, setHoverFarmerNumber] = useState(-1);
  const [hoverFieldNumber, setHoverFieldNumber] = useState(-1);
  const [mapShow, setMapShow] = useState(false);
  
  const scrollToElement = (id) => {
    let searchField = state.fieldList.filter((el)=> el.polygons.some((pol)=> pol.id === id))
    
    if(searchField.length){
      const element = document.getElementById('focusElement_' + searchField[0].id);
      element.classList += " hoverFocusElement"
      
       element.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      setTimeout(()=>{
        element.classList.remove('hoverFocusElement');
      },1500)
    } else{
      let listFarmerFields = []
      for (let i = 0; i < state.farmers.length; i++) {
        const farms = state.farmers[i];
        let searchFieldId= farms.fields.filter((el)=> el.polygons.some((pol)=> pol.id === id))
        if(searchFieldId.length){
          listFarmerFields.push(...searchFieldId)
        }
      }
      if(listFarmerFields.length){
        const element = document.getElementById('focusElement_' + listFarmerFields[0].id);
        element.classList += " hoverFocusElement"
      
       element.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      setTimeout(()=>{
        element.classList.remove('hoverFocusElement');
      },1500)
      }
    }
  };

  const onDragEnd = useCallback((result) => {
    if (result.reason === "DROP") {
      if (!result.destination) {
        return;
      }
      //COLUMNA IZQUIERDA HACIA DERECHA
      if (result.source.droppableId === "fieldList") {
        //si solo se mueve por la misma columna que no haga nada (innecesario)
        if (result.destination.droppableId === "fieldList") {
          return;
        }
      
        if(result.destination.index === state["fieldList"].length){
          result.destination.index = result.destination.index - 1
        }
        dispatch({
          type: "MOVE",
          draggableId: result.draggableId,
          from: result.source.droppableId,
          to: result.destination.droppableId,
          fromIndex: result.source.index,
          toIndex: result.destination.index,
        });
      } else{ 
        //DER A IZQ
        if(result.destination.droppableId === "fieldList"){
          dispatch({
            type: "REMOVE",
            draggableId: result.draggableId,
            from: result.source.droppableId,
            to: result.destination.droppableId,
            fromIndex: result.source.index,
            toIndex: result.destination.index,
          });
        } else {
          dispatch({
            type: "COLUMN",
            draggableId: result.draggableId,
            from: result.source.droppableId,
            to: result.destination.droppableId,
            fromIndex: result.source.index,
            toIndex: result.destination.index,
          });
        }
      }
    }
  }, []);


  const editFarmerName = (farmerId) => {
    dispatch({
      type: "EDITNAME",
      id: farmerId,
      value: fieldName,
    });

    setFieldEditable(false);
    setSelectedField(null);
    setTimeout(() => {
      setFieldName("");
    }, 400);
  };

 const saveData = async () => {
  let farmersAfecteds = state.fieldsModificate
   if (farmersAfecteds.length > 0) {
     for (let i = 0; i < farmersAfecteds.length; i++) {
       const farmer = farmersAfecteds[i];
      //  BODY
      let body= {
        name:farmer.name,
        owner: farmer.owner,
        fields: farmer.fields.map((el)=>el.id).filter((el)=> el != undefined)
      }
       await axios.put(
         `https://agrodigital.io/api/farms/${farmer.id}/`,
         body,
         tokenUser
       ).then(()=>{
        if(farmersAfecteds.length - 1 === i){
          dispatch({ type: "RESET", value:true });
          props.fetchFarms()
        }
       });
     }
   }
 };

 const deleteFarm = async(farm)=>{
  await axios.delete(`https://agrodigital.io/api/farms/${farm.id}/`, tokenUser).then(()=>{
    dispatch({type:"DELETE", farm: farm})
    props.fetchFarms()
  })
 }
 useEffect(()=>{
  if(state.loading === true){
    dispatch({type:"RESET", value: false})
  }
},[state.loading])
useEffect(()=>{
  setBounds(props.fieldList, false)
},[])

const validateMapStatus = () => {
  if (
    mouseOnMapStatus === false &&
    hoverFieldNumber === -1 &&
    hoverFarmerNumber === -1
  ) {
    setBounds(props.fieldList, false);
  }
};
const setBounds = (data, status) => {
  setMapShow(false)
  let left = -180;
  let top = -90;
  let right = 180;
  let bottom = 90;
  let result = [
    [centerLocation.lng, centerLocation.lat],
    [centerLocation.lng, centerLocation.lat],
  ];
    let fieldList = status ? data?.fields : data;
    if (fieldList) {
      setIsFarm(status)
      for (let i = 0; i < fieldList.length; i++) {
        let polygons = fieldList[i].polygons;
        if (polygons.length) {
          for (let j = 0; j < polygons.length; j++) {
            let coords = polygons[j].boundary.coordinates;
            if (coords) {
              for (let k = 0; k < coords.length; k++) {
                let coordinates = coords[k];
                for (let l = 0; l < coordinates.length; l++) {
                  let latitude = coordinates[l][1];
                  let longitude = coordinates[l][0];
                  if (latitude > top) {
                    top = coordinates[l][1];
                  }

                  if (latitude < bottom) {
                    bottom = coordinates[l][1];
                  }

                  if (longitude > left) {
                    left = longitude;
                  }

                  if (longitude < right) {
                    right = longitude;
                  }
                }
              }
            }
          }
        }
      }
      result = [
        [top, left],
        [bottom, right],
      ];
      setMapShow(true);
      setBoundsData(result);
    }
}
//fieldIdex
const MouseRowOver = (
  selectedElement,
  fieldIndex,
  farmerIndex = -1,
  isFarm
) => {
  setHoverFarmerNumber(farmerIndex);
  setHoverFieldNumber(fieldIndex);
  if(isFarm){
    setBounds(selectedElement, isFarm);
  } else {
    setBounds([selectedElement], isFarm);
  }
};

const MouseRowOut = () => {
  setHoverFarmerNumber(-1);
  setHoverFieldNumber(-1);
  setTimeout(function () {
    if (hoverFieldNumber === -1 && hoverFarmerNumber === -1) {
      validateMapStatus();
    }
  }, 6000);
};

const MouseOnMapStatus = (bStatus = false) => {
  setMouseOnMapStatus(bStatus);
  if (bStatus === false) {
    setTimeout(function () {
      validateMapStatus();
    }, 6000);
  }
};

  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the fieldList look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#f6f6f6" : "white",
    borderRadius:"10px",
    marginBottom:"10px",
    border:"1px solid #e2e2e2",
    listStyle: "none",
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  function onFocus() {
    document.getElementById("isFocus").focus();
}
  return (
    <div
      style={{
        background: "#f4f3ef",
        width: "100%",
      }}
    >
      {state.loading === true ? (
        <div className="text-center py-4">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <div
            style={{
              marginLeft: "50px",
              marginRight: "50px",
              fontSize: "revert",
            }}
          >
           {t("globals.editFarmerInfo")}
          </div>
          <div class="rowDragEditFarm">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="fieldList"
                type="EDITFARMERS"
                direction="vertical"
                ignoreContainerClipping={true}
                isCombineEnabled={true}
              >
                {(provided, snapshot) => (
                  <div className="columnFarmDragLeft">
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <div className="items-container scrollContainer">
                        {state.fieldList?.map((field, fieldIndex) => (
                          <Draggable
                            key={field.id}
                            draggableId={`${field.id}`}
                            index={fieldIndex}
                          >
                            {(provided, snapshot) => (
                              <div
                              id={`focusElement_${field.id}`}
                              onMouseOver={() =>{
                                field.polygons.length > 0 &&
                                MouseRowOver(
                                  field,
                                  fieldIndex,
                                  -1,
                                  false
                                )}
                              }
                              onMouseOut={() => MouseRowOut()}
                                className="editFarm-item-container"
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <p style={{ margin: "0" }}>{field.name}</p>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
              <Droppable
                droppableId="farmers"
                type="EDITFARMERS"
                direction="vertical"
                ignoreContainerClipping={true}
                isCombineEnabled={true}
              >
                {(provided) => (
                  <div 
                  className="columnFarmDragRight"
                  >
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state.farmers?.map((farm, farmIndex) => {
                      let isEdit=  selectedField === Number(farm.id) &&
                      fieldEditable ? false : true
                      let focus=  selectedField === Number(farm.id) &&
                      fieldEditable ? "isFocus" : ""
                      return(
                      <Draggable
                        draggableId={`${farm.id}`}
                        index={farmIndex}
                        key={farm.id}
                      >
                        {(provided) => (
                          <div
                            onMouseOver={() =>
                              MouseRowOver(farm, farmIndex, -1, true)
                            }
                            onMouseOut={() => MouseRowOut()}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Droppable
                              droppableId={`${farm.id}`}
                              type="EDITFARMERS"
                              direction="vertical"
                              ignoreContainerClipping={true}
                              isCombineEnabled={true}
                            >
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <div className="editFarm-store-container" style={{background:"#5db283", border:selectedField === Number(farm.id) &&
                                        fieldEditable ?"2px solid #6c757d" :"none" }}>
                                    <input
                                      value={farm.name}
                                      disabled={isEdit}
                                      id={focus}
                                      className="editFarm-titleFarmsList"
                                     onChange={((e)=>{
                                      dispatch({
                                        type: "EDITNAME",
                                        id: farm.id,
                                        value: e.target.value,
                                      });
                                     })}
                                    >
                                    </input>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "0px",
                                      }}
                                    >
                                      {/* DONE EDIT NAME */}
                                      {selectedField === Number(farm.id) &&
                                        fieldEditable && (
                                          <button
                                            style={{
                                              background: "#5db283",
                                              border: "none",
                                            }}
                                            onClick={() => {
                                                setFieldEditable(false);
                                                setSelectedField(null);
                                                setFieldName("")
                                            }}
                                          >
                                            <img
                                              height={13}
                                              src={doneEditFarm}
                                            />
                                          </button>
                                        )}

                                      {/* EDIT */}
                                      {selectedField != Number(farm.id) && (
                                        <button
                                          style={{
                                            background: "#5db283",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setSelectedField(Number(farm.id));
                                            setFieldEditable(true);
                                            setTimeout(()=>{onFocus()},500)
                                          }}
                                        >
                                          <img height={18} src={editFarmIcon} />
                                        </button>
                                      )}
                                      {/* DELETE */}
                                      <button
                                        style={{
                                          background: "#5db283",
                                          border: "none",
                                          borderRadius: "0px 10px 0px 0px",
                                        }}
                                        onClick={() => deleteFarm(farm)}
                                      >
                                        <img height={18} src={deleteIcon} />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="items-container">
                                    {farm.fields?.map(
                                      (farmFields, farmFieldsIndex) => (
                                        <Draggable
                                          key={farmFields.id}
                                          draggableId={`${farmFields.id}`}
                                          index={farmFieldsIndex}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              id={`focusElement_${farmFields.id}`}
                                              className="editFarm-item-container"
                                              {...provided.dragHandleProps}
                                              {...provided.draggableProps}
                                              ref={provided.innerRef}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                              <p style={{ margin: "0" }}>
                                                {farmFields.name}
                                              </p>
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                    {provided.placeholder}
                                  </div>
                                </div>
                              )}
                            </Droppable>
                          </div>
                        )}
                      </Draggable>
                    )})}
                    {provided.placeholder}
                  </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      )}
      {boundsData.length > 0 &&
        mapShow &&
        props.farms.length > 0 && (
          <Card
            className="card-stats card"
            style={{
              height: "28vh",
              margin: "10px 0px 0px",
            }}
          >
            <CardBody
              onMouseOver={() => MouseOnMapStatus(true)}
              onMouseOut={() => MouseOnMapStatus(false)}
            >
              {" "}
              <MapFarmers
                scrollToElement={scrollToElement}
                isFarm={isFarm}
                fieldList={props.fieldList}
                farms={state.farmers}
                bounds={boundsData}
                center={centerLocation}
                zoom={zoom}
                hoverFarmerNumber={hoverFarmerNumber}
                hoverFieldNumber={hoverFieldNumber}
              />
            </CardBody>
          </Card>
        )}
      {state.fieldsModificate.length > 0 && (
        <div style={{ width: "100%", textAlign: "center", marginTop: "2vh" }}>
          <Button
            className="btn-btn btn-success btn-sm  btn btn-success"
            style={{
              border: "none",
              borderRadius: "10px",
              background: "#5db283",
            }}
            onClick={() => {
              saveData();
            }}
          >
            <p style={{ margin: "0", padding: "5px" }}>{t("globals.saveChanges")}</p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditFarmer;

import React, { Fragment, useEffect, useState } from "react";
import { Polygon, Popup } from "react-leaflet";

const BackPolygons = (props) => {
  const { field } = props;
  const getPolygons = (polygon) => {
    const coords = polygon.boundary.coordinates[0];
    let result = [];
    for (let i = 0; i < coords.length; i++) {
      let coord = coords[i];
      let temp = [];
      temp[0] = coord[1];
      temp[1] = coord[0];

      result.push(temp);
    }
    return result;
  };

  let fieldPolygons = field["polygonData"];
  if (fieldPolygons) {
    const fieldComponents = fieldPolygons?.map((polygon, index) => (
      <Polygon key={index} positions={getPolygons(polygon)}>
        <Popup>{polygon.name}</Popup>
      </Polygon>
    ));
    return <Fragment>{fieldComponents}</Fragment>;
  }
  return <Fragment>{}</Fragment>;
};

export default BackPolygons;

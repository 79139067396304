import React, { Component } from "react";
import {
  Map,
  TileLayer,
  Circle,
  FeatureGroup,
  Marker,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";

import "leaflet-draw/dist/leaflet.draw.css";
import { FieldLocationIcon } from "./locationIcon";
import CustomPolygons from "./customPolygons";
import BackPolygons from "./backPolygons";
import CreatedPolygons from "./createdPolygons";
import MapLink from "./MapLink";
// work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png",
});

//

let polyline;

export default class EditCtrl extends Component {
  // see http://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event for leaflet-draw events doc

  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      JSON.stringify(nextProps.bounds) === JSON.stringify(this.props.bounds)
    ) {
      return false;
    }

    if (
      JSON.stringify(nextProps.centerLocation) ===
      JSON.stringify(this.props.centerLocation)
    ) {
      return false;
    }

    return true;
  }

  _onEdited = (e) => {
    let numEdited = 0;
    e.layers.eachLayer((layer) => {
      numEdited += 1;
    });

    this._onChange();
  };

  _onCreated = (e) => {
    let type = e.layerType;
    let layer = e.layer;

    // Do whatever else you need to. (save to db; etc)

    this._onChange();
  };

  _onDeleted = (e) => {
    let numDeleted = 0;
    e.layers.eachLayer((layer) => {
      numDeleted += 1;
    });
  };

  render() {
    const BackPolygonComponents = this.props.fieldList.map((field, index) => (
      <FeatureGroup key={index} color={this.props.backPolygonsColor}>
        <BackPolygons field={field} />
      </FeatureGroup>
    ));

    const CreatedPolygonComponents = this.props.createdList.map(
      (item, index) => (
        <FeatureGroup key={index} color={this.props.createdPolygonsColor}>
          <CreatedPolygons polygonInfo={item} />
        </FeatureGroup>
      )
    );
    return (
      <Map
        center={this.props.center}
        bounds={this.props.bounds}
        style={{ width: "100%", height: "100%", minHeight: "300px" }}
        zoom={this.props.zoom}
        zoomControl={false}
      >
        <MapLink />
        <Marker position={this.props.center} icon={FieldLocationIcon}>
          <Popup>Center of Map!</Popup>
        </Marker>
        {BackPolygonComponents}
        {CreatedPolygonComponents}
        <FeatureGroup
          ref={(ref) => {
            this._onFeatureGroupReady(ref);
          }}
        >
          <EditControl
            position="topright"
            onEdited={this._onEdited}
            onCreated={this._onCreated}
            onDeleted={this._onDeleted}
            draw={{
              rectangle: false,
              marker: false,
              circle: false,
              polyline: false,
              circlemarker: false,
            }}
          />
        </FeatureGroup>
      </Map>
    );
  }

  _editableFG = null;

  _onFeatureGroupReady = (ref) => {
    if (ref === null) {
      return;
    }

    this._editableFG = ref;
    // populate the leaflet FeatureGroup with the geoJson layers

    if (this.props.initGeoJson) {
      let leafletGeoJSON = new L.GeoJSON(this.props.initGeoJson);
      let leafletFG = this._editableFG.leafletElement;
      leafletGeoJSON.eachLayer((layer) => leafletFG.addLayer(layer));
    } else {
      let leafletGeoJSON = new L.GeoJSON(getGeoJson());
      let leafletFG = this._editableFG.leafletElement;
      leafletGeoJSON.eachLayer((layer) => leafletFG.addLayer(layer));
    }
  };

  _onChange = () => {
    // this._editableFG contains the edited geometry, which can be manipulated through the leaflet API

    const { onChange } = this.props;

    if (!this._editableFG || !onChange) {
      return;
    }

    const geojsonData = this._editableFG.leafletElement.toGeoJSON();
    onChange(geojsonData);
  };
}

// data taken from the example in https://github.com/PaulLeCam/react-leaflet/issues/176

function getGeoJson() {
  return {
    type: "FeatureCollection",
    features: [],
  };
}

import React from "react";
import { useTranslation } from "react-i18next";

const CheckDataRasters = (props) => {
  const [t, i18n]= useTranslation("global")

  const noDataRastersCCCI = (
    <>
      <p
        style={{
          backgroundColor: "#dee3ed",
          marginInline: "16px",
          color: "black",
          textAlign: "center",
          borderRadius: "10px",

          padding: "3px",
          paddingBottom: "3px",
        }}
      >
        {t("globals.indexNotAvailable")}
      </p>
    </>
  );

  const noDataRastersNDWI = (
    <>
      <p
        style={{
          backgroundColor: "#dee3ed",
          marginInline: "16px",
          color: "black",
          textAlign: "center",
          borderRadius: "10px",

          padding: "3px",
          paddingBottom: "3px",
        }}
      >
        {t("globals.indexUpdateSoon")}
      </p>
    </>
  );
  return (
    <>
      {props.noDataRasterCCCI && noDataRastersCCCI}
      {props.noDataRasterNDWI && noDataRastersNDWI}
    </>
  );
};

export default CheckDataRasters;

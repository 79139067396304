import React, { Fragment, useEffect, useState } from "react";
import { Polygon, Popup } from "react-leaflet";
import { Button } from "reactstrap";
import ReutIconMapDetail from "./ReutIconMapDetail";

const ReutBackMapDetail = (props) => {
  const { field } = props;
  const getPolygons = (polygon) => {
    const coords = polygon.boundary.coordinates[0];
    let result = [];
    for (let i = 0; i < coords.length; i++) {
      let coord = coords[i];
      let temp = [];
      temp[0] = coord[1];
      temp[1] = coord[0];

      result.push(temp);
    }
    return result;
  };

  let fieldList = field["polygons"];
  let polygon = field;

  if (fieldList) {
    const fieldComponents = fieldList?.map((polygon, index) => (
      <Polygon key={index} positions={getPolygons(polygon)}>
        <ReutIconMapDetail
          polygon={polygon}
          selectedPolygons={props.selectedPolygons}
        />
      </Polygon>
    ));

    return <Fragment>{fieldComponents}</Fragment>;
  }
  if (polygon) {
    let result = [];
    result.push(polygon);
    const polygonComponents = result.map((polygon, index) => (
      <Polygon key={index} positions={getPolygons(polygon)}>
        <ReutIconMapDetail
          polygon={polygon}
          selectedPolygons={props.selectedPolygons}
        />
      </Polygon>
    ));
    return <Fragment>{polygonComponents}</Fragment>;
  }

  return <Fragment>{}</Fragment>;
};

export default ReutBackMapDetail;

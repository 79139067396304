import i18n from "i18next";
import lang_en from "./lang_en.json"
import lang_es from "./lang_es.json"
i18n.init({
    interpolation: { scapeValue: false },
    lng: navigator.language.substring(0,2) === "es" || navigator.language.substring(0,2) === "en" ? navigator.language.substring(0,2) : "en",
    // ns:[lang_es, lang_en],
    resources: {
    //   fallbackLng: "en",
      debug: true,
    //   ns: ["global"],
    //   defaultNS: "global",
    //   keySeparator: false,
      es:{
        global: lang_es
      },
      en:{
        global: lang_en
      },
    //   react: {
    //     wait: true
    // }
    }
  });


  export default i18n
import React from "react";
import { FeatureGroup, Map, Marker } from "react-leaflet";
import SubPolygon from "../../../../components/Maps/subPolygon";
import { getCropIcon } from "../../../../components/Maps/mapIcons";
import L from "leaflet";
import MapLink from "components/Maps/MapLink";

class DetailMap extends React.Component {
  constructor(props) {
    super(props);
  }

  getPolygons = (polygon) => {
    let coords = polygon?.coordinates[0];
    let result = [];
    for (let i = 0; i < coords?.length; i++) {
      let temp = [];
      //long
      temp[1] = coords[i][0].length >= 1 ? coords[i][0][0] : coords[i][0];
      //lat
      temp[0] = coords[i][1].length >= 1 ? coords[i][1][1] : coords[i][1];

      result.push(temp);
    }
    return result;
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getIconInfo = (crop = "") => {
    let iconUrl = "";

    if (!crop) {
      return null;
    }

    iconUrl = getCropIcon(crop);
    if (!iconUrl) {
      return null;
    }

    let iconInfo = L.icon({
      iconUrl: iconUrl,
      iconRetinaUrl: iconUrl,
      iconAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,

      iconSize: [20, 20],
    });
    return iconInfo;
  };

  getColor = () => {
    var colors = ["#88ebcd", "#00EE66", "#22AAcd", "#9999cd"];
    return colors[3];
  };

  render() {
    return (
      <Map
        center={this.props.center}
        bounds={this.props.bounds}
        style={{ width: "100%", height: "100%", borderRadius: "5px" }}
        zoom={this.props.zoom}
        zoomControl={false}
      >
        <MapLink />
        <FeatureGroup>
          {this.props.polygons &&
            this.props.polygons.map((polygon, index) => (
              <SubPolygon
                key={index}
                iconInfo={this.getIconInfo(polygon.crop)}
                hoverStatus={this.props.hoverNumber === index ? true : false}
                positions={this.getPolygons(polygon?.boundary)}
                name={polygon.name}
              />
            ))}
        </FeatureGroup>
      </Map>
    );
  }
}

export default DetailMap;

import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";

const SourceContex = createContext();

//children es basicamente todos los componentes que envolvamos
//value: todo lo que envolvamos tendra acceso a el valor
export function SourceProvider({ children }) {
  const [planBasic, setPlanBasic] = useState(false);
  const [planPlanet, setPlanPlanet] = useState(false);
  const [disabledPlanBasic, setDisabledPlanBasic] = useState(false);
  const [disabledPlanPlanet, setDisabledPlanPlanet] = useState(false);
  const changePlan = (type, value) => {
    if (type === "restart") {
      setPlanBasic(value);
      setPlanPlanet(value);
    }
    if (type === "basic") {
      setPlanBasic(value);
      setPlanPlanet(!value);
    }
    if (type === "planet") {
      setPlanPlanet(value);
      setPlanBasic(!value);
    }
  };

  const changeDisabledButton = (type, value) => {
    if (type === "basic") {
      setDisabledPlanBasic(value);
    }
    if (type === "planet") {
      setDisabledPlanPlanet(value);
    }
  };

  return (
    <SourceContex.Provider
      value={{
        planPlanet,
        planBasic,
        disabledPlanBasic,
        disabledPlanPlanet,
        changePlan,
        changeDisabledButton,
      }}
    >
      {children}
    </SourceContex.Provider>
  );
}

export default SourceContex;

//LISTA IZQUIERDA: LISTADO DE POLIGONOS DE CHACRAS OWNER
//LISTA DERECHA: LISTADO  DE CHACRAS CON POLIGONOS MODIFICABLE
//LISTA DERECHA: EN CASO DE DESPLAZAR DE DER A IZQ HACER QUE SE MODIFIQUE EL DE LA DERECHA, EL OTRO SOLO HACER UN RETURN
//COMO HACER PARA QUE NO SE REPITAN LOS ID DE LOS POLIGONOS (SOLUCIONADO)
// PROBLEMAS CON EL TOINDEX EN dragReducer (AL DESPLAZAR HACIA ABAJO DE TODO TOMA UN INDICE DE MAS Y NO SE POR  QUE)
//TRABAJAR BIEN LO QUE SERIA EL fieldsModificate
//CREAR ESPACIO EN BLANCO EN CASO DE QUE NO HAYA POLIGONOS EN UNA CHACRA
//AMBOS LISTADOS VAN A COMPARTIR EL MISMO ID (ERROR) BUSCAR FORMA DE CORREGIR (solucionado)
//NO PERMITIR QUE DOS POLIGONOS IGUALES ESTEN EN UNA CHACRA
//HACER UN LOADING QUE VAYA HASTA ADMINISTRATION PARA VOLVER A CARGAR LOS FIELDS
import React, { useCallback, useEffect, useReducer, useState, useRef } from "react";
import iconDone from "../../../../assets/img/done.png";
import deleteIcon from "../../../../assets/img/deleteIcon.png";
import doneEditFarm from "../../../../assets/img/doneEditFarm.png";
import editFarmIcon from "../../../../assets/img/editFarmIcon.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import produce from "immer";
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import MapFields from "./MapFields";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import "../../../../assets/css/reorganizeFields.css"
import { NavLink } from "react-router-dom";
import DeleteFieldModal from "../../../components/DeleteFieldModal";
import SaveChangesModal from "./SaveChangesModal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//JSON.parse(JSON.stringify(item))
const dragReducer = produce((draft, action) => {
  if(action.type !== "RESET"){
    if (action.type === "ADDNEWFIELD") {
      let newFieldData =  {
        // id temp
        id: Math.floor(Math.random() * 10000),
        //cambiar area cuando se asigna poligono
        area: 0,
        name: action.defaultFieldName,
        owner: action.owner,
        access: [],
        polygons: []
      }
      draft["addingFieldList"] = [...draft["addingFieldList"], newFieldData.id]
      draft["fieldList"].unshift(newFieldData)
      return
    }
    if (action.type === "EDITFIELDNAME") {
      let searchField= draft["fieldList"].findIndex((el)=> el.id === action.id)
      let searchFieldProps= action.propsFields?.findIndex((el)=> el.id === action.id)
      draft["fieldList"][searchField].name = action.value
      if(searchFieldProps >= 0){
        action.propsFields[searchFieldProps].name = action.value
      }
      let searchInFieldsModificate= draft["fieldsModificate"].findIndex((el)=> el.id === draft["fieldList"][searchField].id)
      if(searchInFieldsModificate >= 0){
        let fieldNameData= draft["fieldList"][searchField]
        draft["fieldsModificate"].splice(searchField, 1)
        draft["fieldsModificate"].splice(searchField, 0, fieldNameData)
      } else {
        let fieldNameData= draft["fieldList"][searchField]
        draft["fieldsModificate"].push(fieldNameData)
      }
      
    }
    if (action.type === "EDITPOLYGONNAME") {
      let searchField= draft["fieldList"].findIndex((el)=> el.id === action.fieldId)
      let searchPolygon = draft["fieldList"][searchField]?.polygons?.findIndex((el) => el.id === action.polId)
      draft["fieldList"][searchField].polygons[searchPolygon].name = action.value
      let searchInFieldsModificate= draft["fieldsModificate"].findIndex((el)=> el.id === draft["fieldList"][searchField].id)
      if(searchInFieldsModificate >= 0){
        let fieldNameData= draft["fieldList"][searchField]
        draft["fieldsModificate"].splice(searchField, 1)
        draft["fieldsModificate"].splice(searchField, 0, fieldNameData)
      }
    }
    if(action.toIndex > draft["fieldList"].length - 1 && action.to === "fieldList"){
      action.to = draft["fieldList"][draft["fieldList"].length - 1]?.id
      action.toIndex = draft["fieldList"].length - 1
    }
    if(action.type === "DELETE"){
      let removeField = draft["fieldList"].filter((el)=> el.id !== action.field.id)
      if(JSON.parse(JSON.stringify(removeField)).length > 0){
        draft["fetchFields"] = false
      } else{
        draft["fetchFields"] = true
      }
      draft["fieldList"] = removeField
      draft["fieldsModificate"] = [];
    }

    if(action.type === "polygonsData"){
      draft["polygonsList"] = action.value
      return;
    }
    if(action.type === "fieldData"){
      draft["fieldList"] = action.value
      return;
    }
  //afecta principalmente al der
     if(action.type === "MOVE"){
      let searchIndexFieldMoved= draft["fieldList"].findIndex((el)=> el.id === Number(action.to))
      if(searchIndexFieldMoved >= 0){
        let fieldNewData= draft["fieldList"][searchIndexFieldMoved]
        draft["fieldsModificate"].splice(searchIndexFieldMoved, 1)
        draft["fieldsModificate"].splice(searchIndexFieldMoved, 0, fieldNewData)
      } else{
        let fieldNewData= draft["fieldList"][searchIndexFieldMoved]
        draft["fieldsModificate"].push(fieldNewData)
      }
      let searchField= draft["fieldList"].filter((el)=> el.id === Number(action.to))[0]
      let [removeData] = draft["polygonsList"].splice(action.fromIndex, 1)
      removeData.draggableId = Math.random();
      searchField?.polygons?.splice(action.toIndex, 0, removeData);
  
      return;
     }
  //afecta a toda la columna
     if(action.type ==="COLUMN"){
       let searchIndexFieldMoved= draft["fieldList"].findIndex((el)=> el.id === Number(action.to))
       let searchIndexFieldRemoved= draft["fieldList"].findIndex((el)=> el.id === Number(action.from))
       let polygonInfo= draft["fieldList"][searchIndexFieldRemoved].polygons.filter((el)=> Number(el.draggableId) === Number(action.draggableId))[0]
  
      if(searchIndexFieldMoved >= 0){
        let fieldNewData= draft["fieldList"][searchIndexFieldMoved]
        draft["fieldsModificate"].splice(searchIndexFieldMoved, 1)
        draft["fieldsModificate"].splice(searchIndexFieldMoved, 0, fieldNewData)
      } else{
        let fieldNewData= draft["fieldList"][searchIndexFieldMoved]
        draft["fieldsModificate"].push(fieldNewData)
      }
      if(searchIndexFieldRemoved >= 0){
        let fieldNewData= draft["fieldList"][searchIndexFieldRemoved]
        draft["fieldsModificate"].splice(searchIndexFieldRemoved, 1)
        draft["fieldsModificate"].splice(searchIndexFieldRemoved, 0, fieldNewData)
      } else {
        let fieldNewData= draft["fieldList"][searchIndexFieldRemoved]
        draft["fieldsModificate"].push(fieldNewData)
      }
    let searchDuplicateIdInField = draft["fieldList"][searchIndexFieldMoved].polygons.filter((el)=> Number(el.id) === Number(polygonInfo.id))
     if(!searchDuplicateIdInField.length){
      let [searchElementRemoved] = draft["fieldList"][searchIndexFieldRemoved].polygons.splice(action.fromIndex, 1)
      draft["fieldList"][searchIndexFieldMoved].polygons.splice(action.toIndex, 0, searchElementRemoved)
     }

     }
  //afecta principalmente al der
  if(action.type === "REMOVE"){
    //buscar duplicado en datos
    let searchFieldModificate= draft["fieldsModificate"].findIndex((el)=> el.id === Number(action.from))
    let searchIndexFieldRemoved= draft["fieldList"].findIndex((el)=> el.id === Number(action.from))
    let zoneInfo = draft["polygonsList"][action.toIndex]
    let polygonInfo = draft["fieldList"][searchIndexFieldRemoved]?.polygons[action.fromIndex]
    if(zoneInfo?.id){
      if((polygonInfo?.deleted === null && zoneInfo?.deleted === null) || (polygonInfo?.deleted !== null && zoneInfo?.deleted !== null)){
        if(searchFieldModificate >= 0){
          let fieldNewData= draft["fieldList"][searchIndexFieldRemoved]
          draft["fieldsModificate"].splice(searchFieldModificate, 1)
          draft["fieldsModificate"].splice(searchFieldModificate, 0, fieldNewData)
        } else{
          let fieldNewData= draft["fieldList"][searchIndexFieldRemoved]
          draft["fieldsModificate"].push(fieldNewData)
        }
        let [searchElementRemoved] = draft["fieldList"][searchIndexFieldRemoved]?.polygons.splice(action.fromIndex, 1)
        draft["polygonsList"].splice(action.toIndex, 0, searchElementRemoved)
        //chequear Si esta en la zona de los poligonos no eliminados
      } else{ 
        return
      }
    } else {
      if(searchFieldModificate >= 0){
        let fieldNewData= draft["fieldList"][searchIndexFieldRemoved]
        draft["fieldsModificate"].splice(searchFieldModificate, 1)
        draft["fieldsModificate"].splice(searchFieldModificate, 0, fieldNewData)
      } else{
        let fieldNewData= draft["fieldList"][searchIndexFieldRemoved]
        draft["fieldsModificate"].push(fieldNewData)
      }
      let [searchElementRemoved] = draft["fieldList"][searchIndexFieldRemoved]?.polygons.splice(action.fromIndex, 1)
      draft["polygonsList"].splice(action.toIndex, 0, searchElementRemoved)
      }
     }
      if(action.type === "replaceNewFieldId"){
      let findIndexField = draft["fieldList"].findIndex((el)=> el.id === action.field)
      let [removeData] = draft["fieldList"].splice(findIndexField, 1)
      removeData.id = action.newField.id
      draft["fieldList"].splice(findIndexField, 0, removeData)
      }
  } else{
    draft["fieldsModificate"] = []
    draft["loading"] = action.value
  }
  
 
});



  const ReorganizeFields = (props) => {
    const [t, i18n] = useTranslation("global")
    const [state, dispatch] = useReducer(dragReducer, {
      //acá se va a copiar la nueva lista modificada
      polygonsList: [],
      //acá se va a copiar la nueva lista modificada
      fieldList: [],
      fieldListLength: props.fieldList.length,
      fieldsModificate: [],
      loading: false,
      addingFieldList:[],
      fetchFields: false,
    });
    
    const onDragEnd = useCallback((result) => {
      setMoveElement(-1)
      if (result.reason === "DROP") {
        if (!result.destination) {
          return;
        }
        //IZQ A DERECHA: SOLO MODIFICAR EL DERECHO, EL OTRO NO SE MODIFICA
        if (result.source.droppableId === "polygonsList") {
          //MISMA COLUMNA
          if (result.destination.droppableId === "polygonsList") {
            return;
          }
          let repeat = fieldsOptions?.includes(Number(result?.destination?.droppableId));
          if (repeat) {} 
          else {
            if(Number(result?.destination?.droppableId)){
              setFieldsOptions([...fieldsOptions, Number(result?.destination?.droppableId),]);
            } 
        }
        dispatch({
          type: "MOVE",
          from: result.source.droppableId,
          to: result.destination.droppableId,
          fromIndex: result.source.index,
          toIndex: result.destination.index,
        });
      } else {
        
        if(result.destination.droppableId ==="polygonsList"){
          dispatch({type:"REMOVE",
          draggableId: result.draggableId,
          from: result.source.droppableId,
          to: result.destination.droppableId,
          fromIndex: result.source.index,
          toIndex: result.destination.index,
        })
      } else {
        dispatch({
          type: "COLUMN",
          draggableId: result.draggableId,
          from: result.source.droppableId,
          to: result.destination.droppableId,
          fromIndex: result.source.index,
              toIndex: result.destination.index,
            });
          }
        }
      }
    }, []);
    //MAP BOUNDSS
    const [boundsData, setBoundsData] = useState([]);
    const [polygonsByUser, setPolygonsByUser] = useState([]);
    const [polygonsDeleted, setPolygonsDeleted] = useState([]);
    const [allPolygons, setAllPolygons] = useState([]);
    const centerLocation = { lat: -32, lng: -57 };
    const zoom = 14;
    const [mouseOnMapStatus, setMouseOnMapStatus] = useState(false);
    const [polygonsDeletedOptions, setPolygonsDeletedOptions] = useState(false);
    const [fieldsOptions, setFieldsOptions] = useState([]);
    const [hoverPolygonNumber, setHoverPolygonNumber] = useState(-1);
    const [hoverFieldNumber, setHoverFieldNumber] = useState(-1);
    const [hoverPolygons, setHoverPolygons] = useState(false);
    const [hoverFields, setHoverFields] = useState(false);
    const [mapShow, setMapShow] = useState(false);
    const [selectedField, setSelectedField] = useState(null);
    const [selectedPolygon, setSelectedPolygon] = useState(null);
    const [selectedFieldDeleted, setSelectedFieldDeleted] = useState(null);
    const [confirmDeleteField, setConfirmDeleteField] = useState("");
    const [fieldEditable, setFieldEditable] = useState(false);
    const [polygonEditable, setPolygonEditable] = useState(false);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [saveDataComplete, setSaveDataComplete] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [moveElement, setMoveElement] = useState(-1);
    const [loadingModal, setLoadingModal] = useState(false)
    const history = useHistory()
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const tokenUser = {
      headers: {
        // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    const scrollToElement = (id) => {
      let searchPolygonInField= state.fieldList.filter((el)=> el.polygons.some((pol)=> pol.id === id))
      let searchPolygonDeleted = state.polygonsList.filter((el)=> el.id === id)
      if(searchPolygonInField.length){
        let fieldInfo = searchPolygonInField[0]
        if(fieldsOptions.length > 0){
          if(fieldsOptions?.some((el)=> el !== fieldInfo.id)){
            setFieldsOptions([...fieldsOptions, fieldInfo.id])
          }
        } else{
          setFieldsOptions([...fieldsOptions, fieldInfo.id])
        }
      }
      if(searchPolygonDeleted.length){
        setPolygonsDeletedOptions(true)
      }
      const element = document.getElementById('focusElement_' + id);
      element.classList += " hoverFocusElement"
       element.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      setTimeout(()=>{
        element.classList.remove('hoverFocusElement');
      },2000)
    };
    useEffect(() => {
      addDraggableIdPolygons(props.fieldList);
      fetchPolygonsByUser()
    }, []);
    useEffect(() => {
      if(state.fetchFields === true){
        setTimeout(() => {
          history.push("/admin/dashboard");
        }, 1000);
      }
    }, [state.fetchFields]);
    useEffect(()=>{
      if(state.loading === true){
        dispatch({type:"RESET", value: false})
      }
    },[state.loading])


    useEffect(()=>{
      if(polygonsByUser.length){
        let polygons = polygonsByUser.filter((el)=> el.deleted === null)
        let polygonsByUserDeleted= polygonsByUser.filter((el)=> el.deleted)?.sort((a, b) => new Date(b.deleted) - new Date(a.deleted))
        let polygonsResult = polygons.concat(polygonsByUserDeleted)
        setPolygonsDeleted(polygonsByUserDeleted)
        dispatch({type:"polygonsData", value: polygonsResult})
        setBounds(polygonsByUser)
      }
    },[polygonsByUser.length])

    const fetchPolygonsByUser= async(deleted =false)=>{
      await axios.get("https://agrodigital.io/api/polygons", tokenUser).then((res)=>{

      setAllPolygons(res.data)
      let usedPolygons= props.polygonsList.map((el)=> el.id)
      if(res.data.length > 0){
        if(deleted){
          let polygonsRemoved= selectedFieldDeleted.polygons.map((el)=> el.id)
          let removePolygonsDeleted= usedPolygons.filter((el)=> !polygonsRemoved.includes(el))
          let unusedPolygons = res.data.filter((el)=> !removePolygonsDeleted.includes(el.id))
         setPolygonsByUser(unusedPolygons)
        } else {
        let unusedPolygons = res.data.filter((el)=> !usedPolygons.includes(el.id))
         setPolygonsByUser(unusedPolygons)
        }
      }
      })
    }
    const addDraggableIdPolygons = (fieldList) => {
      for (let i = 0; i < fieldList.length; i++) {
        const polygons = fieldList[i].polygons;
        for (let j = 0; j < polygons.length; j++) {
          const polygon = polygons[j];
          polygon.draggableId = Math.random();
        }
      }

      dispatch({type:"fieldData", value: fieldList})
    };
    const validateMapStatus = () => {
      if (
        mouseOnMapStatus === false &&
        hoverFieldNumber === -1 &&
        hoverPolygonNumber === -1
      ) {
        setBounds(props.fieldList, false);
      }
    };
    const setBounds = (data, status) => {
      setMapShow(false)
      let left = -180;
      let top = -90;
      let right = 180;
      let bottom = 90;
      let result = [
        [centerLocation.lng, centerLocation.lat],
        [centerLocation.lng, centerLocation.lat],
      ];
      if (status) {
        setHoverPolygons(true);
        setHoverFields(false);
        let polygonsList = data;
        if (polygonsList) {
          for (let j = 0; j < polygonsList.length; j++) {
            let coords = polygonsList[j].boundary.coordinates;
            if (coords) {
              for (let k = 0; k < coords.length; k++) {
                let coordinates = coords[k];
                for (let l = 0; l < coordinates.length; l++) {
                  let latitude = coordinates[l][1];
                  let longitude = coordinates[l][0];
                  if (latitude > top) {
                    top = coordinates[l][1];
                  }

                  if (latitude < bottom) {
                    bottom = coordinates[l][1];
                  }

                  if (longitude > left) {
                    left = longitude;
                  }

                  if (longitude < right) {
                    right = longitude;
                  }
                }
              }
            }
          }
          result = [
            [top, left],
            [bottom, right],
          ];
        }
      } else {
        setHoverPolygons(false);
        setHoverFields(true);
        let fieldList = data;
        if (fieldList) {
          for (let i = 0; i < fieldList.length; i++) {
            let polygons = fieldList[i].polygons;
            if (polygons) {
              for (let j = 0; j < polygons.length; j++) {
                let coords = polygons[j].boundary.coordinates;
                if (coords) {
                  for (let k = 0; k < coords.length; k++) {
                    let coordinates = coords[k];
                    for (let l = 0; l < coordinates.length; l++) {
                      let latitude = coordinates[l][1];
                      let longitude = coordinates[l][0];
                      if (latitude > top) {
                        top = coordinates[l][1];
                      }

                      if (latitude < bottom) {
                        bottom = coordinates[l][1];
                      }

                      if (longitude > left) {
                        left = longitude;
                      }

                      if (longitude < right) {
                        right = longitude;
                      }
                    }
                  }
                }
              }
            }
          }
          result = [
            [top, left],
            [bottom, right],
          ];
        }
      }
      setMapShow(true);
      setBoundsData(result);
    };

    //fieldIdex
    const MouseRowOver = (
      selectedField,
      fieldIndex,
      polygonIndex = -1,
      isPolygon
    ) => {
      setHoverPolygonNumber(polygonIndex);
      setHoverFieldNumber(fieldIndex);
      setBounds([selectedField], isPolygon);
    };

    const MouseRowOut = () => {
      setHoverPolygonNumber(-1);
      setHoverFieldNumber(-1);
      setTimeout(function () {
        if (hoverFieldNumber === -1 && hoverPolygonNumber === -1) {
          validateMapStatus();
        }
      }, 6000);
    };

    const MouseOnMapStatus = (bStatus = false) => {
      setMouseOnMapStatus(bStatus);
      if (bStatus === false) {
        setTimeout(function () {
          validateMapStatus();
        }, 6000);
      }
    };
    const saveData = async () => {
      let fieldsModificate = JSON.parse(JSON.stringify(state.fieldsModificate));
      let newFieldsList = JSON.parse(JSON.stringify(state.addingFieldList));
      let searchNewFields = fieldsModificate.filter((el)=> newFieldsList.includes(el.id))
      let oldFields = fieldsModificate.filter((el)=> !newFieldsList.includes(el.id))
      if(searchNewFields.length > 0){
        for (let i = 0; i < searchNewFields.length; i++) {
          const fieldData = searchNewFields[i];
          let body = {
            name: fieldData.name, owner: fieldData.owner ,
            polygons: fieldData.polygons?.map((el )=> el.id)
          }
          axios
              .post(`https://agrodigital.io/api/fields/`, body, tokenUser)
              .then((res) => {
                let field= res.data
                field["polygons"] = fieldData.polygons
                props.fieldList.push(field)
                dispatch({type:"replaceNewFieldId", field: fieldData.id, newField: res.data})
                if (i === searchNewFields.length - 1) {
                  if(oldFields.length === 0){
                    dispatch({ type: "RESET", value:true });
                    onSaveChangesToggle()
                  }
                }
              })
        }
      }
      if(oldFields.length > 0){
        for (let i = 0; i < oldFields.length; i++) {
          const fields = oldFields[i];
          const polygons = [];
  
          if (fields.polygons.length > 0) {
            polygons = fields.polygons.map((el) => el.id);
          } else {
            polygons = [];
          }
          await axios
            .patch(
              `https://agrodigital.io/api/fields/${fields.id}/`,
              { polygons: polygons, name: fields.name },
              tokenUser
            )
            .then(() => {
              if (i === oldFields.length - 1) {
                dispatch({ type: "RESET", value:true });
                onSaveChangesToggle()
              }
            });
        }
      }
    };

    const onDeleteField = async () => {
      setLoadingModal(true)
      await axios
        .delete(
          `https://agrodigital.io/api/fields/${selectedFieldDeleted.id}/`,
          tokenUser
        )
        .then(() => {
          dispatch({ type: "DELETE", field: selectedFieldDeleted });
          let removeField = state.fieldList.filter((el)=> el.id !== selectedFieldDeleted.id)
          props.setFieldList(removeField)
          fetchPolygonsByUser(true)
          setConfirmDeleteField("")
        });
        onDeleteToggle();
    };
    // const deletePolygons = async () => {
    //   let polygonsDeleted = deletedSelectField.polygons;
    //   for (let i = 0; i < polygonsDeleted.length; i++) {
    //     const polygonId = polygonsDeleted[i].id;
    //     await axios
    //       .delete(
    //         `https://agrodigital.io/api/polygons/${polygonId}/`,
    //         tokenUser
    //       )
    //       .then((res) => {
    //         if (i === polygonsDeleted.length - 1) {
    //           onDeleteField();
    //         }
    //       })
    //   }
    // };
    
    const grid= 8
    const getItemStyle = (isDragging, draggableStyle, focus = false) => ({
      // some basic styles to make the fieldList look a bit nicer
      userSelect: "none",
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
  
      // change background colour if dragging
      background: isDragging ? "#f6f6f6" : "white",
      borderRadius:"10px",
      marginBottom:"10px",
      border:focus ? "2px solid #d3d3d3" : "1px solid #e2e2e2",
      listStyle: "none",
      // styles we need to apply on draggables
      ...draggableStyle,
    });


    const onDeleteToggle = () => {
      setLoadingModal(false)
      setDeleteModal(!showDeleteModal)
    };

    const onSaveChangesToggle = () => {
      setSaveDataComplete(!saveDataComplete)
    };
    function onFocus() {
      document.getElementById("isFocus").focus();
 }

  const editPolygonName = async (polygon, field)=>{
    await axios
    .patch(
      `https://agrodigital.io/api/polygons/${polygon.id}/?field=${field.id}/`,
      polygon,
      tokenUser
    )
    .then(() => {
      setPolygonEditable(false);
      setSelectedPolygon(null);
    })
  }
    const msgModal= t("globals.reorganizeFieldsInfoModal")
    const deleteModalComponent =(
      <DeleteFieldModal 
      showDeleteModal={showDeleteModal} 
      onDeleteToggle={onDeleteToggle}
      fieldName={selectedFieldDeleted?.name}
      confirmDeleteField={confirmDeleteField}
      setConfirmDeleteField={setConfirmDeleteField}
      onDeleteField={onDeleteField}
      loadingModal={loadingModal}
      />
    )
    const saveChangesModal =(
      <SaveChangesModal  status={saveDataComplete} msg={msgModal} toggle={onSaveChangesToggle}/>
    )
    return (
      <>
      {deleteModalComponent}
      {saveChangesModal}
      {state.fieldList?.length > 0 && (
        
        <div
          style={{
            background: "#f4f3ef",
            position: "absolute",
            width: "100%",
            top: "3%",
            padding:"15px"
          }}
        >
          {state.loading === true ? (
            <div className="text-center py-4">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <Row
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginRight: "-15px",
                  marginLeft: "-15px",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "baseline",
                }}
              >
                <Col className="col-5 col-sm-6 col-md-4 col-lg-9">
                  <Card
                    style={{ boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)" }}
                    className="cardReorgFields card-stats card"
                  >
                    <CardBody style={{ padding: "0px" }}>
                      <label
                        title="REORGANIZE FARMER"
                        className="titleReorgFields"
                      >
                       {t("globals.reorganizeFields").toUpperCase()}
                      </label>
                    </CardBody>
                  </Card>
                </Col>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret>
                      <i className="fa fa-plus" />
                      {t("buttons.addFieldButton")}
                    </DropdownToggle>
                    <DropdownMenu
                      right
                      style={{
                        transform: "translate3d(-120px, 3px, 0px)",
                        textAlign: "center",
                      }}
                    >
                      <DropdownItem
                        style={{ padding: "10px" }}
                        tag={NavLink}
                        to="/admin/cropmap/add-field"
                      >
                      {t("globals.addFieldNewPolygons")}
                      </DropdownItem>
                      <DropdownItem
                        style={{ padding: "10px" }}
                        onClick={() => {
                          //  id temp
                          dispatch({
                            type: "ADDNEWFIELD",
                            owner: props.userInfo.id,
                            defaultFieldName:t("globals.insertName")
                          });
                        }}
                      >
                      {t("globals.addFieldPolygonsExist")}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
              </Row>
              <div
            style={{
              marginLeft: "50px",
              marginRight: "50px",
              marginBottom: "10px",
              fontSize: "revert",
            }}
          >
            {t("globals.reorganizeFieldsInfo")}
          </div>
              <div class="rowDragReorgFields">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="polygonsList"
                    type="REORGANIZE"
                    direction="vertical"
                    ignoreContainerClipping={true}
                    isCombineEnabled={true}
                  >
                    {(provided, snapshot) => {
                      return(
                      <div className="columnLeftContainer">
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <div className="reorgField-items-container">
                            {state.polygonsList?.map((polygon, index) => {
                              let firstPolygonDeleted = state.polygonsList.filter((el)=> el.deleted)[0]
                              return(
                                <div>
                               { polygon.deleted  === null ? (
                              <Draggable
                                key={polygon.id}
                                draggableId={`${polygon.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  //ACA HACER FUNCIONALIDAD DE 3 SEGUNDOS ABRA LA COLUMNA
                                  // HACER UN USEEFFECT Y SETSTATE
                                  if(snapshot.draggingOver && Number(snapshot.draggingOver)){
                                    if (Number(snapshot.draggingOver) !== moveElement) {
                                      setMoveElement(Number(snapshot.draggingOver))
                                    };
                                  }
                                  return(
                                  <div
                                   id={`focusElement_${polygon.id}`}
                                    onMouseOver={() =>
                                      MouseRowOver(polygon, -1, index, true)} 
                                    onMouseOut={() => MouseRowOut()}
                                    className="reorgField-item-container"
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                     )}
                                  >
                                    <p  style={{margin:"0", color: polygon.deleted != null ? "red" : "black"}}>{polygon.name}</p>
                                  </div>
                                )}}
                              </Draggable>
                              ) : (
                                <Draggable
                                  draggableId={`${polygon.id}`}
                                  index={index}
                                  key={polygon.id}
                                >
                                  {(provided, snapshot) => {
                                    //ACA HACER FUNCIONALIDAD DE 3 SEGUNDOS ABRA LA COLUMNA
                                 // HACER UN USEEFFECT Y SETSTATE
                                     if(snapshot.draggingOver && Number(snapshot.draggingOver)){
                                      if (Number(snapshot.draggingOver) !== moveElement) {
                                        setMoveElement(Number(snapshot.draggingOver))
                                      };
                                    }
                                    return(
                                    <>
                                      {firstPolygonDeleted.id ===
                                        polygon.id && (
                                        <div className="reorgField-store-container">
                                          <h6
                                            className="reorgField-titleFields"
                                            style={{
                                              background: "#e64646",
                                              borderRadius: "10px 10px 0px 0px",
                                              color:"white",
                                              textTransform: "initial"
                                            }}
                                          >
                                            {`${t("globals.removedPolygons")} (${polygonsDeleted.length})`}
                                          </h6>
                                          <div
                                        style={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "10px",
                                        }}
                                        >
                                           <b
                                           
                                           onClick={()=>{setPolygonsDeletedOptions(!polygonsDeletedOptions)}}
                                            className="fa fa-caret-down"
                                            style={{color:"white", cursor:"pointer", transform: polygonsDeletedOptions && "rotate(180deg)"}}
                                            />
                                        </div>
                                        </div>
                                      )}
                                      <div
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >

                                        <Droppable
                                          droppableId={`${polygon.id} deleted`}
                                          type="REORGANIZE"
                                          direction="vertical"
                                          ignoreContainerClipping={true}
                                          isCombineEnabled={true}
                                          >
                                          {(provided) => (

                                            <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            >
                                              <Draggable
                                                key={polygon.id}
                                                draggableId={`${polygon.id}`}
                                                index={index}
                                                >
                                                {(provided, snapshot) => (
                                                  <>
                                            {polygonsDeletedOptions ? (
                                                  <div
                                                  id={`focusElement_${polygon.id}`}
                                                  onMouseOver={() =>
                                                      MouseRowOver(
                                                        polygon,
                                                        -1,
                                                        index,
                                                        true
                                                      )
                                                    }
                                                    onMouseOut={() =>
                                                      MouseRowOut()
                                                    }
                                                    // className="reorgField-item-container"
                                                    // {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                    style={getItemStyle(
                                                      snapshot.isDragging,
                                                      provided.draggableProps.style
                                                    )}
                                                  >
                                                    <p
                                                      style={{
                                                        margin: "0",
                                                        color:
                                                          polygon.deleted !=
                                                          null
                                                            ? "red"
                                                            : "black",
                                                      }}
                                                    >
                                                      {polygon.name}
                                                    </p>
                                              
                                                  </div>
                                                ): 
                                                (
                                                  <div
                                                  ref={provided.innerRef}
                                                  >
                                                  </div>
                                                )
                                                }
                                                </>
                                                )}
                                              </Draggable>
                                              </div>
                                              )}
                                        </Droppable>
                                      </div>
                                    </>
                                  )}}
                                </Draggable>
                                )}
                                </div>
                              )
                              })}
                          </div>
                              {provided.placeholder}
                        </div>
                      </div>
                    )}}
                  </Droppable>
                  <Droppable
                    droppableId="fieldList"
                    type="REORGANIZE"
                    direction="vertical"
                    ignoreContainerClipping={true}
                    isCombineEnabled={true}
                  >
                    {(provided,snapshot) => {
                      return(
                        <div className="columnRightContainer">
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="columnDragRight"
                      >
                        {state.fieldList?.map((field, fieldIndex) => {
                            let isEdit=  selectedField === Number(field.id) && fieldEditable ? false : true
                            let focus=  selectedField === Number(field.id) && fieldEditable ? "isFocus" : ""
                            let allOptions = fieldsOptions?.includes(field.id);
                            return(
                          <Draggable
                            draggableId={`${field.id}`}
                            index={fieldIndex}
                            key={field.id}
                          >
                            {(provided,snapshot) => {
                              return(
                              <div
                                {...provided.dragHandleProps}
                                // {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <Droppable
                                  droppableId={`${field.id}`}
                                  type="REORGANIZE"
                                  direction="vertical"
                                  ignoreContainerClipping={true}
                                  isCombineEnabled={true}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                          background: "white",
                                          borderRadius: "12px",
                                        }}
                                      >
                                        <div
                                          className="reorgField-store-container"
                                          style={{
                                            border:
                                              selectedField ===
                                                Number(field.id) &&
                                              fieldEditable
                                                ? "2px solid #6c757d"
                                                : "none",
                                          }}
                                          onMouseOver={() =>
                                            MouseRowOver(
                                              field,
                                              fieldIndex,
                                              -1,
                                              false
                                            )
                                          }
                                          onMouseOut={() => MouseRowOut()}
                                        >
                                          <div className="reorgField-titleFields">
                                            <div
                                              style={{
                                                position: "absolute",
                                                top: "5px",
                                                left: "10px",
                                              }}
                                            >
                                              <b
                                                className="fa fa-caret-down"
                                                style={{color:"white", cursor:"pointer", transform: allOptions && "rotate(180deg)"}}
                                                onClick={() => {
                                                  let repeat =
                                                    fieldsOptions?.includes(
                                                      field.id
                                                    );
                                                  if (repeat) {
                                                    let remove =
                                                      fieldsOptions.filter(
                                                        (el) => el !== field.id
                                                      );
                                                    setFieldsOptions(
                                                      remove
                                                    );
                                                  } else {
                                                    setFieldsOptions([
                                                      ...fieldsOptions,
                                                      field.id,
                                                    ]);
                                                  }
                                                }}
                                              />
                                            </div>
                                            <input
                                              value={field.name}
                                              disabled={isEdit}
                                              onChange={(e) => {
                                                dispatch({
                                                  type: "EDITFIELDNAME",
                                                  fieldInfo:field,
                                                  id: field.id,
                                                  value: e.target.value,
                                                  propsFields: props.fieldList
                                                });
                                              }}
                                              id={focus}
                                            ></input>
                                            {field.farmName && (
                                              <span>{`(${field.farmName})`}</span>
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "8px",
                                              right: "0px",
                                            }}
                                          >
                                            {/* DONE EDIT NAME */}
                                            {selectedField ===
                                              Number(field.id) &&
                                              fieldEditable && (
                                                <button
                                                  style={{
                                                    background: "#66d0be",
                                                    border: "none",
                                                  }}
                                                  onClick={() => {
                                                    setFieldEditable(false);
                                                    setSelectedField(null);
                                                  }}
                                                >
                                                  <img
                                                    height={13}
                                                    src={doneEditFarm}
                                                  />
                                                </button>
                                              )}

                                            {/* EDIT */}
                                            {selectedField !=
                                              Number(field.id) && (
                                              <button
                                                style={{
                                                  background: "#66d0be",
                                                  border: "none",
                                                }}
                                                onClick={() => {
                                                  setSelectedField(
                                                    Number(field.id)
                                                  );
                                                  setFieldEditable(true);
                                                  setTimeout(() => {
                                                    onFocus();
                                                  }, 500);
                                                }}
                                              >
                                                <img
                                                  height={18}
                                                  src={editFarmIcon}
                                                />
                                              </button>
                                            )}
                                            {/* DELETE */}
                                            <button
                                              style={{
                                                background: "#66d0be",
                                                border: "none",
                                                borderRadius:
                                                  "0px 10px 0px 0px",
                                              }}
                                              onClick={() => {
                                                setSelectedFieldDeleted(field);
                                                onDeleteToggle();
                                              }}
                                            >
                                              <img
                                                height={18}
                                                src={deleteIcon}
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        {(allOptions || moveElement === field.id)  &&
                                          <div className="reorgField-items-container" style={{minHeight:"55px"}}>
                                          {field.polygons?.map(
                                            // ACA HACER LO DE FOCUS Y DISABLED
                                            (fieldPolygon, polygonIndex) => {
                                              let isEditPolygon =
                                                selectedPolygon ===
                                                  Number(fieldPolygon.id) &&
                                                polygonEditable
                                                  ? false
                                                  : true;
                                              let focusPolygon =
                                                selectedPolygon ===
                                                  Number(fieldPolygon.id) &&
                                                polygonEditable
                                                  ? "isFocus"
                                                  : "";
                                              return (
                                                <Draggable
                                                  key={fieldPolygon.draggableId}
                                                  draggableId={`${fieldPolygon.draggableId}`}
                                                  index={polygonIndex}
                                                >
                                                  {(provided, snapshot) => {
                                                    return (
                                                      <div
                                                        id={`focusElement_${fieldPolygon.id}`}
                                                        onMouseOver={() =>
                                                          MouseRowOver(
                                                            field,
                                                            fieldIndex,
                                                            polygonIndex
                                                          )
                                                        }
                                                        onMouseOut={() =>
                                                          MouseRowOut()
                                                        }
                                                        className="item-container editPolygonIcon"
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                        ref={provided.innerRef}
                                                        style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided
                                                            .draggableProps
                                                            .style,
                                                          selectedPolygon ===
                                                            Number(
                                                              fieldPolygon.id
                                                            ) && polygonEditable
                                                        )}
                                                      >
                                                        {/* <p style={{margin:"0"}}>{fieldPolygon.name}</p> */}
                                                        {/* disabled={isEdit} */}

                                                        <input
                                                          id={focusPolygon}
                                                          value={
                                                            fieldPolygon.name
                                                          }
                                                          disabled={
                                                            isEditPolygon
                                                          }
                                                          onChange={(e) => {
                                                            dispatch({
                                                              type: "EDITPOLYGONNAME",
                                                              fieldId: field.id,
                                                              polId:
                                                                fieldPolygon.id,
                                                              value:
                                                                e.target.value,
                                                            });
                                                          }}
                                                        ></input>

                                                        {/* DONE EDIT POLYGON NAME */}
                                                        {selectedPolygon ===
                                                          Number(
                                                            fieldPolygon.id
                                                          ) &&
                                                          polygonEditable && (
                                                            <button
                                                              style={{
                                                                background:
                                                                  "none",
                                                                border: "none",
                                                                padding: "0",
                                                              }}
                                                              onClick={() => {
                                                                editPolygonName(
                                                                  fieldPolygon,
                                                                  field
                                                                );
                                                              }}
                                                            >
                                                              <img
                                                                height={13}
                                                                src={
                                                                  doneEditFarm
                                                                }
                                                              />
                                                            </button>
                                                          )}
                                                        {/* EDIT POLYGON NAME */}
                                                        {selectedPolygon !=
                                                          Number(
                                                            fieldPolygon.id
                                                          ) && (
                                                          <button
                                                            style={{
                                                              border: "none",
                                                              background:
                                                                "none",
                                                              padding: "0",
                                                            }}
                                                            onClick={() => {
                                                              setPolygonEditable(
                                                                true
                                                              );
                                                              setSelectedPolygon(
                                                                Number(
                                                                  fieldPolygon.id
                                                                )
                                                              );
                                                              setTimeout(() => {
                                                                onFocus();
                                                              }, 500);
                                                            }}
                                                          >
                                                            <img
                                                              height={18}
                                                              src={editFarmIcon}
                                                            />
                                                          </button>
                                                        )}
                                                      </div>
                                                    );
                                                  }}
                                                </Draggable>
                                              );
                                            }
                                          )}
                                          {provided.placeholder}
                                        </div>}
                                      </div>
                                    );}}
                                </Droppable>
                              </div>
                            )}}
                          </Draggable>
                        )})}
                        {provided.placeholder}
                      </div>
                        </div>
                    )}}
                  </Droppable>
                </DragDropContext>
              </div>
              {/* <button
          style={{
            position: "fixed",
            bottom:"50px",
            left: "50px",
            background: "#63B257",
            border: "none",
            borderRadius:"10px"
          }}
          onClick={() => {
            props.setHomeFarmer(true)
            props.setEditFarmer(false)
          }}
        >
          <img
            height={30}
            src={iconDone}
          />
        </button> */}
              {boundsData.length > 0 &&
                mapShow &&
                props.fieldList.length &&
                allPolygons.length > 0 && (
                  <Card
                    className="mapDetails card-stats card"
                  >
                    <CardBody
                      onMouseOver={() => MouseOnMapStatus(true)}
                      onMouseOut={() => MouseOnMapStatus(false)}
                    >
                      {" "}
                      <MapFields
                        scrollToElement={scrollToElement}
                        isPolygons={hoverPolygons}
                        polygonsList={state.polygonsList}
                        fieldList={state.fieldList}
                        bounds={boundsData}
                        center={centerLocation}
                        zoom={zoom}
                        hoverPolygonNumber={hoverPolygonNumber}
                        hoverFieldNumber={hoverFieldNumber}
                        hoverFields={hoverFields}
                        hoverPolygons={hoverPolygons}
                      />
                    </CardBody>
                  </Card>
                )}
            </>
          )}
          {state.fieldsModificate.length > 0 && (
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "0.5vh" }}
            >
              <Button
                className="btn-btn btn-success btn-sm  btn btn-success"
                style={{
                  border: "none",
                  borderRadius: "10px",
                  background: "#5db283",
                }}
                onClick={() => {
                  saveData();
                }}
              >
                <p style={{ margin: "0", padding: "5px" }}>{t("globals.saveChanges")}</p>
              </Button>
            </div>
          )}
        </div>
      )}
      </>

    );
  };

export default ReorganizeFields;

import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { ApiContext } from "../../context/ApiContext";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { Button, Modal } from "reactstrap";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const DeleteFieldOrFarmModal = (props) => {
  const [farmers, setFarmers, token, setToken] = useContext(ApiContext)
  const [t, i18n] = useTranslation("global");

  const onDeleteField = () => {
    props.setStateDashboard((prev) => ({
      ...prev,
      loading: true,
    }));

    let fieldId = props.deleteUrl;

    axios
      .delete(`https://agrodigital.io/api/fields/${fieldId}/`, props.tokenUser)
      .then(function () {
        toast.success(t("globals.removedField"));
        props.setListFarm([])
        props.setStateDashboard((prev) => ({
          ...prev,
          loading: false,
          deleteFarmModal:false,
          showDeleteModal: false,
          fieldList: [],
        }));
        setToken("");
        props.fetchFieldList();
      })
      .catch(function (error) {
        props.setStateDashboard((prev) => ({
          ...prev,
          deleteFarmModal: false,
          loading: false,
          showDeleteModal: false,
        }));

        toast.error(
          t("globals.removedFieldFailed")
        );
      });
  };

  const onDeleteFarm = () => {
    props.setStateDashboard((prev) => ({
      ...prev,
      loading: true,
    }));
    let farmId = props.deleteUrl;

   axios.delete(`https://agrodigital.io/api/farms/${farmId}/`, props.tokenUser)
   .then(()=>{
     toast.success(t("globals.removedFarm"));
     props.setListFarm([])
     props.setStateDashboard((prev) => ({
       ...prev,
       deleteFarmModal:false,
       loading: false,
       showDeleteModal: false,
       fieldList: [],
     }));
     setToken("");
     props.fetchFieldList();
  })
  .catch(function (error) {
    props.setStateDashboard((prev) => ({
      ...prev,
      deleteFarmModal:false,
      loading: false,
      showDeleteModal: false,
    }));

    toast.error(
      t("globals.removedFarmFailed")
    );
  });
  }
  return (
    <Modal isOpen={props.showDeleteModal}>
      {props.deleteFarmModal ? (
        <>
          <div className="modal-header justify-content-center">
            <h5 className="title title-up">{t("globals.confirmDeletedFarm")}</h5>
          </div>
          {!props.loading ? (
            <div className="modal-footer">
              <div className="left-side">
                <Button
                  className={"btn-link"}
                  color="danger"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    props.setStateDashboard((prev) => ({
                      ...prev,
                      deleteFarmModal:false,
                      showDeleteModal: false,
                    }))
                  }
                  >
                  {t("buttons.noButton").toUpperCase()}
                </Button>
              </div>
              <div className="divider" />
              <div className="right-side">
                <Button
                  color="primary"
                  className={"btn-link"}
                  type="button"
                  onClick={() => {
                    onDeleteFarm();
                  }}
                  >
                  {t("buttons.yesButton").toUpperCase()}
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <CircularProgress color="secondary" />
            </div>
          )}
        </>
      ) : (
        <>
          <div className="modal-header justify-content-center">
            <h5 className="title title-up">{t("globals.confirmDeletedField")}</h5>
          </div>
          {!props.loading ? (
            <div className="modal-footer">
              <div className="left-side">
                <Button
                  className={"btn-link"}
                  color="danger"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    props.setStateDashboard((prev) => ({
                      ...prev,
                      showDeleteModal: false,
                    }))
                  }
                >
                  {t("buttons.noButton").toUpperCase()}
                </Button>
              </div>
              <div className="divider" />
              <div className="right-side">
                <Button
                  color="primary"
                  className={"btn-link"}
                  type="button"
                  onClick={() => {
                    onDeleteField();
                  }}
                >
                  {t("buttons.yesButton").toUpperCase()}
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <CircularProgress color="secondary" />
            </div>
          )}
        </>
      )}

      <Toaster />
    </Modal>
  );
};

export default DeleteFieldOrFarmModal;

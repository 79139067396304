import React, { Fragment, useEffect, useState } from "react";
import { Polygon, Popup } from "react-leaflet";
import { Button } from "reactstrap";
import ReutilizarIcon from "./ReutilizarIcon";

const ReutBackPolygon = (props) => {
  const { field } = props;
  const getPolygons = (polygon) => {
    const coords = polygon.boundary.coordinates[0];

    let result = [];
    for (let i = 0; i < coords.length; i++) {
      let coord = coords[i];
      let temp = [];
      temp[0] = coord[1];
      temp[1] = coord[0];

      result.push(temp);
    }
    return result;
  };
  let fieldPolygons = field["polygonData"];
  let polygons = field;
  if (fieldPolygons?.length > 0) {
    let fieldsResult = fieldPolygons.filter((el)=> el.id)
    if(fieldsResult.length > 0){
      const fieldComponents = fieldPolygons?.map((polygon, index) => (
        <Polygon key={index} positions={getPolygons(polygon)}>
          <ReutilizarIcon
            polygon={polygon}
            selectedReutPolygon={props.selectedReutPolygon}
          />
        </Polygon>
      ));
      return <Fragment>{fieldComponents}</Fragment>;
    }
  } else if (polygons?.id) {
    let resPolygons = [];
    resPolygons.push(polygons);
    if (resPolygons.length > 0) {
      const polygonComponents = resPolygons?.map((polygon, index) => (
        <Polygon key={index} positions={getPolygons(polygon)}>
          <ReutilizarIcon
            polygon={polygon}
            selectedReutPolygon={props.selectedReutPolygon}
          />
        </Polygon>
      ));
      return <Fragment>{polygonComponents}</Fragment>;
    }
  } else {
    return <Fragment>{}</Fragment>;
  }
};

export default ReutBackPolygon;

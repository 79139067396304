import * as moment from "moment";
import React, { useEffect, useRef, useState, useContext } from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import DetailMap from "./maps/dashboard/detailMap";
import "../assets/css/inputSearch.css";
import "../assets/css/map.css";
import "../assets/css/emptyView.css";
import Spinner from "reactstrap/es/Spinner";
import PerformanceForm from "./forms/PerformanceForm";
// import NotificationAlert from "react-notification-alert";
import Modal from "reactstrap/es/Modal";
import axios from "axios";
import CreateAndEditSeedForm from "./forms/Forms/CreateAndEditSeedForm";
import toast, { Toaster } from "react-hot-toast";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import LicencesAdmin from "./dashboard/LicencesAdmin";
import TableDashboard from "./dashboard/TableDashboard";
import CardWelcome from "./dashboard/CardWelcome";
import ShareModalDashboard from "./dashboard/ShareModalDashboard";
import { useTranslation, withTranslation } from "react-i18next";
import { ApiContext } from '../context/ApiContext'
import DeleteFieldOrFarmModal from "./dashboard/DeleteFieldOrFarmModal";
const Dashboard = (props) => {
  const [farmers, setFarmers, token, setToken] = useContext(ApiContext)
  const [listFarms, setListFarm] = useState([])
  const [t, i18n] = useTranslation("global");
  const inputRef = useRef();
  const [isFocus, setIsFocus] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [notifications, setNotifications] = useState({
    emergencyDate: [],
  });
  const [shareEmail, setShareEmail] = useState("");
  const [stateLicenses, setStateLicenses] = useState({
    licensesDone: false,
    countLicenses: 0,
    allFieldsLicenses: false,
    updateMessageButton: false,
    fieldsNewLicense: [],
    calculateAreaByField: {},
    showlicensesUserModal: false,
  });

  const [state, setState] = useState({
    selectedElement:null,
    _isMounted: false,
    centerLocation: { lat: -32, lng: -57 },
    zoom: 14,
    fieldList: [],
    fieldListAux: [],
    fieldListLength: null,
    tokenUser: {
      headers: {
        // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    },
    currentUser: null,
    bounds: [],
    loadingTable: false,
    hoverFieldNumber: -1,
    hoverPolygonNumber: -1,
    hoverFarmerNumber:- 1,
    mouseOnMapStatus: false,
    showPerformanceForm: false,
    showSeedFormData: false,
    polygonId: null,
    fieldId: null,
    showDeleteModal: false,
    deleteFarmModal: false,
    deleteUrl: "",
    loading: false,
    showLoading: true,
    showContactModal: false,
    showShareModal: false,
    shareFieldId: null,
    loadingShare: false,
    //notificaciones
    uploadDateEmergencyNotify: false,
    cropData: [],
    varietyData: [],
    validCropsAndVarieties: false,
  });

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      props.history.push("/auth/login");
    } else if (localStorage.getItem("token")) {
      setState((prev) => ({
        ...prev,
        _isMounted: true,
      }));
      axios
        .get("https://agrodigital.io/api/auth/user/", {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((resUser) => {
          setState((prev) => ({
            ...prev,
            currentUser: resUser.data,
          }));
        })
        .catch((err) => console.log("errrr", err));
      fetchVarietyAndCrops();
    }
  }, []);
  useEffect(()=>{
    //llamar a los farmers
    setToken("")
  },[])
  useEffect(() => {
    if (state.fieldList.length) {
      setBounds(state.fieldList, false);
    }
    if (
      state.fieldList.length > 0 &&
      state.fieldList.length === state.fieldListLength
    ) {
      checkLicenses(state.fieldList);
    }
  }, [state.fieldList.length, state.fieldListLength]);

  useEffect(() => {
    if (state.validCropsAndVarieties) {
      fetchFieldList();
    }
  }, [state.validCropsAndVarieties]);
  
  useEffect(() => {
      if ( state.fieldList.length > 0 && (state.fieldList.length === state.fieldListLength)) {
        if(farmers.length > 0){
          setListFarm(farmers)
          for (let j = 0; j < state.fieldList.length; j++) {
            const field = state.fieldList[j];
            for (let i = 0; i < farmers.length; i++) {
              const farm = farmers[i];
              if (farm.fields.includes(field.id)) {
                farm.fields = [...farm.fields, field];
              }
            }
          }
          for (let fa of farmers) {
            fa.fields = fa.fields.filter((el) => el.id);
          }
          let allFieldsToFarmer = [];
          farmers.forEach((el) => allFieldsToFarmer.push(...el.fields));
          let fieldsNotFarmer = state.fieldList.filter(
            (el) => !allFieldsToFarmer.map((el) => el.id).includes(el.id)
          );
          let dataCompleted = farmers.concat(fieldsNotFarmer);
          setState((prev) => ({
            ...prev,
            showLoading: false,
            fieldList: dataCompleted,
            fieldListAux: dataCompleted,
          })); 
      }
      }
  }, [farmers.length, state.fieldList.length]);

  const setBounds = (fieldMap) => {
    let left = -180;
    let top = -90;
    let right = 180;
    let bottom = 90;
    let result = [
      [state.centerLocation.lng, state.centerLocation.lat],
      [state.centerLocation.lng, state.centerLocation.lat],
    ];

    let fieldList = fieldMap;
    if (fieldList) {
      for (let i = 0; i < fieldList.length; i++) {
        let polygons = fieldList[i].polygonData;
        if (polygons) {
          for (let j = 0; j < polygons.length; j++) {
            let coords = polygons[j].boundary.coordinates;
            if (coords) {
              for (let k = 0; k < coords.length; k++) {
                let coordinates = coords[k];
                for (let l = 0; l < coordinates.length; l++) {
                  let latitude = coordinates[l][1];
                  let longitude = coordinates[l][0];
                  if (latitude > top) {
                    top = coordinates[l][1];
                  }

                  if (latitude < bottom) {
                    bottom = coordinates[l][1];
                  }

                  if (longitude > left) {
                    left = longitude;
                  }

                  if (longitude < right) {
                    right = longitude;
                  }
                }
              }
            }
          }
        }
      }
      result = [
        [top, left],
        [bottom, right],
      ];
    }
    setState((prev) => ({
      ...prev,
      bounds: result,
      showLoading: false,
    }));
  };
  const checkLicenses = async (fieldList) => {
    await axios
      .get(`https://agrodigital.io/api/licenses/`, state.tokenUser)
      .then((res) => {
        let fieldsOwner = fieldList
          .map((field) => {
            if (field.owner === state.currentUser?.id) {
              return field;
            }
          })
          .filter((el) => el !== undefined);

        let validData = res.data.filter((el) => el.fields.length > 0);
        if (res.data.length > 0 && validData.length > 0) {
          //id fields que tienen registrada la licencia
          let fieldIdData = res.data.map((el) => [...el.fields, ...el.fields]);
          let fieldsIdResult = [];
          fieldIdData.forEach((element) => {
            fieldsIdResult.push(...element);
          });
          let deleteRepeat = fieldsIdResult.filter((item, index) => {
            return fieldsIdResult.indexOf(item) === index;
          });

          let countLicensesLength = res.data.filter(
            (el) => el.product === "BAS"
          ).length;
          //si son iguales significa que todos los fields tienen alta de plan
          //en caso de que se elimine una chacra esta se va a eliminar tambien del registro de licencias
          if (fieldsOwner.length === deleteRepeat.length) {
            setStateLicenses((prev) => ({
              ...prev,
              allFieldsLicenses: true,
              updateMessageButton: false,
              licensesDone: true,
            }));
          } else {
            let searchFieldId = fieldsOwner.filter(
              (el) => !deleteRepeat.includes(el.id)
            );
            let searchFieldInfo = fieldList.filter((el) =>
              searchFieldId.some((fieldId) => fieldId.id === el.id)
            );
            let calculateArea = {
              status: true,
              fieldInfo: searchFieldInfo,
            };

            setStateLicenses((prev) => ({
              ...prev,
              countLicenses: countLicensesLength,
              allFieldsLicenses: false,
              updateMessageButton: true,
              fieldsNewLicense: searchFieldInfo,
              calculateAreaByField: calculateArea,
              licensesDone: true,
            }));
          }
        } else {
          setStateLicenses((prev) => ({
            ...prev,
            updateMessageButton: false,
            fieldsNewLicense: fieldsOwner,
            licensesDone: true,
          }));
        }
      });
  };
  const validateMapStatus = () => {
    if (
      state.mouseOnMapStatus === false &&
      state.hoverFieldNumber === -1 &&
      state.hoverPolygonNumber === -1 &&
      state.showPerformanceForm
    ) {
      setBounds();
    }
  };

  const MouseOnMapStatus = (bStatus = false) => {
    if (state._isMounted) {
      setState((prev) => ({
        ...prev,
        mouseOnMapStatus: bStatus,
      }));
      if (bStatus === false) {
        setTimeout(function () {
          validateMapStatus();
        }, 6000);
      }
    }
  };
  const fetchVarietyAndCrops = async () => {
    let crops = await axios.get(
      `https://agrodigital.io/api/crops/`,
      state.tokenUser
    );
    let varieties = await axios.get(
      `https://agrodigital.io/api/varieties/`,
      state.tokenUser
    );
    if (varieties.status === 200 && crops.status === 200) {
      setState((prev) => ({
        ...prev,
        cropData: crops.data,
        varietyData: varieties.data,
        validCropsAndVarieties: true,
      }));
    }
  };
  const sendNotificationsEmergency = (field) => {
    setNotifications((prev) => ({
      ...prev,
      emergencyDate: [...prev.emergencyDate, field.name].filter(
        (item, index) => {
          return [...prev.emergencyDate, field.name].indexOf(item) === index;
        }
      ),
    }));
  };
  const checkNotificationEmergencyDate = async (field, polygon) => {
    let logPhenology = await axios.get(
      `https://agrodigital.io/api/logs/?field=${field.id}&type=Phenology`,
      state.tokenUser
    );
    //si hay datos evaluar las fechas y sino hay es por que no tiene datos cargados de emergencia
    if (logPhenology.data.length > 0) {
      let filterEmergencyByPolygons = logPhenology.data.filter(
        (el) => el.polygons.includes(polygon.id) && el.value === 1
      );
      //si hay poligonos que tengan registro de emergencia evaluar la fecha y si cumplen los dias
      //y sino hay quiere decir que tampoco cargó fecha de emergencia
      if (filterEmergencyByPolygons.length > 0) {
        let emergencyDate = filterEmergencyByPolygons.sort(
          (a, b) =>
            new Date(a.date + "T00:00:00").getTime() -
            new Date(b.date + "T00:00:00").getTime()
        )[0];
        let fecha1 = new Date(emergencyDate.date + "T00:00:00");
        let fecha2 = new Date();

        let resta = fecha2.getTime() - fecha1.getTime();
        let result = Math.round(resta / (1000 * 60 * 60 * 24));
        if (result >= 15 && result <= 60) {
          return;
        } else {
          sendNotificationsEmergency(field);
        }
      } else {
        sendNotificationsEmergency(field);
      }
    } else {
      sendNotificationsEmergency(field);
    }
  };

  const fetchFieldList = async () => {
    if (state._isMounted) {
      setState((prev) => ({
        ...prev,
        fieldList: [],
        fieldListAux: [],
        showLoading: true,
      }));
    }
    // reemplazar por el nuevo then
    let fieldsReference = await axios.get(
      "https://agrodigital.io/api/fields/",
      state.tokenUser
    );
    let listFields = fieldsReference.data.map((item, index) => {
      if (item.deleted === null) {
        let tempData = item;
        if (tempData.deleted === null && tempData) {
          tempData["id"] = item.id;
          tempData["color"] = "#22AACD";
          return tempData;
        }
      }
    });

    if (listFields.length < 1) {
      setState((prev) => ({
        ...prev,
        fieldList: [],
        fieldListAux: [],
        showLoading: false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        fieldListLength: listFields.length,
      }));
      for (let i = 0; i < listFields.length; i++) {
        const field = listFields[i];
        let seedLogReferenceSowing = axios.get(
          `https://agrodigital.io/api/logs/?field=${field.id}&type=Sowing`,
          state.tokenUser
        );
        let polygonData = axios.get(
          `https://agrodigital.io/api/polygons/?field=${field.id}`,
          state.tokenUser
        );
        let seedLogReferenceYield = axios.get(
          `https://agrodigital.io/api/logs/?field=${field.id}&type=Yield`,
          state.tokenUser
        );
        Promise.all([
          seedLogReferenceSowing,
          polygonData,
          seedLogReferenceYield,
        ]).then((res) => {
          let registroSiembra = res[0].data;
          let poligonos = res[1].data;
          let registroRendimiento = res[2].data;
          let itemPolygonResult = [];
          if (poligonos.length > 0) {
            poligonos.forEach((itemPolygon) => {
              let sowing = registroSiembra
                .filter((registro) =>
                  registro.polygons.includes(itemPolygon.id)
                )
                .sort(
                  (a, b) =>
                    new Date(a.date + "T00:00:00").getTime() -
                    new Date(b.date + "T00:00:00").getTime()
                )
                .pop();

              let yields = registroRendimiento
                .filter(
                  (registro) =>
                    registro.polygons.includes(itemPolygon.id) &&
                    registro.date >= sowing?.date
                )
                .sort(
                  (a, b) =>
                    new Date(a.date + "T00:00:00").getTime() -
                    new Date(b.date + "T00:00:00").getTime()
                )
                .pop();

              if (yields) {
                itemPolygon.performance = yields.value;
              } else {
                itemPolygon.performance = null;
              }
              if (sowing) {
                let fecha1 = new Date(sowing.date + "T00:00:00");
                let fecha2 = new Date();

                let resta = fecha2.getTime() - fecha1.getTime();
                let result = Math.round(resta / (1000 * 60 * 60 * 24));

                if (result > 180) {
                  itemPolygon.date = moment(
                    new Date(sowing.date + "T00:00:00")
                  ).format("DD-MM-YYYY");
                  itemPolygon.past = true;
                } else {
                  itemPolygon.date = moment(
                    new Date(sowing.date + "T00:00:00")
                  ).format("DD-MM-YYYY");
                  itemPolygon.past = false;
                }

                if (result >= 15 && result <= 60 && sowing.crop === 38) {
                  checkNotificationEmergencyDate(field, itemPolygon);
                }
                if (sowing.variety) {
                  itemPolygon.crop = state.cropData.filter(
                    (el) => el.id === sowing.crop
                  )[0]?.specie;
                  itemPolygon.cropName = state.cropData.filter(
                    (el) => el.id === sowing.crop
                  )[0]?.name;
                  itemPolygon.variety = state.varietyData.filter(
                    (el) => el.id === sowing.variety
                  )[0]?.name;
                } else {
                  itemPolygon.crop = state.cropData.filter(
                    (el) => el.id === sowing.crop
                  )[0]?.specie;
                  itemPolygon.cropName = state.cropData.filter(
                    (el) => el.id === sowing.crop
                  )[0]?.name;
                  itemPolygon.variety = "-";
                }
              } else {
                itemPolygon.crop = "-";
                itemPolygon.variety = "-";
                itemPolygon.date = "-";
              }
              itemPolygonResult.push(itemPolygon);
              field.polygonData = itemPolygonResult;
            });
            setState((prev) => ({
              ...prev,
              showLoading: false,
              fieldList: [...prev.fieldList, field],
              fieldListAux: [...prev.fieldList, field],
            }));
          } else {
            setState((prev) => ({
              ...prev,
              showLoading: false,
              fieldList: [...prev.fieldList, field],
              fieldListAux: [...prev.fieldList, field],
            }));
          }
        });
      }
    }
  };

  const filterFields = (e, value) => {
    e?.preventDefault();
    let resultFilterFields = [];
    if (!value) {
      resultFilterFields = state.fieldList;
    } else {
      let valueSearch = value.toLowerCase();
      let filterSearch = state.fieldList.filter((fields) => {
        if (
          //replace: reemplace los acentos
          fields.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toString()
            .toLowerCase()
            .includes(
              valueSearch.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            )
        )
          return fields;
      });
      if (filterSearch.length > 0) {
        resultFilterFields = filterSearch;
      } else {
        resultFilterFields = state.fieldList;
      }
    }
    setState((prev) => ({
      ...prev,
      fieldListAux: resultFilterFields,
    }));
  };

  const closeModal = () => {
    if (state._isMounted && state.showPerformanceForm) {
      setState((prev) => ({
        ...prev,
        showPerformanceForm: false,
      }));
    }

    if (state._isMounted && state.showSeedFormData) {
      setState((prev) => ({
        ...prev,
        showSeedFormData: false,
      }));
    }
  };

  const onSavePerformance = (value) => {
    if (value) {
      toast.success(t("alerts.saveLogYield"));
      fetchFieldList();
    } else {
      toast.error(t("alerts.saveDataError"));
    }
  };
  const onSaveSeedForm = (value) => {
    if (value) {
      toast.success(t("alerts.saveLogSowing"));
      fetchFieldList();
    } else {
      toast.error(t("alerts.saveDataError"));
    }
  };

  const onCloseContactAlert = () => {
    setState((prev) => ({
      ...prev,
      showContactModal: false,
    }));
  };

  const closeShareModal = () => {
    setState((prev) => ({
      ...prev,
      showShareModal: false,
      shareFieldId: null,
      loadingShare: false,
    }));
    setShareEmail("");
  };

  const contactModal = (
    <Modal isOpen={state.showContactModal}>
      <div className="modal-header justify-content-center">
        <h4 className="title title-up">{t("contact.attentionTitle")}</h4>
      </div>
      <div className="modal-body">
        <Row>
          <Col sm={12}>
            <label className="text-center">
              {t("contact.contactDescription")}
            </label>
          </Col>
        </Row>
      </div>
      <div className="empty-view-modal-footer">
        <Button
          className="btn-link"
          color="danger"
          data-dismiss="modal"
          type="button"
          style={{ width: "150px" }}
          onClick={() => onCloseContactAlert()}
        >
          {t("buttons.acceptButton")}
        </Button>
      </div>
    </Modal>
  );
  const seedForm = (
    <CreateAndEditSeedForm
      t={t}
      isOpen={state.showSeedFormData}
      onClose={closeModal}
      onSaveTapped={onSaveSeedForm}
      selectedPolygons={{ [state.polygonId]: {} }}
      fieldId={state.fieldId}
      selectedLog={null}
      dashboard={true}
    ></CreateAndEditSeedForm>
  );

  return (
    <>
      {state.showSeedFormData && seedForm}
      {contactModal}
      {state.showShareModal && (
        <ShareModalDashboard
          tokenUser={state.tokenUser}
          showShareModal={state.showShareModal}
          loadingShare={state.loadingShare}
          setShareEmail={setShareEmail}
          closeShareModal={closeShareModal}
          shareEmail={shareEmail}
          shareFieldId={state.shareFieldId}
        />
      )}
      {stateLicenses.showlicensesUserModal && stateLicenses.licensesDone && (
        <LicencesAdmin
          setStateLicenses={setStateLicenses}
          countLicenses={stateLicenses.countLicenses}
          allFieldsLicenses={stateLicenses.allFieldsLicenses}
          updateMessageButton={stateLicenses.updateMessageButton}
          fieldsNewLicense={stateLicenses.fieldsNewLicense}
          calculateAreaByField={stateLicenses.calculateAreaByField}
          tokenUser={state.tokenUser}
          isOpen={stateLicenses.showlicensesUserModal}
          fieldList={state?.fieldList}
          currentUser={state.currentUser}
        />
      )}
      <div className="content">
        <AdminNavbar
          notifications={notifications}
          setNotifications={setNotifications}
        />
        {!state.showLoading ? (
          state.fieldList.length <= 0 ? (
            <CardWelcome setStateDashboard={setState} />
          ) : (
            <Row>
              <Col lg={4} md={6} sm={12}>
                <Card className="card-stats" style={{ padding: "8px" }}>
                  <CardBody
                    onMouseOver={() => MouseOnMapStatus(true)}
                    onMouseOut={() => MouseOnMapStatus(false)}
                    style={{ height: "633px", padding: "0" }}
                  >
                    {state.bounds.length > 0 && state.fieldList.length > 0 && (
                      <DetailMap
                      bounds={state.bounds}
                      center={state.centerLocation}
                      zoom={state.zoom}
                      fieldList={state.fieldListAux}
                      hoverFarmerNumber={state.hoverFarmerNumber}
                      hoverFieldNumber={state.hoverFieldNumber}
                      hoverPolygonNumber={state.hoverPolygonNumber}
                      selectedElement={state.selectedElement}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={8} md={6} sm={12}>
                <Form
                  style={{ marginRight: "50%" }}
                  onSubmit={(e) => filterFields(e, inputValue)}
                >
                  <InputGroup
                    style={{ marginLeft: "50%", alignItems: "center" }}
                  >
                    <Input
                      placeholder={t("inputSearchDashboard")}
                      type="text"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      style={{
                        border: "none",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                      onFocus={() => setIsFocus(true)}
                      onBlur={() => {
                        if (!isHover) {
                          setIsFocus(false);
                        }
                      }}
                      ref={inputRef}
                    ></Input>
                    <svg
                      onClick={(e) => filterFields(e, inputValue)}
                      viewBox="0 0 1024 1024"
                      fill="currentColor"
                      width="auto"
                      color="#66615B"
                      style={{
                        position: "absolute",
                        margin: "0",
                        right: "0",
                        height: "20px",
                        background: "none",
                        border: "none",
                        padding: "0",
                        width: "35px",
                        cursor: "pointer",
                        zIndex: "3",
                      }}
                    >
                      <path d="M772 772c20 22.667 22 43.333 6 62l-46 46c-24 21.333-46.667 21.333-68 0L474 690c-49.333 28-101.333 42-156 42-85.333 0-159.667-31.667-223-95S0 499.333 0 414s30-158.333 90-219 132.667-91 218-91 160 31.667 224 95 96 137.667 96 223c0 58.667-15.333 112.667-46 162l190 188M94 414c0 58.667 22.667 110.667 68 156s97.333 68 156 68 109-21 151-63 63-93 63-153c0-58.667-22.667-110.333-68-155s-97.333-67-156-67-109 21-151 63-63 92.333-63 151" />
                    </svg>
                    {isFocus && (
                      <div
                        className="suggestions"
                        onMouseEnter={() => {
                          setIsHover(true);
                        }}
                        onMouseLeave={() => {
                          setIsHover(false);
                        }}
                      >
                        {state.fieldList.map((field, index) => {
                          const isMatch =
                            field.name
                              .toLowerCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .indexOf(
                                inputValue
                                  .normalize("NFD")
                                  .replace(/[\u0300-\u036f]/g, "")
                                  .toLowerCase()
                              ) > -1;
                          return (
                            <div key={index}>
                              {isMatch && (
                                <div
                                  className="suggestionSelect"
                                  onClick={(e) => {
                                    setInputValue(field.name);
                                    filterFields(e, field.name);
                                    inputRef.current.focus();
                                    setIsFocus(false);
                                  }}
                                >
                                  {field.name}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </InputGroup>
                </Form>

                <Card className="card-stats" st>
                  <CardBody style={{ height: "600px", zIndex: "1" }}>
                    {state.fieldList && state.fieldListAux.length > 0 && (
                      <>
                        <TableDashboard
                          lengthFields={state.fieldListLength}
                          tokenUser={state.tokenUser}
                          fieldList={state.fieldListAux}
                          currentUser={state.currentUser}
                          setStateDashboard={setState}
                          showPerformanceForm={state.showPerformanceForm}
                          _isMounted={state._isMounted}
                          hoverFieldNumber={state.hoverFieldNumber}
                          hoverPolygonNumber={props.hoverPolygonNumber}
                          centerLocation={state.centerLocation}
                          listFarms={listFarms}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
                {state.fieldList.length > 0 && (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      color="success"
                      onClick={() => {
                        setStateLicenses((prev) => ({
                          ...prev,
                          showlicensesUserModal: true,
                        }));
                      }}
                    >
                      {stateLicenses.allFieldsLicenses
                        ? t("buttons.areaSummaryButton")
                        : t("buttons.previusAreaButton")}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          )
        ) : (
          <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <Spinner
              style={{ marginTop: "100px" }}
              animation="border"
              variant={"primary"}
            ></Spinner>
          </div>
        )}
      </div>
      {state.showPerformanceForm && (
        <PerformanceForm
          t={t}
          isOpen={state.showPerformanceForm}
          onClose={closeModal}
          onSaveTapped={onSavePerformance}
          polygonId={state.polygonId}
          fieldId={state.fieldId}
        ></PerformanceForm>
      )}
      {state.showDeleteModal && (
        <DeleteFieldOrFarmModal
          fetchFarms={setToken}
          deleteFarmModal={state.deleteFarmModal}
          tokenUser={state.tokenUser}
          showDeleteModal={state.showDeleteModal}
          loading={state.loading}
          setStateDashboard={setState}
          fetchFieldList={fetchFieldList}
          deleteUrl={state.deleteUrl}
          setListFarm={setListFarm}
        />
      )}

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default withTranslation("i18n")(Dashboard);

import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import excelIcon from "../../assets/img/excelIcon.png";
import { Button, CardBody, Modal, Table } from "reactstrap";
import { ExportToCSV } from "views/components/ExportCSV";
import { useTranslation } from "react-i18next";

const LicencesAdmin = (props) => {
  const [t, i18n]= useTranslation("global")
  const [totalAreaFieldsDefault, setTotalAreaFieldsDefault] = useState(0);
  const [totalAreaFieldsNew, setTotalAreaFieldsNew] = useState(0);
  const [validButton, setValidButton] = useState(false);
  const [showNewPlanView, setShowNewPlanView] = useState(false);
  const [selectedFarmOptions, setSelectedFarmOptions] = useState([])
  const [allFields, setAllFields] = useState([])

  useEffect(() => {
    calculateAreaByField();
  }, []);

  // useEffect(() => {
  //   if (props.calculateAreaByField?.status === true) {
  //     calculateAreaByField(true, props.calculateAreaByField?.fieldInfo);
  //   }
  // }, [props.calculateAreaByField?.status]);

  useEffect(() => {
    if (props.allFieldsLicenses || totalAreaFieldsDefault === 0) {
      setValidButton(true);
    } else {
      setValidButton(false);
    }
  }, [props.allFieldsLicenses, totalAreaFieldsDefault]);

  //CHEQUEAR_
  // EN CASO DE QUE EL FARMER NO TENGA CHACRAS
  const calculateAreaByField = (newArea = false, fieldNewPlan = null) => {
    let allFieldsResult =[]
    let fieldsArea= 0
    let checkFarms = props.fieldList.filter((el)=> el.fields)
    let checkFields = props.fieldList.filter((el)=> !el.fields && el.owner === props.currentUser?.id)
    if(checkFarms.length > 0){
     let fieldsAreaInFarm= checkFarms.map((el) => [...el.fields])
     let fields = [];
     fieldsAreaInFarm.forEach((element) => {
      allFieldsResult.push(...element)
      fields.push(...element);
     });
     let areaTotalFarms = fields.map((el)=> el.area).reduce((a, b) => a + b)
     fieldsArea += areaTotalFarms
    }

    if(checkFields.length > 0){
    allFieldsResult.push( ...checkFields)
    let areaTotalFields = checkFields.map((el)=> el.area).reduce((a, b) => a + b)
     fieldsArea += areaTotalFields
    }
    setAllFields(allFieldsResult)
    setTotalAreaFieldsDefault(fieldsArea.toFixed(1))
  };

  const createLicenses = () => {
    let fieldsId = props.fieldsNewLicense.map((el) => el.id);
    let count = props.countLicenses + 1;

    let bodyLicensesBAS = {
      name: `${props.currentUser?.first_name} ${props.currentUser.last_name} #${count}`,
      max_area: 0,
      product: "BAS",
      fields: fieldsId,
    };
    let bodyLicensesPLA = {
      name: `${props.currentUser?.first_name} ${props.currentUser.last_name} #${count}`,
      max_area: 0,
      product: "PLA",
      fields: fieldsId,
    };

    let licensesBAS = axios.post(
      "https://agrodigital.io/api/licenses/",
      bodyLicensesBAS,
      props.tokenUser
    );
    let licensesPLA = axios.post(
      "https://agrodigital.io/api/licenses/",
      bodyLicensesPLA,
      props.tokenUser
    );
    Promise.all([licensesBAS, licensesPLA]).then((res) => {
      let basResponse = res[0].data;
      let plaResponse = res[1].data;

      if (basResponse.id && plaResponse.id) {
        //toast notify
        toast.success(t("alerts.sendRequest"));
        props.setStateLicenses((prev) => ({
          ...prev,
          showlicensesUserModal: false,
        }));
      }
    });
  };
  const exportDataAreaCSV = () => {
    let data = [];
    let jsonData = [];

    props.fieldList.forEach((field) => {
      if (field.owner === props.currentUser?.id) {
        if(field.fields?.length){
          let areaInFarm = field.fields.map((el)=> el.area)
          let areaResult= areaInFarm.length > 1 ? areaInFarm.reduce((a, b) => a + b)?.toFixed(1) : areaInFarm[0]?.toFixed(1);
          data.push({ name: field.name, area: Number(areaResult) });
        } else{
          data.push({ name: field.name, area: field.area });
        }
      }
    });
    data.push({ name: "", area: "" });
    data.push({ name: t("globals.totalArea"), area: totalAreaFieldsDefault })

    jsonData[t("alerts.areSummary")] = data;
    ExportToCSV.export(
      jsonData,
      [
        {
          name: t("globals.name"),
          area: t("globals.area"),
        },
      ],
      [t("globals.name"), t("globals.area")],
      t("globals.areaSum"),
      90
    );
  };
  const defaultPlanView = (
    <div>
      <CardBody style={{ height: "300px" }}>
        <Table
          responsive
          hover={false}
          className="detail-table"
          style={{
            paddingBottom: "2px",
            overflow: "auto!important",
            maxHeight: "600px !important",
            height: "590px !important",
          }}
        >
          <thead className="text-primary" style={{ textAlign: "center" }}>
            <tr className="text-primary">
              <th></th>
              <th>{t("globals.field")}</th>
              <th>{t("globals.area")}</th>
            </tr>
          </thead>
          {props.fieldList.length > 0 &&
            props.fieldList.map((field, fieldIndex) => {
              let areaTotalFarmer=[]
              let allOptionsFarmer = selectedFarmOptions.includes(field.id);
              if (field.owner === props.currentUser?.id) {
                if(field.fields?.length > 0){
                 let fieldsFarmerId = field.fields.map((el) => el.area);
                areaTotalFarmer = fieldsFarmerId.length > 1
                ? fieldsFarmerId.reduce((a, b) => a + b)?.toFixed(1)
                : fieldsFarmerId[0]?.toFixed(1);
                }
                return (
                  <tbody>
                    {field.fields?.length > 0 ? (
                      <>
                        <tr key={fieldIndex} 
                        onClick={() => {
                          let repeat = selectedFarmOptions.includes(field.id);
                          if (repeat) {
                            let remove = selectedFarmOptions.filter(
                              (el) => el !== field.id
                            );
                            setSelectedFarmOptions(remove);
                          } else {
                            setSelectedFarmOptions([
                              ...selectedFarmOptions,
                              field.id,
                            ]);
                          }
                        }}
                        >
                          <td>
                            <b
                              className="fa fa-caret-down"
                              style={{transform: allOptionsFarmer && "rotate(180deg)", fontSize:"medium"}}
                            />
                          </td>
                          <td
                          className="text-center"
                            style={{ paddingLeft: "15px", textAlign: "center" }}
                          >
                            {field.name}
                          </td>
                          <td style={{ textAlign: "center" }}>{areaTotalFarmer &&areaTotalFarmer} has</td>
                        </tr>
                        {field.fields.length && allOptionsFarmer && field.fields?.map((fieldFarm, fieldFarmIndex) => (
                          <tr key={fieldFarmIndex}>
                            <td></td>
                            <td className="text-center">{fieldFarm.name}</td>
                            <td className="text-center"> {fieldFarm.area?.toFixed(1)}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr key={fieldIndex}>
                        <td></td>
                        <td
                          style={{ paddingLeft: "15px", textAlign: "center" }}
                        >
                          {field.name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {field.area % 1 != 0
                            ? field.area?.toFixed(1)
                            : field.area}
                        </td>
                      </tr>
                    )}
                  </tbody>
                );
              }
            })}
        </Table>
      </CardBody>
      <p style={{ textAlign: "center", fontWeight: "bold", paddingBottom:"10px" }}>
        {`${t("globals.totalArea")}:`} {totalAreaFieldsDefault}
      </p>
    </div>
  );

  const newPlanView = (
    <div>
      <CardBody style={{ height: "300px" }}>
        <Table
          responsive
          hover={false}
          className="detail-table"
          style={{
            paddingBottom: "2px",
            overflow: "auto!important",
            maxHeight: "600px !important",
            height: "590px !important",
          }}
        >
          <thead className="text-primary" style={{ textAlign: "center" }}>
            <tr className="text-primary">
              <th>{t("globals.field")}</th>
              <th>{t("globals.area")}</th>
            </tr>
          </thead>
          {props.fieldsNewLicense.length > 0 &&
            props.fieldsNewLicense.map((field, fieldIndex) => {
              return (
                <tbody>
                  <tr key={fieldIndex}>
                    <td style={{ paddingLeft: "15px", textAlign: "center" }}>
                      {field.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {field.area % 1 != 0
                        ? field.area?.toFixed(1)
                        : field.area}
                    </td>
                  </tr>
                </tbody>
              );
            })}
        </Table>
      </CardBody>
      <p style={{ textAlign: "center", fontWeight: "bold" }}>
        {`${t("globals.totalArea")}:`} {totalAreaFieldsNew}
      </p>
    </div>
  );
  return (
    <Modal isOpen={props.isOpen}>
      <button
        style={{
          background: "none",
          padding: "10px",
          border: "none",
          position: "absolute",
          right: "0",
        }}
        onClick={() => {
          props.setStateLicenses((prev) => ({
            ...prev,
            showlicensesUserModal: false,
          }));
        }}
      >
        {" "}
        <svg
          fill="none"
          viewBox="0 0 24 24"
          color="red"
          style={{ height: "20px" }}
        >
          <path
            fill="currentColor"
            d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z"
          />
        </svg>
      </button>
      <div
        className="modal-header justify-content-center"
        style={{ border: "none" }}
      >
        <h4 className="title title-up">
          {!showNewPlanView
            ? t("globals.summaryEnteredAreas")
            : t("globals.summaryAreaWithoutPlan")}
        </h4>
      </div>
      <div style={{ textAlign: "center" }}>
        <button
          style={{
            padding: "0px",
            border: "none",
            outline: "none",
          }}
          onClick={() => exportDataAreaCSV()}
        >
          <img src={excelIcon} />
        </button>
      </div>
      {!showNewPlanView ? defaultPlanView : newPlanView}
      {/* <div className="empty-view-modal-footer">
        {props.updateMessageButton ? (
          <>
            {showNewPlanView && (
              <Button
                className="btn-link"
                color="secondary"
                data-dismiss="modal"
                type="button"
                style={{ height: "50px" }}
                onClick={() => {
                  setShowNewPlanView(false);
                }}
              >
               {t("globals.backToSummary")}
              </Button>
            )}
            <Button
              className="btn-link"
              color="success"
              data-dismiss="modal"
              type="button"
              style={{ height: "50px" }}
              onClick={() => {
                if (showNewPlanView) {
                  createLicenses();
                } else {
                  setShowNewPlanView(true);
                }
              }}
              disabled={validButton}
            >
             {t("globals.expandDownloadPlan")}
            </Button>
          </>
        ) : (
          <>
            <Button
              className="btn-link"
              color="success"
              data-dismiss="modal"
              type="button"
              style={{ height: "50px" }}
              onClick={() => {
                createLicenses();
              }}
              disabled={validButton}
            >
             {t("globals.subscriptionPlan")}
            </Button>
          </>
        )}
      </div> */}
      <Toaster />
    </Modal>
  );
};

export default LicencesAdmin;

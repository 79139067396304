import SubPolygon from "components/Maps/subPolygon";
import React, { useState } from "react";
import { FeatureGroup, Map, Marker } from "react-leaflet";
import { getCropIcon } from "../../../../components/Maps/mapIcons";
import L from "leaflet";
import MapLink from "../../../../components/Maps/MapLink";


const MapFields = (props) => {
    const  getPolygons = (polygon) => {
        let coordinates = polygon?.boundary.coordinates[0];
        let result = [];
        for (let i = 0; i < coordinates?.length; i++) {
          let coords = coordinates[i];
          let temp = [];
          //long
          temp[1] = coords[0];
          //lat
          temp[0] = coords[1];
    
          result.push(temp);
        }
        return result;
      };

      const getIconInfo = (crop = "") => {
        let iconUrl = "";
    
        if (!crop) {
          return null;
        }
    
        iconUrl = getCropIcon(crop);
        if (!iconUrl) {
          return null;
        }
    
        let iconInfo = L.icon({
          iconUrl: iconUrl,
          iconRetinaUrl: iconUrl,
          iconAnchor: null,
          shadowUrl: null,
          shadowSize: null,
          shadowAnchor: null,
    
          iconSize: [20, 20],
        });
        return iconInfo;
      };
      const getColor = () => {
        var colors = ["#88ebcd", "#00EE66", "#22AAcd", "#9999cd"];
        return colors[3];
      };
      return  (
        <>
      
        {props.isPolygons ? (
          <Map
            center={props.center}
            bounds={props.bounds}
            style={{ width: "100%", height: "100%", borderRadius: "5px" }}
            /*zoom={props.zoom}*/ zoomControl={false}
          >
            <MapLink />
            <FeatureGroup>
              {props.polygonsList?.map(
                (polygon, index) => 
                    <FeatureGroup key={index}>
                      {polygon.id &&
                          <SubPolygon
                          id={polygon.id}
                          reorgFields={true}
                          scrollToElement={props.scrollToElement}
                            key={index}
                            iconInfo={getIconInfo(polygon.crop)}
                            positions={getPolygons(polygon)}
                            hoverStatus={
                                props.hoverPolygonNumber === index
                                ? true
                                : false
                            }
                            name={polygon.name}
                          />
                      }
                    </FeatureGroup>
              )}
            </FeatureGroup>
          </Map>

        ): (  <Map
          center={props.center}
          bounds={props.bounds}
          style={{ width: "100%", height: "100%", borderRadius: "5px" }}
          /*zoom={props.zoom}*/ zoomControl={false}
        >
          <MapLink />
          {props.fieldList.map(
            (field, index) =>
                <FeatureGroup key={index} color={props.hoverFieldNumber === index ? "#FF8000" : "#3388ff" }>
                  {field.polygons &&
                    field.polygons.map((polygon, subIndex) => (
                      <SubPolygon
                        id={polygon.id}
                        reorgFields={true}
                        scrollToElement={props.scrollToElement}
                        key={subIndex}
                        iconInfo={getIconInfo(polygon.crop)}
                        positions={getPolygons(polygon)}
                        hoverStatus={
                          props.hoverFieldNumber === index &&
                          (props.hoverPolygonNumber === -1 ||
                            props.hoverPolygonNumber === subIndex)
                            ? true
                            : false
                        }
                        name={polygon.name}
                      />
                    ))}
                </FeatureGroup>
          )}
        </Map>
)}
      </>
      );
};

export default MapFields;

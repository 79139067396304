/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from "axios";
import React, { useEffect, useState } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Select from "react-select";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const Register = () => {
  const [t, i18n] = useTranslation("global");

  const history = useHistory();
  const [register, setRegister] = useState({
    email: "",
    password: "",
    confirmPass: "",
    first_name: "",
    last_name: "",
    company: "",
    phone: "",
    country: "",
  });

  const [valid, setValid] = useState({
    validEmail: true,
    validPassword: true,
    validConfirmPass: true,
    validiFirst_name: true,
    validLast_name: true,
    validCompany: true,
    validPhone: true,
    terms: false,
  });

  const [alert, setAlert] = useState(null);
  const handleChange = ({ target: { name, value } }) => {
    if (name === "email") {
      verifyEmail(value);
    } else if (name === "password") {
      verifyPassword(value);
    } else if (name === "first_name") {
      if (value.length >= 3) {
        setValid({ ...valid, validiFirst_name: true });
      } else {
        setValid({ ...valid, validiFirst_name: false });
      }
    } else if (name === "company") {
      if (value.length >= 3) {
        setValid({ ...valid, validCompany: true });
      } else {
        setValid({ ...valid, validCompany: false });
      }
    } else if (name === "last_name") {
      if (value.length >= 3) {
        setValid({ ...valid, validLast_name: true });
      } else {
        setValid({ ...valid, validLast_name: false });
      }
    } else if (name === "phone") {
      verifyNumber(value);
    }
    setRegister({ ...register, [name]: value });
  };

  const verifyEmail = (value) => {
    var emailRex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // if (value.length === 0) {
    // }
    if (emailRex.test(value)) {
      setValid({ ...valid, validEmail: true });
      return true;
    } else {
      setValid({ ...valid, validEmail: false });
      return false;
    }
  };

  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[+]{0,1}[0-9]+$");
    if (numberRex.test(value) && value.length >= 6) {
      setValid({ ...valid, validPhone: true });
      return true;
    } else {
      setValid({ ...valid, validPhone: false });
      return false;
    }
  };

  const verifyPassword = (value) => {
    const passRex = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");
    if (passRex.test(value) && value.length >= 8) {
      setValid({ ...valid, validPassword: true });
      return true;
    } else {
      setValid({ ...valid, validPassword: false });
      return false;
    }
  };

  const checkPassword = () => {
    if (register.password === register.confirmPass) {
      setValid({ ...valid, validConfirmPass: true });
      return true;
    } else {
      setValid({ ...valid, validConfirmPass: false });
      return false;
    }
  };

  const checkTerms = () => {
    if (valid.terms) {
      return true;
    } else {
      return false;
    }
  };

  const createError = (error) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Error"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      >
        {error}
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 5000);
  };
  const loginSuccess = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title=""
        onConfirm={() => hideAlert}
        showConfirm={false}
      >
       {t("alerts.loginSuccess")}
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 2000);
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const checkResult = () => {
    if (checkPassword() && checkTerms()) {
      return axios
        .post("https://agrodigital.io/api/auth/register/", {
          email: register.email,
          password: register.password,
          first_name: register.first_name,
          last_name: register.last_name,
          company: register.company,
          phone: register.phone,
          country: register.country,
        })
        .then((registerBody) => {
          axios
            .post("https://agrodigital.io/api/auth/", {
              username: registerBody.data.email,
              password: register.password,
            })
            .then((login) => {
              let tokenUser = login.data.token;
              if (login.status === 200 && tokenUser) {
                localStorage.setItem("token", tokenUser);
                if (localStorage.getItem("token")) {
                  loginSuccess();
                  setTimeout(() => {
                    history.push("/admin/dashboard");
                  }, 3000);
                }
              }
            });
        })
        .catch((error) => {
          const request = Object.values(error);
          const message = request[2].request;
          if (
            message.responseText ===
            `{"email":["user with this email address already exists."]}`
          ) {
            createError(t("alerts.emailAlreadyExist"));
          }
        });
    } else {
      createError(t("alerts.registerError"));
    }
  };

  return (
    <div className="register-page">
      <Container>
        {alert}
        <Row>
          <Col className="ml-auto" lg="5" md="5">
            <div className="info-area info-horizontal mt-5">
              <div className="icon icon-primary">
                <i className="fi flaticon-satelite" />
              </div>
              <div className="description">
                <h5 className="info-title">{t("description.objective")}</h5>
                <p className="description" style={{ textAlign: "left" }}>
                {t("description.objetiveDescription")}
                </p>
              </div>
            </div>
            <div className="info-area info-horizontal">
              <div className="icon icon-primary">
                <i className="fi flaticon-intercambio" />
              </div>
              <div className="description">
                <h5 className="info-title">{t("description.scope")}</h5>
                <p className="description" style={{ textAlign: "left" }}>
                {t("description.scopeDescription")}
                </p>
              </div>
            </div>
            <div className="info-area info-horizontal">
              <div className="icon icon-info">
                <i className="fi flaticon-arroz" />
              </div>
              <div className="description">
                <h5 className="info-title">{t("description.reason")}</h5>
                <p className="description" style={{ textAlign: "left" }}>
                {t("description.reasonDescription")}
                </p>
              </div>
            </div>
          </Col>
          <Col className="mr-auto" lg="4" md="6">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">{t("loginAndRegisterScreen.createAccountTitle")}</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="" className="form" method="">
                  <InputGroup
                    className={
                      !valid.validiFirst_name
                        ? "has-label has-danger"
                        : "has-label has-success"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginAndRegisterScreen.namePlaceholderText")}
                      type="text"
                      name="first_name"
                      onChange={handleChange}
                    />
                    {!valid.validiFirst_name ? (
                      <label className="error">{t("loginAndRegisterScreen.nameError")}</label>
                    ) : null}
                  </InputGroup>
                  <InputGroup
                    className={
                      !valid.validLast_name
                        ? "has-label has-danger"
                        : "has-label has-success"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginAndRegisterScreen.lastNamePlaceholderText")}
                      type="text"
                      name="last_name"
                      onChange={handleChange}
                    />
                    {!valid.validLast_name ? (
                      <label className="error">{t("loginAndRegisterScreen.lastNameError")}</label>
                    ) : null}
                  </InputGroup>
                  <InputGroup
                    className={
                      !valid.validCompany
                        ? "has-label has-danger"
                        : "has-label has-success"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-briefcase-24" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginAndRegisterScreen.companyPlaceholderText")}
                      type="text"
                      name="company"
                      onChange={handleChange}
                    />
                    {!valid.validCompany ? (
                      <label className="error">{t("loginAndRegisterScreen.companyError")}</label>
                    ) : null}
                  </InputGroup>
                  <InputGroup
                    className={
                      !valid.validEmail
                        ? "has-label has-danger"
                        : "has-label has-success"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginAndRegisterScreen.emailPlaceholderText")}
                      type="email"
                      autoComplete="UserName"
                      name="email"
                      onChange={handleChange}
                    />
                    {!valid.validEmail ? (
                      <label className="error">
                        {t("loginAndRegisterScreen.emailError")}
                      </label>
                    ) : null}
                  </InputGroup>
                  <InputGroup
                    className={
                      !valid.validPhone
                        ? "has-label has-danger"
                        : "has-label has-success"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-mobile" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginAndRegisterScreen.phonePlaceholderText")}
                      type="tel"
                      name="phone"
                      onChange={handleChange}
                    />
                    {!valid.validPhone ? (
                      <label className="error">{t("loginAndRegisterScreen.phoneError")}</label>
                    ) : null}
                  </InputGroup>
                  <InputGroup
                    className={
                      !valid.validPassword
                        ? "has-label has-danger"
                        : "has-label has-success"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginAndRegisterScreen.passwordPlaceholderText")}
                      type="password"
                      autoComplete="password"
                      name="password"
                      onChange={handleChange}
                    />
                    {!valid.validPassword ? (
                      <label className="error">
                        {t("loginAndRegisterScreen.passwordError")}
                      </label>
                    ) : null}
                  </InputGroup>

                  <InputGroup
                    className={
                      !valid.validConfirmPass
                        ? "has-label has-danger"
                        : "has-label has-success"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="Confirmar contraseña"
                      type="password"
                      name="confirmPass"
                      onChange={handleChange}
                    />
                    {!valid.validConfirmPass ? (
                      <label className="error">{t("loginAndRegisterScreen.confirmPasswordError")}
                      </label>
                    ) : null}
                  </InputGroup>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    options={[
                      { value: "ARG", label: "Argentina" },
                      { value: "BRA", label: "Brasil" },
                      { value: "BOL", label: "Bolivia" },
                      { value: "COL", label: "Colombia" },
                      { value: "USA", label: "Estados Unidos" },
                      { value: "PRY", label: "Paraguay" },
                      { value: "PER", label: "Perú" },
                      { value: "PRT", label: "Portugal" },
                      { value: "VEN", label: "Venezuela" },
                      { value: "URY", label: "Uruguay" },
                    ]}
                    placeholder="Pais"
                    onChange={(country) =>
                      setRegister({ ...register, country: country.value })
                    }
                  />

                  <FormGroup check className="text-left">
                    <Label check>
                      <Input
                        type="checkbox"
                        onChange={(e) =>
                          setValid({ ...valid, terms: e.target.checked })
                        }
                      />
                      <span className="form-check-sign" />
                      {t("loginAndRegisterScreen.checkAceptTerms")}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                      </a>
                    </Label>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-round"
                  color="info"
                  onClick={() => {
                    checkResult();
                  }}
                >
                  {t("loginAndRegisterScreen.createAccount")}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/arroz.jpg")})`,
        }}
      />
    </div>
  );
};

export default Register;

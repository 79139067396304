import React, { Component, useEffect, useState } from "react";
import { FeatureGroup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import RightSidebar from "../../../components/Maps/sideBar";
import MapPart from "../../../components/Maps/mapPart";
import LoadingOverlay from "react-loading-overlay";
import { Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const cropMap = () => {
  const [t, i18n] = useTranslation("global")
  const [mapCrop, setMapCrop] = useState({
    centerLocation: { lat: -32, lng: -57 },
    zoom: 14,
    fieldList: [],
    fieldListLength: null,
    fieldsUrl: "fields",
    openSidebar: false,
    sideBarInfo: [],
    clickPolygon: [],
    currentUser: {
      headers: {
        // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    },
    bounds: [],
    loading: true,
    done: false,
    cropData: [],
    varietyData: [],
    validCropsAndVarieties: false,
  });

  useEffect(() => {
    if (!mapCrop.currentUser) {
      history.push("/auth/login");
    } else {
      fetchCropsAndVarieties();
    }
  }, []);

  useEffect(() => {
    if (mapCrop.validCropsAndVarieties) {
      fetch();
    }
  }, [mapCrop.validCropsAndVarieties]);

  useEffect(() => {
    if (
      mapCrop.fieldList.length > 0 &&
      mapCrop.fieldList.length === mapCrop.fieldListLength
    ) {
      setBounds(mapCrop.fieldList);
    }
  }, [mapCrop.fieldList.length, mapCrop.fieldListLength]);

  const fetchCropsAndVarieties = async () => {
    let crops = await axios.get(
      `https://agrodigital.io/api/crops/`,
      mapCrop.currentUser
    );
    let varieties = await axios.get(
      `https://agrodigital.io/api/varieties/`,
      mapCrop.currentUser
    );
    if (varieties.status === 200 && crops.status === 200) {
      setMapCrop((prev) => ({
        ...prev,
        cropData: crops.data,
        varietyData: varieties.data,
        validCropsAndVarieties: true,
      }));
    }
  };

  const fetch = async () => {
    let fieldsReference = await axios.get(
      "https://agrodigital.io/api/fields/",
      mapCrop.currentUser
    );
    let listFields = fieldsReference.data.map((item, index) => {
      if (item.deleted === null) {
        let tempData = item;
        tempData["id"] = item.id;
        tempData["color"] = getColor();
        return tempData;
      }
    });
    if (listFields.length === 0) {
      setMapCrop((prev) => ({
        ...prev,
        fieldList: [],
      }));
      setBounds();
    } else {
      setMapCrop((prev) => ({
        ...prev,
        fieldListLength: listFields.length,
      }));
      for (let i = 0; i < listFields.length; i++) {
        const field = listFields[i];
        let seedLogReferenceSowing = axios.get(
          `https://agrodigital.io/api/logs/?field=${field.id}&type=Sowing`,
          mapCrop.currentUser
        );
        let polygonData = axios.get(
          `https://agrodigital.io/api/polygons/?field=${field.id}`,
          mapCrop.currentUser
        );
        Promise.all([seedLogReferenceSowing, polygonData]).then((res) => {
          let registroSiembra = res[0].data;
          let poligonos = res[1].data;
          let itemPolygonResult = [];
          poligonos.forEach((itemPolygon) => {
            if (itemPolygon.deleted === null) {
              let sowing = registroSiembra
                .filter((registro) =>
                  registro.polygons.includes(itemPolygon.id)
                )
                .sort(
                  (a, b) =>
                    new Date(a.date + "T00:00:00").getTime() -
                    new Date(b.date + "T00:00:00").getTime()
                )
                .pop();
              if (sowing) {
                itemPolygon.date = sowing.date;
                if (sowing.variety) {
                  itemPolygon.crop = mapCrop.cropData.filter(
                    (el) => el.id === sowing.crop
                  )[0]?.specie;
                  itemPolygon.cropName = mapCrop.cropData.filter(
                    (el) => el.id === sowing.crop
                  )[0]?.name;
                  itemPolygon.variety = mapCrop.varietyData.filter(
                    (el) => el.id === sowing.variety
                  )[0]?.name;
                } else {
                  itemPolygon.crop = mapCrop.cropData.filter(
                    (el) => el.id === sowing.crop
                  )[0]?.specie;
                  itemPolygon.cropName = mapCrop.cropData.filter(
                    (el) => el.id === sowing.crop
                  )[0]?.name;
                  itemPolygon.variety = "-";
                }
              } else {
                itemPolygon.crop = "-";
                itemPolygon.variety = "-";
                itemPolygon.date = "-";
              }
              itemPolygonResult.push(itemPolygon);
              field.polygonData = itemPolygonResult;
            }
          });
          setMapCrop((prev) => ({
            ...prev,
            fieldList: [...prev.fieldList, field],
          }));
        });
      }
    }
  };

  ////////////////////////////////////////////////////////////////
  const onOpenSidebar = (polygon, field) => {
    setMapCrop((prev) => ({
      ...prev,
      sideBarInfo: field,
      clickPolygon: polygon,
    }));
    setTimeout(() => {
      setMapCrop((prev) => ({
        ...prev,
        openSidebar: true,
      }));
    }, 1500);
  };

  const onCloseSidebar = () => {
    setMapCrop((prev) => ({
      ...prev,
      openSidebar: false,
    }));
  };

  const setBounds = (fieldMap) => {
    let left = -180;
    let top = -90;
    let right = 180;
    let bottom = 90;
    let fieldList = fieldMap;
    let result = [
      [-30, -59],
      [-35, -52],
    ];
    if (fieldList) {
      for (let i = 0; i < fieldList.length; i++) {
        let polygons = fieldList[i].polygonData;
        if (polygons) {
          for (let j = 0; j < polygons.length; j++) {
            let coords = polygons[j].boundary.coordinates;
            if (coords) {
              for (let k = 0; k < coords.length; k++) {
                let coordinates = coords[k];
                for (let l = 0; l < coordinates.length; l++) {
                  let latitude = coordinates[l][1];
                  let longitude = coordinates[l][0];
                  if (latitude > top) {
                    top = coordinates[l][1];
                  }

                  if (latitude < bottom) {
                    bottom = coordinates[l][1];
                  }

                  if (longitude > left) {
                    left = longitude;
                  }

                  if (longitude < right) {
                    right = longitude;
                  }
                }
              }
            }
          }
        }
      }
      result = [
        [top, left],
        [bottom, right],
      ];
    }
    setMapCrop((prev) => ({
      ...prev,
      bounds: result,
      loading: false,
    }));
  };
  const getColor = () => {
    const random = "#" + Math.floor(Math.random() * 16777215).toString(16);

    return random;
  };

  return (
    <LoadingOverlay active={mapCrop.loading} spinner text={t("globals.loading")}>
      <>
        <RightSidebar
          width={280}
          onHideSidebar={() => {
            onCloseSidebar();
          }}
          open={mapCrop.openSidebar}
          field={mapCrop.sideBarInfo}
          polygon={mapCrop.clickPolygon}
        />
        {mapCrop.bounds.length > 0 && mapCrop.fieldList.length > 0 ? (
          <MapPart
            bounds={mapCrop.bounds}
            center={mapCrop.centerLocation}
            zoom={mapCrop.zoom}
            fieldList={mapCrop.fieldList}
            onShowSidebar={(polygon, field) => onOpenSidebar(polygon, field)}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100vh",
              backgroundColor: "#fff",
            }}
          ></div>
        )}

        {mapCrop.openSidebar ? (
          <div
            style={{
              position: "fixed",
              bottom: "40px",
              right: "300px",
              zIndex: 500,
            }}
          >
            <Button
              className="btn-round btn-lg"
              style={{ zIndex: 500 }}
              color="success"
              tag={NavLink}
              to="/admin/cropmap/add-field"
            >
              <i className="fa fa-plus" />
              {t("buttons.addFieldButton")}
            </Button>
          </div>
        ) : (
          <div
            style={{
              position: "fixed",
              bottom: "40px",
              right: "40px",
              zIndex: 500,
            }}
          >
            <Button
              className="btn-round btn-lg"
              style={{ zIndex: 500 }}
              color="success"
              tag={NavLink}
              to="/admin/cropmap/add-field"
            >
              <i className="fa fa-plus" />
              {t("buttons.addFieldButton")}
            </Button>
          </div>
        )}
      </>
    </LoadingOverlay>
  );
};

export default cropMap;

import React, { Fragment } from "react";
import SubPolygon from "./subPolygon";
import { getCropIcon } from "./mapIcons";
import L from "leaflet";

const CustomPolygons = (props) => {
  const { field } = props;

  const getPolygons = (polygon) => {
    let coordinates = polygon?.boundary.coordinates[0];
    let result = [];
    for (let i = 0; i < coordinates?.length; i++) {
      let coords = coordinates[i];
      let temp = [];
      //long
      temp[1] = coords[0];
      //lat
      temp[0] = coords[1];

      result.push(temp);
    }
    return result;
  };

  const getIconInfo = (crop = "") => {
    let iconUrl = "";

    if (crop === "") {
      return null;
    } else {
      iconUrl = getCropIcon(crop);
    }

    if (!iconUrl) {
      return null;
    }

    let iconInfo = L.icon({
      iconUrl: iconUrl,
      iconRetinaUrl: iconUrl,
      iconAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,

      iconSize: [20, 20],
    });

    return iconInfo;
  };

  let polygons = field.polygonData;
  if (polygons) {
    const polygonComponents = polygons.map((polygon, index) => (
      <SubPolygon
        iconInfo={getIconInfo(polygon.crop)}
        onClick={() => props.onShowSidebar(polygon)}
        key={index}
        positions={getPolygons(polygon)}
        name={polygon.name}
      />
    ));
    return <Fragment>{polygonComponents}</Fragment>;
  }
  return <Fragment>{}</Fragment>;
};

export default CustomPolygons;

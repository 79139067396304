/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  FormGroup,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Button from "reactstrap/es/Button";
import EditCtrl from "../../../../components/Maps/mapEditCtrl";
import { createBrowserHistory } from "history";
import Modal from "reactstrap/es/Modal";
import LoadingOverlay from "react-loading-overlay";
import Dropzone from "../components/Dropzone";
import axios from "axios";
import ReutilizarPol from "./reutilizarAddField/ReutilizarPol";
const backPolygonsColor = "#ffe913";
const createdPolygonsColor = "#d15f0d";
const drawingPolygonsColor = "#26f5fa";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenUser: null,
      openedCollapses: ["collapseOne", "collapse1"],
      reutilizarPol: false,
      mapShow: false,

      centerLocation: { lat: -32, lng: -57 },
      zoom: 14,
      initGeoJson: [],
      polygonName: "",
      coordinates: [],
      polygonSelected: [],
      polygonStatus: true,

      polygonsArr: [],

      fieldList: [],
      listPolygonsReut: [],
      fieldsUrl: "",
      bounds: [
        [-90, -180],
        [90, 180],
      ],

      showPolygonNameModal: false,
      onlyFirst: true,

      showKmlModal: false,
      kmlPolygonData: [],
    };

    this.selectedReutPolygon = this.selectedReutPolygon.bind(this);
  }

  removeDuplicates = (array) => {
    return array.filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o.id === obj.id && o.name === obj.name)
    );
  };

  selectedReutPolygon = (polResult, status) => {
    if (status === "add") {
      this.props.setDataWizard("polygon",this.removeDuplicates([...this.state.polygonsArr, polResult]))

      this.setState((prev) => ({
        polygonsArr: this.removeDuplicates([...prev.polygonsArr, polResult]),
        mapShow: true,
      }));
    }

    if (status === "delete") {
      const newState = (prev) => {
        return prev.filter((el) => el.id != polResult);
      };
      this.props.setDataWizard("polygon",newState(this.state.polygonsArr))
      this.setState((prev) => ({
        polygonsArr: newState(prev.polygonsArr),
        mapShow: true,
      }));
    }
  };

  getBounds = () => {
    var left = -57;
    var top = -32;
    var right = 57;
    var bottom = 32;

    let fieldList = this.state.fieldList;
    if (fieldList) {
      for (let i = 0; i < fieldList.length; i++) {
        let polygons = fieldList[i].polygonData;
        if (polygons.length > 0) {
          for (let j = 0; j < polygons.length; j++) {
            let coordinates = polygons[i]?.boundary.coordinates[0];
            if (coordinates) {
              for (let k = 0; k < coordinates.length; k++) {
                let coords = coordinates[k];

                let latitude = coords[1];
                let longitude = coords[0];
                if (latitude > top) {
                  top = coords[1];
                }

                if (latitude < bottom) {
                  bottom = coords[1];
                }

                if (longitude > left) {
                  left = longitude;
                }

                if (longitude < right) {
                  right = longitude;
                }
              }
            }
          }
        }
      }
    }

    let result = [
      [top, left],
      [bottom, right],
    ];
    return result;
  };

  componentDidMount() {
    const fetch = async () => {
      let userInfo = await axios.get(
        "https://agrodigital.io/api/auth/user/",
        this.state.tokenUser
      );
      let fieldData = await axios.get(
        "https://agrodigital.io/api/fields/",
        this.state.tokenUser
      );
      const allPolygons = await axios.get(
        `https://agrodigital.io/api/polygons/`,
        this.state.tokenUser
      );
      let filterFieldOwner = fieldData.data.filter(
        (el) => el.owner === userInfo.data.id
      );
      if (filterFieldOwner.length > 0) {
        for (let i = 0; i < filterFieldOwner.length; i++) {
          let fieldListArray = filterFieldOwner[i];
          const polygonsField = axios.get(
            `https://agrodigital.io/api/polygons/?field=${fieldListArray.id}`,
            this.state.tokenUser
          );
          Promise.all([polygonsField]).then((res) => {
            let polygonsFieldData = res[0].data;
            let polygonData = [];
            fieldListArray.polygons.forEach((polId) => {
              let findPolId = polygonsFieldData.find((pol) => pol.id === polId);
              if (findPolId) {
                polygonData.push(findPolId);
              }
            });
            let fieldWithPolygonInfo = { ...fieldListArray, polygonData };
            this.setState((prevState) => ({
              fieldList: [...prevState.fieldList, fieldWithPolygonInfo],
            }));
          });
        }
        this.setState({
          listPolygonsReut: allPolygons?.data,
        });
        this.centerLocationUser(userInfo.data);
      } else {
        this.setState({
          listPolygonsReut: allPolygons?.data,
        });
        this.centerLocationUser(userInfo.data);
      }
    };

    if (!localStorage.getItem("token")) {
      createBrowserHistory().push("/auth/login");
    } else {
      this.setState(
        {
          tokenUser: {
            headers: {
              // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          },
          fieldsUrl: `fields`,
        },
        () => {
          fetch();
        }
      );
    }
  }
  centerLocationUser = async (userInfo) => {
    let countryInfo = await axios
      .get(`https://restcountries.com/v3.1/alpha/${userInfo.country}`)
      .then((res) => {
        let countryData = res.data;

        let latLong = {
          lat: countryData[0].latlng[0],
          lng: countryData[0].latlng[1],
        };

        this.setState({
          centerLocation: latLong,
        });
      });

    // let vm = this;
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   let resultLatLong = {
    //     lat: Number(position.coords.latitude.toFixed()),
    //     lng: Number(position.coords.longitude.toFixed()),
    //   };
    //   vm.setState({
    //     centerLocation: resultLatLong,
    //   });
    // });
  };
  collapsesToggle = (collapse) => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter((item) => item !== collapse),
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses,
      });
    }
  };

  onSetPolygonName = () => {
    let tempPolygonInfo = {
      name: this.state.polygonName,
      coordinates: this.state.coordinates,
    };

    this.onToggle();
    this.props.setDataWizard("polygon", [...this.state.polygonsArr, tempPolygonInfo])
    this.setState(
      (prevState) => ({
        mapShow: false,
        polygonsArr: [...prevState.polygonsArr, tempPolygonInfo],
      }),
      () => {
        this.setState({
          coordinates: [],
          polygonName: "",
          mapShow: true,
        });
      }
    );
  };

  onAddPolygon = () => {
    this.setState({
      onlyFirst: false,
    });

    if (this.state.mapShow === false) {
      setTimeout(() => {
        this.setState({
          mapShow: true,
        });
      }, 3000);
    }

    if (this.state.polygonStatus === false) {
      this.setState({
        polygonStatus: true,
      });
    }
  };

  leftSide = () =>
    this.state.polygonsArr.map((item, index) => {
      return (
        <Card className="card-plain" key={index}>
          <CardHeader role="tab">{item.name}</CardHeader>
        </Card>
      );
    });

  onAddKmlPolygon = (polygonInfo) => {
    let kmlPolygonData = this.state.kmlPolygonData;

    let bOk = true;
    for (let i = 0; i < kmlPolygonData.length; i++) {
      if (kmlPolygonData[i].fileName === polygonInfo.fileName) {
        bOk = false;
        break;
      }
    }

    if (bOk === true) {
      kmlPolygonData.push(polygonInfo);
      this.setState({
        kmlPolygonData: kmlPolygonData,
      });
    }
  };

  onRemoveKmlPolygon = (fileName) => {
    let kmlPolygonData = this.state.kmlPolygonData;

    const index = kmlPolygonData.findIndex((e) => e.fileName === fileName);

    kmlPolygonData.splice(index, 1);

    this.setState({
      kmlPolygonData: kmlPolygonData,
    });
  };

  onToggle = () => {
    this.setState({
      showPolygonNameModal: !this.state.showPolygonNameModal,
    });
  };

  onKmlToggle = () => {
    this.setState({
      showKmlModal: !this.state.showKmlModal,
    });
  };

  onDrawKmlData = () => {
    let kmlPolygonData = this.state.kmlPolygonData;
    let currentPolygonArr = this.state.polygonsArr;
    kmlPolygonData.map((item, index) => {
      let polygons = item.polygons;

      polygons.map((subItem, subIndex) => {
        let tempCoords = subItem.coords;
        let coords = [];
        let name = subItem.name;

        tempCoords.map((coord) => {
          let tempArr = [];
          tempArr[0] = coord.lng;
          tempArr[1] = coord.lat;

          coords.push(tempArr);
        });

        let tempPolygonInfo = {
          name: "",
          coordinates: [],
        };
        tempPolygonInfo.name = name;
        tempPolygonInfo.coordinates[0] = coords;

        currentPolygonArr.push(tempPolygonInfo);
      });
    });
    this.props.setDataWizard("polygon", currentPolygonArr)
    this.setState(
      () => ({
        mapShow: false,
        polygonsArr: currentPolygonArr,
      }),
      () => {
        this.setState({
          coordinates: [],
          showKmlModal: false,
          polygonName: "",
          kmlPolygonData: [],
          mapShow: true,
        });
      }
    );
  };

  onEditChange = (geoJson) => {
    if (geoJson.features) {
      let tempGeoJson = [];

      for (let i = 0; i < geoJson.features.length; i++) {
        let temp = geoJson.features[i].geometry;

        if (temp.type === "Polygon") {
          tempGeoJson.push(temp.coordinates[0]);
        }
      }

      this.setState(
        {
          coordinates: tempGeoJson,
        },
        () => {
          if (this.state.showPolygonNameModal === false) {
            this.setState({
              showPolygonNameModal: true,
            });
          }
        }
      );
    }
  };

  isValidated = () => {
    if (this.state.polygonsArr.length < 1) {
      this.setState({
        polygonStatus: false,
      });
      return false;
    }

    return true;
  };

  render() {
    const {t}= this.props
    const polygonSettingModal = (
      <Modal
        isOpen={this.state.showPolygonNameModal}
        toggle={() => this.onToggle()}
        backdrop={false}
        keyboard={false}
      >
        <div className="modal-header justify-content-center">
          <h4 className="title title-up">{t("globals.insertPolygonName")}</h4>
        </div>
        <div className="modal-body">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-air-baloon" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={t("globals.polygonName")}
              type="text"
              onChange={(e) =>
                this.setState({
                  polygonName: e.target.value,
                })
              }
            />
          </InputGroup>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => this.onToggle()}
            >
              {t("buttons.cancelButton")}
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className="btn-link"
              color="primary"
              type="button"
              onClick={() => {
                this.onSetPolygonName();
              }}
            >
              {t("buttons.saveButton")}
            </Button>
          </div>
        </div>
      </Modal>
    );

    const polygonKmlModal = (
      <Modal
        isOpen={this.state.showKmlModal}
        toggle={() => this.onKmlToggle()}
        backdrop={false}
        keyboard={false}
      >
        <div className="modal-header justify-content-center">
          <h4 className="title title-up">{t("globals.importPolygonKml")}</h4>
        </div>
        <div className="modal-body">
          <Dropzone
            onAddKmlPolygon={this.onAddKmlPolygon}
            onRemoveKmlPolygon={this.onRemoveKmlPolygon}
          />
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => this.onKmlToggle()}
            >
              {t("buttons.cancelButton")}
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className={
                this.state.kmlPolygonData.length > 0
                  ? "btn-link"
                  : "btn-link disabled"
              }
              color="primary"
              type="button"
              onClick={() => {
                this.onDrawKmlData();
              }}
            >
              {t("buttons.importButton")}
            </Button>
          </div>
        </div>
      </Modal>
    );

    return (
      <>
        {!this.state.reutilizarPol ? (
          <Row className="justify-content-center">
            {polygonSettingModal}
            {polygonKmlModal}
            <Col sm={this.state.onlyFirst === false ? 3 : 8}>
              <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                style={{
                  paddingRight: "20px",
                  height: this.state.mapShow === false ? "220px" : "240px",
                  overflow: "auto",
                }}
                role="tablist"
              >
                {this.leftSide()}
                {this.state.polygonStatus === false ? (
                  <label style={{ color: "#ff0000" }}>{t("globals.newsPolygons")}</label>
                ) : null}
              </div>
              <div style={{ display: this.state.onlyFirst === true && "none" }}>
                <Button
                  color="info"
                  style={{ width: "100%" }}
                  onClick={() => {
                    this.setState({
                      reutilizarPol: true,
                    });
                  }}
                >
                  <span className="btn-label">
                    <i className="nc-icon nc-simple-add" />
                  </span>
                  {t("globals.reUsePolygon")}
                </Button>
              </div>
              <div style={{ display: this.state.onlyFirst === true && "none" }}>
                <Button
                  color="info"
                  style={{ width: "100%" }}
                  onClick={() => this.onKmlToggle()}
                >
                  <span className="btn-label">
                    <i className="nc-icon nc-simple-add" />
                  </span>
                  {t("buttons.importKmlButton")}
                </Button>
              </div>

              {this.state.mapShow === false && this.state.onlyFirst === true && (
                <Button
                  color="info"
                  style={{ width: "100%" }}
                  onClick={() => {
                    this.onAddPolygon();
                  }}
                >
                  <span className="btn-label">
                    <i className="nc-icon nc-simple-add" />
                  </span>
                  {t("buttons.addPolygonButton")}
                </Button>
              )}
            </Col>
            <Col sm="9">
              <LoadingOverlay
                active={!this.state.mapShow}
                spinner
                text={t("globals.loading")}
              >
                {this.state.mapShow === true ? (
                  <EditCtrl
                    mapShow={this.state.mapShow}
                    center={this.state.centerLocation}
                    zoom={12}
                    initGeoJson={this.state.initGeoJson}
                    onChange={this.onEditChange}
                    bounds={this.getBounds()}
                    fieldList={this.state.fieldList}
                    backPolygonsColor={backPolygonsColor}
                    createdList={this.state.polygonsArr}
                    createdPolygonsColor={createdPolygonsColor}
                    drawingPolygonsColor={drawingPolygonsColor}
                  />
                ) : (
                  <div style={{ width: "100%", height: "100%" }}></div>
                )}
              </LoadingOverlay>
            </Col>
          </Row>
        ) : (
          <>
            <ReutilizarPol
              selectedReutPolygon={this.selectedReutPolygon}
              isOpen={this.state.reutilizarPol}
              center={this.state.centerLocation}
              zoom={12}
              initGeoJson={this.state.initGeoJson}
              onChange={this.onEditChange}
              bounds={this.getBounds()}
              fieldList={this.state.fieldList}
              listPolygonsReut={this.state.listPolygonsReut}
              createdList={this.state.polygonsArr}
            />

            <Button
              style={{ float: "right" }}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ reutilizarPol: false });
              }}
            >
              {t("buttons.readyButton")}
            </Button>
          </>
        )}
      </>
    );
  }
}

export default Wizard;
